import { SHARED_UTILS } from '@shared-utils/utils';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import { apiClient } from 'web/apis/instances/api-client';
import { useLanguageList } from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingSending/components/ChattingSendingBottom/hooks/use-language-list';
import { type ChatRoomID, chatRoomIDAtom } from 'web/templates/CustomerChat/states/chatRoomID';
import { hserviceIDValueState } from '../../../states/hserviceID-value';

const getChatRoomInfo = async (chatRoomID: ChatRoomID) => {
  if (!chatRoomID) return;
  const { data } = await apiClient.v3.apiChatroomElFindOne({
    chatRoomID: chatRoomID,
  });
  return SHARED_UTILS.api.checkApiResponse(data);
};

export const useChatRoomInfoOne = () => {
  const chatRoomID = useAtomValue(chatRoomIDAtom);
  const setHserviceIDValue = useSetAtom(hserviceIDValueState);

  const { data, isLoading, refetch } = useQuery({
    queryKey: ['chatRoomInfo', chatRoomID],
    queryFn: () => getChatRoomInfo(chatRoomID),
    enabled: !!chatRoomID,
  });

  const { data: languageList } = useLanguageList();

  useEffect(() => {
    if (chatRoomID) {
      refetch();
    }
  }, [chatRoomID, refetch]);

  useEffect(() => {
    if (data) {
      setHserviceIDValue(data.hserviceID?._id);
    }
  }, [data, setHserviceIDValue]);

  const { usingTranslation: isUsingTranslation, counselors, users, languageID } = data || {};

  const languageCodeISOAlpha2 = languageList.find(
    (item) => item?.languageCodeISOAlpha2 === languageID?.languageCodeISOAlpha2,
  )?.languageCodeISOAlpha2;

  return {
    isUsingTranslation,
    participants: users ?? [],
    counselors: counselors ?? [],
    languageCodeISOAlpha2,
    isLoading,
    refetch,
  };
};
