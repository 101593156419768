import ParentDropdown from '@templates/CustomerChat/components/ParentDropDown/ParentDropdown';
import { useDropdown } from '@templates/CustomerChat/components/ParentDropDown/hooks/use-dropdown';
import { useDropdownData } from '@templates/CustomerChat/components/ParentDropDown/hooks/use-dropdown-data';
import BookmarkToggle from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/components/ChattingMessage/components/BookmarkToggle/BookmarkToggle';
import { getRedTextIndices } from 'web/templates/CustomerChat/components/ParentDropDown/functions/get-red-text-indeices';
import { useChatMessageContext } from '../../ChatMessageContext';
import Message from '../../Message/Message';
import MessageTimestamp from '../../MessageTimestamp';

export default function LeftMessage() {
  const { userName, createdAt } = useChatMessageContext();
  const { data, isAuthoriztionControlPatientChatting } = useDropdownData();
  const { isDropdownOpen, handleDropdown } = useDropdown();

  const redTextIndices = getRedTextIndices(data, isAuthoriztionControlPatientChatting ?? false);

  return (
    <div className='group relative ml-20 flex flex-col'>
      <div
        className='mb-8 cursor-pointer text-left text-Header12 text-black200'
        onClick={handleDropdown}>
        {userName}
      </div>
      {isDropdownOpen && (
        <ParentDropdown
          options={data}
          redTextIndices={redTextIndices}
          handleToggle={handleDropdown}
        />
      )}
      <div className='flex flex-row items-end gap-8'>
        <Message />
        <div className='flex flex-col items-start justify-end'>
          <BookmarkToggle isRightMessage={false} />
          {<MessageTimestamp createdAt={createdAt} />}
        </div>
      </div>
    </div>
  );
}
