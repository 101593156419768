import type {
  FetchNextPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
} from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { debounce } from 'lodash-es';
import { type MutableRefObject, useEffect, useLayoutEffect, useState } from 'react';
import type { ChatFileAndContentIDs } from 'web/apis/swaggers/swagger-docs';
import { selectedMessageIdState } from 'web/templates/CustomerChat/components/SupportBot/states/selected-message-id';
import type { DataResponse } from '../../../hooks/use-conditional-chat-data';

// Define the props for useScrollObserver
type UseBottomScrollObserverProps = {
  fetchNextPage: (
    options?: FetchNextPageOptions,
  ) => Promise<
    InfiniteQueryObserverResult<InfiniteData<ChatFileAndContentIDs[], unknown>, Error>
  > | void;
  isLoading: boolean;
  chatContainerRef: MutableRefObject<HTMLDivElement | null>;
  loadNextMoreRef: MutableRefObject<HTMLDivElement | null>;
  setPreviousScrollHeight: React.Dispatch<React.SetStateAction<number>>;
  hasNextPage: boolean | null;
  isFetchingNextPage: boolean | null;
  previousScrollHeight: number;
  messages: DataResponse[];
};

//아래의 부분은 .withDebounce를 사용하기 위한 실험적 Function prototype 확장
declare global {
  interface Function {
    withDebounce(options: { wait: number }): Function;
    withSetTimeout(delay: number): void;
  }
}

const extendFunctionPrototypeForDebounce = () => {
  if (!Function.prototype.withDebounce) {
    Function.prototype.withDebounce = function ({ wait = 1000 }) {
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      return debounce((...args: any[]) => {
        return this.apply(this, args);
      }, wait);
    };
  }
  if (!Function.prototype.withSetTimeout) {
    Function.prototype.withSetTimeout = function (delay) {
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      return setTimeout((...args: any[]) => {
        this.apply(this, args);
      }, delay);
    };
  }
};

extendFunctionPrototypeForDebounce();

export const useBottomScrollObserver = ({
  hasNextPage,
  isFetchingNextPage,
  fetchNextPage,
  isLoading,
  chatContainerRef,
  loadNextMoreRef,
  setPreviousScrollHeight,
  previousScrollHeight,
  messages,
}: UseBottomScrollObserverProps) => {
  const selectedMessageId = useAtomValue(selectedMessageIdState);

  const [isBottomButtonVisible, setIsBottomButtonVisible] = useState(false);

  useEffect(() => {
    const observer = new IntersectionObserver(
      async (entries) => {
        for (const entry of entries) {
          const container = chatContainerRef.current;
          if (container) {
            if (entry.target === loadNextMoreRef.current) {
              if (entry.isIntersecting) {
                setIsBottomButtonVisible(false);

                setPreviousScrollHeight(container.scrollHeight);

                selectedMessageId && (await fetchNextPage.withDebounce({ wait: 1000 })());
              } else {
                setIsBottomButtonVisible(true);
              }
            }
          }
        }
      },
      {
        root: chatContainerRef.current,
        rootMargin: '5px',
        threshold: 0.9,
      },
    );

    if (loadNextMoreRef.current) {
      observer.observe(loadNextMoreRef.current);
    }

    return () => {
      if (loadNextMoreRef.current) {
        observer.unobserve(loadNextMoreRef.current);
      }
    };
  }, [isLoading, chatContainerRef.current, loadNextMoreRef.current, setPreviousScrollHeight]);

  useLayoutEffect(() => {
    const container = chatContainerRef.current;

    if (container && messages) {
      requestAnimationFrame(() => {
        container.scrollTop = previousScrollHeight - container.scrollHeight;
      });
    }
  }, [isFetchingNextPage, chatContainerRef.current]);

  return { isBottomButtonVisible };
};
