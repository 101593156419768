import type { ManusFolders } from '../../../hooks/use-manus-folders';

export const findFirstOpenIndex = (newData: ManusFolders[]): number => {
  let indexCount = 0;

  for (let i = 0; i < newData.length; i++) {
    const folder = newData[i];

    // Case 1: Check if the main folder/item has isOpen set to true
    if (folder.isOpen) {
      return indexCount;
    }

    // Increment index count for the folder itself
    indexCount++;

    // Case 2: Check if any of the items inside the folder have isOpen set to true
    if (folder.items && folder.items.length > 0) {
      for (let j = 0; j < folder.items.length; j++) {
        const item = folder.items[j];
        if (item.isOpen) {
          return indexCount;
        }
        // Increment index count for each item inside the folder
        indexCount++;
      }
    }
  }

  return 0;
};
