import Divider from '@afterdoc-design-system/components/Atoms/Divider/Divider';
import LabelText from '@afterdoc-design-system/components/Atoms/LabelText/LabelText';
import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import dayjs from 'dayjs';
import type { V2Reservation } from 'web/apis/swaggers/swagger-docs';

export const CustomerReservationCell = ({ reservation }: { reservation: V2Reservation }) => {
  const getDate = (date?: string) => {
    return dayjs(date).format('M월 D일 (ddd) A H:mm');
  };

  const getReservationDurationAndNote = (reservation: V2Reservation) => {
    return `${reservation.duration}분 ${reservation.note ? ',' : ''} ${reservation.note ?? ''}`;
  };

  // MARK: - Actions
  const handleClick = () => {
    window?.electron?.ipcRenderer.send('Application.showReservationWindow', { value: reservation });
  };

  // MARK: - Template
  return (
    <div
      onClick={() => handleClick()}
      style={{ height: 75 }}
      className='mr-26 mb-10 cursor-pointer rounded-r16 border border-white500 p-15 pb-10'>
      <div className='mb-4 flex justify-between'>
        <div className='flex items-end'>
          <Icon
            className='mr-10'
            name={reservation.isCanceled ? 'calendar-remove-outline' : 'calendar-check-outline'}
            size={20}
            color={reservation.isCanceled ? 'red500' : 'blue500'}
          />
          <LabelText textClassName='text-Header14 text-black700'>
            {getDate(reservation.startedAt)}
          </LabelText>
        </div>
        <div className='center'>
          <span
            style={{ maxWidth: '200px' }}
            className='truncate text-Body12'
            title={reservation.programTitle}>
            {reservation.programTitle}
          </span>
        </div>
      </div>
      <div className='flex justify-between'>
        <div className='flex min-w-0 flex-1 items-end'>
          <span
            style={{ maxWidth: '200px' }}
            className='truncate text-Body12'
            title={getReservationDurationAndNote(reservation)}>
            {getReservationDurationAndNote(reservation)}
          </span>
        </div>
        <div className='center flex items-center pl-8'>
          <span
            style={{ maxWidth: '60px' }}
            className={customTwMerge('text-Body10Bold', 'truncate')}
            title={reservation.groupTitle}>
            {reservation.groupTitle}
          </span>
          <Divider type={'line'} direction={'vertical'} className='mx-10' height={18} />
          <span
            style={{ maxWidth: '60px' }}
            className={customTwMerge('text-Body10Bold', 'truncate')}
            title={reservation.columnTitle}>
            {reservation.columnTitle}
          </span>
        </div>
      </div>
    </div>
  );
};
