import IconButton from '@afterdoc-design-system/components/Atoms/Button/IconButton';
import DayPicker from '@afterdoc-design-system/components/Atoms/Calendar/DayPicker';
import TextInput from '@afterdoc-design-system/components/Atoms/Input/TextInput';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import dayjs from 'dayjs';
import { useSetAtom } from 'jotai';
import { debounce } from 'lodash-es';
import { useCallback, useEffect, useRef, useState } from 'react';
import { reservationSelectedDateState } from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/AfterDoc/ReservationHistory/states/date';

const YEAR_RANGE = [1900, 2500] as [number, number];

const calculateYearIndex = (year: number, yearRange: [number, number]) => {
  if (yearRange[0] < yearRange[1]) {
    return year - yearRange[0];
  }
  return yearRange[0] - year;
};

const formatDateWithHyphens = (dateStr: string) => {
  const cleaned = dateStr.replace(/[^0-9]/g, '');
  if (cleaned.length <= 4) return cleaned;
  if (cleaned.length <= 6) return `${cleaned.slice(0, 4)}-${cleaned.slice(4)}`;
  return `${cleaned.slice(0, 4)}-${cleaned.slice(4, 6)}-${cleaned.slice(6)}`;
};

const isValidDate = (year: number, month: number, day: number) => {
  if (year < 1900 || year > 9999) return false;
  if (month < 1 || month > 12) return false;
  const daysInMonth = new Date(year, month, 0).getDate();
  if (day < 1 || day > daysInMonth) return false;
  return true;
};

interface DaySelectorProps {
  className?: string;
  width?: string | number;
  selectedDate?: Date;
  onSelect: (date: Date) => void;
  handleInput?: (value: string) => void;
  hasDayChangeButton?: boolean;
  disableBeforeDate?: Date;
  disableAfterDate?: Date;
  yearRange?: [number, number];
}

export default function DaySelector({
  selectedDate,
  onSelect,
  handleInput,
  className,
  width,
  hasDayChangeButton = true,
  disableBeforeDate,
  disableAfterDate,
  yearRange,
}: DaySelectorProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  const setReservationSelectedDate = useSetAtom(reservationSelectedDateState);
  const [isValid, setIsValid] = useState(true);
  const [date, setDate] = useState(new Date());
  const [isToggle, setIsToggle] = useState(false);
  const [inputValue, setInputValue] = useState(dayjs(date).format('YYYY-MM-DD'));

  const isDateDisabled = (day: Date) => {
    const dayjsDate = dayjs(day);
    if (disableBeforeDate && dayjsDate.isBefore(dayjs(disableBeforeDate), 'day')) {
      return true;
    }
    if (disableAfterDate && dayjsDate.isAfter(dayjs(disableAfterDate), 'day')) {
      return true;
    }
    return false;
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter' || e.key === 'Escape') {
      setIsToggle(!isToggle);
      inputRef.current?.blur();
    }
  };

  const handleInputDebounced = useCallback(
    debounce((formattedInput: string) => {
      handleInput?.(formattedInput);
    }, 200),
    [handleInput],
  );

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (!isToggle) {
      setIsToggle(true);
    }

    const input = e.target.value;

    if (/[^0-9-]/.test(input)) {
      return;
    }

    const formattedInput = formatDateWithHyphens(input);
    setInputValue(formattedInput);

    if (formattedInput.length === 10) {
      const [year, month, day] = formattedInput.split('-').map(Number);
      if (isValidDate(year, month, day)) {
        setIsValid(true);
        setDate(new Date(year, month - 1, day));
        onSelect(new Date(year, month - 1, day));
      } else {
        setIsValid(false);
      }
    } else {
      setIsValid(false);
    }

    handleInputDebounced(formattedInput);
  };

  useEffect(() => {
    if (!selectedDate) return;

    setDate(selectedDate);
    setInputValue(dayjs(selectedDate).format('YYYY-MM-DD'));
    setReservationSelectedDate({
      startedAt: selectedDate,
      endedAt: selectedDate,
    });
  }, [selectedDate]);

  return (
    <div className='flex items-center gap-10'>
      {hasDayChangeButton && (
        <IconButton
          icon='chevron-left'
          size={16}
          color='black500'
          className='h-24 w-24 rounded-r6 border border-white400 p-3'
          onClick={() => {
            const newDate = dayjs(date).subtract(1, 'day').toDate();
            if (!isDateDisabled(newDate)) {
              setDate(newDate);
              setInputValue(dayjs(newDate).format('YYYY-MM-DD'));
            }
          }}
        />
      )}
      <div className='relative'>
        <TextInput
          ref={inputRef}
          onKeyDown={handleKeyDown}
          value={inputValue}
          onChange={handleInputChange}
          onClick={() => {
            setIsToggle(!isToggle);
          }}
          maxLength={10}
          className={isValid ? '' : 'input-error'}
          width={width}
        />
        {isToggle && (
          <DayPicker
            hasError={!isValid}
            className={customTwMerge('absolute z-10', className)}
            onChangeDate={(selectedDate) => {
              if (!selectedDate) return;

              setDate(selectedDate);
              setInputValue(dayjs(selectedDate).format('YYYY-MM-DD'));
              setIsToggle(false);

              onSelect(selectedDate);
            }}
            selectedDate={date}
            handleToggle={() => setIsToggle(false)}
            yearRange={yearRange ?? YEAR_RANGE}
            disableBeforeDate={disableBeforeDate}
            disableAfterDate={disableAfterDate}
          />
        )}
      </div>
      {hasDayChangeButton && (
        <IconButton
          icon='chevron-right'
          size={16}
          color='black500'
          className='h-24 w-24 rounded-r6 border border-white400 p-3'
          onClick={() => {
            const newDate = dayjs(date).add(1, 'day').toDate();
            if (!isDateDisabled(newDate)) {
              setDate(newDate);
              setInputValue(dayjs(newDate).format('YYYY-MM-DD'));
            }
          }}
        />
      )}
    </div>
  );
}
