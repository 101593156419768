import { SHARED_UTILS } from '@shared-utils/utils';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { useMemo } from 'react';
import { apiClient } from 'web/apis/instances/api-client';
import type { ApiChatroomElFindParams } from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import type { TempChatRoomHServiceLastMessageUser } from 'web/templates/CustomerChat/components/ChattingList/hooks/use-chattinglist';
import { chatRoomPatientIDState } from 'web/templates/CustomerChat/states/chat-room-patient';

const getChatRoom = async (query: ApiChatroomElFindParams) => {
  const { data } = await apiClient.v3.apiChatroomElFind(query);
  return SHARED_UTILS.api.checkApiResponse(data) as TempChatRoomHServiceLastMessageUser[];
};

const defaultChattingListQuery = {
  key: 'createdAt',
  way: -1,
  limit: 10,
};

export const useChatRoomPatientUnreadCount = () => {
  const { hospitalID } = useSelectedHospitalInfo();

  const patientID = useAtomValue(chatRoomPatientIDState);

  const chattingListQuery = {
    ...defaultChattingListQuery,
    ...{ hospitalID },
  };

  const {
    data: chatRoomHServiceLastMessageUsers = [],
    isRefetching,
    refetch,
  } = useQuery({
    queryKey: ['chattingRoomUnreadCount', { hospitalID, patientID }],
    queryFn: () => getChatRoom(chattingListQuery),
  });
  const chatRoomPatientUnreadCount = useMemo(() => {
    const { info: infos } = chatRoomHServiceLastMessageUsers.find(
      ({ userID }) => userID._id === patientID,
    ) ?? { info: [] };

    const patientInfo = infos.find((info) => info.userID === patientID);
    return patientInfo?.count ?? 0;
  }, [chatRoomHServiceLastMessageUsers, patientID]);

  return { chatRoomPatientUnreadCount, refetch, isRefetching };
};
