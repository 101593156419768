import dayjs from 'dayjs';
import { useEffect } from 'react';
import type { V2Reservation } from 'web/apis/swaggers/swagger-docs';

type Payload<T> = {
  value: T;
};

export const useReservationListener = (setText: React.Dispatch<React.SetStateAction<string>>) => {
  useEffect(() => {
    const handleCreateReservationDone = (
      _: Record<string, never>,
      payload: Payload<V2Reservation>,
    ) => {
      const reservation = payload.value;
      const startDateString = dayjs(reservation.startedAt).format('M/DD(ddd) a H:mm');
      const message = `${startDateString} 예약이 등록되었습니다.`;
      setText(message);
    };

    window.electron?.ipcRenderer.on(
      'Application.doneCreateReservation',
      handleCreateReservationDone,
    );

    return () => {
      window.electron?.ipcRenderer.removeListener(
        'Application.doneCreateReservation',
        handleCreateReservationDone,
      );
    };
  }, [setText]);
};
