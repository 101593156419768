import { SHARED_UTILS } from '@shared-utils/utils';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type {
  ApiAutomationsElAlgorithmsMessagesParams,
  SingleAlgorithmHandlerParams,
} from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import type { MarketingAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/components/RegisterMarketingAutomationDialogContent';
import {
  selectedContentTabIndexState,
  selectedMessageState,
} from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/message-form';
import { messageModeState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/message-mode';
import { originMessagesState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/origin-message';

const fetchSingleAlgorithmInfo = async (params: SingleAlgorithmHandlerParams) => {
  const response = await apiClient.v3.singleAlgorithmHandler(params);
  return response.data.data;
};

const fetchAutomationsElAlgorithmsMessages = async (
  params: ApiAutomationsElAlgorithmsMessagesParams,
) => {
  const response = await apiClient.v3.apiAutomationsElAlgorithmsMessages(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export const useAlgorithmData = () => {
  const { setValue, getValues } = useFormContext<MarketingAutomationAPIFormValues>();
  const { hospitalID } = useSelectedHospitalInfo();

  const setSelectedContentTabIndex = useSetAtom(selectedContentTabIndexState);
  const setOriginMessages = useSetAtom(originMessagesState);
  const setSelectedMessage = useSetAtom(selectedMessageState);
  const messageMode = useAtomValue(messageModeState);

  const { data: algorithmData } = useQuery({
    queryKey: [
      'singleAlgorithm',
      {
        algorithmId: messageMode.mode !== 'CREATE' ? messageMode.algorithmId : '',
        hospitalID,
      },
    ] as const,
    enabled: messageMode.mode !== 'CREATE' && !!messageMode.algorithmId,
    queryFn: ({ queryKey }) => {
      const algorithmId = queryKey[1].algorithmId;
      if (!algorithmId) return;

      return fetchSingleAlgorithmInfo({
        algorithmId,
        hospitalID,
      });
    },
  });

  const { data: algorithmMessagesData } = useQuery({
    queryKey: [
      QUERY_KEY.apiAutomationsElAlgorithmsMessages,
      {
        algorithmId: messageMode.mode !== 'CREATE' ? messageMode.algorithmId : '',
        hospitalID,
      },
    ] as const,
    queryFn: ({ queryKey }) => {
      if (messageMode.mode !== 'CREATE') {
        return fetchAutomationsElAlgorithmsMessages(queryKey[1]);
      }
    },
    enabled: messageMode.mode !== 'CREATE',
  });

  const messagesData: MarketingAutomationAPIFormValues['messages'] = useMemo(
    () =>
      algorithmMessagesData?.map(({ id, name, content, sendingDateTime }) => {
        return {
          id,
          name,
          sendingType: 'BASIC' as const,
          sendingDateTime,
          content: {
            text: content?.text,
            image: JSON.stringify(
              content?.images?.map((image, index) => ({
                id: index.toString(),
                url: image,
              })),
            ),
          },
        };
      }) || [],
    [algorithmMessagesData],
  );

  useEffect(() => {
    if (messageMode.mode === 'EDIT' && algorithmMessagesData && messagesData && algorithmData) {
      const handleContentTabIndex = () => {
        const newContentTabIndex: {
          [key: number]: number;
        } = {};

        messagesData.forEach((message, index) => {
          if (message.content.image && message.content.image.length > 0) {
            newContentTabIndex[index] = 1;
          }
        });

        setSelectedContentTabIndex((prev) => ({
          ...prev,
          ...newContentTabIndex,
        }));
      };
      handleContentTabIndex();

      const {
        name,
        tagsHaveFolder,
        tagsHaveNoFolder,
        sendingSchedule,
        appliedNationalities,
        isForNoTreatmentTags,
        isForNoNationality,
      } = algorithmData;

      // Set Base Data
      if (!getValues('name').length) {
        setValue('name', name);
      }

      if (!getValues('isForNoTreatmentTags') && isForNoTreatmentTags) {
        setValue('isForNoTreatmentTags', true);
      }

      if (!getValues('isForNoNationality') && isForNoNationality) {
        setValue('isForNoNationality', true);
      }

      if (!getValues('targetTreatmentTags')?.length) {
        setValue('targetTreatmentTags', [
          ...tagsHaveFolder.flatMap(({ tags }) => tags.map((tag) => tag._id)),
          ...tagsHaveNoFolder.map((tag) => tag._id),
        ]);
      }

      if (!getValues('toBeAppliedNationalityIds')?.length) {
        setValue(
          'toBeAppliedNationalityIds',
          appliedNationalities.map((appliedNationality) => appliedNationality._id),
        );
      }

      if (
        !dayjs(getValues('sendingSchedule')?.startDay).isSame(
          dayjs().hour(8).minute(0).second(0).millisecond(0),
        ) &&
        getValues('sendingSchedule')?.sendingCount !== 1
      ) {
        setValue('sendingSchedule', {
          startDay:
            sendingSchedule?.startDay ??
            dayjs().hour(8).minute(0).second(0).millisecond(0).toISOString(),
          sendingCount: sendingSchedule?.sendingCount ?? 1,
        });
      }

      // Set messages
      setOriginMessages(algorithmMessagesData);
      if (!getValues('messages').length) {
        setValue('messages', messagesData);
      }
      setSelectedMessage({
        ...messagesData[0],
        index: 0,
      });
      return;
    }

    if (messageMode.mode === 'COPY' && algorithmData && messagesData && algorithmMessagesData) {
      const {
        name,
        tagsHaveFolder,
        tagsHaveNoFolder,
        sendingSchedule,
        appliedNationalities,
        isForNoTreatmentTags,
        isForNoNationality,
      } = algorithmData;

      console.log('sendingSchedule', sendingSchedule);

      // Set Base Data
      if (!getValues('name').length) {
        setValue('name', name);
      }

      if (!getValues('isForNoTreatmentTags') && isForNoTreatmentTags) {
        setValue('isForNoTreatmentTags', true);
      }

      if (!getValues('isForNoNationality') && isForNoNationality) {
        setValue('isForNoNationality', true);
      }

      if (!getValues('targetTreatmentTags')?.length) {
        setValue('targetTreatmentTags', [
          ...tagsHaveFolder.flatMap(({ tags }) => tags.map((tag) => tag._id)),
          ...tagsHaveNoFolder.map((tag) => tag._id),
        ]);
      }

      if (!getValues('toBeAppliedNationalityIds')?.length) {
        setValue(
          'toBeAppliedNationalityIds',
          appliedNationalities.map((appliedNationality) => appliedNationality._id),
        );
      }

      if (
        !dayjs(getValues('sendingSchedule')?.startDay).isSame(
          dayjs().hour(8).minute(0).second(0).millisecond(0),
        ) &&
        getValues('sendingSchedule')?.sendingCount !== 1
      ) {
        setValue('sendingSchedule', {
          startDay: sendingSchedule?.startDay ?? dayjs().toISOString(),
          sendingCount: sendingSchedule?.sendingCount ?? 1,
        });
      }

      // Set messages
      setOriginMessages(algorithmMessagesData);

      if (!getValues('messages').length) {
        setValue('messages', messagesData);
      }
      setSelectedMessage({
        ...messagesData[0],
        index: 0,
      });
    }
  }, [algorithmData, messagesData, messageMode.mode]);
};
