import type { AlgorithmWithMessagesInput } from 'web/apis/swaggers/swagger-docs';
import LayoutBox from 'web/shared/components/LayoutBox/LayoutBox';
import SuspenseWithLoading from 'web/shared/components/SuspenseWithLoading/SuspenseWithLoading';
import AutomationZoomInOutViewerContent from 'web/templates/Automation/containers/Viewer/AutomationZoomInOutViewer/components/AutomationZoomInOutViewerContent';
import AutomationZoomInOutViewerHeader from 'web/templates/Automation/containers/Viewer/AutomationZoomInOutViewer/components/AutomationZoomInOutViewerHeader';

export interface AutomationZoomInOutViewerContainerProps {
  algorithmId: string;
  type: Exclude<AlgorithmWithMessagesInput['type'], 'COMMON'>;
  onClickEditButton: () => void;
}

export default function AutomationZoomInOutViewerContainer({
  algorithmId,
  type,
  onClickEditButton,
}: AutomationZoomInOutViewerContainerProps) {
  return (
    <>
      {/* 조회 헤더 */}
      <LayoutBox noStyle>
        <AutomationZoomInOutViewerHeader type={type} />
      </LayoutBox>

      {/* 조회 컨텐츠 */}
      <SuspenseWithLoading>
        <AutomationZoomInOutViewerContent
          algorithmId={algorithmId}
          onClickEditButton={onClickEditButton}
          type={type}
        />
      </SuspenseWithLoading>
    </>
  );
}
