import EmojiPicker from 'emoji-picker-react';
import { useAtomValue } from 'jotai';
import { useRef } from 'react';
import { isEmptyWithTrim } from 'web/templates/CustomerChat/functions/isEmptyWithTrim';
import { isActivatedChattingState } from 'web/templates/CustomerChat/states/is-activated-chatting';
import ChatTextArea from './components/ChatTextArea/ChatTextArea';
import ChattingSendingBottom from './components/ChattingSendingBottom/ChattingSendingBottom';
import { useChatInput } from './hooks/use-chat-input';
import { useEmojiPicker } from './hooks/use-emoji-picker';
import { useReservationListener } from './hooks/use-reservation-listener';

export default function ChattingSending() {
  const isActivatedChatting = useAtomValue(isActivatedChattingState);
  const placeholder = isActivatedChatting
    ? '메시지를 입력해주세요'
    : '상담 종료한 채팅방입니다.\n상담을 진행하시려면 메시지를 입력해주세요.';
  const { text, setText, handleChange, handleSend } = useChatInput();
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  useReservationListener(setText);

  const { showEmojiPicker, emojiPickerPosition, emojiPickerRef, toggleEmojiPicker, onEmojiClick } =
    useEmojiPicker(setText);

  return (
    <div className='flex w-full flex-col rounded-r16 bg-white50 opacity-0"'>
      <div>
        <ChatTextArea
          textAreaRef={textAreaRef}
          placeholder={placeholder}
          onChange={handleChange}
          isShowBottom={false}
          errorText='에러 메시지'
          value={text}
          noBorder={true}
          maxLength={1000}
          maxHeight={200}
          className='overflow-hidden'
        />
      </div>
      {showEmojiPicker && (
        <div
          ref={emojiPickerRef}
          style={{
            position: 'absolute',
            top: emojiPickerPosition.top,
            left: emojiPickerPosition.left,
            zIndex: 100,
          }}>
          <EmojiPicker onEmojiClick={onEmojiClick} width={340} height={370} />
        </div>
      )}
      <ChattingSendingBottom
        disabled={isEmptyWithTrim(text)}
        toggleEmojiPicker={toggleEmojiPicker}
        onClickSend={handleSend}
        textAreaRef={textAreaRef}
      />
    </div>
  );
}
