import LabelText from '@afterdoc-design-system/components/Atoms/LabelText/LabelText';
import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';

export const CustomerReservationChatRoomNull = () => {
  return (
    <div className='flex h-full w-full flex-col items-center justify-center'>
      <div className='mb-4'>
        <Icon name='warning' color='white600' size={48} />
      </div>
      <div className='flex flex-col-center'>
        <LabelText textClassName='text-Header14' width='fit-content'>
          고객을 선택해 주세요.
        </LabelText>
        <LabelText textClassName='text-Body13'>
          고객의 예약 정보와 메모를 확인할 수 있습니다.
        </LabelText>
      </div>
    </div>
  );
};
