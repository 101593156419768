import * as React from "react";
import type { SVGProps } from "react";
const SvgKeyboardPositionMove = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 242 64"
    {...props}
  >
    <rect width={242} height={64} fill="#F4F4F4" rx={10} />
    <path
      fill="#555"
      d="M11.24 20.273V8.561h2.747v.948h-1.44v9.816h1.44v.948zm7.402-11.676c1.692 0 2.916.972 2.916 2.376 0 1.38-1.224 2.376-2.916 2.376-1.68 0-2.903-.996-2.903-2.376 0-1.404 1.223-2.376 2.903-2.376m0 1.296c-.803 0-1.38.384-1.38 1.08 0 .684.576 1.068 1.38 1.068.817 0 1.393-.384 1.393-1.068 0-.696-.576-1.08-1.393-1.08m-.744 4.56h1.596v4.476h-1.596zm4.86-6.3h1.585v11.112h-1.584zm-7.487 7.14-.193-1.296c1.945 0 4.692-.024 7.044-.384l.108 1.164c-2.411.48-5.04.504-6.96.516m18.443-7.14h1.608v11.112h-1.608zm-4.896 2.88h1.26v.576c0 2.352-1.044 4.716-3.096 5.604l-.852-1.224c1.824-.816 2.688-2.7 2.688-4.38zm.348 0h1.248v.576c0 1.596.876 3.42 2.712 4.2l-.852 1.224c-2.052-.852-3.108-3.144-3.108-5.424zm-2.664-1.068h6.18v1.26h-6.18zm2.316-1.56h1.596v2.208h-1.596zm18.641-.264h1.608V19.29H47.46zm-4.38.78c1.68 0 2.916 1.536 2.916 3.96 0 2.448-1.236 3.984-2.916 3.984s-2.916-1.536-2.916-3.984c0-2.424 1.236-3.96 2.916-3.96m0 1.452c-.828 0-1.392.876-1.392 2.508 0 1.656.564 2.544 1.392 2.544s1.38-.888 1.38-2.544c0-1.632-.552-2.508-1.38-2.508m7.811 3h10.056v1.272H50.89zm4.236-1.668h1.584v2.16h-1.584zm-3.024-.504h7.704v1.26h-7.704zm0-2.568h7.644v1.248h-6.06v2.124h-1.584zm3.78 6.564c2.4 0 3.852.732 3.852 2.04s-1.452 2.04-3.852 2.04-3.864-.732-3.864-2.04 1.464-2.04 3.864-2.04m0 1.2c-1.488 0-2.244.276-2.244.84 0 .576.756.828 2.244.828s2.244-.252 2.244-.828c0-.564-.756-.84-2.244-.84m6.12 3.876v-.948h1.44V9.51h-1.44v-.948h2.747v11.712z"
    />
    <rect
      width={56.416}
      height={24.636}
      x={11.485}
      y={32.682}
      fill="#404246"
      rx={2.969}
    />
    <rect
      width={56.416}
      height={24.636}
      x={11.485}
      y={32.682}
      stroke="#2C2D31"
      strokeWidth={2.969}
      rx={2.969}
    />
    <path
      fill="#fff"
      d="M24.259 49v-6.17h-2.09V41.6h5.66v1.23h-2.09V49zm5.23.14c-1 0-1.65-.7-1.65-1.66 0-1.19.97-1.82 3.22-2.07-.03-.55-.26-.96-.95-.96-.52 0-1.03.22-1.54.53l-.53-.98c.66-.4 1.45-.73 2.34-.73 1.42 0 2.15.84 2.15 2.47V49h-1.2l-.11-.59h-.04c-.49.42-1.05.73-1.69.73m.48-1.15c.42 0 .73-.19 1.09-.54v-1.13c-1.37.17-1.82.54-1.82 1.05 0 .43.3.62.73.62m6.857 1.15c-.5 0-1.04-.26-1.49-.73h-.04l-.12.59h-1.15v-7.97h1.47v2.01l-.05.89c.45-.39 1-.66 1.56-.66 1.37 0 2.2 1.12 2.2 2.84 0 1.93-1.15 3.03-2.38 3.03m-.32-1.21c.67 0 1.19-.58 1.19-1.79 0-1.08-.33-1.67-1.11-1.67-.37 0-.72.19-1.09.58v2.47c.34.31.71.41 1.01.41"
    />
    <path
      fill="#fff"
      fillRule="evenodd"
      d="M47.3 45.611v-6.113h.612v6.113zM51.578 42.25h6.113v.61h-6.113v1.46l-3.056-1.765 3.056-1.765zM57.691 50.502v-6.113h-.61v6.113zM53.414 47.14h-6.113v.611h6.113v1.46l3.057-1.766-3.057-1.764z"
      clipRule="evenodd"
    />
    <path
      fill="#555"
      d="M85.704 44.708H89.7v1.272h-3.996zm-5.364 3.78h10.056v1.308H80.34zm4.2-2.04h1.584v2.628H84.54zm1.164-5.592h3.936v1.284H87.3v3.216h-1.596zm-4.608.012h3.924v1.272h-2.328v3.108h-1.596zm0 3.804h.708c1.284 0 2.208-.036 3.288-.252l.108 1.308c-1.104.216-2.064.252-3.396.252h-.708zm11.519-1.716h7.704v1.26h-7.704zm-1.248 2.412h10.068v1.272H91.367zm1.248-5.016h1.584v3.228h-1.584zm-.108 9.204h7.872v1.272h-7.872zm0-2.028h1.596v2.364h-1.596z"
    />
    <rect
      width={23.754}
      height={23.754}
      x={113.871}
      y={33.123}
      fill="#404246"
      rx={2.969}
    />
    <rect
      width={23.754}
      height={23.754}
      x={113.871}
      y={33.123}
      stroke="#2C2D31"
      strokeWidth={2.969}
      rx={2.969}
    />
    <path
      fill="#fff"
      d="M130.697 44.381v1.237h-7.423l3.402 3.403-.878.878-4.9-4.9 4.9-4.898.878.878-3.402 3.402z"
    />
    <rect
      width={23.754}
      height={23.754}
      x={145.047}
      y={33.123}
      fill="#404246"
      rx={2.969}
    />
    <rect
      width={23.754}
      height={23.754}
      x={145.047}
      y={33.123}
      stroke="#2C2D31"
      strokeWidth={2.969}
      rx={2.969}
    />
    <path
      fill="#fff"
      d="M157.541 49.949h-1.237v-7.424l-3.402 3.403-.879-.879 4.9-4.9 4.899 4.9-.878.879-3.403-3.403z"
    />
    <rect
      width={23.754}
      height={23.754}
      x={176.227}
      y={33.123}
      fill="#404246"
      rx={2.969}
    />
    <rect
      width={23.754}
      height={23.754}
      x={176.227}
      y={33.123}
      stroke="#2C2D31"
      strokeWidth={2.969}
      rx={2.969}
    />
    <path
      fill="#fff"
      d="M187.484 40.051h1.237v7.423l3.402-3.402.879.879-4.9 4.899-4.899-4.9.879-.878 3.402 3.402z"
    />
    <rect
      width={23.754}
      height={23.754}
      x={207.403}
      y={33.123}
      fill="#404246"
      rx={2.969}
    />
    <rect
      width={23.754}
      height={23.754}
      x={207.403}
      y={33.123}
      stroke="#2C2D31"
      strokeWidth={2.969}
      rx={2.969}
    />
    <path
      fill="#fff"
      d="M214.328 44.381v1.237h7.423l-3.402 3.403.878.878 4.9-4.9-4.9-4.898-.878.878 3.402 3.402z"
    />
  </svg>
);
export default SvgKeyboardPositionMove;
