import { noticeCreateOrUpdateParamState } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Notice/states/notice-create-or-update-param-state';
import { selectedNoticeEditTitleErrorAtom } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Notice/states/notice-edit-error-state';
import TextInput from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import { useAtom, useSetAtom } from 'jotai/index';
import { type ChangeEvent, memo } from 'react';

interface NoticeTitleInputProps {
  title: string;
  isDisabled: boolean;
}
function NoticeTitleInput({ title, isDisabled }: NoticeTitleInputProps) {
  const setNoticeCreateOrUpdateParams = useSetAtom(noticeCreateOrUpdateParamState);

  const [noticeTitleError, setNoticeTitleError] = useAtom(selectedNoticeEditTitleErrorAtom);

  const handleTitleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setNoticeTitleError(null);
    setNoticeCreateOrUpdateParams((prev) => ({ ...prev, title: e.target.value }));
  };

  return (
    <div className='flex items-center gap-24'>
      <LabelText width={76} isRequired className='flex h-40 items-center'>
        공지사항 제목
      </LabelText>
      <TextInput
        disabled={isDisabled}
        className='h-full flex-grow'
        maxLength={100}
        value={title}
        onChange={handleTitleInput}
        placeholder='공지사항 제목을 입력해주세요.'
        errorText={noticeTitleError}
        hasError={!!noticeTitleError}
      />
    </div>
  );
}

export default memo(NoticeTitleInput);
