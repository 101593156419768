import { fullDimmedLoadingService } from '@afterdoc-design-system/components/Atoms/Loading/FullDimmedLoading/FullDimmedLoading.service';
import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { modalService } from '@afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { overlayPageService } from '@afterdoc-design-system/components/Molecules/OverlayPage/OverlayPage.service';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { AlgorithmWithMessagesInput, MessageInput } from 'web/apis/swaggers/swagger-docs';
import { OVERLAY_PAGE_ID } from 'web/shared/constants/overlay-page-id';
import { useProcessImagesForUpload } from 'web/shared/hooks/files/images/use-process-images-for-upload';
import { useProcessVideoForUpload } from 'web/shared/hooks/files/video/use-process-video-for-upload';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import type { CounselAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/components/RegisterCounselAutomationDialogContent';
import type { ParseEventOrHomecareContent } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/EventOrHomecareContentForm/EventOrHomecareContentForm';
import type { ParseWorkoutVideoContent } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/WorkoutVideoContentForm/WorkoutVideoContentForm';
import { selectedMessageIndexState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-form';
import { messageModeState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-mode';
import { originMessagesState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/origin-message';
import { useHandleCounselAutomationMessages } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/hooks/use-handle-counsel-automation-messages';
import { useResetAll } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/hooks/use-reset-all';

const registerCounselAutomation = async (params: AlgorithmWithMessagesInput) => {
  const response = await apiClient.v3.apiAutomationsElAlgorithmsSingle(params);
  return response.data;
};

export const useSubmitRegisterCounselAutomation = () => {
  const { hospitalID } = useSelectedHospitalInfo();
  const queryClient = useQueryClient();
  const { getValues, reset, watch } = useFormContext<CounselAutomationAPIFormValues>();
  const { handleAutomationMessages } = useHandleCounselAutomationMessages();

  const [disabled, setDisabled] = useState(true);
  const originMessages = useAtomValue(originMessagesState);
  const messageMode = useAtomValue(messageModeState);
  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);

  const hasApprovedAlimtalkTemplate =
    originMessages[selectedMessageIndex]?.hasApprovedAlimtalkTemplate ?? false;
  const messageLists = watch('messages');
  const name = watch(`messages.${selectedMessageIndex}.name`);
  const text = watch(`messages.${selectedMessageIndex}.content.text`);
  const surveyQuestion = watch(`messages.${selectedMessageIndex}.content.survey.question`);
  const firstSurveyAnswer = watch(`messages.${selectedMessageIndex}.content.survey.answers.0`);
  const secondSurveyAnswer = watch(`messages.${selectedMessageIndex}.content.survey.answers.1`);

  const registerText = useMemo(() => {
    if (messageMode.mode === 'CREATE') {
      return '관리자동화가 등록되었습니다.';
    }

    if (messageMode.mode === 'COPY') {
      return '관리자동화가 복사되었습니다.';
    }
  }, [messageMode.mode]);

  const { processImagesForUpload } = useProcessImagesForUpload();

  const { processVideoForUpload } = useProcessVideoForUpload();

  const { resetAll } = useResetAll({
    reset,
  });

  const handleOnSuccess = (text: string) => {
    if (messageMode.mode === 'CREATE') return;

    toastService.successMsg({
      text,
    });
    resetAll();

    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY.apiAutomationsElMainList, { hospitalID }],
    });
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY.apiAutomationsElOngoing, { hospitalID }],
    });
    queryClient.invalidateQueries({
      queryKey: [
        QUERY_KEY.apiAutomationsElAlgorithmsMessages,
        { algorithmId: messageMode.algorithmId, hospitalID },
      ],
    });
    queryClient.invalidateQueries({
      queryKey: [
        QUERY_KEY.singleAlgorithmHandler,
        { algorithmId: messageMode.algorithmId, hospitalID },
      ],
    });

    modalService.popAll();
    overlayPageService.popById(OVERLAY_PAGE_ID['register-counsel-automation-dialog']);
  };

  const registerMutation = useMutation({
    mutationFn: registerCounselAutomation,
    onSuccess: (data) => {
      if (data.code === 0) {
        toastService.successMsg({
          text: registerText,
        });

        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.apiAutomationsElMainList, { hospitalID }],
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.apiAutomationsElOngoing, { hospitalID }],
        });

        resetAll();
        modalService.popAll();
        overlayPageService.popById(OVERLAY_PAGE_ID['register-counsel-automation-dialog']);
      } else {
        toastService.errorMsg({
          text: data.message ?? '관리자동화 등록을 실패했습니다. 다시 시도해 주세요.',
        });
      }
      setDisabled(false);
    },
    onError: (error) => {
      console.error('error', error);
      toastService.errorMsg({
        text: '관리자동화 등록을 실패했습니다. 다시 시도해 주세요.',
      });
      setDisabled(false);
    },
  });

  const handleRegister = async () => {
    const originValues = getValues() as Exclude<AlgorithmWithMessagesInput, 'messages'> & {
      messages: (MessageInput & {
        id: string;
      })[];
    };
    console.log('originValues', originValues);
    const newValues = { ...originValues };

    setDisabled(true);

    try {
      for (let i = 0; i < newValues.messages.length; i++) {
        const message = newValues.messages[i];
        const originMessage = originMessages[i];

        if (message.content.image) {
          newValues.messages[i].content.image = await processImagesForUpload({
            newImages: JSON.parse(message.content.image),
            originalImages:
              originMessage?.content?.images?.map((image) => ({
                url: image,
              })) ?? [],
          });
        } else if (message.content.video) {
          newValues.messages[i].content.video = await processVideoForUpload(
            JSON.parse(message.content.video),
          );
        } else if (message.content.workoutVideo) {
          const parseWorkoutVideoContent: ParseWorkoutVideoContent = JSON.parse(
            message.content.workoutVideo,
          );
          newValues.messages[i].content.workoutVideo = parseWorkoutVideoContent._id;
        } else if (message.content.homecareContent) {
          const parsedHomecareContent: ParseEventOrHomecareContent = JSON.parse(
            message.content.homecareContent,
          );
          newValues.messages[i].content.homecareContent = parsedHomecareContent._id;
        } else if (message.content.event) {
          const parsedEventContent: ParseEventOrHomecareContent = JSON.parse(message.content.event);
          newValues.messages[i].content.event = parsedEventContent._id;
        }

        if (message.content.text === '') {
          newValues.messages[i].content.text = undefined;
        }
      }
    } catch (error) {
      console.error('File upload failed:', error);
      toastService.errorMsg({
        text: '파일 업로드에 실패했습니다. 다시 시도해 주세요.',
      });
      setDisabled(false);
      return;
    }

    if (messageMode.mode === 'EDIT') {
      handleAutomationMessages({
        onSuccess: () => {
          handleOnSuccess('관리자동화가 수정되었습니다.');
          setDisabled(false);
        },
        newMessagesValues: newValues.messages,
        setDisabled,
      });
    } else {
      registerMutation.mutate(newValues);
    }
  };

  const handleRegisterWithCondition = () => {
    if (disabled) return;

    if (messageMode.mode === 'CREATE') return handleRegister();
    if (hasApprovedAlimtalkTemplate) {
      return modalService.defaultWarning({
        title: '메시지를 수정할까요?',
        contents:
          '자동화를 수정한 시점부터 해당하는 고객에게 메시지를 전송합니다. 카카오 알림톡은 검수 담당자의 승인 이후 적용됩니다. 승인까지는 3~5일이 소요됩니다.',
        onConfirm: async () => {
          modalService.pop();
          fullDimmedLoadingService.on();
          await handleRegister();
          fullDimmedLoadingService.off();
        },
        onCancel: modalService.pop,
      });
    }

    return modalService.defaultWarning({
      title: '메시지를 수정할까요?',
      contents: '자동화를 수정한 시점부터 해당하는 고객에게 메시지를 전송합니다.',
      onConfirm: async () => {
        modalService.pop();
        fullDimmedLoadingService.on();
        await handleRegister();
        fullDimmedLoadingService.off();
      },
      onCancel: modalService.pop,
    });
  };

  useEffect(() => {
    if (!messageLists.length) return;

    const isDisabled = messageLists.some((item) => {
      return (
        !item.name?.length ||
        (item.sendingType !== 'SURVEY' && !item.content.text?.length) ||
        (item.sendingType === 'SURVEY' &&
          (!item.content.survey?.question?.length ||
            !item.content.survey?.answers?.[0].length ||
            !item.content.survey?.answers?.[1].length))
      );
    });

    setDisabled(isDisabled);
  }, [messageLists, name, text, surveyQuestion, firstSurveyAnswer, secondSurveyAnswer]);

  return {
    disabled: disabled || registerMutation.isPending,
    handleRegister: handleRegisterWithCondition,
  };
};
