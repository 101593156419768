import Divider from '@afterdoc-design-system/components/Atoms/Divider/Divider';
import Scrollbar from '@afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import dayjs from 'dayjs';
import { useAtomValue, useSetAtom } from 'jotai';
import { useFormContext } from 'react-hook-form';
import type { MarketingAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/components/RegisterMarketingAutomationDialogContent';
import AutomationMessageHeader from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/containers/MessageContentForm/components/AutomationMessageHeader';
import MessageNameForm from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/containers/MessageContentForm/components/MessageNameForm';
import SendContentForm from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/containers/MessageContentForm/components/SendContentForm/SendContentForm';
import SendDateForm from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/containers/MessageContentForm/components/SendDateTimeForm';
import { messageModeState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/message-mode';
import { useSubmitRegisterMarketingAutomation } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/hooks/use-submit-register-marketing-automation';
import { selectedMarketingAutomationTabState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/states/selected-marketing-automation-tab-state';
import PrevButton from 'web/templates/Automation/containers/shared/components/Buttons/PrevButton';
import RegisterButton from 'web/templates/Automation/containers/shared/components/Buttons/RegisterButton';

export default function MessageContentFormContainer() {
  return (
    <div className='h-[770px] min-w-[700px] max-w-[700px]'>
      <Scrollbar disabledX>
        <div className='min-w-[700px] max-w-[700px] flex-shrink-0 rounded-r16 bg-white50 px-40 py-20 shadow-modal'>
          {/* 자동화 메시지 헤더 */}
          <AutomationMessageHeader />
          <Divider type='line' className='my-20' />

          {/* 발송시점 */}
          <SendDateForm />
          <Divider type='line' className='my-20' />

          {/* 메시지명 */}
          <MessageNameForm />
          <Divider type='line' className='my-20' />

          {/* 내용 */}
          <SendContentForm />

          {/* 하단 버튼 */}
          <BottomDirectionButton />
        </div>
      </Scrollbar>
    </div>
  );
}

const BottomDirectionButton = () => {
  const { watch } = useFormContext<MarketingAutomationAPIFormValues>();

  const messageMode = useAtomValue(messageModeState);
  const setSelectedAutomationTab = useSetAtom(selectedMarketingAutomationTabState);

  const messageLists = watch('messages');
  const allSendingDateTime = messageLists.map((message) => message.sendingDateTime);

  const startDay = watch('sendingSchedule.startDay');

  const { disabled, handleRegister } = useSubmitRegisterMarketingAutomation();

  return (
    <div className='mx-auto mt-30 flex w-[200px] gap-8'>
      <PrevButton
        disabled={messageMode.mode === 'EDIT'}
        onClick={() => setSelectedAutomationTab(1)}
      />
      <RegisterButton
        onClick={() => {
          if (disabled) return;

          if (
            allSendingDateTime.some((sendingDateTime) =>
              dayjs(sendingDateTime).isBefore(dayjs(startDay)),
            )
          ) {
            toastService.errorMsg({
              text: '발송시점은 발송 시작일 이후로 설정해주세요.',
            });
          }

          if (
            allSendingDateTime.some((sendingDateTime) =>
              dayjs(sendingDateTime, 'day').isBefore(dayjs(), 'day'),
            )
          ) {
            toastService.errorMsg({
              text: `발송시점은 ${dayjs().format('YYYY-MM-DD')}부터 설정 가능합니다.`,
            });
            return;
          }

          handleRegister();
        }}
        disabled={disabled}
      />
    </div>
  );
};
