import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import FullLoading from 'web/shared/components/FullLoading/FullLoading';
import '../../styles/ManualContainer.scss';
import ManualPanelFolder from './components/ManualPanelFolder';
import ManualPanelFolderItem from './components/ManualPanelFolderItem/ManualPanelFolderItem';
import { useManusFolders } from './hooks/use-manus-folders';

export default function ManualList({ keyword }: { keyword: string }) {
  const { data, firstOpenIndex, isLoading } = useManusFolders({ keyword });
  const [manualPanelOpenMap, setManualPanelOpenMap] = useState(() => new Map<string, boolean>());
  const manualListContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (data && data.length > 0) {
      setManualPanelOpenMap((prevManualPanelOpenMap) => {
        const newManualPanelOpenMap = new Map(prevManualPanelOpenMap);
        for (const { id, isOpen } of data) {
          // 기존 맵에 해당 id가 없을 때만 새로운 값 추가
          newManualPanelOpenMap.set(id, isOpen);
        }
        return newManualPanelOpenMap;
      });
    }
  }, [data]);

  useLayoutEffect(() => {
    if (firstOpenIndex !== null && firstOpenIndex >= 0) {
      const container = manualListContainerRef.current;

      if (container) {
        requestAnimationFrame(() => {
          const { clientHeight } = container.children[0];
          const movingTopValue = clientHeight * firstOpenIndex;
          if (container) {
            container.scrollTop = movingTopValue;
          }
        });
      }
    }
  }, [firstOpenIndex]);

  const handleClick = ({ id }: { id: string }) => {
    const newManualPanelOpenMap = new Map<string, boolean>(manualPanelOpenMap);
    newManualPanelOpenMap.set(id, !manualPanelOpenMap.get(id));
    setManualPanelOpenMap(newManualPanelOpenMap);
  };

  if (isLoading) return <FullLoading />;

  return (
    <div className='h-full '>
      <div id='manual-container' ref={manualListContainerRef} className='select-none'>
        {data.map((parentItem, folderIndex) => {
          const isOpen = manualPanelOpenMap.get(parentItem.id) ?? false;
          return (
            <ManualPanelFolder
              key={parentItem.title}
              id={parentItem.id}
              isOpen={isOpen}
              handleClick={handleClick}
              parentItem={parentItem}>
              {parentItem.items.map((childItem, itemIndex) => {
                const { id: parentID } = parentItem;
                const { key: childID, isOpen: isChildOpen } = childItem;

                return (
                  <ManualPanelFolderItem
                    key={childID}
                    childID={childID}
                    id={parentID}
                    isOpen={isOpen}
                    folderItem={parentItem}
                    folderIndex={folderIndex}
                    itemIndex={itemIndex}
                    isManualDetail={isChildOpen}
                  />
                );
              })}
            </ManualPanelFolder>
          );
        })}
      </div>
    </div>
  );
}
