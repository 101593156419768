import ContainedButton from '@afterdoc-design-system/components/Atoms/Button/ContainedButton';
import IconButton from '@afterdoc-design-system/components/Atoms/Button/IconButton';
import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import TextFieldSelectBoxDropdown from '@afterdoc-design-system/components/Molecules/TextSelect/TextFieldSelectBoxDropdown';
import { useAtomValue, useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useEffect, useRef, useState } from 'react';
import FilePicker, { type FilePickerHandle } from 'web/shared/components/FilePicker/FilePicker';
import { useUserInfo } from 'web/shared/hooks/use-user-info';
import { FileUploadController } from 'web/shared/utils/fileUpload/FileUploadController';
import { FileService } from 'web/shared/utils/fileUpload/services/FileServices';
import type { FileUploadResponse } from 'web/shared/utils/fileUpload/types/types';
import { channelIntegrationInfoState } from 'web/templates/CustomerChat/components/ChattingList/states/channel-integration-info-state';
import { useLanguageList } from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingSending/components/ChattingSendingBottom/hooks/use-language-list';
import {
  FILE_TYPE,
  KAKAO_FILES_TYPE_FOR_API,
} from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingSending/constants/types-codes';
import { useChatRoomInfoOne } from 'web/templates/CustomerChat/components/ChattingRoom/hooks/use-chatroom-info-one';
import { selectedMessageIdState } from 'web/templates/CustomerChat/components/SupportBot/states/selected-message-id';
import { isActivatedChattingState } from 'web/templates/CustomerChat/states/is-activated-chatting';
import { translateLanguageState } from 'web/templates/CustomerChat/states/translate-language';
import type { ImageChattingResponse } from 'web/templates/CustomerChat/types';
import { chatRoomIDAtom } from '../../../../../../states/chatRoomID';
import { isActivateChatTranslateAtom } from '../../../../states/isChatTranslate';
import { MESSAGE_TYPE_CODE } from '../../../ChattingContainer/components/ChattingContent/components/ChattingMessage/constants/message';
import { useHandleChatSocket } from '../../../ChattingContainer/components/ChattingContent/hooks/use-handle-chat-socket';
import { chattingRequestState } from '../../../ChattingContainer/states/chatting-request';
import { isSendingMessagesState } from '../../../ChattingContainer/states/isSendingMessages';
import { kakaoIntegrationAxiosInstance } from '../../api/kakaoIntegrationAxiosInstance';
import type { TempSendFile } from '../../types/send-file';

interface ChattingSendingBottomProps {
  disabled: boolean;
  toggleEmojiPicker: (iconButtonRef: HTMLButtonElement | null) => void;
  onClickSend: () => void;
  textAreaRef: React.RefObject<HTMLTextAreaElement>;
}

export default function ChattingSendingBottom({
  disabled,
  toggleEmojiPicker,
  onClickSend,
  textAreaRef,
}: ChattingSendingBottomProps) {
  const isActivateChatTranslate = useAtomValue(isActivateChatTranslateAtom);
  const { userId, userType, name, realName, jobType, photo } = useUserInfo();
  const chatRoomID = useAtomValue(chatRoomIDAtom);
  const { roomType, roomInfo } = useAtomValue(channelIntegrationInfoState);
  const isIKakao = roomType === 'ikakao';

  const isActivatedChatting = useAtomValue(isActivatedChattingState);

  const resetSelectedMessageId = useResetAtom(selectedMessageIdState);
  const setReq = useSetAtom(chattingRequestState);
  const setIsSendingMessages = useSetAtom(isSendingMessagesState);
  const setTranslateLanguage = useSetAtom(translateLanguageState);
  // const updateFileProgress = useSetAtom(setFileProgressAtom);

  const filePickerRef = useRef<FilePickerHandle>(null);
  const emojiButtonRef = useRef<HTMLButtonElement>(null);
  const isComposingRef = useRef(false);

  const { data } = useLanguageList();
  const options = data.map((item) => {
    const { koreanLanguageName } = item;
    return `${koreanLanguageName}로 변환`;
  });

  const { languageCodeISOAlpha2 } = useChatRoomInfoOne();
  const { sendMessage } = useHandleChatSocket({ enableListening: false });
  const [selectedIndex, setSelectedIndex] = useState(0);

  const handleCompositionStart = () => {
    isComposingRef.current = true;
  };

  const handleCompositionEnd = () => {
    isComposingRef.current = false;
  };

  const handleKeyDown = (event: KeyboardEvent) => {
    event.stopPropagation();

    if (
      event.key === 'Enter' &&
      textAreaRef.current &&
      document.activeElement === textAreaRef.current &&
      !isComposingRef.current
    ) {
      if (event.shiftKey) return; // Shift + Enter인 경우 줄바꿈을 허용

      event.preventDefault();
      onClickSend();
    }
  };

  useEffect(() => {
    if (languageCodeISOAlpha2) {
      const index = data.findIndex((item) => item.languageCodeISOAlpha2 === languageCodeISOAlpha2);
      setSelectedIndex(index);
    }
  }, [languageCodeISOAlpha2]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    const textarea = textAreaRef.current;

    if (textarea) {
      textarea.addEventListener('compositionstart', handleCompositionStart);
      textarea.addEventListener('compositionend', handleCompositionEnd);
      textarea.addEventListener('keydown', handleKeyDown);

      return () => {
        textarea.removeEventListener('compositionstart', handleCompositionStart);
        textarea.removeEventListener('compositionend', handleCompositionEnd);
        textarea.removeEventListener('keydown', handleKeyDown);
      };
    }
  }, [textAreaRef, onClickSend]); //맨 처음 실행되면 enter가 미동작그래서 onClinckSend를 넣어줌

  const handleClick = () => {
    filePickerRef.current?.clickMultipleFile();
  };

  const handleFileChange = async (files: File[]) => {
    const userName = name ?? '';
    const userRealName = realName ?? '';
    const userJobType = jobType ?? '';
    const filePath = photo as unknown as TempSendFile;
    const photoPath = filePath?.paths[0];

    const { pf_id, user_key } = roomInfo;

    const callback = async (result: FileUploadResponse) => {
      if (result.code !== 0 || !result.data) return;

      const { data } = result;

      for (const file of data) {
        if (chatRoomID === null) return;

        const { _id: fileIDsBundleID, type } = file;
        sendMessage({
          type: FILE_TYPE,
          payload: {
            chatRoomID,
            userType,
            userName,
            userRealName,
            userID: userId,
            jobType: userJobType,
            fileIDs: [fileIDsBundleID],
            type,
            photoPath,
          },
        });
      }
    };

    const filteredFiles = files.filter((file) => {
      if (FileService.validateFileSize(file)) return true;
      toastService.errorMsg({ text: '용량 초과로 전송을 실패했습니다.' });
      return false;
    });

    setIsSendingMessages(true);
    resetSelectedMessageId();

    if (isIKakao) {
      const imageAndFileData: ImageChattingResponse[] = [];
      const imageFiles = filteredFiles.filter(FileService.isImageFile);
      if (imageFiles.length !== 0) {
        const payload = {
          pf_id,
          user_key,
          userType,
          userName,
          userRealName,
          userID: userId,
          jobType: userJobType,
          type: 300,
          photoPath,
          images: imageFiles,
        };
        const {
          data: { data: imagesData },
        } = await kakaoIntegrationAxiosInstance.post('/biz/file/image_upload', payload, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        const imagesDataWithMockingType = imagesData.map((item: ImageChattingResponse) => ({
          ...item,
          type: MESSAGE_TYPE_CODE.IMAGE_MOCKING,
        }));
        imageAndFileData.push(...imagesDataWithMockingType);
      }

      const nonImageFiles = filteredFiles.filter((file) => !FileService.isImageFile(file));

      if (nonImageFiles.length !== 0) {
        const filePayload = {
          pf_id,
          user_key,
          userType,
          userName,
          userRealName,
          userID: userId,
          jobType: userJobType,
          type: 500,
          photoPath,
          files: nonImageFiles,
        };

        const {
          data: { data: filesData },
        } = await kakaoIntegrationAxiosInstance.post('/biz/file/file_upload', filePayload, {
          headers: {
            'Content-Type': 'multipart/form-data',
          },
        });

        const mockingFileIDs = nonImageFiles.map((file) => {
          const { name, size } = file;
          const mockingAwsObject = {
            Bucket: '',
            Etag: '',
            Key: '',
            Location: '',
            fileName: name,
            fileSize: size,
            key: '',
          };
          return {
            aws: [mockingAwsObject],
            createdAt: '',
            expiredAt: '',
            paths: [],
            type: MESSAGE_TYPE_CODE.FILE_MOCKING,
            updatedAt: '',
            userID: '',
            _v: 0,
            _id: '',
          };
        });

        const filesDataWithMockingType = [];
        for (const idx in filesData) {
          const numberIdx = Number(idx);

          filesDataWithMockingType.push({
            ...filesData[idx],
            type: MESSAGE_TYPE_CODE.FILE_MOCKING,
            fileIDs: [mockingFileIDs[numberIdx]],
          });
        }

        imageAndFileData.push(...filesDataWithMockingType);
      }

      setReq({
        type: KAKAO_FILES_TYPE_FOR_API,
        payload: imageAndFileData,
      });
    } else {
      FileUploadController.uploadFiles({
        files: filteredFiles,
        userID: userId,
        callback,
      });
    }
  };

  const handleSelectLanguage = (index: number) => {
    setSelectedIndex(index);
    setTranslateLanguage(data[index].languageCodeISOAlpha2);
  };

  return (
    <div className='mr-10 mb-10 ml-15 flex items-center justify-between'>
      <FilePicker ref={filePickerRef} onFileChange={handleFileChange} />
      <div className='flex items-center gap-8'>
        <IconButton size={20} icon={'add-circle'} onClick={handleClick} />
        <IconButton
          size={20}
          icon={'emoji-outline'}
          onClick={() => toggleEmojiPicker(emojiButtonRef.current)}
          className='mr-12'
          ref={emojiButtonRef}
        />
        {isActivateChatTranslate && (
          <TextFieldSelectBoxDropdown
            width={116}
            inputClassName='h-20 text-Body10'
            options={options}
            dropdownWrapperClassName='absolute bottom-full mb-2'
            manualInput={false}
            onSelect={handleSelectLanguage}
            selectedIndex={selectedIndex}
            focusedIndex={selectedIndex}
          />
        )}
      </div>
      <div className='flex items-center'>
        <ContainedButton
          className='flex items-center'
          buttonSize='big'
          disabled={disabled || !isActivatedChatting}
          onClick={onClickSend}
          iconProps={{ name: 'send', color: 'white50' }}>
          보내기
        </ContainedButton>
      </div>
    </div>
  );
}
