import { contentPanelLayerState } from '@templates/Content/containers/ContentPanel/states/content-panel-layer-state';
import Icon, { type IconColorType } from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { useAtom } from 'jotai/index';
import type { ReactNode } from 'react';

interface ContentPanelFolderProps {
  children: ReactNode;
  folderIndex: number;
  folderIconColor?: IconColorType;
}

export default function ContentPanelFolder({
  children,
  folderIndex,
  folderIconColor = 'white700',
}: ContentPanelFolderProps) {
  const [contentPanelLayer, setContentPanelLayer] = useAtom(contentPanelLayerState);

  const { title, isOpen } = contentPanelLayer[folderIndex];

  const handleClick = () => {
    setContentPanelLayer((prev) =>
      prev.map((folder, index) =>
        index === folderIndex ? { ...folder, isOpen: !folder.isOpen } : folder,
      ),
    );
  };

  return (
    <div>
      <div
        className='flex h-40 cursor-pointer items-center justify-start gap-10 px-20'
        onClick={handleClick}>
        {/* TODO - 색상 적용이 안됨 */}
        <Icon name={isOpen ? 'chevron-down' : 'chevron-right'} size={16} color={folderIconColor} />
        <Icon name='folder-outline' size={20} />
        <span className='line-clamp-1 text-Header14 text-black500 '>{title}</span>
      </div>
      {children}
    </div>
  );
}
