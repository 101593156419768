import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { isEqual } from 'lodash-es';
import { useFormContext } from 'react-hook-form';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { CommonAlgorithmInput } from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';

const editCommonAutomation = async (params: CommonAlgorithmInput) => {
  const response = await apiClient.v3.apiAutomationsElCommonAlgorithmUpsert(params);
  return response.data;
};

export const useSubmitEditCommonAutomation = () => {
  const queryClient = useQueryClient();
  const { hospitalID } = useSelectedHospitalInfo();
  const {
    getValues,
    setError,
    reset,
    formState: { defaultValues },
  } = useFormContext<CommonAlgorithmInput>();

  const values = getValues();

  const registerMutation = useMutation({
    mutationFn: editCommonAutomation,
    onSuccess: (data) => {
      if (data.code === 0) {
        toastService.successMsg({
          text: '공동 자동화가 수정되었습니다.',
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.apiAutomationsElMainList, { hospitalID }],
        });
        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.apiAutomationsElCommonAlgorithm, { hospitalID }],
        });
        reset(values);
      } else {
        toastService.errorMsg({
          text: '공동 자동화 수정을 실패했습니다. 다시 시도해 주세요.',
        });
      }
    },
    onError: (error) => {
      console.error('error', error);
      toastService.errorMsg({
        text: '공동 자동화 수정을 실패했습니다. 다시 시도해 주세요.',
      });
    },
  });

  const handleRegister = async () => {
    if (isEqual(values, defaultValues)) {
      toastService.errorMsg({
        text: '수정된 내용이 없습니다.',
      });
      return;
    }

    if (!values.content || values.content.trim() === '') {
      setError('content', {
        type: 'manual',
        message: '내용을 입력해 주세요.',
      });
      return;
    }

    try {
      registerMutation.mutate(values);
    } catch (error) {
      console.error('File upload failed:', error);
      toastService.errorMsg({
        text: '파일 업로드에 실패했습니다. 다시 시도해 주세요.',
      });
    }
  };

  return {
    handleRegister,
    isPending: registerMutation.isPending,
  };
};
