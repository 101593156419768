import FilledTag from '@afterdoc-design-system/components/Atoms/Tag/FilledTag';
import { Color } from '@tailwind-base/styles/color';
import { usePatient } from '../../../../hooks/use-patient';
import { makePatientInfoStringDisplay } from './functions/makePatientInfoStringDisplay';

export default function PatientDetail() {
  const { gender, birthdate, countryCode, phoneNumber, nationalityKoreanCountryName } =
    usePatient();
  const genderAndBirthDisplay = makePatientInfoStringDisplay({
    type: 'genderAndBirthdate',
    payload: {
      gender,
      birthdate,
    },
  });
  const { koreanCountryName, internationalDialingCodes } = countryCode || {};

  const countryDisplay = makePatientInfoStringDisplay({
    type: 'country',
    payload: {
      koreanCountryName,
      internationalDialingCodes,
    },
  });

  const nationalityName = makePatientInfoStringDisplay({
    type: 'nationalityKoreanCountryName',
    payload: {
      nationalityKoreanCountryName,
    },
  });
  const phoneNumberDisplay = makePatientInfoStringDisplay({
    type: 'phoneNumber',
    payload: {
      phoneNumber,
      internationalDialingCodes,
    },
  });
  return (
    <div className='my-12 flex'>
      <FilledTag maxTextLength={30} bgColor='#F7F7F7' textColor={Color.black500} className='mx-10'>
        {genderAndBirthDisplay}
      </FilledTag>
      <FilledTag bgColor='#F7F7F7' textColor={Color.black500} className='mx-10'>
        {countryDisplay}
      </FilledTag>
      <FilledTag maxTextLength={30} bgColor='#F7F7F7' textColor={Color.black500} className='mx-10'>
        {phoneNumberDisplay}
      </FilledTag>
      <FilledTag maxTextLength={30} bgColor='#F7F7F7' textColor={Color.black500} className='mx-10'>
        {nationalityName}
      </FilledTag>
    </div>
  );
}
