import { useState } from 'react';

export const useToggleSelect = (initialSelectedIDs: string[]) => {
  const [selectedUserIDs, setSelectedUsers] = useState<string[]>(initialSelectedIDs);
  const isSelected = (id: string) => {
    return selectedUserIDs.includes(id);
  };

  const toggleSelect = (id: string) => {
    if (isSelected(id)) return;

    setSelectedUsers((prevSelectedUsers) => [...prevSelectedUsers, id]);
  };

  return { selectedUserIDs, toggleSelect, isSelected };
};
