import { useAtomValue } from 'jotai';
import ChattingList from './components/ChattingList/ChattingList';
import ChattingRoom from './components/ChattingRoom/ChattingRoom';
import { useHandleChatSocket } from './components/ChattingRoom/components/ChattingContainer/components/ChattingContent/hooks/use-handle-chat-socket';
import { CustomerMemo } from './components/CustomerMemo/CustomerMemo';
import { CustomerReservation } from './components/CustomerReservation/CustomerReservation';
import { CustomerReservationChatRoomNull } from './components/CustomerReservation/CustomerReservationChatRoomNull';
import Menual from './components/Manual/Manual';
import ManualToggleButton from './components/ManualToggleButton/ManualToggleButton';
import { SupportBot } from './components/SupportBot/SupportBot';
import { MIN_LAYOUT_WIDTH } from './constants/layout';
import useIsMenualOpen from './hooks/use-is-menual-open';
import { chatRoomIDAtom } from './states/chatRoomID';

export default function CustomerChatContainer() {
  const chatRoomID = useAtomValue(chatRoomIDAtom);
  useHandleChatSocket();
  const { isMenualOpen, toggleMenual } = useIsMenualOpen();

  return (
    <div className='h-full w-full flex-row-center'>
      <div className='h-full w-[400px] min-w-[400px]'>
        <ChattingList />
      </div>
      {isMenualOpen && (
        <div className='h-full w-[400px] min-w-[400px] border-l border-l-white400'>
          <Menual />
        </div>
      )}
      <div className='relative flex h-full w-full border border-white400'>
        <ChattingRoom minWidth={MIN_LAYOUT_WIDTH} isMenualOpen={isMenualOpen} />
        <ManualToggleButton isMenualOpen={isMenualOpen} onClick={toggleMenual} />
      </div>
      <div className='flex h-full w-[400px] min-w-[400px] flex-col'>
        {chatRoomID === null ? (
          <CustomerReservationChatRoomNull />
        ) : (
          <>
            <CustomerMemo />
            <CustomerReservation />
          </>
        )}
        <SupportBot />
      </div>
    </div>
  );
}
