import * as React from "react";
import type { SVGProps } from "react";
const SvgRedo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill="#000"
      d="M36.8 21.2C33.1 18 28.3 16 23 16c-9.3 0-17.16 6.06-19.92 14.44L7.8 32A16 16 0 0 1 23 21c3.9 0 7.46 1.44 10.24 3.76L26 32h18V14z"
      className="redo_svg__ql-fill"
    />
  </svg>
);
export default SvgRedo;
