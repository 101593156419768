import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { type ReactNode, memo, useEffect, useMemo, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import type {
  ApiServiceSettingsElCountriesData,
  ApiTreatmentTagsElData,
} from 'web/apis/swaggers/swagger-docs';
import { KOREA_COUNTRY_CODE_ID } from 'web/shared/constants/country-code-id';
import {
  GENDER_DISPLAY,
  GENDER_OPTIONS,
  VISIT_DISPLAY,
  VISIT_OPTIONS,
} from 'web/shared/constants/options';
import type { UnTaggedCustomerManagementTableRowArr } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/UnTaggedCustomerManagement/containers/UnTaggedCustomerManagementEditableTable/types/table';
import useUnTaggedCustomerKeyboardNavigation from 'web/templates/CustomerManagement/containers/BoardPanel/containers/UnTaggedCustomerManagement/containers/hooks/use-untagged-customer-keyboard-navigation';
import MultipleTagsSelectBoxTagDropdownForTable from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/components/MultipleTagsSelectBoxTagDropdownForTable/MultipleTagsSelectBoxTagDropdownForTable';
import SingleTagSelectBoxTagDropdownForTable from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/components/SingleTagSelectBoxTagDropdownForTable/SingleTagSelectBoxTagDropdownForTable';
import { COLUMN_WIDTHS } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/constants/width';
import type { useVirtualScroll } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/hooks/use-virtual-scroll';
import './UnTaggedCustomerManagementEditableTableBody.scss';

type UnTaggedCustomerManagementEditableTableBodyProps = {
  rows: UnTaggedCustomerManagementTableRowArr;
  countriesList: ApiServiceSettingsElCountriesData['data'];
  treatmentTagsList: ApiTreatmentTagsElData['data']['treatmentTags'];
  virtualScrollData: ReturnType<
    typeof useVirtualScroll<UnTaggedCustomerManagementTableRowArr[number]>
  >;
};

type SelectedCell = {
  row: number;
  col: number;
};

type FocusedCell = {
  row: number | null;
  col: number | null;
};

const DisabledTableCell = ({
  value,
  placeholder,
  className,
}: { value?: string; placeholder?: string; className?: string }) => (
  <td className={customTwMerge('border-line-table border-y border-r bg-disabled', className)}>
    <input
      type='text'
      placeholder={placeholder}
      disabled={true}
      value={value ?? ''}
      className={customTwMerge(
        'w-full bg-disabled px-12 py-11 text-Body12 placeholder-white600 outline-none placeholder:text-white600',
      )}
    />
  </td>
);

const renderIsSelected = (rowIndex: number, colIndex: number, selectedCell: SelectedCell) => {
  return selectedCell.row === rowIndex && selectedCell.col === colIndex;
};

const renderIsFocused = (rowIndex: number, colIndex: number, focusedCell: FocusedCell) => {
  return focusedCell.row === rowIndex && focusedCell.col === colIndex;
};

const getTableCellProps = (
  rowIndex: number,
  colIndex: number,
  selectedCell: SelectedCell,
  onClick: () => void,
  className?: string,
) => ({
  className: `group border-line-table border-y border-r text-Body12 hover:bg-blueLight ${
    renderIsSelected(rowIndex, colIndex, selectedCell)
      ? '-outline-offset-1 bg-blue50 outline outline-1 outline-blue500'
      : ''
  } ${className}`,
  onClick,
});

const TableCell = ({
  rowIndex,
  colIndex,
  selectedCell,
  onClick,
  children,
  className,
}: {
  rowIndex: number;
  colIndex: number;
  selectedCell: SelectedCell;
  onClick: () => void;
  children: ReactNode;
  className?: string;
}) => (
  <td {...getTableCellProps(rowIndex, colIndex, selectedCell, onClick, className)}>{children}</td>
);

function UnTaggedCustomerManagementEditableTableBody({
  rows,
  countriesList,
  treatmentTagsList,
  virtualScrollData,
}: UnTaggedCustomerManagementEditableTableBodyProps) {
  const { visibleRows } = virtualScrollData;
  const contentEditableRefs = useRef<Array<HTMLDivElement | null>>([]);

  const { selectedCell, focusedCell, handleCellClick } = useUnTaggedCustomerKeyboardNavigation(
    {
      maxRows: rows.length,
    },
    virtualScrollData,
  );

  const { control, setValue } = useFormContext();

  const inputRefs = useRef<Array<HTMLInputElement | HTMLDivElement | null>>([]);

  const countriesListWithDialingCodes = useMemo(() => {
    return countriesList.map((item) => {
      return {
        name: `${item.koreanCountryName}(${item.internationalDialingCodes})`,
        id: item.countryId,
      };
    });
  }, [countriesList]);

  const columnsToRender = useMemo(
    () => [
      { visible: true },
      { visible: true },
      { visible: true },
      { visible: true },
      { visible: true },
      { visible: true },
      { visible: true },
      { visible: true },
      { visible: true },
    ],
    [],
  );

  const activeColumns = useMemo(
    () =>
      columnsToRender
        .map((col, index) => (col.visible ? index : null))
        .filter((index) => index !== null),
    [columnsToRender],
  );

  useEffect(() => {
    if (selectedCell.row === null || selectedCell.col === null) {
      return;
    }
    const index = selectedCell.row * activeColumns.length + selectedCell.col;
    const inputElement = inputRefs.current[index];

    if (inputElement) {
      if (focusedCell.row === selectedCell.row && focusedCell.col === selectedCell.col) {
        inputElement.classList.remove('hidden-caret');
        inputElement.focus();
      } else {
        inputElement.classList.add('hidden-caret');
        inputElement.focus();
      }
    }
  }, [selectedCell, focusedCell]);

  useEffect(() => {
    if (
      inputRefs.current.length === rows.length &&
      inputRefs.current.every((ref) => ref !== null)
    ) {
    }
  }, [rows.length]);

  useEffect(() => {
    if (!rows) return;

    // 폼 리셋 후 contentEditable 필드 초기화
    for (const ref of contentEditableRefs.current) {
      if (ref) {
        ref.textContent = '';
      }
    }
  }, [rows]);

  return (
    <>
      <colgroup>
        <col className='w-[128px]' />
        <col className='w-[128px]' />
        <col className='w-[98px]' />
        <col className='w-[148px]' />
        <col className='w-[128px]' />
        <col className='w-[98px]' />
        <col className='w-[78px]' />
        <col className='min-w-[178px]' />
        <col className='w-[138px]' />
      </colgroup>
      <tbody>
        {visibleRows.map((row, rowIndex) => {
          const {
            name,
            chartNumber,
            isFirstVisit,
            countryCode,
            phoneNumber,
            birthdate,
            gender,
            nationality,
            treatmentTags,
            patientId,
          } = row;

          const order = rowIndex + 1;

          return (
            <tr key={patientId}>
              {activeColumns.map((colIndex, index) => {
                switch (colIndex) {
                  case 0:
                    return (
                      <DisabledTableCell
                        key={`name-${order - 1}`}
                        value={name}
                        placeholder='예) 홍길동'
                        className={`w-[${COLUMN_WIDTHS.name}]`}
                      />
                    );

                  case 1:
                    return (
                      <DisabledTableCell
                        key={`chartNumber-${order - 1}`}
                        value={chartNumber}
                        placeholder='예) 00000'
                        className={`w-[${COLUMN_WIDTHS.chartNumber}]`}
                      />
                    );

                  case 2:
                    return (
                      <td
                        key={`isFirstVisit-${order - 1}`}
                        className={`w-[${COLUMN_WIDTHS.isFirstVisit}] border-line-table border-y border-r bg-disabled`}>
                        <SingleTagSelectBoxTagDropdownForTable
                          placeholder='선택'
                          disabled={true}
                          defaultSelectedTag={
                            isFirstVisit !== undefined
                              ? {
                                  name: isFirstVisit
                                    ? VISIT_DISPLAY.FIRST_VISIT
                                    : VISIT_DISPLAY.REVISIT,
                                }
                              : undefined
                          }
                          tagOptions={VISIT_OPTIONS.map((option) => ({
                            name: option,
                          }))}
                        />
                      </td>
                    );

                  case 3:
                    return (
                      <td
                        key={`countryCode-${order - 1}`}
                        className={`w-[${COLUMN_WIDTHS.countryCode}] border-line-table border-y border-r bg-disabled`}>
                        <SingleTagSelectBoxTagDropdownForTable
                          placeholder='선택'
                          disabled={true}
                          defaultSelectedTag={
                            countryCode
                              ? {
                                  name: `${countryCode.koreanCountryName}(${countryCode.internationalDialingCodes})`,
                                  id: countryCode.id,
                                }
                              : {
                                  name: '한국(+82)',
                                  id: KOREA_COUNTRY_CODE_ID,
                                }
                          }
                          tagOptions={countriesListWithDialingCodes}
                        />
                      </td>
                    );

                  case 4:
                    return (
                      <DisabledTableCell
                        key={`phoneNumber-${order - 1}`}
                        value={phoneNumber}
                        placeholder='예) 01012345678'
                        className={`w-[${COLUMN_WIDTHS.phoneNumber}]`}
                      />
                    );

                  case 5:
                    return (
                      <DisabledTableCell
                        key={`birthdate-${order - 1}`}
                        value={birthdate}
                        placeholder='예) YYYY-MM-DD'
                        className={`w-[${COLUMN_WIDTHS.birthdate}]`}
                      />
                    );

                  case 6:
                    return (
                      <td
                        key={`gender-${order - 1}`}
                        className={`w-[${COLUMN_WIDTHS.gender}] border-line-table border-y border-r bg-disabled`}>
                        <SingleTagSelectBoxTagDropdownForTable
                          placeholder='선택'
                          disabled={true}
                          onSelectedTagChange={(tag) => {
                            setValue(
                              `rows[${order - 1}].gender`,
                              tag?.name === GENDER_DISPLAY.MALE
                                ? GENDER_OPTIONS[0]
                                : GENDER_OPTIONS[1],
                            );
                            setValue(`rows[${order - 1}].patientId`, patientId);
                          }}
                          defaultSelectedTag={
                            gender !== undefined
                              ? {
                                  name: GENDER_DISPLAY[gender],
                                }
                              : undefined
                          }
                          tagOptions={countriesListWithDialingCodes}
                        />
                      </td>
                    );

                  case 7:
                    return (
                      <TableCell
                        key={`treatmentTags-${order - 1}`}
                        onClick={() => handleCellClick(rowIndex, index)}
                        rowIndex={order - 1}
                        selectedCell={selectedCell}
                        colIndex={index}
                        className='min-w-[150px]'>
                        <Controller
                          control={control}
                          name={`rows[${order - 1}].treatmentTagIds`}
                          render={({ field }) => (
                            <MultipleTagsSelectBoxTagDropdownForTable
                              {...field}
                              ref={(el) => {
                                contentEditableRefs.current[rowIndex] = el;
                                inputRefs.current[rowIndex * activeColumns.length + index] = el;
                              }}
                              isFocused={renderIsFocused(order - 1, index, focusedCell)}
                              isSelected={renderIsSelected(order - 1, index, selectedCell)}
                              placeholder='예) 도수치료, 리프팅'
                              tagOptions={
                                treatmentTagsList?.map((tag) => ({
                                  name: tag.name,
                                  id: tag.tagId,
                                  color: tag.color,
                                })) ?? []
                              }
                              defaultSelectedTags={treatmentTags}
                              onSelectedTagsChange={(tags) => {
                                setValue(
                                  `rows[${order - 1}].treatmentTagIds`,
                                  tags.map((tag) => tag.id),
                                );
                                setValue(`rows[${order - 1}].patientId`, patientId);
                              }}
                            />
                          )}
                        />
                      </TableCell>
                    );

                  case 8:
                    return (
                      <TableCell
                        key={`nationality-${order - 1}`}
                        onClick={() => handleCellClick(rowIndex, index)}
                        rowIndex={order - 1}
                        selectedCell={selectedCell}
                        colIndex={index}
                        className='w-[140px]'>
                        <Controller
                          control={control}
                          name={`rows[${order - 1}].nationalityId`}
                          defaultValue={nationality ? nationality.id : undefined}
                          render={({ field }) => (
                            <SingleTagSelectBoxTagDropdownForTable
                              {...field}
                              ref={(el) => {
                                contentEditableRefs.current[rowIndex] = el;
                                inputRefs.current[rowIndex * activeColumns.length + index] = el;
                              }}
                              placeholder='선택'
                              isFocused={renderIsFocused(order - 1, index, focusedCell)}
                              isSelected={renderIsSelected(order - 1, index, selectedCell)}
                              defaultSelectedTag={
                                nationality !== undefined && nationality !== null
                                  ? { name: nationality.koreanCountryName }
                                  : undefined
                              }
                              tagOptions={countriesList.map((item) => ({
                                name: item.koreanCountryName ?? '',
                                id: item.countryId,
                              }))}
                              onSelectedTagChange={(tag) => {
                                if (tag?.id) {
                                  field.onChange(tag.id);
                                } else {
                                  field.onChange(undefined);
                                }
                              }}
                            />
                          )}
                        />
                      </TableCell>
                    );

                  default:
                    return null;
                }
              })}
            </tr>
          );
        })}
      </tbody>
    </>
  );
}

export default memo(UnTaggedCustomerManagementEditableTableBody);
