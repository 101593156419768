import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import { SHARED_UTILS } from '@shared-utils/utils';
import { useAtomValue } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { apiClient } from 'web/apis/instances/api-client';
import { chattingRequestState } from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/states/chatting-request';
import { chattingResponseState } from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/states/chatting-response';
import { isSendingMessagesState } from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/states/isSendingMessages';
import { kakaoIntegrationAxiosInstance } from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingSending/api/kakaoIntegrationAxiosInstance';
import { useHandleChatSocket } from '../../../../../hooks/use-handle-chat-socket';
import { useChatMessageContext } from '../../../ChatMessageContext';
import type { SendIKakaoTextPayload } from '../../../types/message';

export default function BottomError({ isFailed }: { isFailed: boolean }) {
  const { _id } = useChatMessageContext();
  const req = useAtomValue(chattingRequestState);
  const resetIsSendingMessages = useResetAtom(isSendingMessagesState);
  const resetReq = useResetAtom(chattingRequestState);
  const resetRes = useResetAtom(chattingResponseState);
  const { sendMessage } = useHandleChatSocket({ enableListening: false });

  const handleResend = async () => {
    switch (req?.type) {
      case 'ikakao': {
        const { payload } = req;
        await sendMessage(req);
        const { data } = await kakaoIntegrationAxiosInstance.post<SendIKakaoTextPayload>(
          '/biz/chat/write',
          payload,
        );
        console.info('resend res data', data);
        break;
      }
      case 'text': {
        sendMessage(req);
        break;
      }
      default: {
        console.error('unknown resend type');
      }
    }
  };

  const handleRemove = async () => {
    if (isFailed) {
      const { data } = await apiClient.v3.apiChatElRemove({
        _id,
        isRemoved: true,
      });
      console.info(SHARED_UTILS.api.checkApiResponse(data));
    }

    resetReq();
    resetRes();
    resetIsSendingMessages();
  };

  return (
    <div className='flex justify-end gap-6'>
      <div className='flex cursor-pointer' onClick={handleResend}>
        <div className='px-6 text-Body11 text-red500'>재전송</div>
        <Icon name='replay' color='red500' size={16} />
      </div>
      <div>
        <div className='cursor-pointer px-6 text-Body11' onClick={handleRemove}>
          삭제
        </div>
      </div>
    </div>
  );
}
