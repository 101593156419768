import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import { focusedContentSubLayerState } from '@templates/Content/containers/ContentPanel/states/focused-content-sub-layer-state';
import { AnimatePresence, motion } from 'framer-motion';

import { useAtom } from 'jotai/index';
import { useEffect, useState } from 'react';
import { usePatient } from 'web/templates/CustomerChat/hooks/use-patient';
import type { ManusFolders } from '../../hooks/use-manus-folders';
import TagDetailPanel from './components/TagDetailPanel/TagDetailPanel';

interface ContentPanelFolderItemProps {
  id: string;
  childID: string;
  folderItem: ManusFolders;
  folderIndex: number;
  itemIndex: number;
  isOpen: boolean;
  isManualDetail: boolean;
}

export default function ManualPanelFolderItem({
  childID,
  id,
  folderItem,
  folderIndex,
  itemIndex,
  isOpen,
  isManualDetail: isManualDetailDefault,
}: ContentPanelFolderItemProps) {
  const [focusedContentSubLayer, setFocusedContentSubLayer] = useAtom(focusedContentSubLayerState);
  const [isManualDetail, setIsManualDetail] = useState<boolean>(false);
  useEffect(() => {
    setIsManualDetail(isManualDetailDefault);
  }, [isManualDetailDefault]);

  const { treatmentTags } = usePatient();
  const treatmentTagsIds = treatmentTags?.map((tag) => tag.tagId) ?? [];
  const isInTreatmentTagsIds = treatmentTagsIds.includes(childID);

  const item = folderItem.items[itemIndex];

  const handleClick = async () => {
    setFocusedContentSubLayer(item);
    setIsManualDetail(!isManualDetail);
  };

  return (
    <AnimatePresence initial={false}>
      {isOpen && (
        <motion.ul
          key={`motion-ul-${id}`}
          initial={isOpen ? { height: 0, opacity: 0 } : { height: 'auto', opacity: 1 }}
          animate={{ height: 'auto', opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          transition={{ duration: 0.4 }}
          className='overflow-hidden'>
          <li className='px-8 py-4'>
            <div
              onClick={handleClick}
              className={`flex w-full cursor-pointer items-center justify-start gap-4 rounded-r6 py-11 pr-20 pl-56 text-Body12 hover:bg-blueLight ${
                isInTreatmentTagsIds
                  ? 'bg-purple100 font-bold text-black500'
                  : focusedContentSubLayer?.key === item.key
                    ? 'bg-blue50 font-bold text-black500'
                    : 'bg-white50 font-regular text-black500'
              }`}>
              <Icon name={isManualDetail ? 'chevron-down' : 'chevron-right'} size={16} />
              <Icon name='tag' size={20} customColor={item.color} />

              {item.text}
            </div>
          </li>
          {isManualDetail && <TagDetailPanel treatmentTagId={item.key} />}
        </motion.ul>
      )}
    </AnimatePresence>
  );
}
