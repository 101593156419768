import OutlinedButton from '@afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import { memo, useMemo } from 'react';
import type { ApiServiceSettingsElToBeDisplayedPatientsFieldsData } from 'web/apis/swaggers/swagger-docs';
import { EDITABLE_TABLE_HEADERS } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementEditableTableHeader/constants/table';

type CustomerManagementEditableTableFooterProps = {
  handleAddRow: () => void;
} & ApiServiceSettingsElToBeDisplayedPatientsFieldsData['data'];

const CustomerManagementEditableTableFooter = ({
  handleAddRow,
  ...usingColumns
}: CustomerManagementEditableTableFooterProps) => {
  const {
    usingChartNumber,
    usingFirstVisitStartDate,
    usingCountryCode,
    usingBirthDay,
    usingGender,
    usingTreatmentTag,
    usingNationality,
  } = usingColumns;

  const headers = useMemo(
    () =>
      EDITABLE_TABLE_HEADERS.filter((header) => {
        if (usingChartNumber && header === '차트번호') return true;
        if (usingFirstVisitStartDate && header === '초/재진') return true;
        if (usingCountryCode && header === '국가번호*') return true;
        if (usingBirthDay && header === '생년월일') return true;
        if (usingGender && header === '성별') return true;
        if (usingNationality && header === '국적') return true;
        if (usingTreatmentTag && header === '치료태그') return true;

        if (header === 'No') return true;
        if (header === '고객명*') return true;
        if (header === '휴대폰번호*') return true;

        return false;
      }),
    [
      usingChartNumber,
      usingFirstVisitStartDate,
      usingCountryCode,
      usingBirthDay,
      usingGender,
      usingNationality,
      usingTreatmentTag,
    ],
  );

  return (
    <tfoot>
      <tr>
        <td colSpan={headers.length}>
          <OutlinedButton
            className='mx-auto my-10'
            onClick={handleAddRow}
            iconProps={{
              name: 'add-circle',
              color: 'blue500',
              size: 20,
            }}>
            입력칸 50개 추가
          </OutlinedButton>
        </td>
      </tr>
    </tfoot>
  );
};

export default memo(CustomerManagementEditableTableFooter);
