import { atomWithReset } from 'jotai/utils';
import type { ApiPatientsElTemporaryListOrSearchParams } from 'web/apis/swaggers/swagger-docs';

export interface EditableHeaderFilterState {
  isFirstVisit: ApiPatientsElTemporaryListOrSearchParams['isFirstVisit'] | 'all';
  countryCode: ApiPatientsElTemporaryListOrSearchParams['countryCode'];
  gender: ApiPatientsElTemporaryListOrSearchParams['gender'];
  treatmentCategories: ApiPatientsElTemporaryListOrSearchParams['treatmentCategories'];
  nationality: ApiPatientsElTemporaryListOrSearchParams['nationality'];
}

export const editableHeaderFilterState = atomWithReset<EditableHeaderFilterState>({
  isFirstVisit: undefined,
  countryCode: undefined,
  gender: undefined,
  treatmentCategories: undefined,
  nationality: undefined,
});
