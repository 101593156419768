import ProgressBar from '@afterdoc-design-system/components/Molecules/ProgressBar/ProgressBar';
import { useSetAtom } from 'jotai';
import type { ErrorMessage } from 'react-use-downloader/dist/types';
import { deleteFileProgressAtom, setFileProgressAtom } from '../../states/chatFileProgress';
import { progressStatusDisplay } from './progressStatusDisplay';

export default function ProgressItem({
  fileName,
  progress,
  error,
}: { fileName: string; progress: number; error: ErrorMessage | null }) {
  const deleteFileProgress = useSetAtom(deleteFileProgressAtom);
  const reloadFileProgress = useSetAtom(setFileProgressAtom);
  const cancelFileProgress = useSetAtom(setFileProgressAtom);
  const status = progressStatusDisplay({ progress, error });
  const deleteClickCallback = () => {
    if (status === 'complete' || status === 'error') {
      deleteFileProgress(fileName);
    } else {
      cancelFileProgress({ fileName, triggerCancel: true });
    }
  };
  const reloadClickCallback = () => {
    reloadFileProgress({ fileName, triggerReload: true });
  };
  return (
    //TODO - onFileOpenClickCallback은 IPC 통신과 함께
    <ProgressBar
      fileName={fileName}
      status={status}
      percentage={progress}
      onDeleteClickCallback={deleteClickCallback}
      onReloadClickCallback={reloadClickCallback}
      onFileOpenClickCallback={() => {}}
    />
  );
}
