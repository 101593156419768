import IconButton from '@afterdoc-design-system/components/Atoms/Button/IconButton';
import { useAtomValue } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { type RefObject, forwardRef, useEffect } from 'react';
import { scrollToBottom } from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/functions/scrollToBottom';
import { useInfiniteChatData } from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/hooks/use-infinite-chat-data';
import { selectedMessageIdState } from 'web/templates/CustomerChat/components/SupportBot/states/selected-message-id';

export const MoveBelowButton = forwardRef<HTMLDivElement>((_, ref) => {
  const selectedMessageId = useAtomValue(selectedMessageIdState);
  const resetSelectedMessageId = useResetAtom(selectedMessageIdState);

  const { refetch } = useInfiniteChatData();

  const moveBelow = () => {
    resetSelectedMessageId();
    refetch();

    scrollToBottom(ref as RefObject<HTMLDivElement>);
  };

  useEffect(() => {
    if (selectedMessageId === null) {
      scrollToBottom(ref as RefObject<HTMLDivElement>);
    }
  }, [selectedMessageId]);

  return (
    <div className='absolute bottom-10 flex w-full flex-center'>
      <div
        className='flex h-40 w-40 items-center justify-center rounded-full border border-none bg-black100'
        onClick={moveBelow}>
        <IconButton icon='chevron-down' color='white50' size={32} />
      </div>
    </div>
  );
});
