import * as React from "react";
import type { SVGProps } from "react";
const SvgH3 = (props: SVGProps<SVGSVGElement>) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 18" {...props}>
    <path
      d="M10 4v10c0 .6-.4 1-1 1s-1-.4-1-1v-4H3v4c0 .6-.4 1-1 1s-1-.4-1-1V4c0-.6.4-1 1-1s1 .4 1 1v4h5V4c0-.6.4-1 1-1s1 .4 1 1M13.9 15c1.5 0 2.7-.9 2.8-2.3v-.2c0-.7-.2-1.3-.8-1.9v-.3c.4-.5.6-1 .6-1.6v-.2c0-.6-.4-1.2-.9-1.6s-1.1-.6-1.8-.5c-1 0-1.9.4-2.4 1.2s-.2.4.1.7l.4.4c.2.1.4.2.7-.1.2-.3.6-.4 1-.4.5 0 .9.4.9.9 0 .3-.2.8-.6.9h-1.4c-.2 0-.3.2-.3.3v.8c0 .2.2.3.3.3H14c.6.2.8.5.8 1s0 .6-.3.8-.6.2-.9.2c-.4 0-.8-.3-1-.6-.4-.4-.6-.3-.8-.1l-.5.4c-.4.3-.3.6-.1.7.6.8 1.6 1.3 2.6 1.3Z"
      className="h3_svg__ql-fill"
    />
  </svg>
);
export default SvgH3;
