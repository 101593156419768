import type { KakaoConsultationTalkLinkageHandlerBodyRequest } from '@apis/swaggers/swagger-docs';
import { ISO_DATE_TIME_TIME_FORMAT } from '@shared/constants/iso-date-time-format';
import IntegrationStartDayPicker from '@templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/common/RequestInfoWrapper/BeStartDateTimeInput/IntegrationStartDayPicker';
import IntegrationStartTimePicker from '@templates/HospitalSetting/containers/ChannelIntegration/components/IntegrationFormWrapper/common/RequestInfoWrapper/BeStartDateTimeInput/IntegrationStartTimePicker';
import { toBeStartedAtErrorAtom } from '@templates/HospitalSetting/containers/ChannelIntegration/states/kakao-consult-form-error-state';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import HoverTooltip from 'afterdoc-design-system/components/Atoms/Tooltip/HoverTooltip';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import dayjs from 'dayjs';
import { useAtom } from 'jotai';
import { useCallback, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';

export default function BeStartDateTimeInput() {
  const { setValue, getValues } = useFormContext<KakaoConsultationTalkLinkageHandlerBodyRequest>();

  const [toBeStartedAtError, setToBeStartedAtError] = useAtom(toBeStartedAtErrorAtom);

  const [currentDate, setCurrentDate] = useState(() => getValues('toBeStartedAt') || '');

  const updateToBeStartedAt = (date: dayjs.Dayjs) => {
    const formattedDate = date.format(ISO_DATE_TIME_TIME_FORMAT);
    setToBeStartedAtError(false);
    setCurrentDate(formattedDate);
    setValue('toBeStartedAt', formattedDate);
  };

  const onHandleChangeDate = useCallback(
    (date: string, isValid: boolean) => {
      const cloneDate = dayjs(currentDate);
      if (isValid) {
        const newDate = dayjs(date).set('hour', cloneDate.hour()).set('minute', cloneDate.minute());
        if (newDate.isValid()) updateToBeStartedAt(newDate);
      } else {
        setValue('toBeStartedAt', '');
      }
    },
    [currentDate, setValue],
  );

  const onHandleChangeTime = useCallback(
    (time: string) => {
      const cloneDate = dayjs(currentDate);
      let newTime = dayjs(time);
      if (newTime.isValid() && cloneDate.isValid()) {
        newTime = newTime
          .set('year', cloneDate.year())
          .set('month', cloneDate.month())
          .set('date', cloneDate.date());
        if (newTime.isValid()) updateToBeStartedAt(newTime);
      } else {
        setValue('toBeStartedAt', '');
      }
    },
    [currentDate, setValue],
  );

  useEffect(() => {
    const toBeStartedAt = dayjs(currentDate);
    if (toBeStartedAt.isValid()) {
      const formattedDate = toBeStartedAt.format(ISO_DATE_TIME_TIME_FORMAT);
      setValue('toBeStartedAt', formattedDate);
    }
  }, [currentDate]);

  return (
    <div className='flex min-h-[32px] gap-10'>
      <div className='flex h-[32px] w-[140px] flex-shrink-0 items-center gap-6'>
        <LabelText isRequired width={'w-fit'}>
          <span className='text-blue500'>시작희망일시</span>
        </LabelText>
        <HoverTooltip
          position='topRight'
          text={'연동은 희망하시는 날로부터 영업일 기준 2~3일 소요됩니다.'}>
          <Icon name={'info-circle-outline'} size={16} />
        </HoverTooltip>
      </div>
      <div className='flex flex-grow flex-col'>
        <div className='flex gap-10'>
          <IntegrationStartDayPicker
            currentDate={currentDate}
            onHandleChangeDate={onHandleChangeDate}
          />
          <IntegrationStartTimePicker
            currentDate={currentDate}
            onHandleChangeTime={onHandleChangeTime}
          />
        </div>
        {toBeStartedAtError && (
          <span className='text-Body10 text-red500'>시작희망일시를 입력해 주세요.</span>
        )}
      </div>
    </div>
  );
}
