import dayjs from 'dayjs';
import { useRef } from 'react';

type shouldShowDateProps = {
  index: number;
  createdAt: string | undefined;
};

export const useDateDivider = () => {
  const lastDateRef = useRef<null | string>(null);

  const shouldShowDate = ({ index, createdAt }: shouldShowDateProps) => {
    if (!createdAt) return false;
    const dividerLineDate = dayjs(createdAt).format('YYYY년 M월 D일 dddd');

    //0번째 일 경우 이전 렌더링의 것을 보여주기 때문에 부자연스럽지만 방어 로직 추가
    if (lastDateRef.current === null || index === 0) {
      lastDateRef.current = dividerLineDate;
      return false;
    }

    if (dividerLineDate !== lastDateRef.current) {
      const temp = lastDateRef.current;
      lastDateRef.current = dividerLineDate;
      console;
      return temp;
    }

    return false;
  };

  return { shouldShowDate };
};
