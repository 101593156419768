import { useAtomValue } from 'jotai';
import type { apiClient } from 'web/apis/instances/api-client';
import RegisterCounselAutomationDialogTabs from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/components/RegisterCounselAutomationDialogTabs';
import CounselBaseSettingContainer from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselBaseSetting/CounselBaseSetting.container';
import CounselCommonReVisitMessageSettingContainer from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselCommonReVisitMessageSetting/CounselCommonReVisitMessageSetting.container';
import CounselMessageSettingContainer from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/CounselMessageSetting.container';
import { selectedCounselAutomationTabState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/states/selected-counsel-automation-tab-state';

export type CounselAutomationAPIFormValues = Parameters<
  typeof apiClient.v3.apiAutomationsElAlgorithmsSingle
>[0];

export default function RegisterCounselAutomationDialogContent() {
  const selectedAutomationTab = useAtomValue(selectedCounselAutomationTabState);

  return (
    <div className='w-full flex-col-center pt-40'>
      <RegisterCounselAutomationDialogTabs />
      {/* 기본설정 */}
      {selectedAutomationTab === 0 && <CounselBaseSettingContainer />}
      {/* 공통 재내원 안내 */}
      {selectedAutomationTab === 1 && <CounselCommonReVisitMessageSettingContainer />}
      {/* 메시지 */}
      {selectedAutomationTab === 2 && <CounselMessageSettingContainer />}
    </div>
  );
}
