import { apiClient } from '@apis/instances/api-client';
import type { File as MPFile } from '@apis/swaggers/swagger-docs';
import { PutObjectCommand, type PutObjectCommandInput } from '@aws-sdk/client-s3';
import { BUCKET_NAME, REGION, s3 } from '@shared/utils/fileUpload/constants/awsConfig';
import type { AwsResult, s3FileUploadParams } from '@shared/utils/fileUpload/types/types';
import dayjs from 'dayjs';

type ServerFileUploadParams = {
  userID: string;
  type: number;
  paths: string[];
  aws: AwsResult[];
};

async function uploadFiles({ files, sizes, type, userID, flags }: s3FileUploadParams) {
  let fileArr: File[] = [];

  if (Array.isArray(files)) {
    fileArr = files;
  }

  const model: ServerFileUploadParams = {
    userID,
    type,
    paths: [],
    aws: [],
  };

  const awsResults: AwsResult[] = await Promise.all(
    fileArr.map((file, i) => uploadFile(file, sizes[i], userID)),
  );

  for (const result of awsResults) {
    model.paths.push(result.Location);
    model.aws.push(result);
  }

  if (flags) {
    flags.push(true);
  }

  return await createFileToServerRequest(model);
}

async function uploadFile(file: File, size: object, userID: string): Promise<AwsResult> {
  const params: PutObjectCommandInput = {
    Bucket: BUCKET_NAME,
    Key: `${userID}/${dayjs().format('YYYYMMDDHHmmss')}/${file.name}`,
    Body: file,
    ACL: 'public-read',
  };

  try {
    const command = new PutObjectCommand(params);
    const response = await s3.send(command);
    const encodedKey = encodeURIComponent(params.Key || '');

    return {
      Bucket: BUCKET_NAME,
      Key: params.Key,
      key: params.Key,
      Location: `https://${BUCKET_NAME}.s3.${REGION}.amazonaws.com/${encodedKey}`,
      ETag: response.ETag,
      size,
      fileName: file.name,
      fileSize: file.size,
    };
  } catch (err) {
    console.error('Error uploading file to AWS-S3:', err);
    throw err;
  }
}

const createFileToServerRequest = async (params: ServerFileUploadParams) => {
  try {
    const response = await apiClient.v3.apiFiles(params);
    if (response.data.code !== 0 || !response.data.data) {
      throw new Error(response.data.message);
    }
    return response.data.data as MPFile;
  } catch (error) {
    throw new Error(`Error uploading file to server: ${error ?? ''}`);
  }
};

export const FileUploader = {
  uploadFiles,
};
