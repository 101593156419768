import WeekPicker from '@afterdoc-design-system/components/Atoms/Calendar/WeekPicker';
import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import { SHARED_UTILS } from '@shared-utils/utils';
import { useSuspenseQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { Suspense, forwardRef, memo, useRef, useState } from 'react';
import { apiClient } from 'web/apis/instances/api-client';
import type { ApiAutomationsElDashboardParams } from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import ActiveCustomersCardItem from 'web/templates/Automation/containers/Main/DashBoardContent/components/ActiveCustomersCardItem';
import ConsultationCompletionRateCardItem from 'web/templates/Automation/containers/Main/DashBoardContent/components/ConsultationCompletionRateCardItem';
import ConsultationResponseSpeedCardItem from 'web/templates/Automation/containers/Main/DashBoardContent/components/ConsultationResponseSpeedCardItem';
import ManagedCustomersCardItem from 'web/templates/Automation/containers/Main/DashBoardContent/components/ManagedCustomersCardItem';

interface WeekOfMonthInfo {
  year: number;
  month: number;
  week: number;
}

const getWeekOfMonth = (date: Date): WeekOfMonthInfo => {
  const dayjsDate = dayjs(date);
  const startOfMonth = dayjsDate.startOf('month');

  // 첫 번째 목요일을 찾습니다.
  let firstThursday = startOfMonth.day(4);
  if (firstThursday.isBefore(startOfMonth)) {
    firstThursday = firstThursday.add(1, 'week');
  }

  // 첫 번째 목요일이 포함된 주의 시작일(일요일)을 찾습니다.
  const week1Start = firstThursday.startOf('week');

  // 선택한 날짜가 첫 번째 주의 시작일보다 이전이면 이전 달로 계산합니다.
  if (dayjsDate.isBefore(week1Start, 'day')) {
    const previousMonthEnd = dayjsDate.subtract(1, 'month').endOf('month');
    return getWeekOfMonth(previousMonthEnd.toDate());
  }

  // 주차를 계산합니다.
  const weekNumber = dayjsDate.diff(week1Start, 'week') + 1;

  return {
    year: dayjsDate.year(),
    month: dayjsDate.month() + 1,
    week: weekNumber,
  };
};

const fetchAutomationsElDashboard = async (params: ApiAutomationsElDashboardParams) => {
  const response = await apiClient.v3.apiAutomationsElDashboard(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export default function DashBoardContent() {
  const weekPickerIgnoreRef = useRef<HTMLDivElement>(null);

  const [isWeekDropdownOpen, setIsWeekDropdownOpen] = useState(false);
  const [selectedDate, onChangeDate] = useState(new Date());

  const { year, month, week } = getWeekOfMonth(selectedDate);

  return (
    <div>
      <div className='flex items-center gap-20'>
        <div className='flex h-[70px] items-center text-Header16 text-black700'>대시보드</div>
        <div className='flex items-center gap-12'>
          <div className='relative h-auto'>
            <CalendarButton
              ref={weekPickerIgnoreRef}
              text={`${year}년 ${month}월 ${week}주`}
              onClick={() => setIsWeekDropdownOpen(!isWeekDropdownOpen)}
            />

            {isWeekDropdownOpen && (
              <WeekPicker
                selectedDate={selectedDate}
                onChangeDate={(date) => onChangeDate(date)}
                wrapperClassName='absolute top-25 w-[400px] -left-[150px]'
                handleToggle={() => setIsWeekDropdownOpen(!isWeekDropdownOpen)}
                ignoreRefs={[weekPickerIgnoreRef]}
                disableAfterDate={new Date()}
              />
            )}
          </div>
        </div>
      </div>

      <Suspense
        fallback={
          <div className='mt-10 flex flex-col gap-10 rounded-r16 bg-[#E5EEF8] p-10'>
            {Array.from({ length: 4 }).map((_, index) => (
              <div
                key={index}
                className='h-full min-h-[210px] w-full max-w-[360px] rounded-r10 border border-white400 bg-white50'
              />
            ))}
          </div>
        }>
        <DashBoardCardLists selectedDate={selectedDate} />
      </Suspense>
    </div>
  );
}

interface DashBoardCardListsProps {
  selectedDate: Date;
}

const DashBoardCardLists = memo(({ selectedDate }: DashBoardCardListsProps) => {
  const { hospitalID } = useSelectedHospitalInfo();
  const { year, month, week } = getWeekOfMonth(selectedDate);
  const { data } = useSuspenseQuery({
    queryKey: [
      'automationsElDashboard',
      {
        hospitalID,
        year,
        month,
        week,
      },
    ] as const,
    queryFn: ({ queryKey }) => fetchAutomationsElDashboard(queryKey[1]),
  });

  return (
    <div className='mt-10 flex flex-col gap-10 rounded-r16 bg-[#E5EEF8] p-10'>
      <div className='flex flex-col gap-y-16'>
        <ManagedCustomersCardItem managedCustomers={data.managedCustomers} />
        <ActiveCustomersCardItem activeCustomers={data.activeCustomers} />
        <ConsultationCompletionRateCardItem
          consultationCompletionRate={data.consultationCompletionRate}
        />
        <ConsultationResponseSpeedCardItem
          consultationResponseSpeed={data.consultationResponseSpeed}
        />
      </div>
    </div>
  );
});

interface CalendarButtonProps {
  text: string;
  onClick: () => void;
}

const CalendarButton = memo(
  forwardRef<HTMLDivElement, CalendarButtonProps>(({ text, onClick }, ref) => (
    <div
      ref={ref}
      onClick={onClick}
      className='flex cursor-pointer gap-19 rounded-r16 bg-white50 py-4 pr-6 pl-10'>
      <div className='text-Header16 text-black500'>{text}</div>
      <div className='rounded-r10 bg-white200 p-2'>
        <Icon name='chevron-down' size={16} color='white800' />
      </div>
    </div>
  )),
);

CalendarButton.displayName = 'CalendarButton';
