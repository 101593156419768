export const WIDTH = 115;
export const OPTIONS = ['예약하기', '상세정보', '채팅방 나가기'];
export const OPTION_USER_ADD = '담당자 추가';
export const ADDITIONAL_OPTIONS = [OPTION_USER_ADD, '상담 종료'];
export const AUTH_CONTROL_OPTIONS = [
  ...OPTIONS.slice(0, 2), // '예약하기', '상세정보'
  OPTION_USER_ADD,
  '상담 종료',
  OPTIONS[2], // '채팅방 나가기'
];

export const RED_TEXT_AUTH_CONTROL = [ADDITIONAL_OPTIONS[1], OPTIONS[2]]; // '상담 종료', '채팅방 나가기'
export const RED_TEXT_DEFAULT = [OPTIONS[2]]; // '채팅방 나가기'
