import { SHARED_UTILS } from '@shared-utils/utils';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useMemo } from 'react';
import { useFormContext } from 'react-hook-form';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type {
  ApiAutomationsElAlgorithmsMessagesParams,
  SingleAlgorithmHandlerParams,
} from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import type { CounselAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/components/RegisterCounselAutomationDialogContent';
import {
  selectedContentTabIndexState,
  selectedMessageState,
} from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-form';
import { messageModeState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-mode';
import { originMessagesState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/origin-message';

const fetchSingleAlgorithmInfo = async (params: SingleAlgorithmHandlerParams) => {
  const response = await apiClient.v3.singleAlgorithmHandler(params);
  return response.data.data;
};

const fetchAutomationsElAlgorithmsMessages = async (
  params: ApiAutomationsElAlgorithmsMessagesParams,
) => {
  const response = await apiClient.v3.apiAutomationsElAlgorithmsMessages(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export const useAlgorithmData = () => {
  const { setValue, getValues } = useFormContext<CounselAutomationAPIFormValues>();
  const { hospitalID } = useSelectedHospitalInfo();

  const setSelectedContentTabIndex = useSetAtom(selectedContentTabIndexState);
  const setOriginMessages = useSetAtom(originMessagesState);
  const setSelectedMessage = useSetAtom(selectedMessageState);
  const messageMode = useAtomValue(messageModeState);

  const { data: algorithmData } = useQuery({
    queryKey: [
      'singleAlgorithm',
      {
        algorithmId: messageMode.mode !== 'CREATE' ? messageMode.algorithmId : '',
        hospitalID,
      },
    ] as const,
    enabled: messageMode.mode !== 'CREATE' && !!messageMode.algorithmId,
    queryFn: ({ queryKey }) => {
      const algorithmId = queryKey[1].algorithmId;
      if (!algorithmId) return;

      return fetchSingleAlgorithmInfo({
        algorithmId,
        hospitalID,
      });
    },
  });

  const { data: algorithmMessagesData } = useQuery({
    queryKey: [
      QUERY_KEY.apiAutomationsElAlgorithmsMessages,
      {
        algorithmId: messageMode.mode !== 'CREATE' ? messageMode.algorithmId : '',
        hospitalID,
      },
    ] as const,
    queryFn: ({ queryKey }) => {
      if (messageMode.mode !== 'CREATE') {
        return fetchAutomationsElAlgorithmsMessages(queryKey[1]);
      }
    },
    enabled: messageMode.mode !== 'CREATE',
    select: (data) =>
      data
        ?.filter((message) => !message.isCommonAlgorithmMessage)
        .map((message) => ({
          ...message,
          hasApprovedAlimtalkTemplate: message.hasApprovedAlimtalkTemplate ?? false,
        })),
  });

  const messagesData: CounselAutomationAPIFormValues['messages'] = useMemo(
    () =>
      algorithmMessagesData?.map(
        ({
          id,
          name,
          daysAfter,
          sendingType,
          content,
          consultationGuide,
          showConsultationGuide,
          timeOfDay,
        }) => {
          return {
            id,
            name,
            daysAfter,
            sendingType,
            content: {
              text: content?.text,
              image: JSON.stringify(
                content?.images?.map((image) => ({
                  id: content.imageFileID,
                  url: image,
                })),
              ),
              video: JSON.stringify(content?.video),
              workoutVideo: JSON.stringify(content?.workoutVideo),
              homecareContent: JSON.stringify(content?.homecareContent),
              event: JSON.stringify(content?.event),
              survey: {
                question: content?.survey?.question,
                answers: content?.survey?.answers,
              },
            },
            consultationGuide,
            showConsultationGuide,
            timeOfDay,
          };
        },
      ) || [],
    [algorithmMessagesData],
  );

  useEffect(() => {
    if (messageMode.mode === 'EDIT' && algorithmMessagesData && messagesData && algorithmData) {
      const handleContentTabIndex = () => {
        const newContentTabIndex: {
          [key: number]: number;
        } = {};

        messagesData.forEach((message, index) => {
          if (message.content.image && message.content.image.length > 0) {
            newContentTabIndex[index] = 1;
          } else if (message.content.video) {
            newContentTabIndex[index] = 2;
          } else if (message.content.workoutVideo) {
            newContentTabIndex[index] = 3;
          } else if (message.content.homecareContent || message.content.event) {
            newContentTabIndex[index] = 4;
          } else {
            newContentTabIndex[index] = 0;
          }
        });

        setSelectedContentTabIndex((prev) => ({
          ...prev,
          ...newContentTabIndex,
        }));
      };
      handleContentTabIndex();

      const { name, tagsHaveFolder, tagsHaveNoFolder, commonAlgorithm, appliedNationalities } =
        algorithmData;

      // Set Base Data
      if (!getValues('name').length) {
        setValue('name', name);
      }

      if (
        getValues('commonAlgorithm')?.enabled !== false &&
        getValues('commonAlgorithm')?.sendingDaysAfterStart !== 60
      ) {
        setValue('commonAlgorithm', commonAlgorithm);
      }

      if (!getValues('targetTreatmentTags')?.length) {
        setValue('targetTreatmentTags', [
          ...tagsHaveFolder.flatMap(({ tags }) => tags.map((tag) => tag._id)),
          ...tagsHaveNoFolder.map((tag) => tag._id),
        ]);
      }

      if (!getValues('toBeAppliedNationalityIds')?.length) {
        setValue(
          'toBeAppliedNationalityIds',
          appliedNationalities.map((appliedNationality) => appliedNationality._id),
        );
      }

      // Set Messages
      if (!getValues('messages').length) {
        setValue('messages', messagesData);
      }
      setOriginMessages(algorithmMessagesData);
      setSelectedMessage({
        ...messagesData[0],
        index: 0,
      });
      return;
    }

    if (messageMode.mode === 'COPY' && algorithmMessagesData && algorithmData && messagesData) {
      const { name, tagsHaveFolder, tagsHaveNoFolder, commonAlgorithm, appliedNationalities } =
        algorithmData;

      // Set Base Data
      if (!getValues('name').length) {
        setValue('name', name);
      }

      if (
        getValues('commonAlgorithm')?.enabled !== false &&
        getValues('commonAlgorithm')?.sendingDaysAfterStart !== 60
      ) {
        setValue('commonAlgorithm', commonAlgorithm);
      }

      if (!getValues('targetTreatmentTags')?.length) {
        setValue('targetTreatmentTags', [
          ...tagsHaveFolder.flatMap(({ tags }) => tags.map((tag) => tag._id)),
          ...tagsHaveNoFolder.map((tag) => tag._id),
        ]);
      }

      if (!getValues('toBeAppliedNationalityIds')?.length) {
        setValue(
          'toBeAppliedNationalityIds',
          appliedNationalities.map((appliedNationality) => appliedNationality._id),
        );
      }

      // Set Messages
      if (!getValues('messages').length) {
        setValue('messages', messagesData);
      }
      setOriginMessages(algorithmMessagesData);
      setSelectedMessage({
        ...messagesData[0],
        index: 0,
      });
      return;
    }
  }, [algorithmData, algorithmMessagesData, messageMode.mode]);
};
