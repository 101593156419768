import BaseLoading from '@afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import { modalService } from '@afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { useClickOutside } from '@shared-hooks/use-click-outside';
import { SHARED_UTILS } from '@shared-utils/utils';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { useAtomValue } from 'jotai';
import { useCallback, useId, useRef } from 'react';
import { SUCCESS } from 'web/shared/constants/codes';
import { useUserInfo } from 'web/shared/hooks/use-user-info';
import { useChatRoomInfoOne } from 'web/templates/CustomerChat/components/ChattingRoom/hooks/use-chatroom-info-one';
import { usePatient } from 'web/templates/CustomerChat/hooks/use-patient';
import { chatRoomIDAtom } from 'web/templates/CustomerChat/states/chatRoomID';
import { WIDTH } from '../../constants/dropdown';
import { postChatroomUserRemove } from '../../functions/postChatroomUserRemove';
import BottomButtons from '../BottomButtons/BottomButtons';
import CheckboxImOut from './components/CheckboxImOut/CheckboxImOut';
import { createTopPosition } from './functions/create-top-position';
import { displayAddNames } from './functions/display-add-names';
import { postChatroomCounselerAdd } from './functions/postChatroomCounselerAdd';
import { handleError, showToastMessage } from './functions/response-handle';
import { useGetHworkers } from './hooks/use-get-hworkers';
import { useRenderingPosition } from './hooks/use-rendering-position';
import { useToggleSelect } from './hooks/use-toggle-select';
import { isCheckedImOutState } from './states/is-checked-im-out';

type DropdownProps = {
  handleToggle: () => void;
  triggerPosition: { top: number; left: number };
  isOnMouseLeave: boolean;
};

export default function ChildDropdown({
  handleToggle,
  triggerPosition,
  isOnMouseLeave,
}: DropdownProps) {
  const { top, left } = triggerPosition;

  const id = useId();
  const { userId: userID } = useUserInfo();
  const chatRoomID = useAtomValue(chatRoomIDAtom);
  const { name } = usePatient();

  const dropdownRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);

  const { counselors, refetch } = useChatRoomInfoOne();
  const initailSelectedIDs = counselors
    .map((counselor) => counselor._id)
    .filter((id) => id !== userID);
  const { selectedUserIDs, toggleSelect, isSelected } = useToggleSelect(initailSelectedIDs);
  const isCheckboxChecked = useAtomValue(isCheckedImOutState);

  const { hworkers, isLoading } = useGetHworkers();

  const dropdownHeight = 30 * Math.min(hworkers.length + 1, 6) + 10;
  const { position } = useRenderingPosition({
    renderingRef: dropdownRef,
    renderingHeight: dropdownHeight,
  });

  useClickOutside({
    id,
    ref: dropdownRef,
    contentRef,
    onClose: handleToggle,
  });

  const onMouseLeaveOption = useCallback(() => {
    isOnMouseLeave && handleToggle();
  }, [isOnMouseLeave, handleToggle]);

  if (!chatRoomID) return null;

  const handleConfirmClick = async () => {
    try {
      isCheckboxChecked && (await postChatroomUserRemove({ chatRoomID, userIDs: [userID] }));

      const { code } = await postChatroomCounselerAdd({ chatRoomID, selectedUserIDs });
      const isSuccess = code === SUCCESS;

      showToastMessage(isSuccess);

      if (isSuccess) {
        modalService.pop();
        refetch();
      }
    } catch (error) {
      handleError(error);
    }
  };

  const handleModal = () => {
    const onlyAddIDs = selectedUserIDs.filter((id) => !initailSelectedIDs.includes(id));
    modalService.defaultSuccess({
      title: '담당자를 추가할까요?',
      contents: (
        <div className='flex flex-col gap-10'>
          <div>
            {name}님 채팅방에 {displayAddNames(onlyAddIDs, hworkers)}님을 담당자로 추가합니다.
          </div>
          <CheckboxImOut />
        </div>
      ),
      buttonType: 'CUSTOM',
      bottomButtons: BottomButtons({ handleConfirmClick: () => handleConfirmClick() }),
    });
  };
  9;
  const user = hworkers?.[0];
  //TODO: 실제 유저를 반영하는 것 상혁님 //TODO: 이것과 연결해서 내가 나가기 QA 해야함.
  // const user = hworkers.find(({ _id }) => _id === userID);
  const newHworkers = hworkers.filter(({ _id }) => _id !== user._id);
  const isDisabled = initailSelectedIDs.length === selectedUserIDs.length;

  return (
    <div
      ref={dropdownRef}
      className='relative'
      style={{ zIndex: 200 }}
      onMouseLeave={onMouseLeaveOption}>
      {
        <div
          ref={contentRef}
          className={customTwMerge(
            'absolute w-full select-none rounded-r6 bg-white50 py-4 shadow-modal ring-1 ring-black ring-opacity-5',
          )}
          data-ignore-click-outside={true}
          style={{
            top: createTopPosition({ top, dropdownHeight, position }),
            left,
            height: dropdownHeight,
            width: SHARED_UTILS.css.getCssSizeValue(WIDTH),
          }}>
          {isLoading ? (
            <div className='flex flex-center'>
              <BaseLoading />
            </div>
          ) : (
            <Scrollbar disabledX>
              <ul aria-orientation='vertical' aria-labelledby='options-menu' className='relative'>
                {user && (
                  <li
                    key={user._id}
                    className={customTwMerge(
                      'flex items-center justify-between px-10 py-7 text-Header12 text-disabled',
                    )}>
                    <span>{user.name}</span>
                    <Icon name='done' color='blue500' size={16} />
                  </li>
                )}
                {newHworkers.map(({ _id: id, name }, index) => (
                  <li
                    key={id}
                    tabIndex={index}
                    onClick={() => toggleSelect(id)}
                    className={customTwMerge(
                      'flex cursor-pointer items-center justify-between px-10 py-7 text-Body12 hover:bg-blue50 hover:text-Header12 focus:bg-blue50 focus:outline-none',
                      isSelected(id) ? 'text-Header12' : 'text-Body12',
                    )}
                    item-index={index}
                    aria-selected={isSelected(id) ? 'true' : undefined}>
                    <span className='line-clamp-1'>{name}</span>
                    {isSelected(id) && <Icon name='done' color='blue500' size={16} />}
                  </li>
                ))}
              </ul>
            </Scrollbar>
          )}
          <button
            disabled={isDisabled}
            type='button'
            onClick={handleModal}
            className={`flex w-full items-center justify-center rounded-r6 py-4 text-Body12 shadow-modal ${
              isDisabled
                ? 'cursor-not-allowed bg-white50 text-disabled'
                : 'bg-white50 text-blue500 hover:bg-blue50'
            }`}>
            추가
          </button>
        </div>
      }
    </div>
  );
}
