export const MAX_LENGTH = 1000;

export const COMMON_REPLACEABLE_TEXTS = [
  {
    text: '{{고객명}}',
    description: '해당 메시지를 수신 받을 고객명이 표시됩니다. 예) 홍길동',
    length: 15,
  },
  {
    text: '{{병원명}}',
    description: '병원명이 표시됩니다. 예) 애프터닥의원',
    length: 30,
  },
  {
    text: '{{치료태그명}}',
    description: '고객에게 첫번째로 등록된 치료태그명이 표시됩니다. 예) 도수치료',
    length: 50,
  },
  {
    text: '{{시술일}}',
    description: '태그를 입력한 날짜로 표시됩니다. 예) 2024년 1월 1일',
    length: 10,
  },
  {
    text: '{{경과일}}',
    description: '안내문구 추가 예정. 예) 30일',
    length: 5,
  },
  {
    text: '{{시술일+n일}}',
    description: '안내문구 추가 예정. 예) 2024년 1월 31일',
    length: 10,
  },
];
