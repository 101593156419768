import { overlayPageService } from '@afterdoc-design-system/components/Molecules/OverlayPage/OverlayPage.service';
import dayjs from 'dayjs';
import { useResetAtom } from 'jotai/utils';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { OVERLAY_PAGE_ID } from 'web/shared/constants/overlay-page-id';
import { useNavigationBlocker } from 'web/shared/hooks/use-navigation-blocker';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import RegisterMarketingAutomationDialogContent, {
  type MarketingAutomationAPIFormValues,
} from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/components/RegisterMarketingAutomationDialogContent';
import RegisterMarketingAutomationDialogHeader from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/components/RegisterMarketingAutomationDialogHeader';
import {
  checkedLayersState,
  checkedOrphanSubLayersState,
  checkedSubLayersState,
} from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingBaseSetting/states/selected-layers';

export default function RegisterMarketingAutomationDialogContainer() {
  const { hospitalID } = useSelectedHospitalInfo();

  const memoizedDefaultValues: MarketingAutomationAPIFormValues = useMemo(
    () =>
      ({
        hospitalID,
        name: '',
        targetTreatmentTags: undefined,
        toBeAppliedNationalityIds: undefined,
        messages: [],
        type: 'MARKETING',
        sendingSchedule: {
          sendingCount: 1,
          startDay: dayjs().hour(8).minute(0).second(0).millisecond(0).toISOString(),
        },
      }) as const,
    [hospitalID],
  );

  const methods = useForm<MarketingAutomationAPIFormValues>({
    mode: 'onChange',
    defaultValues: memoizedDefaultValues,
  });

  const {
    formState: { isDirty },
    reset,
  } = methods;

  const resetCheckedLayers = useResetAtom(checkedLayersState);
  const resetCheckedSubLayers = useResetAtom(checkedSubLayersState);
  const resetCheckedOrphanSubLayers = useResetAtom(checkedOrphanSubLayersState);

  const resetAll = () => {
    resetCheckedLayers();
    resetCheckedSubLayers();
    resetCheckedOrphanSubLayers();
    reset(memoizedDefaultValues);
  };

  const onCloseModal = () => {
    overlayPageService.popById(OVERLAY_PAGE_ID['register-marketing-automation-dialog']);
  };

  useNavigationBlocker({
    shouldBlock: isDirty,
    onConfirm: () => {
      resetAll();
      onCloseModal();
    },
  });

  return (
    <FormProvider {...methods}>
      <RegisterMarketingAutomationDialogHeader />
      <RegisterMarketingAutomationDialogContent />
    </FormProvider>
  );
}
