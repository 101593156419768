import { MESSAGE_TYPE_CODE } from '../../ChattingRoom/components/ChattingContainer/components/ChattingContent/components/ChattingMessage/constants/message';

export const displayTextContent = (
  type: number | undefined,
  defaultCallback: () => string,
): string => {
  switch (type) {
    case 300:
    case 12300:
    case 11300:
    case 20300:
      return '(사진을 보냈습니다.)';
    case 400:
    case 12400:
    case 410:
    case 12410:
    case 11400:
    case 11410:
      return '(동영상을 보냈습니다.)';
    case 500:
    case MESSAGE_TYPE_CODE.FILE_MOCKING:
      return '(파일을 보냈습니다.)';
    case 600:
      return '(예약상담을 보냈습니다.)';
    case 700:
    case 701:
    case 711:
    case 11700:
    case 12700:
      return '(이벤트를 보냈습니다.)';
    case 710:
      return '(홈케어상품을 보냈습니다.)';
    case 801:
      return '(공지사항을 보냈습니다.)';
    default:
      return defaultCallback();
  }
};
