import Divider from '@afterdoc-design-system/components/Atoms/Divider/Divider';
import Scrollbar from '@afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import { useAtomValue, useSetAtom } from 'jotai';
import AutomationMessageHeader from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/AutomationMessageHeader';
import MessageNameForm from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/MessageNameForm';
import SendContentForm from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/SendContentForm';
import SendDateForm from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendDateForm';
import ShowCounsellingOrNotForm from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/ShowCounsellingOrNotForm';
import { selectedMessageState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-form';
import { messageModeState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-mode';
import { useSubmitRegisterCounselAutomation } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/hooks/use-submit-register-counsel-automation';
import { selectedCounselAutomationTabState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/states/selected-counsel-automation-tab-state';
import PrevButton from 'web/templates/Automation/containers/shared/components/Buttons/PrevButton';
import RegisterButton from 'web/templates/Automation/containers/shared/components/Buttons/RegisterButton';

export default function MessageContentFormContainer() {
  const selectedMessage = useAtomValue(selectedMessageState);

  if (!selectedMessage) {
    return (
      <div className='flex h-[800px] w-[700px] items-end rounded-r16 bg-white50 px-40 py-20 shadow-modal'>
        <BottomDirectionButton />
      </div>
    );
  }

  return (
    <div className='h-[800px] min-w-[700px] max-w-[700px]'>
      <Scrollbar disabledX>
        <div className='min-w-[700px] max-w-[700px] flex-shrink-0 rounded-r16 bg-white50 px-40 py-20 shadow-modal'>
          {/* 자동화 메시지 헤더 */}
          <AutomationMessageHeader />
          <Divider type='line' className='my-20' />

          {/* 발송시점 */}
          <SendDateForm />
          <Divider type='line' className='my-20' />

          {/* 메시지명 */}
          <MessageNameForm />
          <Divider type='line' className='my-20' />

          {/* 내용 */}
          <SendContentForm />
          <Divider type='line' className='my-20' />

          {/* 상담가이드 */}
          <ShowCounsellingOrNotForm />

          {/* 하단 버튼 */}
          <BottomDirectionButton />
        </div>
      </Scrollbar>
    </div>
  );
}

const BottomDirectionButton = () => {
  const setSelectedAutomationTab = useSetAtom(selectedCounselAutomationTabState);

  const messageMode = useAtomValue(messageModeState);
  const { disabled, handleRegister } = useSubmitRegisterCounselAutomation();

  return (
    <div className='mx-auto mt-20 flex w-[200px] gap-8'>
      <PrevButton
        disabled={messageMode.mode === 'EDIT'}
        onClick={() => setSelectedAutomationTab(1)}
      />
      <RegisterButton
        onClick={() => {
          if (disabled) return;

          handleRegister();
        }}
        disabled={disabled}
      />
    </div>
  );
};
