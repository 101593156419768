import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useEffect, useRef, useState } from 'react';
import type { Socket } from 'socket.io-client';
import { useUserInfo } from 'web/shared/hooks/use-user-info';
import { selectedMessageIdState } from 'web/templates/CustomerChat/components/SupportBot/states/selected-message-id';
import { chatRoomIDAtom } from 'web/templates/CustomerChat/states/chatRoomID';
import type { ChattingResponse, TextChattingResponse } from 'web/templates/CustomerChat/types';
import { isActivatedChattingState } from '../../../../../../../states/is-activated-chatting';
import { isActivateChatTranslateAtom } from '../../../../../states/isChatTranslate';
import { chattingRequestState } from '../../../states/chatting-request';
import { isSendingMessagesState } from '../../../states/isSendingMessages';
import type {
  MessageType,
  PayloadTypeByMessageType,
} from '../components/ChattingMessage/types/message';
import { createSocketConnection } from '../functions/createSocketConnection';
import { withLogging } from '../functions/withLogging';
import { isSendingTranslateState } from '../states/is-sending-translate';
import { useInfiniteChatData } from './use-infinite-chat-data';

type WsParams = {
  enableListening?: boolean;
};

export const useHandleChatSocket = ({ enableListening = true }: WsParams = {}) => {
  const { userId: userID } = useUserInfo();
  const chatRoomID = useAtomValue(chatRoomIDAtom);
  const resetSelectedMessageId = useResetAtom(selectedMessageIdState);

  const { refetch } = useInfiniteChatData();

  const socketRef = useRef<Socket | null>(null);
  const [req, setReq] = useAtom(chattingRequestState);
  const [res, setRes] = useState<ChattingResponse | TextChattingResponse | null>(null);
  const resetReq = useResetAtom(chattingRequestState);

  const isActivatedChatting = useAtomValue(isActivatedChattingState);
  const isActivateChatTranslate = useAtomValue(isActivateChatTranslateAtom);
  const setIsSendingTranslate = useSetAtom(isSendingTranslateState);
  const setIsSendingMessages = useSetAtom(isSendingMessagesState);

  const isDisabled = !isActivatedChatting || userID === '' || chatRoomID === null;

  useEffect(() => {
    // res && refetch();
    if (res) {
      // resetRes();
      setRes(null);
      resetReq(); //이걸 넣으면 로딩이 안사라짐.
    }
  }, [res, refetch]);

  useEffect(() => {
    if (isDisabled) return;

    socketRef.current = createSocketConnection({ userID, chatRoomID });

    if (!enableListening) return;

    const handleReceivedMessage = async (event: ChattingResponse) => {
      console.info('received msg', event);

      const isIsTranslatedKey = event.isTranslated ?? false;
      const isSent = event.isSent ?? false;

      //TODO - 코드 개선 필요
      if (isActivateChatTranslate) {
        if (isIsTranslatedKey) {
          setIsSendingTranslate(false);
          setRes(event);
        }

        if (isSent) {
          setIsSendingMessages(false);
          setRes(event);
        }
      } else {
        if (isSent) {
          setIsSendingMessages(false);
          setRes(event);
        }
      }
    };

    const socket = socketRef.current;

    if (socket) {
      socket.on('msg', handleReceivedMessage);
    }

    return () => {
      if (socket) {
        socket.off('msg', handleReceivedMessage);
        socket.disconnect();
        socket.close();
        setIsSendingMessages(false);
        socketRef.current = null;
      }
    };
  }, [chatRoomID, userID, enableListening, isDisabled]);

  const sendMessage = <T extends MessageType>(typeAndMessage: PayloadTypeByMessageType<T>) => {
    console.info('sendMessage', typeAndMessage);
    if (isDisabled) return;

    setIsSendingMessages(true);
    resetSelectedMessageId();

    const { payload } = typeAndMessage;

    switch (typeAndMessage.type) {
      case 'text': {
        // setReq(typeAndMessage);
        isActivateChatTranslate && setIsSendingTranslate(true);
        break;
      }
    }

    if (socketRef.current) {
      socketRef.current.emit('msg', payload);
      console.info('send msg', payload);
    } else {
      withLogging({
        msg: 'WebSocket 연결이 되어있지 않습니다.',
        type: 'error',
      });
    }
  };

  return { sendMessage, req, res };
};
