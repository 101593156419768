import { SHARED_UTILS } from '@shared-utils/utils';
import { useQuery } from '@tanstack/react-query';
import { apiClient } from 'web/apis/instances/api-client';
import type { ApiHworkersElFindCounselorParams, User } from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';

//backend 수정하기
export interface TempUser extends User {
  _id: string;
}

const getHWorkers = async (query: ApiHworkersElFindCounselorParams) => {
  const { data } = await apiClient.v3.apiHworkersElFind(query);
  return SHARED_UTILS.api.checkApiResponse(data) as TempUser[];
};

export const useGetHworkers = () => {
  const { hospitalID } = useSelectedHospitalInfo();

  const { data, isLoading } = useQuery({
    queryKey: ['hworkers', { hospitalID }] as const,
    queryFn: ({ queryKey }) => getHWorkers(queryKey[1]),
  });

  return {
    hworkers: data ?? [],
    isLoading,
  };
};
