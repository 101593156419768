import { useChatMessageContext } from '../ChatMessageContext';
import FileDownload from './FileDownloadContainer';

export default function FileMessage() {
  const { fileIDs, isMine, messageWidthStyle } = useChatMessageContext();

  return (
    <div
      className={`rounded-r10 px-16 py-10 shadow-modal ${isMine ? 'bg-[rgba(255,245,208,1)]' : 'bg-white50'}`}
      style={messageWidthStyle}>
      {fileIDs?.map((fileID) => (
        <FileDownload key={fileID.createdAt} fileID={fileID} />
      ))}
    </div>
  );
}
