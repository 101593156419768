import { overlayPageService } from '@afterdoc-design-system/components/Molecules/OverlayPage/OverlayPage.service';
import { useMemo } from 'react';
import type { AlgorithmWithMessagesInput } from 'web/apis/swaggers/swagger-docs';
import { OVERLAY_PAGE_ID } from 'web/shared/constants/overlay-page-id';

interface AutomationZoomInOutViewerHeaderProps {
  type: Exclude<AlgorithmWithMessagesInput['type'], 'COMMON'>;
}

export default function AutomationZoomInOutViewerHeader({
  type,
}: AutomationZoomInOutViewerHeaderProps) {
  const title = useMemo(() => {
    if (type === 'MARKETING') {
      return '마케팅자동화 조회';
    }

    if (type === 'CONSULTATION') {
      return '관리자동화 조회';
    }
  }, [type]);

  const overlayPageId = useMemo(() => {
    if (type === 'CONSULTATION') {
      return OVERLAY_PAGE_ID['counsel-automation-zoom-in-out-viewer'];
    }

    if (type === 'MARKETING') {
      return OVERLAY_PAGE_ID['marketing-automation-zoom-in-out-viewer'];
    }
  }, [type]);

  return (
    <div className='flex w-full items-center justify-between bg-white50 px-20 pt-20 pb-8'>
      <div className='text-Header16 text-black700'>{title}</div>
      <button
        type='button'
        onClick={() => {
          if (!overlayPageId) return;
          overlayPageService.popById(overlayPageId);
        }}
        className='text-Body14 text-black700'>
        닫기
      </button>
    </div>
  );
}
