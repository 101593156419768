import { useAtomValue } from 'jotai';
import { useEffect } from 'react';
import { selectedMessageIdState } from 'web/templates/CustomerChat/components/SupportBot/states/selected-message-id';

type UseRefetchOnMessagesChangeProps = {
  refetch: () => void;
};

export function useRefetchOnMessagesChange({ refetch }: UseRefetchOnMessagesChangeProps) {
  const selectedMessageId = useAtomValue(selectedMessageIdState);
  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    refetch();
  }, [selectedMessageId, refetch]);
}
