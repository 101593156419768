import TextButton from '@afterdoc-design-system/components/Atoms/Button/TextButton';
import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import ProgressBar from '@afterdoc-design-system/components/Molecules/ProgressBar/ProgressBar';
import { SHARED_UTILS } from '@shared-utils/utils';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ManualAllContentsHandlerParams } from 'web/apis/swaggers/swagger-docs';
import { NEXT_PUBLIC_API_URL } from 'web/config/url';
import useFileDownloadWithProgress from 'web/shared/hooks/files/use-file-download-with-progress';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { useUserInfo } from 'web/shared/hooks/use-user-info';

const fetchAllManualContent = async (params: ManualAllContentsHandlerParams) => {
  const response = await apiClient.v3.manualAllContentsHandler(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export default function DownloadManualPDFButton() {
  const { name: hospitalName, hospitalID } = useSelectedHospitalInfo();
  const { startDownload, cancelDownload, openFileFolder, progress, status } =
    useFileDownloadWithProgress();

  const [downloadButtonDisabled, setDownloadButtonDisabled] = useState(false);

  const { data } = useSuspenseQuery({
    queryKey: [QUERY_KEY.manualAllContentsHandler, { hospitalID }] as const,
    queryFn: ({ queryKey }) => fetchAllManualContent(queryKey[1]),
  });

  const { authorizationTypeID } = useUserInfo();
  const canUserControlManualFolderAndTagStatus =
    authorizationTypeID?.canControlManualFolderAndTagStatus;

  const handleGeneratePDF = async () => {
    setDownloadButtonDisabled(true);
    toastService.loadingMsg({
      id: 'generate-manual-pdf',
      text: '매뉴얼을 생성중입니다. 잠시만 기다려주세요.',
      rightButton: {
        text: '취소',
        onClick: () => {
          response.body?.cancel();
          toastService.close('generate-manual-pdf');
        },
      },
    });

    const response = await fetch(`${NEXT_PUBLIC_API_URL}/api/generate-manual-pdf`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        fileName: `${hospitalName} 매뉴얼.pdf`,
        data,
      }),
    });

    if (!response.ok) {
      throw new Error('PDF 생성 실패');
    }
    const pdfBlob = await response.blob();

    toastService.close('generate-manual-pdf');

    return pdfBlob;
  };

  const handleDownload = async () => {
    const pdfBlob = await handleGeneratePDF();

    startDownload(pdfBlob, `${hospitalName} 매뉴얼.pdf`, [
      {
        name: 'pdf',
        extensions: ['pdf'],
      },
    ]);
  };

  const handleClickDownloadManual = async () => {
    if (!canUserControlManualFolderAndTagStatus) {
      return toastService.errorMsg({
        text: '매뉴얼 폴더 및 태그를 관리할 권한이 없습니다.',
      });
    }

    if (!data.length) {
      return toastService.errorMsg({
        text: '다운로드할 매뉴얼이 없습니다.',
      });
    }

    handleDownload();
  };

  useEffect(() => {
    if (status === 'complete' || status === 'error') {
      setDownloadButtonDisabled(false);
    }
  }, [status]);

  return (
    <>
      <TextButton
        iconProps={{
          name: 'tray-download',
          color: downloadButtonDisabled ? 'disabled' : 'black200',
          size: 16,
          disabled: !canUserControlManualFolderAndTagStatus,
        }}
        textColor='black200'
        disabled={downloadButtonDisabled}
        onClick={handleClickDownloadManual}>
        매뉴얼 전체 다운로드
      </TextButton>
      {!!status && (
        <div className='fixed right-40 bottom-40 z-[10000] w-[630px]'>
          <ProgressBar
            fileName={`${hospitalName} 매뉴얼.pdf`}
            status={status}
            percentage={progress}
            onCancelClickCallback={(e) => {
              e.preventDefault();
              e.stopPropagation();

              cancelDownload();
            }}
            onFileOpenClickCallback={(e) => {
              e.preventDefault();
              e.stopPropagation();

              openFileFolder();
            }}
            shouldAutoHide={true}
            onReloadClickCallback={(e) => {
              e.preventDefault();
              e.stopPropagation();

              handleDownload();
            }}
          />
        </div>
      )}
    </>
  );
}
