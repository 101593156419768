import BaseLoading from '@afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import { SHARED_UTILS } from '@shared-utils/utils';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { Suspense, useEffect, useState } from 'react';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ApiV2ReservationElCountParams } from 'web/apis/swaggers/swagger-docs';
import { usePatientDetailInfo } from 'web/shared/hooks/use-get-patient-detail-info';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import TableBody from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/AfterDoc/ReservationHistory/components/ReservationHistoryTable/components/TableBody';
import TableHeader from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/AfterDoc/ReservationHistory/components/ReservationHistoryTable/components/TableHeader';
import { RESERVATION_HISTORY_TABLE_LIMIT } from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/AfterDoc/ReservationHistory/components/ReservationHistoryTable/constants/reservation-history-table-limit';
import { reservationSelectedDateState } from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/AfterDoc/ReservationHistory/states/date';
import ReservationPagination from '../ReservationPagination';
import { RESERVATION_HISTORY_TABLE_HEADERS } from './constants/reservation-history-table-headers';

const fetchReservationHistoryCount = async (params: ApiV2ReservationElCountParams) => {
  const response = await apiClient.v2.apiV2ReservationElCount(params);
  return SHARED_UTILS.api.checkApiResponse<number>(response.data);
};

export default function ReservationHistoryTable() {
  const { hospitalID } = useSelectedHospitalInfo();
  const { patientId } = usePatientDetailInfo();
  const reservationSelectedDate = useAtomValue(reservationSelectedDateState);

  const [currentPage, setCurrentPage] = useState(1);

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  const { data: reservationCount } = useQuery({
    queryKey: [
      QUERY_KEY.apiV2ReservationElCount,
      {
        hospitalID,
        hserviceID: patientId,
        startedAt: reservationSelectedDate?.startedAt,
        endedAt: reservationSelectedDate?.endedAt,
      },
    ] as const,
    queryFn: ({ queryKey }) => fetchReservationHistoryCount(queryKey[1]),
  });

  useEffect(() => {
    setCurrentPage(1);
  }, [reservationSelectedDate]);

  return (
    <>
      <table className='table-fixed bg-white text-Body12'>
        <colgroup>
          <col className='w-[198px]' />
          <col className='w-[178px]' />
          <col className='w-[198px]' />
          <col className='w-[98px]' />
          <col />
          <col className='w-[110px]' />
          <col className='w-[80px]' />
        </colgroup>
        <TableHeader />
        <tbody>
          <Suspense
            fallback={
              <tr>
                <td colSpan={RESERVATION_HISTORY_TABLE_HEADERS.length}>
                  <div className='flex h-[300px] w-full items-center justify-center'>
                    <BaseLoading />
                  </div>
                </td>
              </tr>
            }>
            <TableBody
              reservationSelectedDate={reservationSelectedDate}
              currentPage={currentPage}
            />
          </Suspense>
        </tbody>
      </table>
      {reservationCount && Math.floor(reservationCount / RESERVATION_HISTORY_TABLE_LIMIT) >= 0 && (
        <ReservationPagination
          totalPages={Math.ceil(reservationCount / RESERVATION_HISTORY_TABLE_LIMIT)}
          currentPage={currentPage}
          handlePageChange={handlePageChange}
        />
      )}
    </>
  );
}
