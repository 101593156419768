import Divider from '@afterdoc-design-system/components/Atoms/Divider/Divider';
import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import { Fragment } from 'react';
import type { ConsultationSpeedData } from 'web/apis/swaggers/swagger-docs';
import CardItemLayout from 'web/templates/Automation/containers/Main/shared/components/CardItemLayout';

interface ConsultationResponseSpeedCardItemProps {
  consultationResponseSpeed?: ConsultationSpeedData;
}

export default function ConsultationResponseSpeedCardItem({
  consultationResponseSpeed,
}: ConsultationResponseSpeedCardItemProps) {
  if (!consultationResponseSpeed) {
    return <CardItemLayout />;
  }

  const { averageResponseTime, changeFromLastMonth, topConsultants } = consultationResponseSpeed;

  return (
    <CardItemLayout>
      <div>
        <div className='text-Header12 text-black500'>상담 응답 속도</div>
        <div className='flex items-center gap-10'>
          <div className='text-Header16 text-black700'>
            평균 {averageResponseTime ? `${averageResponseTime}분` : '-분'}
          </div>
          <div className='flex items-center'>
            <span className='text-Body12 text-black200'>전달대비</span>
            <span
              className={`ml-6 text-Header12 ${changeFromLastMonth && changeFromLastMonth > 0 ? 'text-red500' : 'text-blue500'}`}>
              {changeFromLastMonth ? `${Math.abs(changeFromLastMonth)}분` : '-분'}
            </span>
            {changeFromLastMonth && changeFromLastMonth > 0 ? (
              <Icon name='arrow-up-right-thick' color='red500' size={16} />
            ) : (
              <Icon name='arrow-down-left-thick' color='blue500' size={16} />
            )}
          </div>
        </div>
        {topConsultants && (
          <div className='mt-33 flex'>
            {topConsultants?.map((consultant, index) => (
              <Fragment key={index}>
                <div className='flex w-full items-start'>
                  <div className='flex flex-col gap-2 '>
                    <div className='text-Body12 text-black200'>Top {index + 1}</div>
                    <div className='text-Header14 text-black700'>{consultant.name}</div>
                    <div className='text-Header14 text-red500'>
                      {consultant.averageResponseTime}분
                    </div>
                  </div>
                </div>
                {index !== topConsultants.length - 1 && (
                  <Divider type='line' direction='vertical' height={82} className='mx-14' />
                )}
              </Fragment>
            ))}
          </div>
        )}
      </div>
    </CardItemLayout>
  );
}
