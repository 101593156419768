import type { PanelListProps } from '@afterdoc-design-system/components/Atoms/PanelList/PanelList';

export const DEFAULT_PANEL_ITEMS: PanelListProps['layers'] = [
  {
    title: '고객정보 관리',
    items: [],
    state: 'focus',
  },
  {
    title: '치료태그 미입력',
    items: [],
    state: 'default',
  },
];
