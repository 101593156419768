import Checkbox from '@afterdoc-design-system/components/Atoms/Checkbox/Checkbox';
import LabelText from '@afterdoc-design-system/components/Atoms/LabelText/LabelText';
import Scrollbar from '@afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import DashedTag from '@afterdoc-design-system/components/Atoms/Tag/DashedTag';
import FilledTag from '@afterdoc-design-system/components/Atoms/Tag/FilledTag';
import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import { SHARED_UTILS } from '@shared-utils/utils';
import { useSuspenseQuery } from '@tanstack/react-query';
import { AnimatePresence, motion } from 'framer-motion';
import { useAtom } from 'jotai';
import { type ChangeEvent, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { TargetableNationalitiesHandlerParams } from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import type { MarketingAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/components/RegisterMarketingAutomationDialogContent';
import { isNationalitySelectBoxOpenedState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingBaseSetting/states/is-nationality-select-box-opened';

const getTargetableNationalities = async (params: TargetableNationalitiesHandlerParams) => {
  const response = await apiClient.v3.targetableNationalitiesHandler(params);

  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export default function NationalitySetting() {
  const { hospitalID } = useSelectedHospitalInfo();

  const { setValue, getValues, register, watch } =
    useFormContext<MarketingAutomationAPIFormValues>();
  const targetTreatmentTags = watch('targetTreatmentTags');
  const isForNoTreatmentTags = watch('isForNoTreatmentTags');
  const isForNoNationality = watch('isForNoNationality');
  const toBeAppliedNationalityIds = watch('toBeAppliedNationalityIds') || [];

  const [isOpened, setIsOpened] = useAtom(isNationalitySelectBoxOpenedState);

  const { data } = useSuspenseQuery({
    queryKey: [
      QUERY_KEY.targetableNationalitiesHandler,
      { hospitalID, treatmentTagId: targetTreatmentTags?.join(',') },
    ] as const,
    queryFn: ({ queryKey: [, params] }) => getTargetableNationalities(params),
    staleTime: 0,
    gcTime: 0,
  });

  const handleClick = () => {
    if (!targetTreatmentTags?.length && !isForNoTreatmentTags) {
      return;
    }

    setIsOpened((prev) => !prev);
  };

  const handleSelectTagChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      if (!toBeAppliedNationalityIds.includes(e.target.value)) {
        setValue('toBeAppliedNationalityIds', [...toBeAppliedNationalityIds, e.target.value]);
      }
    } else {
      setValue(
        'toBeAppliedNationalityIds',
        toBeAppliedNationalityIds.filter((id) => id !== e.target.value),
      );
    }
  };

  const handleIsForNoNationalityChange = (checked: boolean) => {
    setValue('isForNoNationality', checked);
  };

  useEffect(() => {
    if (!isForNoTreatmentTags && !targetTreatmentTags?.length && isOpened) {
      setIsOpened(false);
      setValue('toBeAppliedNationalityIds', undefined);
      setValue('isForNoNationality', false);
    }
  }, [targetTreatmentTags, isForNoTreatmentTags]);

  return (
    <div className='mt-16 rounded-r10 border border-white600 bg-white200 p-10'>
      <button
        type='button'
        className='flex w-full justify-between'
        onClick={handleClick}
        disabled={!targetTreatmentTags?.length && !isForNoTreatmentTags}>
        <div className='text-Header12 text-black500'>국적 (선택)</div>
        <Icon name={isOpened ? 'chevron-up' : 'chevron-down'} color='black200' />
      </button>

      <AnimatePresence initial={false}>
        {isOpened && (
          <motion.div
            initial={{ height: 0, opacity: 0 }}
            animate={{ height: 279, opacity: 1 }}
            exit={{ height: 0, opacity: 0 }}
            transition={{ duration: 0.3 }}>
            <LabelText textClassName='text-Body10Bold'>국적</LabelText>
            <motion.div
              initial={{ height: 0, opacity: 0 }}
              animate={{ height: 250, opacity: 1 }}
              exit={{ height: 0, opacity: 0 }}
              transition={{ duration: 0.3 }}
              className='rounded-r10 border border-black200 bg-white50 py-4 pr-4 pl-16'>
              <Scrollbar disabledX>
                <div className='flex flex-col gap-10 pt-8 pb-12'>
                  <Checkbox
                    size={20}
                    label={<DashedTag tagSize='small'>국적 미입력</DashedTag>}
                    checked={isForNoNationality}
                    onChange={(e) => {
                      handleIsForNoNationalityChange(e.target.checked);
                    }}
                  />
                  {data
                    .filter((nationalities) => nationalities._id !== '-1')
                    .map((nationality) => (
                      <div className='flex items-center gap-10' key={nationality._id}>
                        <Checkbox
                          size={20}
                          value={nationality._id}
                          label={
                            <FilledTag className='cursor-pointer' tagSize='small'>
                              {nationality.koreanCountryName}
                            </FilledTag>
                          }
                          {...register('toBeAppliedNationalityIds', {
                            required: false,
                            onChange: handleSelectTagChange,
                          })}
                        />
                      </div>
                    ))}
                </div>
              </Scrollbar>
            </motion.div>
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  );
}
