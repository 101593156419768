const TITLE = '채널연동';

export default function ChannelIntegrationTitle() {
  return (
    <div className='flex h-fit w-full select-none items-start justify-between px-20 pt-20 pb-8'>
      <div className='flex flex-col gap-4'>
        <h1 className={'text-Header16 text-black700'}>{TITLE}</h1>
        <h2 className='text-Body11 text-black200'>
          채널 연동은 유료 기능입니다. 자세한 내용은{' '}
          <a
            className='cursor-pointer text-blue500 underline'
            href='https://www.naver.com'
            target='_blank'
            rel='noopener noreferrer'>
            연동 매뉴얼
          </a>
          을 참고해주세요.
        </h2>
      </div>
    </div>
  );
}
