import { SHARED_UTILS } from '@shared-utils/utils';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type {
  ApiServiceSettingsElCountriesParams,
  ApiServiceSettingsElToBeDisplayedPatientsFieldsParams,
  ApiTreatmentTagsElParams,
} from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import CustomerManagementEditableTableHeader from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementEditableTableHeader/CustomerManagementEditableTableHeader';
import CustomerManagementExcelFooter from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementExcelFooter/CustomerManagementExcelFooter';
import KeyboardExplanationContainer from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/components/KeyboardExplanation.container';
import {
  focusedCellState,
  selectedCellState,
} from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/states/table';
import TableContainer from './containers/Table.container';
import type { CustomerManagementTableRowArr } from './types/table';

const fetchTreatmentTagsList = async (params: ApiTreatmentTagsElParams) => {
  const response = await apiClient.v3.apiTreatmentTagsEl(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const fetchCountriesList = async (params: ApiServiceSettingsElCountriesParams) => {
  const response = await apiClient.v3.apiServiceSettingsElCountries(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const fetchDisplayedPatientsFields = async (
  params: ApiServiceSettingsElToBeDisplayedPatientsFieldsParams,
) => {
  const response = await apiClient.v3.apiServiceSettingsElToBeDisplayedPatientsFields(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};
export default function CustomerManagementEditableTableContainer() {
  const { hospitalID } = useSelectedHospitalInfo();

  const { data: usingColumns } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiServiceSettingsElToBeDisplayedPatientsFields, { hospitalID }] as const,
    queryFn: ({ queryKey }) => fetchDisplayedPatientsFields(queryKey[1]),
  });

  const {
    data: { treatmentTags },
  } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiTreatmentTagsEl, { hospitalID }] as const,
    queryFn: ({ queryKey }) => fetchTreatmentTagsList(queryKey[1]),
  });

  const { data: countriesList } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiServiceSettingsElCountries, { hospitalID, limit: 240 }] as const,
    queryFn: ({ queryKey }) => fetchCountriesList(queryKey[1]),
  });

  const tableRef = useRef<HTMLDivElement>(null);

  const [isExpanded, setIsExpanded] = useState(false);

  const setSelectedCell = useSetAtom(selectedCellState);
  const setFocusedCell = useSetAtom(focusedCellState);

  const methods = useForm<{
    rows: CustomerManagementTableRowArr;
  }>({
    defaultValues: {
      rows: [],
    },
  });

  const handleExpand = (expanded: boolean) => {
    setIsExpanded(expanded);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      const targetElement = event.target as HTMLElement;

      if (tableRef.current && !tableRef.current.contains(targetElement)) {
        setSelectedCell({
          row: -1,
          col: -1,
        });
        setFocusedCell({
          row: null,
          col: null,
        });
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [tableRef]);

  return (
    <>
      <KeyboardExplanationContainer expanded={isExpanded} onExpand={handleExpand} />
      <FormProvider {...methods}>
        <div
          className={customTwMerge(
            'flex w-full justify-center border-line-table border-t',
            isExpanded ? 'h-[calc(100vh-240px)]' : 'h-[calc(100vh-196px)]',
          )}>
          <div className='flex flex-col'>
            <table className='w-full table-fixed'>
              <CustomerManagementEditableTableHeader {...usingColumns} />
            </table>
            <TableContainer
              isExpanded={isExpanded}
              countriesList={countriesList}
              treatmentTags={treatmentTags}
              {...usingColumns}
            />
          </div>
        </div>
        <CustomerManagementExcelFooter />
      </FormProvider>
    </>
  );
}
