import { SHARED_UTILS } from '@shared-utils/utils';
import { useAtomValue, useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useState } from 'react';
import { useUserInfo } from 'web/shared/hooks/use-user-info';
import { channelIntegrationInfoState } from 'web/templates/CustomerChat/components/ChattingList/states/channel-integration-info-state';
import { selectedMessageIdState } from 'web/templates/CustomerChat/components/SupportBot/states/selected-message-id';
import { isEmptyWithTrim } from 'web/templates/CustomerChat/functions/isEmptyWithTrim';
import { translateLanguageState } from 'web/templates/CustomerChat/states/translate-language';
import type { ChattingResponse } from 'web/templates/CustomerChat/types';
import { chatRoomIDAtom } from '../../../../../states/chatRoomID';
import { isActivateChatTranslateAtom } from '../../../states/isChatTranslate';
import { MESSAGE_TYPE_CODE } from '../../ChattingContainer/components/ChattingContent/components/ChattingMessage/constants/message';
import { useHandleChatSocket } from '../../ChattingContainer/components/ChattingContent/hooks/use-handle-chat-socket';
import { isSendingTranslateState } from '../../ChattingContainer/components/ChattingContent/states/is-sending-translate';
import { chattingRequestState } from '../../ChattingContainer/states/chatting-request';
import { isSendingMessagesState } from '../../ChattingContainer/states/isSendingMessages';
import { kakaoIntegrationAxiosInstance } from '../api/kakaoIntegrationAxiosInstance';
import { KAKAO_TEXT_TYPE_FOR_API, MESSAGE_TYPE_MAP, TEXT_TYPE } from '../constants/types-codes';
import type { TempSendFile } from '../types/send-file';

export const useChatInput = () => {
  const [text, setText] = useState<string>('');
  const chatRoomID = useAtomValue(chatRoomIDAtom);
  const translateLanguage = useAtomValue(translateLanguageState);
  const isActivateChatTranslate = useAtomValue(isActivateChatTranslateAtom);
  const setIsSendingTranslate = useSetAtom(isSendingTranslateState);
  const resetSelectedMessageId = useResetAtom(selectedMessageIdState);
  const setReq = useSetAtom(chattingRequestState);
  const setIsSendingMessages = useSetAtom(isSendingMessagesState);

  const { userId, userType, realName, jobType, photo, name } = useUserInfo();
  const { roomType, roomInfo } = useAtomValue(channelIntegrationInfoState);

  const { sendMessage } = useHandleChatSocket({ enableListening: false });

  const sendText = async (messageText: string) => {
    if (chatRoomID === null) return;

    const userName = name ?? '';
    const userRealName = realName ?? '';
    const userJobType = jobType ?? '';
    const filePath = photo as unknown as TempSendFile;
    const photoPath = filePath?.paths[0];

    switch (roomType) {
      case 'ikakao': {
        const { pf_id, user_key } = roomInfo;

        setIsSendingMessages(true);
        resetSelectedMessageId();

        const payload = {
          pf_id,
          user_key,
          jobType: userJobType,
          message_type: KAKAO_TEXT_TYPE_FOR_API,
          message: messageText,
          userID: userId,
          language: translateLanguage,
        };
        isActivateChatTranslate && setIsSendingTranslate(true);

        const { data } = await kakaoIntegrationAxiosInstance.post('/biz/chat/write3', payload);
        const chatData = SHARED_UTILS.api.checkApiResponse(data) as ChattingResponse;

        setReq({
          type: 'ikakao',
          payload: { ...chatData, type: MESSAGE_TYPE_CODE.TEXT_MOCKING },
        });

        // console.info(SHARED_UTILS.api.checkApiResponse(data));
        break;
      }
      default: {
        const payload = {
          chatRoomID,
          content: messageText,
          jobType: userJobType,
          type: MESSAGE_TYPE_MAP[TEXT_TYPE],
          userRealName,
          userName,
          userID: userId,
          userType,
          photoPath,
          language: translateLanguage,
        };
        // console.log('text payload', { ...payload, type: MESSAGE_TYPE_CODE.TEXT_MOCKING });
        setReq({
          type: TEXT_TYPE,
          payload: { ...payload, type: MESSAGE_TYPE_CODE.TEXT_MOCKING },
        });
        sendMessage({
          type: TEXT_TYPE,
          payload,
        });
        break;
      }
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  };

  const handleSend = () => {
    if (isEmptyWithTrim(text)) return;

    sendText(text);
    setText('');
  };

  return {
    text,
    setText,
    handleChange,
    handleSend,
  };
};
