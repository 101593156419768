import { useResetAtom } from 'jotai/utils';
import { useCallback } from 'react';
import type { UseFormReset } from 'react-hook-form';
import type { AlgorithmWithMessagesInput } from 'web/apis/swaggers/swagger-docs';
import {
  checkedLayersState,
  checkedOrphanSubLayersState,
  checkedSubLayersState,
} from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselBaseSetting/states/selected-layers';
import {
  selectedContentTabIndexState,
  selectedMessageState,
} from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-form';
import { messageModeState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-mode';
import { selectedCounselAutomationTabState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/states/selected-counsel-automation-tab-state';

interface UseResetAllProps {
  reset: UseFormReset<AlgorithmWithMessagesInput>;
  defaultValues?: AlgorithmWithMessagesInput;
}

export const useResetAll = ({ reset, defaultValues }: UseResetAllProps) => {
  const resetCheckedLayers = useResetAtom(checkedLayersState);
  const resetCheckedSubLayers = useResetAtom(checkedSubLayersState);
  const resetCheckedOrphanSubLayers = useResetAtom(checkedOrphanSubLayersState);
  const resetContentTabIndex = useResetAtom(selectedContentTabIndexState);
  const resetSelectedCounselAutomationTab = useResetAtom(selectedCounselAutomationTabState);
  const resetSelectedMessage = useResetAtom(selectedMessageState);
  const resetMessageMode = useResetAtom(messageModeState);

  const resetAll = useCallback(() => {
    resetCheckedLayers();
    resetCheckedSubLayers();
    resetCheckedOrphanSubLayers();
    resetContentTabIndex();
    resetSelectedCounselAutomationTab();
    resetSelectedMessage();
    resetMessageMode();
    reset(defaultValues);
  }, [
    reset,
    resetCheckedLayers,
    resetCheckedSubLayers,
    resetCheckedOrphanSubLayers,
    resetContentTabIndex,
    resetSelectedCounselAutomationTab,
    resetSelectedMessage,
    resetMessageMode,
    defaultValues,
  ]);

  return { resetAll };
};
