import LoadingSpinner from '@afterdoc-design-system/components/Atoms/Loading/LoadingSpinner';
import Scrollbar from '@afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import NoData from '@afterdoc-design-system/components/Molecules/NoData/NoData';
import { SHARED_UTILS } from '@shared-utils/utils';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';
import { Suspense, memo, useEffect, useMemo, useRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type {
  ApiPatientsElListOrSearchForUntaggedParams,
  ApiServiceSettingsElCountriesData,
  ApiTreatmentTagsElData,
} from 'web/apis/swaggers/swagger-docs';
import FullLoading from 'web/shared/components/FullLoading/FullLoading';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import UnTaggedCustomerManagementEditableTableBody from 'web/templates/CustomerManagement/containers/BoardPanel/containers/UnTaggedCustomerManagement/containers/UnTaggedCustomerManagementEditableTable/components/UnTaggedCustomerManagementEditableTableBody/UnTaggedCustomerManagementEditableTableBody';
import { unTaggedHeaderFilterState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/UnTaggedCustomerManagement/containers/UnTaggedCustomerManagementEditableTable/states/editable-header-filter';
import type { UnTaggedCustomerManagementAPIFormValues } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/UnTaggedCustomerManagement/containers/UnTaggedCustomerManagementEditableTable/types/table';
import {
  searchTextState,
  totalSearchCountState,
} from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/containers/SearchFilter/states/search';
import { selectedSearchCriteriaState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/containers/SearchFilter/states/selected-search-criteria';
import { useIntersectionObserver } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/hooks/use-intersection-observer';
import { useVirtualScroll } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/hooks/use-virtual-scroll';

const fetchUnTaggedPatientsListOrSearch = async ({
  pageParam = undefined,
  ...params
}: { pageParam?: string } & ApiPatientsElListOrSearchForUntaggedParams) => {
  const response = await apiClient.v3.apiPatientsElListOrSearchForUntagged({
    cursor: pageParam,
    ...params,
  });
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

interface TableContainerProps {
  isExpanded: boolean;
  countriesList: ApiServiceSettingsElCountriesData['data'];
  treatmentTags: ApiTreatmentTagsElData['data']['treatmentTags'];
}

function TableContainer({ isExpanded, countriesList, treatmentTags }: TableContainerProps) {
  const { watch, setValue } = useFormContext<{ rows: UnTaggedCustomerManagementAPIFormValues }>();
  const { hospitalID } = useSelectedHospitalInfo();

  const rows = watch('rows');
  const rowHeight = useRef(40);
  const loadMoreRef = useRef<HTMLTableSectionElement | null>(null);

  const containerHeight = useMemo(() => {
    return isExpanded ? window.innerHeight - 231 : window.innerHeight - 187;
  }, [isExpanded]);

  const unTaggedHeaderFilter = useAtomValue(unTaggedHeaderFilterState);
  const searchText = useAtomValue(searchTextState);
  const selectedSearchCriteria = useAtomValue(selectedSearchCriteriaState);

  const setTotalSearchCount = useSetAtom(totalSearchCountState);

  const [isRefReady, setIsRefReady] = useState(false);

  const virtualScrollData = useVirtualScroll(rows, rowHeight.current, containerHeight, 10);

  const params = useMemo(() => {
    return {
      hospitalID,
      limit: 50,
      chartNumber: selectedSearchCriteria === 'chartNumber' ? searchText : undefined,
      patientName: selectedSearchCriteria === 'patientName' ? searchText : undefined,
      phone: selectedSearchCriteria === 'phoneNumber' ? searchText : undefined,
      birth: selectedSearchCriteria === 'birthdate' ? searchText : undefined,
      ...unTaggedHeaderFilter,
      isFirstVisit:
        unTaggedHeaderFilter.isFirstVisit === 'all' ? undefined : unTaggedHeaderFilter.isFirstVisit,
    };
  }, [unTaggedHeaderFilter, hospitalID, searchText, selectedSearchCriteria]);

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isError, isLoading } =
    useInfiniteQuery({
      queryKey: [QUERY_KEY.apiPatientsElListOrSearchForUntagged, JSON.stringify(params)],
      queryFn: ({ pageParam }: { pageParam?: string }) =>
        fetchUnTaggedPatientsListOrSearch({
          pageParam,
          ...params,
        }),
      getNextPageParam: (lastPage) => lastPage.nextCursor || undefined,
      initialPageParam: undefined,
    });

  useIntersectionObserver({
    target: loadMoreRef.current,
    onIntersect: fetchNextPage,
    enabled: hasNextPage && !isError && !isLoading && isRefReady,
  });

  useEffect(() => {
    if (!isLoading && data) {
      setIsRefReady(true);
    }
  }, [isLoading, data]);

  useEffect(() => {
    if (data?.pages) {
      const allUnTaggedPatients = data.pages
        .flatMap((page) => page.patients)
        .filter((patient) => patient !== undefined)
        .map((patient) => ({
          ...patient,
          treatmentTagIds: patient.treatmentTags?.map((tag) => tag._id) as string[],
        }));

      const totalCount = data.pages[0].totalCount;
      setTotalSearchCount(totalCount);

      if (totalCount > 0) {
        setValue('rows', allUnTaggedPatients);
      }
    }
  }, [data, setTotalSearchCount]);

  if (isLoading && !data) {
    return <FullLoading />;
  }

  if (!rows.length) {
    return (
      <div className='flex-full-center'>
        <NoData
          iconProps={{
            name: 'person-help',
            size: 48,
            color: 'white600',
          }}
          title='치료태그 미입력 고객이 없습니다.'
        />
      </div>
    );
  }

  return (
    <Scrollbar onScroll={(scrollTop) => virtualScrollData.handleScroll(scrollTop)}>
      <div
        className='relative w-full flex-col-center'
        style={{ height: `${virtualScrollData.totalHeight}px` }}>
        <table
          className='absolute left-0 w-full table-fixed'
          style={{ top: `${virtualScrollData.offsetY}px` }}>
          <Suspense>
            <UnTaggedCustomerManagementEditableTableBody
              rows={virtualScrollData.visibleRows}
              countriesList={countriesList}
              treatmentTagsList={treatmentTags}
              virtualScrollData={virtualScrollData}
            />
            <tbody ref={loadMoreRef} className='h-1' />
            {isFetchingNextPage && (
              <div className='absolute bottom-0 left-0 flex w-full justify-center bg-white50'>
                <LoadingSpinner />
              </div>
            )}
          </Suspense>
        </table>
      </div>
    </Scrollbar>
  );
}

export default memo(TableContainer);
