import TextInput from '@afterdoc-design-system/components/Atoms/Input/TextInput';
import Icon, { type IconType } from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import NoData from '@afterdoc-design-system/components/Molecules/NoData/NoData';
import { DragDropContext, Draggable, type DropResult, Droppable } from '@hello-pangea/dnd';
import { Color } from '@tailwind-base/styles/color';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { AnimatePresence, motion } from 'framer-motion';
import { isEmpty } from 'lodash-es';
import {
  type KeyboardEvent,
  type PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

export interface SubLayer {
  id: string;
  text: React.ReactNode;
  state?: 'default' | 'focus';
  rightContent?: React.ReactNode;
  icon?: IconType;
  color?: string;
  sortNum: number;
}

export interface Layer {
  id: string;
  title: React.ReactNode | string;
  rightContent?: React.ReactNode;
  items: SubLayer[];
  icon?: IconType;
  sortNum: number;
}

type ContextMenuHandler = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, id?: string) => void;
export interface SequencePanelListProps {
  layers: Layer[];
  orphanSubLayers: SubLayer[];
  onLayerClick?: (layerId: string) => void;
  onItemClick?: (itemId: string) => void;
  onLayersReorder?: (layers: Layer[], changedLayer?: Layer) => void;
  onSubLayersReorder?: (
    subLayers: SubLayer[],
    destinationLayerIndex: number,
    destinationDroppableId: string,
    changedSubLayer?: SubLayer,
  ) => void;
  layerClassName?: string;
  subLayerClassName?: string;
  orphanSubLayerClassName?: string;
  isDragDisabled?: boolean;
  isSearching?: boolean;
  isAddingNewFolder?: boolean;
  isEditingFolderName?: boolean;
  editingFolderId: string | null;
  folderNameInput?: string;
  onFolderNameChangeBlur?: () => void;
  onFolderNameChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: KeyboardEvent<HTMLInputElement>, folderId: string) => void;
  layerProps?: {
    onContextMenu?: ContextMenuHandler;
  };
  defaultFocusedItemId?: string;
  isFolderOpenedList?: { [key: string]: boolean };
  onChangeFolderOpenedList?: (state: { [key: string]: boolean }) => void;
}

export default function SequencePanelList({
  layers,
  orphanSubLayers,
  onLayerClick,
  onLayersReorder,
  onSubLayersReorder,
  onItemClick,
  layerClassName,
  subLayerClassName,
  orphanSubLayerClassName,
  isDragDisabled,
  isSearching,
  isAddingNewFolder,
  isEditingFolderName,
  editingFolderId,
  folderNameInput,
  onFolderNameChangeBlur,
  onFolderNameChange,
  onKeyDown,
  layerProps,
  defaultFocusedItemId,
  isFolderOpenedList: _isFolderOpenedList,
  onChangeFolderOpenedList,
}: SequencePanelListProps) {
  const isFirstRender = useRef(true);
  const folderInputRef = useRef<HTMLInputElement>(null);

  const [isFolderOpenedList, setIsFolderOpenedList] = useState<{ [key: string]: boolean }>({});
  const [currentLayers, setLayers] = useState<Layer[]>(layers);
  const [focusedItemId, setFocusedItemId] = useState<string>();
  const [currentOrphanSubLayers, setOrphanSubLayers] = useState<SubLayer[]>(orphanSubLayers);

  const handleLayerClick = (layerId: string) => {
    const updatedOpenState = { ...isFolderOpenedList };

    updatedOpenState[layerId] = !updatedOpenState[layerId];

    if (onLayerClick) onLayerClick(layerId);
    setIsFolderOpenedList(updatedOpenState);
    onChangeFolderOpenedList?.(updatedOpenState);
  };

  const onDragChangeLayer = (
    source: { index: number; droppableId: string },
    destination: { index: number; droppableId: string },
  ) => {
    const newLayers = [...currentLayers];
    const [movedLayer] = newLayers.splice(source.index, 1);
    newLayers.splice(destination.index, 0, movedLayer);

    setLayers(newLayers);

    const updatedOpenState = newLayers.reduce(
      (acc, layer) => {
        acc[layer.id] = isFolderOpenedList[layer.id] ?? false;
        return acc;
      },
      {} as { [key: string]: boolean },
    );

    setIsFolderOpenedList({ ...updatedOpenState });
    onChangeFolderOpenedList?.(updatedOpenState);

    if (onLayersReorder && movedLayer) {
      onLayersReorder(newLayers, movedLayer);
    }
  };

  const onDragChangeOrphanSubLayer = (
    source: { index: number; droppableId: string },
    destination: { index: number; droppableId: string },
  ) => {
    const newLayers = [...currentLayers];
    const newOrphanSubLayers = [...currentOrphanSubLayers];

    let movedItem: SubLayer | undefined;

    const sourceLayerIndex =
      source.droppableId === 'orphan-subLayers'
        ? -1
        : Number.parseInt(source.droppableId.split('-')[1], 10);
    const destinationLayerIndex =
      destination.droppableId === 'orphan-subLayers'
        ? -1
        : Number.parseInt(destination.droppableId.split('-')[1], 10);

    if (sourceLayerIndex === -1 && destinationLayerIndex === -1) {
      [movedItem] = newOrphanSubLayers.splice(source.index, 1);
      newOrphanSubLayers.splice(destination.index, 0, movedItem);
    } else if (sourceLayerIndex === -1) {
      [movedItem] = newOrphanSubLayers.splice(source.index, 1);
      newLayers[destinationLayerIndex].items.splice(destination.index, 0, movedItem);
    } else if (destinationLayerIndex === -1) {
      [movedItem] = newLayers[sourceLayerIndex].items.splice(source.index, 1);
      newOrphanSubLayers.splice(destination.index, 0, movedItem);
    } else {
      [movedItem] = newLayers[sourceLayerIndex].items.splice(source.index, 1);
      newLayers[destinationLayerIndex].items.splice(destination.index, 0, movedItem);
    }

    setOrphanSubLayers(newOrphanSubLayers);

    if (onSubLayersReorder && movedItem) {
      onSubLayersReorder?.(
        newOrphanSubLayers,
        destinationLayerIndex,
        destination.droppableId,
        movedItem,
      );
    }
  };

  const onDragEnd = (result: DropResult) => {
    const { source, destination, type } = result;

    if (!destination) return;

    if (type === 'layer') {
      onDragChangeLayer(source, destination);
    }

    if (type === 'subLayer') {
      onDragChangeOrphanSubLayer(source, destination);
    }
  };

  const handleItemClick = (itemId: string) => {
    setFocusedItemId(itemId);
    if (onItemClick) onItemClick(itemId);
  };

  const handleInputRef = useCallback(
    (inputElement: HTMLInputElement | null) => {
      if (isAddingNewFolder && inputElement) {
        inputElement.focus();
      }
    },
    [isAddingNewFolder],
  );

  useEffect(() => {
    if (!defaultFocusedItemId) return;
    setFocusedItemId(defaultFocusedItemId);
  }, [defaultFocusedItemId]);

  useEffect(() => {
    setLayers(layers);
    setOrphanSubLayers(orphanSubLayers);
  }, [layers, orphanSubLayers]);

  useEffect(() => {
    const updatedOpenState = layers.reduce(
      (acc, layer) => {
        acc[layer.id] = isFolderOpenedList[layer.id] ?? false;
        return acc;
      },
      {} as { [key: string]: boolean },
    );

    setIsFolderOpenedList(updatedOpenState);
  }, [layers]);

  useEffect(() => {
    if (!isEmpty(_isFolderOpenedList) && isFirstRender.current) {
      isFirstRender.current = false;
      setIsFolderOpenedList(_isFolderOpenedList);
    }
  }, [_isFolderOpenedList]);

  useEffect(() => {
    if (isEditingFolderName && folderInputRef.current) {
      folderInputRef.current.focus();
    }
  }, [isEditingFolderName]);

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId='droppable-layers' type='layer' direction='vertical'>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {currentLayers.map((layer, layerIndex) => (
              <Draggable
                isDragDisabled={isSearching || isDragDisabled}
                key={`layer-${layer.id}`}
                draggableId={`layer-${layer.id}`}
                index={layerIndex}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      ...provided.draggableProps.style,
                      borderRadius: '10px',
                      boxShadow: snapshot.isDragging
                        ? '0px 1px 4px 0px rgba(0, 0, 0, 0.15)'
                        : 'none',
                    }}
                    className={customTwMerge(layerClassName)}>
                    <div
                      onClick={() => handleLayerClick(layer.id)}
                      className='flex w-full cursor-pointer items-center gap-10 rounded-r6 bg-white50 px-20 py-10 hover:bg-blueLight'
                      onContextMenu={(e) => {
                        if (layerProps?.onContextMenu) {
                          layerProps.onContextMenu(e, layer.id);
                        }
                      }}>
                      {!isSearching && (
                        <Icon
                          name='exchange'
                          size={20}
                          color='white600'
                          className='flex-shrink-0'
                        />
                      )}
                      <Icon
                        name={isFolderOpenedList[layer.id] ? 'chevron-down' : 'chevron-right'}
                        size={16}
                        color='black200'
                        className='flex-shrink-0'
                      />
                      <Icon
                        name={layer.icon ?? 'folder-outline'}
                        size={20}
                        color='white700'
                        className='flex-shrink-0'
                      />
                      {(editingFolderId === layer.id && isEditingFolderName) ||
                      (isAddingNewFolder && layer.id === 'new-folder') ? (
                        <TextInput
                          ref={
                            editingFolderId === layer.id && isEditingFolderName
                              ? folderInputRef
                              : handleInputRef
                          }
                          type='text'
                          value={folderNameInput}
                          onChange={onFolderNameChange}
                          onKeyDown={(e) => onKeyDown?.(e, layer.id)}
                          className='w-full'
                          maxLength={1000}
                          onBlur={onFolderNameChangeBlur}
                        />
                      ) : (
                        <span className='select-none truncate text-Header14 text-black500'>
                          {layer.title || '이름없음'}
                        </span>
                      )}
                      <div className='ml-auto'>{layer.rightContent}</div>
                    </div>
                    <AnimatePresence initial={false}>
                      {isFolderOpenedList[layer.id] && (
                        <Droppable
                          droppableId={`droppable-${layerIndex}`}
                          key={`droppable-${layer.id}`}
                          type='subLayer'>
                          {(provided) => (
                            <div ref={provided.innerRef} {...provided.droppableProps}>
                              {layer.items.length > 0 ? (
                                <motion.ul
                                  initial={{ height: 0, opacity: 0 }}
                                  animate={{ height: 'auto', opacity: 1 }}
                                  exit={{ height: 0, opacity: 0 }}
                                  transition={{ duration: 0.3 }}>
                                  {layer.items.map((item, itemIndex) => (
                                    <Draggable
                                      isDragDisabled={isSearching || isDragDisabled}
                                      key={item.id}
                                      draggableId={item.id}
                                      index={itemIndex}>
                                      {(provided, snapshot) => (
                                        <div
                                          ref={provided.innerRef}
                                          {...provided.draggableProps}
                                          {...provided.dragHandleProps}
                                          style={{
                                            ...provided.draggableProps.style,
                                            borderRadius: '10px',
                                            boxShadow: snapshot.isDragging
                                              ? '0px 1px 4px 0px rgba(0, 0, 0, 0.15)'
                                              : 'none',
                                          }}>
                                          <motion.div
                                            onClick={() => handleItemClick(item.id)}
                                            className={customTwMerge(
                                              'flex w-full cursor-pointer items-center rounded-r6 px-20 py-10 text-Body12 hover:bg-blueLight',
                                              focusedItemId === item.id
                                                ? 'bg-blue50 text-black700'
                                                : 'bg-white50 text-black500',
                                              subLayerClassName,
                                            )}>
                                            {!isSearching && (
                                              <Icon name='exchange' size={20} color='white600' />
                                            )}
                                            <Icon
                                              name={item.icon ?? 'tag'}
                                              size={16}
                                              customColor={item.color ?? Color.white600}
                                              className='ml-40 flex-shrink-0'
                                            />
                                            <span className='ml-4 truncate text-black500'>
                                              {item.text}
                                            </span>
                                            <div className='ml-auto'>{item.rightContent}</div>
                                          </motion.div>
                                        </div>
                                      )}
                                    </Draggable>
                                  ))}
                                  <Placeholder key={`__dnd_placeholder-${layerIndex}`}>
                                    {provided.placeholder}
                                  </Placeholder>
                                </motion.ul>
                              ) : (
                                <div className='bg-white50 py-30'>
                                  <NoData
                                    iconProps={{
                                      name: 'warning',
                                      size: 48,
                                      color: 'white600',
                                    }}
                                    title='표시할 내용이 없습니다.'
                                    titleClassName='text-Header12 text-black500'
                                  />
                                  <Placeholder key={`__dnd_placeholder-${layer.id}`}>
                                    {provided.placeholder}
                                  </Placeholder>
                                </div>
                              )}
                            </div>
                          )}
                        </Droppable>
                      )}
                    </AnimatePresence>
                  </div>
                )}
              </Draggable>
            ))}
            <Placeholder key='__dnd_placeholder-root'>{provided.placeholder}</Placeholder>
          </div>
        )}
      </Droppable>

      <Droppable droppableId='orphan-subLayers' type='subLayer' direction='vertical'>
        {(provided) => (
          <div ref={provided.innerRef} {...provided.droppableProps}>
            {currentOrphanSubLayers.map((item, index) => (
              <Draggable
                isDragDisabled={isSearching || isDragDisabled}
                key={item.id}
                draggableId={item.id}
                index={index}>
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    style={{
                      ...provided.draggableProps.style,
                      borderRadius: '10px',
                      boxShadow: snapshot.isDragging
                        ? '0px 1px 4px 0px rgba(0, 0, 0, 0.15)'
                        : 'none',
                    }}
                    onClick={() => handleItemClick(item.id)}
                    className={customTwMerge(
                      'mb-2 flex w-full cursor-pointer items-center gap-20 rounded-r6 bg-white50 px-20 py-10 text-Body12 hover:bg-blueLight',
                      focusedItemId === item.id
                        ? 'bg-blue50 text-black700'
                        : 'bg-white50 text-black500',
                      orphanSubLayerClassName,
                    )}>
                    {!isSearching && <Icon name='exchange' size={20} color='white600' />}
                    <div className='flex w-full items-center gap-4 truncate'>
                      <Icon
                        name='tag'
                        size={16}
                        customColor={item.color ?? Color.white600}
                        className='flex-shrink-0'
                      />
                      <span className='select-none truncate'>{item.text || '이름없음'}</span>
                    </div>
                    <div className='ml-auto'>{item.rightContent}</div>
                  </div>
                )}
              </Draggable>
            ))}
            <Placeholder key='__dnd_placeholder-orphan'>{provided.placeholder}</Placeholder>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
}

const Placeholder = ({ children }: PropsWithChildren) => children;
