import TextArea from '@afterdoc-design-system/components/Atoms/Input/TextArea/TextArea';
import LabelText from '@afterdoc-design-system/components/Atoms/LabelText/LabelText';
import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { modalService } from '@afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { SHARED_UTILS } from '@shared-utils/utils';
import { useQuery } from '@tanstack/react-query';
import { format, parseISO } from 'date-fns';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { useUserInfo } from 'web/shared/hooks/use-user-info';
import { isThereSomeThingToSaveState } from 'web/shared/states/is-there-some-thing-to-save';
import { showModalBeforeRouteState } from 'web/shared/states/show-modal-before-route';
import { hserviceIDValueState } from '../../states/hserviceID-value';

type Params = {
  hospitalID: string;
  userID: string;
  hserviceID: string;
};

const getHServiceMemo = async (params: Params) => {
  const { data } = await apiClient.v3.apiPatientsMemosElFindOne(params);
  return SHARED_UTILS.api.checkApiResponse(data);
};

const postHServiceMemo = async (params: Params, text: string) => {
  const body = {
    ...params,
    content: text,
  };
  const response = await apiClient.v3.apiPatientsMemosElUpdate(body);
  return response;
};

export const CustomerMemo = () => {
  const { hospitalID } = useSelectedHospitalInfo();
  const thatHospitalPatientId = useAtomValue(hserviceIDValueState);

  const { userId } = useUserInfo();
  const params = {
    hospitalID,
    userID: userId,
    hserviceID: thatHospitalPatientId,
  };
  const { data, status, error, isLoading, refetch } = useQuery({
    queryKey: [QUERY_KEY.apiPatientsMemosElFindOne, params],
    queryFn: () => getHServiceMemo(params),
  });
  const navigate = useNavigate();

  const setIsThereSomeThingToSave = useSetAtom(isThereSomeThingToSaveState);
  const [showModalBeforeRoute, setShowModalBeforeRoute] = useAtom(showModalBeforeRouteState);
  const [showModal, setShowModal] = useState(false);

  const [text, setText] = useState<string>('');
  const [defaultText, setDefaultText] = useState<string>('');
  const isSameTextAndDefaultText = text === defaultText;

  const handleConfirmLeave = () => {
    modalService.pop();
  };

  const handleCancelLeave = () => {
    modalService.pop();
  };

  useEffect(() => {
    if (showModal) {
      modalService.defaultWarning({
        title: '저장하지 않고 나가시겠어요?',
        contents: '페이지 이탈 시 저장하지 않은 정보를 잃게 됩니다.',
        onConfirm: handleConfirmLeave,
        onCancel: handleCancelLeave,
      });
      setShowModal(false);
    }
  }, [showModal]);

  const closeModal = () => {
    modalService.pop();
    setShowModalBeforeRoute((prev) => ({
      ...prev,
      CUSTOMER_CHAT: {
        showModal: false,
        nextPath: '',
      },
    }));
  };

  useEffect(() => {
    if (showModalBeforeRoute.CUSTOMER_CHAT.showModal) {
      modalService.defaultWarning({
        onConfirm: () => {
          closeModal();
          const nextPath = showModalBeforeRoute.CUSTOMER_CHAT.nextPath;
          navigate(nextPath);
        },
        onCancel: closeModal,
      });
    }
  }, [showModalBeforeRoute.CUSTOMER_CHAT.showModal]);

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        modalService.pop();
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  useEffect(() => {
    return () => {
      setIsThereSomeThingToSave((prev) => ({
        ...prev,
        CUSTOMER_CHAT: {
          customerChattingCustomerMemo: false,
        },
      }));
    };
  }, [setIsThereSomeThingToSave]);

  useEffect(() => {
    setIsThereSomeThingToSave((prev) => ({
      ...prev,
      CUSTOMER_CHAT: {
        ...prev.CUSTOMER_CHAT,
        customerChattingCustomerMemo: !isSameTextAndDefaultText,
      },
    }));
  }, [text, defaultText, setIsThereSomeThingToSave, isSameTextAndDefaultText]);

  useEffect(() => {
    const content = data?.content || '';
    setText(content);
    setDefaultText(content); // 초기 값을 defaultText로 설정
  }, [data]);

  const bottomText =
    data?.updatedAt && !Number.isNaN(parseISO(data.updatedAt).getTime())
      ? `${format(parseISO(data.updatedAt), 'yyyy-MM-dd HH:mm')}/${data.modifierID?.name}`
      : '';

  const handleSave = () => {
    (async () => {
      try {
        //TODO - 이 또한 200 이 적절한지 확인 필요 code 0d이 적절한지
        const { status } = await postHServiceMemo(params, text);
        if (status === 200) {
          toastService.successMsg({ text: '고객메모를 저장했습니다.' });
          refetch();
        }
      } catch (error) {
        console.error('Error saving memo:', error);
        toastService.errorMsg({ text: '고객메모 저장을 실패했습니다.' });
      }
    })();
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  };

  const handleInput = (event: React.FormEvent<HTMLTextAreaElement>) => {
    setText(event.currentTarget.value);
  };

  return (
    <div className='flex h-200 flex-col gap-8 border-b border-b-white400 px-16 pt-20 pb-10'>
      <LabelText textClassName='text-Header16 text-black700 ml-4'>고객메모</LabelText>
      <TextArea
        placeholder='고객 상담 시, 참고 사항을 기록해둘 수 있습니다.'
        onSave={handleSave}
        onChange={handleChange}
        onInput={handleInput}
        disabled={isLoading || status === 'error'}
        hasError={status === 'error'}
        errorText={error ? error.message : ''}
        isShowBottom={true}
        leftBottomText={bottomText}
        value={text}
        isSaveButtonDisabled={isSameTextAndDefaultText}
        width={368}
        height={128}
      />
    </div>
  );
};
