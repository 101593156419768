import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type {
  HospitalPushSettingUpdateHandlerBodyRequest,
  SingleHospitalPushSettingHandlerResponse,
} from '@apis/swaggers/swagger-docs';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { notificationSettingErrorState } from '@templates/UserInfoSetting/containers/NotificationSetting/states/notification-setting-error-state';
import { notificationSettingState } from '@templates/UserInfoSetting/containers/NotificationSetting/states/notification-setting-state';
import type { TimeSettingControlKeys } from '@templates/UserInfoSetting/containers/NotificationSetting/types/res-notification-setting-type';
import { fullDimmedLoadingService } from 'afterdoc-design-system/components/Atoms/Loading/FullDimmedLoading/FullDimmedLoading.service';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import DownloadFooter from 'afterdoc-design-system/components/Molecules/DownloadFooter/DownloadFooter';
import dayjs from 'dayjs';
import { useAtomValue, useSetAtom } from 'jotai';
import React from 'react';
import { SHARED_UTILS } from 'utils/utils';

const updateHospitalPushSetting = async (param: HospitalPushSettingUpdateHandlerBodyRequest) => {
  const { data } = await apiClient.v3.hospitalPushSettingUpdateHandler(param);
  return SHARED_UTILS.api.checkApiResponse(data);
};

const TOAST_MESSAGES = {
  success: '알림 설정이 저장되었습니다.',
  error: '알림 설정 저장이 실패하였습니다. 다시 시도해 주세요.',
};

const validateTimeRanges = (
  notificationSetting: SingleHospitalPushSettingHandlerResponse['data'],
) => {
  const timeFields = [
    ['hasTimeSettingForInChargeChat', 'inChargeChatStartedAt', 'inChargeChatEndedAt'],
    ['onChatsDependsTime', 'chatStartedAt', 'chatEndedAt'],
    ['onManagerChatsDependsTime', 'managerChatStartedAt', 'managerChatEndedAt'],
  ] as const;

  const errors: Record<keyof TimeSettingControlKeys, boolean> = {
    hasTimeSettingForInChargeChat: false,
    onChatsDependsTime: false,
    onManagerChatsDependsTime: false,
  };

  for (const [errorKey, startKey, endKey] of timeFields) {
    const isChecked = notificationSetting[errorKey];
    if (!isChecked) continue;
    const start = notificationSetting[startKey];
    const end = notificationSetting[endKey];

    if (start && end) {
      const startTime = dayjs(start);
      const endTime = dayjs(end);

      if (startTime.isValid() && endTime.isValid()) {
        if (
          endTime.hour() < startTime.hour() ||
          (endTime.hour() === startTime.hour() && endTime.minute() < startTime.minute())
        ) {
          errors[errorKey as keyof TimeSettingControlKeys] = true;
        }
      }
    }
  }

  return errors;
};

export default function NotificationSettingSaveFooter() {
  const queryClient = useQueryClient();
  const notificationSetting = useAtomValue(notificationSettingState);
  const setNotificationSettingError = useSetAtom(notificationSettingErrorState);

  const updateMutation = useMutation({
    mutationFn: (params: HospitalPushSettingUpdateHandlerBodyRequest) =>
      updateHospitalPushSetting(params),
    onMutate: () => fullDimmedLoadingService.on(),
    onSettled: () => fullDimmedLoadingService.off(),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.singleHospitalPushSettingHandler],
      });
      toastService.successMsg({ text: TOAST_MESSAGES.success });
    },
    onError: () => toastService.errorMsg({ text: TOAST_MESSAGES.error }),
  });

  const handleSave = () => {
    if (notificationSetting) {
      const errors = validateTimeRanges(notificationSetting);
      setNotificationSettingError({ ...errors });
      const hasErrors = Object.values(errors).some((error) => error);
      if (!hasErrors) {
        updateMutation.mutate(notificationSetting);
      }
    }
  };

  return (
    <>
      <DownloadFooter
        saveButton='저장하기'
        className='sticky right-0 bottom-0'
        onSave={handleSave}
      />
    </>
  );
}
