import Scrollbar from '@afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import { useAtom, useAtomValue } from 'jotai';
import { Suspense, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import LayoutBox from 'web/shared/components/LayoutBox/LayoutBox';
import type { CounselAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/components/RegisterCounselAutomationDialogContent';
import AutomatedMessageLists from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/components/AutomatedMessageLists';
import MessageTypeDialog from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/components/MessageTypeDialog';
import PhoneView from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/components/PhoneView/PhoneView';
import MessageContentFormContainer from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/MessageContentForm.container';
import { messageModeState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-mode';
import { showMessageTypeDialogState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-type-dialog';

export default function CounselMessageSettingContainer() {
  const { watch } = useFormContext<CounselAutomationAPIFormValues>();

  const messageMode = useAtomValue(messageModeState);
  const [showMessageTypeDialog, setShowMessageTypeDialog] = useAtom(showMessageTypeDialogState);

  const messageLists = watch('messages');

  const onCloseMessageTypeDialog = () => {
    setShowMessageTypeDialog((prev) => ({
      ...prev,
      isShow: false,
    }));
  };

  useEffect(() => {
    if (!messageLists.length && messageMode.mode === 'CREATE') {
      setShowMessageTypeDialog({
        type: 'create',
        isShow: true,
      });
    }
  }, [messageLists, setShowMessageTypeDialog, messageMode.mode]);

  return (
    <>
      <div className='h-[calc(100vh-100px)] w-full'>
        <Scrollbar>
          <div className='mt-40 mb-20 flex w-full justify-center gap-50'>
            <Suspense>
              <AutomatedMessageLists />
            </Suspense>

            <LayoutBox noStyle>
              <MessageContentFormContainer />
            </LayoutBox>

            <LayoutBox noStyle>
              <PhoneView />
            </LayoutBox>
          </div>
        </Scrollbar>
      </div>
      {showMessageTypeDialog.isShow && (
        <MessageTypeDialog
          type={showMessageTypeDialog.type}
          onCloseMessageTypeDialog={onCloseMessageTypeDialog}
        />
      )}
    </>
  );
}
