import type { TreatmentTag } from '@apis/swaggers/swagger-docs';
import TagColorSelector from '@templates/HospitalSetting/containers/ServiceSetting/containers/TreatmentTag/components/TreatmentTagList/TreatmentTagListItem/components/TagColorSelector';
import TagCreateButton from '@templates/HospitalSetting/containers/ServiceSetting/containers/TreatmentTag/components/TreatmentTagList/TreatmentTagListItem/components/TagCreateButton';
import TagRemoveButton from '@templates/HospitalSetting/containers/ServiceSetting/containers/TreatmentTag/components/TreatmentTagList/TreatmentTagListItem/components/TagRemoveButton';
import { treatmentTagsState } from '@templates/HospitalSetting/containers/ServiceSetting/containers/TreatmentTag/states/hospital-treatment-tags';
import { showColorPickerIndexState } from '@templates/HospitalSetting/containers/ServiceSetting/containers/TreatmentTag/states/show-color-picker-index';
import { tagInputErrorMessageState } from '@templates/HospitalSetting/containers/ServiceSetting/containers/TreatmentTag/states/tag-input-error-message-state';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { useAtom, useAtomValue } from 'jotai';
import { useSetAtom } from 'jotai/index';
import { useResetAtom } from 'jotai/utils';
import { useEffect, useState } from 'react';

interface TreatmentTagListItemProps {
  index: number;
  treatmentTag: TreatmentTag;
}

export default function TreatmentTagListItem({ index, treatmentTag }: TreatmentTagListItemProps) {
  const [showColorPickerIndex, setShowColorPickerIndex] = useAtom(showColorPickerIndexState);

  const resetTagInputErrorMessage = useResetAtom(tagInputErrorMessageState);
  const tagInputErrorMessage = useAtomValue(tagInputErrorMessageState);

  const setTreatmentTags = useSetAtom(treatmentTagsState);

  const [isFocused, setIsFocused] = useState(false);

  const { tagId, name, color } = treatmentTag;

  const additionalItem = index === 0;

  const tagColorStyle = {
    backgroundColor: color,
  };

  const handleClick = () => setShowColorPickerIndex(index);

  const handleFocus = () => setIsFocused(true);

  const handleBlur = () => setIsFocused(false);

  const handleInput = (e: React.ChangeEvent<HTMLInputElement>) => {
    const name = e.target.value;
    setTreatmentTags((prev) => prev.map((tag) => (tag.tagId === tagId ? { ...tag, name } : tag)));
    resetTagInputErrorMessage();
  };

  return (
    <div className='flex flex-col gap-10'>
      <div className='w-full flex-center gap-8'>
        <div
          className={`h-32 flex-center flex-grow rounded-r16 border py-4 pr-16 pl-4 ${isFocused ? 'border-blue500' : 'border-white600'}`}>
          <div
            className='relative flex h-24 w-44 cursor-pointer items-center gap-4 pl-4'
            onClick={handleClick}>
            <div className='h-18 w-18 rounded-full' style={tagColorStyle} />
            <Icon name={'chevron-down'} size={16} color={'black200'} />
            {showColorPickerIndex === index && <TagColorSelector color={color ?? ''} id={tagId} />}
          </div>
          <input
            type='text'
            onFocus={handleFocus}
            onBlur={handleBlur}
            className='ml-10 h-[24px] flex-grow bg-white50 text-Body12 outline-none'
            value={name}
            onChange={handleInput}
            maxLength={1000}
            placeholder='태그를 입력해 주세요.'
          />
        </div>
        {additionalItem ? (
          <TagCreateButton {...treatmentTag} />
        ) : (
          <TagRemoveButton {...treatmentTag} />
        )}
      </div>
      {additionalItem && tagInputErrorMessage && (
        <span className='text-Body10 text-red500'>{tagInputErrorMessage}</span>
      )}
    </div>
  );
}
