import {
  HoverKeyboardCloseList,
  HoverKeyboardInput,
  HoverKeyboardPositionMove,
  KeyboardCloseList,
  KeyboardInput,
  KeyboardPositionMove,
} from '@afterdoc-design-system/assets/images';
import TextButton from '@afterdoc-design-system/components/Atoms/Button/TextButton';
import HoverComponent from '@afterdoc-design-system/components/Atoms/Tooltip/HoverComponent';

interface KeyboardExplanationContainerProps {
  expanded: boolean;
  onExpand: (expanded: boolean) => void;
}

export default function KeyboardExplanationContainer({
  expanded,
  onExpand,
}: KeyboardExplanationContainerProps) {
  return (
    <div className='flex w-full items-start justify-between px-40 py-12'>
      {expanded ? (
        <div className='flex gap-36'>
          <div className='text-Header12 text-black700'>키보드 사용방법</div>
          <HoverComponent
            content={<HoverKeyboardPositionMove width={470} />}
            position='bottomRight'>
            <KeyboardPositionMove width={242} />
          </HoverComponent>
          <HoverComponent content={<HoverKeyboardInput width={470} />} position='bottomRight'>
            <KeyboardInput width={104} />
          </HoverComponent>
          <HoverComponent content={<HoverKeyboardCloseList width={470} />} position='bottomRight'>
            <KeyboardCloseList width={87} />
          </HoverComponent>
          {/* <HoverComponent
              content={<HoverKeyboardMultipleSelection width={470} />}
              position='bottomRight'>
              <KeyboardMultipleSelection width={476} />
            </HoverComponent> */}
        </div>
      ) : (
        <div className='flex gap-40'>
          <div className='text-Header12 text-black700'>키보드 사용방법</div>
          <div className='text-Body13 text-black500'>
            *Tip) 키보드 Tab 키, 방향키, Enter 키로 데이터를 다중 선택하여 한번에 등록할 수
            있습니다.
          </div>
        </div>
      )}
      {expanded ? (
        <TextButton
          textColor='black200'
          iconProps={{
            name: 'chevron-up',
            color: 'black200',
            size: 16,
          }}
          onClick={() => onExpand(false)}>
          최소화
        </TextButton>
      ) : (
        <TextButton
          textColor='black200'
          iconProps={{
            name: 'chevron-down',
            color: 'black200',
            size: 16,
          }}
          onClick={() => onExpand(true)}>
          자세히
        </TextButton>
      )}
    </div>
  );
}
