import { fullDimmedLoadingService } from '@afterdoc-design-system/components/Atoms/Loading/FullDimmedLoading/FullDimmedLoading.service';
import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { SHARED_UTILS } from '@shared-utils/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useResetAtom } from 'jotai/utils';
import { useFormContext } from 'react-hook-form';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { PatientSavingRequest } from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import type { CustomerManagementTableRowArr } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/types/table';
import {
  focusedCellState,
  selectedCellState,
} from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/states/table';

const postPatientsInfo = async (params: PatientSavingRequest) => {
  const response = await apiClient.v3.apiPatientsEl(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export const useSaveUser = () => {
  const queryClient = useQueryClient();
  const { reset } = useFormContext<{ rows: CustomerManagementTableRowArr }>();
  const { hospitalID } = useSelectedHospitalInfo();

  const resetSelectedCell = useResetAtom(selectedCellState);
  const resetFocusedCell = useResetAtom(focusedCellState);

  const postSaveFinalUserInfos = useMutation({
    mutationFn: postPatientsInfo,
    onMutate: () => {
      fullDimmedLoadingService.on();
    },
    onSuccess: async (data) => {
      reset();
      resetSelectedCell();
      resetFocusedCell();

      // 쿼리 캐시 초기화
      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiPatientsElTemporaryListOrSearch] }),
        queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiPatientsElListOrSearch] }),
        queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiPatientsElCount, { hospitalID }] }),
        queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiPatientsElDetail] }),
      ]);

      if (!data.failCount && data.successCount > 0) {
        toastService.successMsg({ text: '고객 정보를 업데이트 했습니다.' });
      }

      if (data.failCount > 0) {
        toastService.errorMsg({
          text: `${data.failCount}명의 고객정보 업데이트를 실패했습니다. 입력된 정보를 다시 확인해주세요`,
        });
      }
    },
    onError: (error) => {
      toastService.errorMsg({
        text: '일시적인 오류가 발생하여 업데이트에 실패하였습니다. 다시 시도해 주세요.',
      });
      console.error('error', error);
    },
    onSettled: () => {
      fullDimmedLoadingService.off();
    },
  });

  const handleSaveFinalUserInfo = () => {
    postSaveFinalUserInfos.mutate({
      hospitalID,
    });
  };

  return {
    handleSaveFinalUserInfo,
  };
};
