import { useAtomValue } from 'jotai';
import { type RefObject, useLayoutEffect } from 'react';
import { selectedMessageIdState } from 'web/templates/CustomerChat/components/SupportBot/states/selected-message-id';
import { chatRoomIDAtom } from 'web/templates/CustomerChat/states/chatRoomID';
import { chattingRequestState } from '../states/chatting-request';

type UseForcedMoveToBottomProps = {
  chatContainerRef: RefObject<HTMLDivElement>;
  isLoading: boolean;
};

export const useForcedMoveToBottom = ({
  chatContainerRef,
  isLoading,
}: UseForcedMoveToBottomProps) => {
  const chatRoomID = useAtomValue(chatRoomIDAtom);
  const selectedMessageId = useAtomValue(selectedMessageIdState);
  const req = useAtomValue(chattingRequestState);

  const scrollToTop = () => {
    const container = chatContainerRef.current;
    if (container) {
      const isNotAtTop = container.scrollTop !== 0;
      if (isNotAtTop) {
        requestAnimationFrame(() => {
          setTimeout(() => {
            container.scrollTop = 0;
          }, 0);
        });
      }
    }
  };

  useLayoutEffect(() => {
    scrollToTop();
  }, [isLoading, chatRoomID, selectedMessageId]);

  useLayoutEffect(() => {
    if (req) {
      scrollToTop();
    }
  }, [req]);
};
