import type { ProgressBarProps } from '@afterdoc-design-system/components/Molecules/ProgressBar/ProgressBar';
import { progressBarService } from '@afterdoc-design-system/components/Molecules/ProgressBar/ProgressBar.service';
import { useEffect, useRef, useState } from 'react';

const CHUNK_SIZE = 1024 * 1024;

export default function useFileDownloadWithProgress() {
  const fileNameRef = useRef<string | null>(null);
  const fileBlobRef = useRef<Blob | null>(null);
  const fileUrlRef = useRef<string | null>(null);

  const [isCanceled, setIsCanceled] = useState(false);
  const [progress, setProgress] = useState(0);
  const [status, setStatus] = useState<ProgressBarProps['status']>();
  const [filePath, setFilePath] = useState<string>();

  const startDownload = async (
    source: Blob | string,
    fileName: string,
    filters: { name: string; extensions: string[] }[],
  ) => {
    if (typeof source === 'string') {
      fileUrlRef.current = source;
      fileBlobRef.current = null;
    } else {
      fileBlobRef.current = source;
      fileUrlRef.current = null;
    }

    fileNameRef.current = fileName;

    window.electron?.ipcRenderer.send('Application.startFileDownload', {
      title: 'Download File',
      fileName,
      filters,
    });
  };

  const downloadFileAfterSaveDialog = async () => {
    if (!fileBlobRef.current && !fileUrlRef.current) return;
    setIsCanceled(false);
    setStatus('downloading');
    setProgress(0);

    let offset = 0;

    const sendChunk = async () => {
      if (isCanceled) return;

      if (!fileBlobRef.current && fileUrlRef.current) {
        const response = await fetch(fileUrlRef.current);
        fileBlobRef.current = await response.blob();
      }

      const blob = fileBlobRef.current;
      if (!blob) return;
      const totalSize = blob.size;

      if (offset >= totalSize) {
        window.electron?.ipcRenderer.send('Application.downloadFileEnd');
        return;
      }

      const chunk = blob.slice(offset, offset + CHUNK_SIZE);
      const reader = new FileReader();

      reader.onload = () => {
        if (isCanceled) return;

        const chunkData = reader.result;
        if (chunkData) {
          const progress = Math.floor((offset / totalSize) * 100);
          window.electron?.ipcRenderer.send('Application.downloadFileChunk', {
            chunk: chunkData,
            progress,
          });
          setProgress(progress);
          offset += CHUNK_SIZE;
          sendChunk();
        }
      };

      reader.onerror = () => {
        console.error('Error reading file chunk');
      };

      reader.readAsArrayBuffer(chunk);
    };

    sendChunk();
  };

  const cancelDownload = () => {
    setIsCanceled(true);
    setStatus('error');
    window.electron?.ipcRenderer.send('Application.cancelFileDownload');
  };

  const openFileFolder = () => {
    if (!filePath) return;
    window.electron?.ipcRenderer.send('Application.openFileLocatedFolder', filePath);
  };

  useEffect(() => {
    window.electron?.ipcRenderer.on('Application.writeStreamCreated', () => {
      downloadFileAfterSaveDialog();
    });

    window.electron?.ipcRenderer.on('Application.fileSaveDialogResult', (_, path) => {
      setStatus(path ? 'complete' : 'error');
      setFilePath(path);
    });

    window.electron?.ipcRenderer.on('Application.openFileLocatedFolderResult', (_, { success }) => {
      if (!success) {
        console.error('Error opening folder');
      }
    });

    window.electron?.ipcRenderer.on('Application.cancelDownloadCompleted', () => {
      setIsCanceled(true);
      setStatus('error');
    });

    return () => {
      window.electron?.ipcRenderer.removeAllListeners('Application.writeStreamCreated');
      window.electron?.ipcRenderer.removeAllListeners('Application.fileSaveDialogResult');
      window.electron?.ipcRenderer.removeAllListeners('Application.openFileLocatedFolderResult');
      window.electron?.ipcRenderer.removeAllListeners('Application.cancelDownloadCompleted');
    };
  }, []);

  useEffect(() => {
    if (status) {
      progressBarService.show({
        status,
        percentage: progress,
        fileName: fileNameRef.current ?? '',
        onCancelClickCallback: (e) => {
          e.preventDefault();
          e.stopPropagation();

          cancelDownload();
        },
        onFileOpenClickCallback: (e) => {
          e.preventDefault();
          e.stopPropagation();

          openFileFolder();
        },
        shouldAutoHide: true,
        onReloadClickCallback: (e) => {
          e.preventDefault();
          e.stopPropagation();

          if (fileBlobRef.current || fileUrlRef.current) {
            startDownload(
              fileBlobRef.current || (fileUrlRef.current as Blob | string),
              fileNameRef.current as string,
              [{ name: fileNameRef.current as string, extensions: ['*'] }],
            );
          }
        },
      });
    }
  }, [status]);

  return { startDownload, cancelDownload, openFileFolder, progress, status, filePath };
}
