import { useWindowSize } from '@shared-hooks/use-window-size';
import { useCallback, useMemo, useState } from 'react';

export function useVirtualScroll<RowType>(
  rows: RowType[],
  rowHeight: number,
  containerHeight: number,
  buffer = 5,
) {
  const { width, height } = useWindowSize();
  const [scrollTop, setScrollTop] = useState(0);

  const totalHeight = rows.length * rowHeight;
  const visibleRowCount = Math.ceil(containerHeight / rowHeight) + buffer * 2;

  const startRow = Math.max(0, Math.floor(scrollTop / rowHeight) - buffer);
  const endRow = Math.min(rows.length, startRow + visibleRowCount);

  const offsetY = startRow * rowHeight;

  const visibleRows = useMemo(
    () => rows.slice(startRow, endRow),
    [rows, startRow, endRow, width, height],
  );

  const handleScroll = useCallback(
    (newScrollTop: number) => {
      const maxScrollTop = totalHeight - containerHeight;
      const clampedScrollTop = Math.min(newScrollTop, maxScrollTop);
      setScrollTop(clampedScrollTop);
    },
    [totalHeight, containerHeight, width, height],
  );

  return {
    visibleRows,
    totalHeight,
    offsetY,
    handleScroll,
  };
}
