import OutlinedButton from '@afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import LabelText from '@afterdoc-design-system/components/Atoms/LabelText/LabelText';
import AlwaysVisibleScrollbar from '@afterdoc-design-system/components/Atoms/Scrollbar/AlwaysVisibleScrollbar';
import Scrollbar from '@afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import { useAtomValue } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import { apiClient } from 'web/apis/instances/api-client';
import type { ApiV2ReservationElFindParams, V2Reservation } from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { useIntersectionObserver } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/hooks/use-intersection-observer';
import { hserviceIDValueState } from '../../states/hserviceID-value';
import { CustomerReservationCell } from './CustomerReservationCell';
import { CustomerReservationEmpty } from './CustomerReservationEmpty';

interface TempApiV2ReservationElFindParamsExtension extends ApiV2ReservationElFindParams {
  hserviceID?: string;
}

const countPerPage = 5;

export const CustomerReservation = () => {
  // MARK: - useStates
  const [reservations, setReservations] = useState([] as V2Reservation[]);
  const thatHospitalPatientId = useAtomValue(hserviceIDValueState);

  const [reservationCount, setReservationCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const { hospitalID } = useSelectedHospitalInfo();
  const loadMoreRef = useRef<HTMLDivElement | null>(null);

  // MARK: - Functions
  const getReservationCount = async () => {
    const params = {
      hospitalID,
      hserviceID: thatHospitalPatientId,
    };
    const { data } = await apiClient.v3.apiV2ReservationElCount(params);
    const totalCount = data.data as unknown as number | undefined;
    if (totalCount !== undefined) setReservationCount(totalCount);
  };
  const getReservations = async () => {
    const params: TempApiV2ReservationElFindParamsExtension = {
      hospitalID,
      hserviceID: thatHospitalPatientId,
      isRemoved: false,
      key: 'startedAt',
      way: -1,
      skip: reservations.length,
      limit: countPerPage,
    };
    if (isLoading) return;
    setIsLoading(true);
    const { data } = await apiClient.v3.apiV2ReservationElFind(params);
    setIsLoading(false);
    return data.data as unknown as V2Reservation[];
  };
  const getFirstReservations = async () => {
    await getReservationCount();
    setReservations([]);
    const newReservations = await getReservations();
    setReservations(newReservations ?? []);
  };
  const getNextReservations = async () => {
    const skip = reservations.length;
    const limit = countPerPage;
    if (skip + limit >= reservationCount) return;
    const newReservations = await getReservations();
    setReservations(reservations.concat(newReservations ?? []));
  };

  useEffect(() => {
    getFirstReservations();
  }, [thatHospitalPatientId, hospitalID]);

  const handleScroll = () => {
    getNextReservations();
  };

  useIntersectionObserver({
    target: loadMoreRef.current,
    onIntersect: handleScroll,
    enabled: reservations.length + countPerPage < reservationCount,
    rootMargin: '0px 0px 10px 0px',
  });

  // MARK: - Actions
  const handleClick = () => {
    window.electron?.ipcRenderer.send('Application.showReservationWindow', {
      value: { hserviceID: thatHospitalPatientId },
    });
  };

  // MARK: - Template
  return (
    <>
      <div className='p-20 pb-8'>
        <LabelText textClassName='text-Header16 text-black700 ml-4'>예약내역</LabelText>
      </div>
      <div className='mx-16 mb-23'>
        <OutlinedButton
          onClick={handleClick}
          className='w-full justify-center'
          btnColor='blue'
          iconProps={{ name: 'add-circle', color: 'blue500', size: 20 }}>
          예약하기
        </OutlinedButton>
        {reservations.length !== 0 && (
          <AlwaysVisibleScrollbar style={{ height: 245 }} className='mt-10 justify-between'>
            {reservations.map((reservation) => (
              <CustomerReservationCell key={reservation._id} reservation={reservation} />
            ))}
            <div ref={loadMoreRef} className='h-1' />
            {reservations.length === 0 && <CustomerReservationEmpty />}
          </AlwaysVisibleScrollbar>
        )}
        {reservations.length === 0 && (
          <Scrollbar style={{ height: 245 }} className='mt-10 justify-between'>
            {reservations.map((reservation) => (
              <CustomerReservationCell key={reservation._id} reservation={reservation} />
            ))}
            <div ref={loadMoreRef} className='h-1' />
            {reservations.length === 0 && <CustomerReservationEmpty />}
          </Scrollbar>
        )}
      </div>
    </>
  );
};
