import ContainedButton from '@afterdoc-design-system/components/Atoms/Button/ContainedButton';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { useAtom } from 'jotai';
import { usePatientDetailInfo } from 'web/shared/hooks/use-get-patient-detail-info';
import ReservationHistoryDatePickerContainer from '../containers/ReservationHistoryDatePicker.container';
import { PERIOD_OPTIONS } from '../containers/constants/period-options';
import { periodTabState } from '../containers/states/period-tab';

export default function TitleAndSubText() {
  const { patientId } = usePatientDetailInfo();
  const [selectedTab, setSelectedTab] = useAtom(periodTabState);

  return (
    <div className='flex w-full flex-row items-center justify-between'>
      <div className='flex flex-col gap-8'>
        <div className='text-Header14 text-black500'>예약내역</div>
        <div className='flex flex-row items-center gap-6'>
          <div className='h-14 w-14 rounded-full bg-red500' />
          <div className='text-Body12 text-black500'>
            해당 색으로 표기된 예약내역은 예약취소건을 뜻합니다.
          </div>
        </div>
      </div>
      <div className='flex flex-row items-center gap-20'>
        <div className='flex flex-row p-6'>
          {PERIOD_OPTIONS.map((option, index) => (
            <button
              key={option}
              type='button'
              className={customTwMerge(
                'h-fit w-fit select-none rounded-r6 px-14 py-4 text-Body11',
                PERIOD_OPTIONS.findIndex((option) => option === selectedTab) === index
                  ? 'bg-blue50 text-blue500'
                  : 'bg-white50 text-white800',
              )}
              onClick={() => setSelectedTab(option)}>
              {option}
            </button>
          ))}
        </div>
        <ReservationHistoryDatePickerContainer />
        <ContainedButton
          onClick={() => {
            if (window.electron === undefined) {
              alert('앱에서 실행해야 하는 기능입니다.');
            }
            window.electron?.ipcRenderer.send('Application.showReservationWindow', {
              value: { hserviceID: patientId },
            });
          }}
          buttonSize='small'>
          예약 등록
        </ContainedButton>
      </div>
    </div>
  );
}
