import Divider from '@afterdoc-design-system/components/Atoms/Divider/Divider';
import { useChatMessageContext } from '../ChatMessageContext';
import ContentText from './components/ContentText';
import MessageTitle from './components/MessageTitle';
export default function AlgorythmSuveyMessage() {
  const { isMine, messageWidthStyle, contentParts } = useChatMessageContext();
  const _question =
    '하다희님 앞으로의 치료 방향과 관리를 위해서 현재 불편하신 부위와 통증의 점수를 선택해 주실 수 있을까요~?';
  const answers = ['1점', '2점', '3점', '4점', '5점'];
  return (
    <div
      className={`rounded-r10 px-16 py-10 shadow-modal ${isMine ? 'bg-[rgba(255,245,208,1)]' : 'bg-white50'}`}
      style={messageWidthStyle}>
      <MessageTitle title='설문' />
      <ContentText parts={contentParts} />
      <Divider type='line' margin={16} />
      {answers?.map((answer) => (
        <div key={answer} className='flex items-center justify-between'>
          <div className='text-Body12'>{answer}</div>
        </div>
      ))}
    </div>
  );
}
