import TextButton from '@afterdoc-design-system/components/Atoms/Button/TextButton';
import Title from '@afterdoc-design-system/components/Molecules/Title/Title';
import { OVERLAY_PAGE_ID } from 'web/shared/constants/overlay-page-id';
import { useOpenAutomationDialog } from 'web/shared/hooks/overlayPage/use-open-automation-dialog';
import RegisterMarketingAutomationDialogContainer from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/RegisterMarketingAutomationDialog.container';

export default function MarketingAutomationTitle() {
  const openAutomationDialog = useOpenAutomationDialog();

  const onClickOpenAutomationDialog = () => {
    openAutomationDialog(
      <RegisterMarketingAutomationDialogContainer />,
      OVERLAY_PAGE_ID['register-marketing-automation-dialog'],
    );
  };

  return (
    <Title
      title='마케팅자동화'
      subTitle='치료태그가 입력된 고객 유형별로 마케팅 메시지를 설정할 수 있습니다.'
      trailingChildren={
        <TextButton
          onClick={onClickOpenAutomationDialog}
          textColor='blue500'
          className='text-Body14'>
          등록
        </TextButton>
      }
    />
  );
}
