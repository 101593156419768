import IconButton from '@afterdoc-design-system/components/Atoms/Button/IconButton';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import type { File } from 'web/apis/swaggers/swagger-docs';
import FilePicker from 'web/shared/components/FilePicker/FilePicker';
import ImageViewer from 'web/shared/components/ImageViewer/ImageViewer';
import {
  isLocalImage,
  useUploadMultipleImages,
} from 'web/shared/hooks/files/images/use-upload-multiple-images';
import type {
  CreateMemosAPIFormValues,
  UpdateMemosAPIFormValues,
} from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/CustomerInfoInputBoard/ManagementDirection/ManagementDirectionDialog.container';

interface ManagementDirectionImageGalleryProps {
  disabled?: boolean;
}

const VISIBLE_COUNT = 10;

export default function ManagementDirectionImageGallery({
  disabled,
}: ManagementDirectionImageGalleryProps) {
  const { setValue, watch } = useFormContext<CreateMemosAPIFormValues | UpdateMemosAPIFormValues>();
  const fileIDs = watch('fileIDs');

  const {
    images,
    setImages,
    handleFileChange,
    handleRemoveImage,
    handleAttachMultipleImages,
    filePickerRef,
  } = useUploadMultipleImages();

  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [startIndex, setStartIndex] = useState(0);
  const [selectedIndex, setSelectedIndex] = useState<number>();

  const handlePrev = () => {
    setStartIndex((prev) => Math.max(prev - VISIBLE_COUNT, 0));
  };

  const handleNext = () => {
    setStartIndex((prev) =>
      Math.min(
        prev + VISIBLE_COUNT,
        images.length - (images.length % VISIBLE_COUNT || VISIBLE_COUNT),
      ),
    );
  };

  const handleAddImage = () => {
    if (disabled) return;
    handleAttachMultipleImages();
  };

  const onRemoveImage = (index: number) => {
    if (index < images.length) {
      handleRemoveImage(index);
    } else {
      const previewIndex = index - images.length;
      setImages(images.filter((_, i) => i !== previewIndex));
      handleRemoveImage(previewIndex);
    }
  };

  const handleCapture = () => {
    window.electron?.ipcRenderer.send('Application.showCaptureWindow');
  };

  useEffect(() => {
    window.electron?.ipcRenderer.on('Application.captureWindowBounds', async (_, payload) => {
      if (payload.data) {
        const base64Data = payload.data.split(',')[1];
        const byteCharacters = atob(base64Data);
        const byteNumbers = new Array(byteCharacters.length).map((_, i) =>
          byteCharacters.charCodeAt(i),
        );
        const byteArray = new Uint8Array(byteNumbers);
        const blob = new Blob([byteArray], { type: 'image/png' });

        const localImage = {
          id: uuidv4(),
          blobUrl: URL.createObjectURL(blob),
          name: `captured-image-${uuidv4()}.png`,
          size: blob.size,
          type: blob.type,
          lastModified: Date.now(),
        };

        setImages((prevImages) => [...prevImages, localImage]);

        setTimeout(() => URL.revokeObjectURL(localImage.blobUrl), 1000);
      }
    });

    return () => {
      window.electron?.ipcRenderer.removeAllListeners('Application.captureWindowBounds');
    };
  }, []);

  useEffect(() => {
    if (images.length % VISIBLE_COUNT === 0) {
      setStartIndex(Math.max(images.length - VISIBLE_COUNT, 0));
    }
  }, [images]);

  useEffect(() => {
    if (images && images.length > 0) {
      setValue('fileIDs', [JSON.stringify(images)]);
    }
  }, [images]);

  useEffect(() => {
    if (fileIDs && fileIDs.length > 0 && !images.length) {
      const imageArr = JSON.parse(fileIDs[0]) as File[];

      if (!imageArr?.length) return;

      setImages(
        imageArr?.[0]?.paths?.map((path) => ({
          id: imageArr?.[0]._id,
          url: path,
        })),
      );
      return;
    }

    if (fileIDs === undefined) {
      setImages([]);
    }
  }, [fileIDs]);

  return (
    <>
      <div className='absolute right-0 bottom-[16px] left-0 flex flex-col gap-12 px-20'>
        <div className='flex w-full items-center justify-between gap-12'>
          {!disabled && images.length > 0 && (
            <IconButton
              icon='chevron-left'
              size={24}
              color={'black500'}
              className={customTwMerge(
                'h-24 w-24 flex-center rounded-r6 border border-white400 bg-white50 ',
                startIndex === 0 && 'cursor-not-allowed opacity-50',
              )}
              onClick={handlePrev}
              disabled={startIndex === 0 || images.length <= VISIBLE_COUNT}
            />
          )}
          <div className='flex w-full items-center justify-start gap-12'>
            {images.slice(startIndex, startIndex + VISIBLE_COUNT).map((image, index) => (
              <div
                key={uuidv4()}
                className='relative flex cursor-pointer flex-row items-center gap-6'
                onClick={() => {
                  setSelectedIndex(index + startIndex);
                  setIsImageViewerOpen(true);
                }}>
                <img
                  src={isLocalImage(image) ? image.blobUrl : image.url}
                  alt=''
                  className='h-40 w-40 rounded-r10 border border-white600'
                />
                <IconButton
                  icon='close'
                  color='white50'
                  size={16}
                  className='-right-[6px] -top-[6px] absolute h-16 w-16 flex-center rounded-full bg-[rgba(34,34,34,0.80)]'
                  onClick={(e) => {
                    e.stopPropagation();
                    onRemoveImage(index + startIndex);
                  }}
                />
              </div>
            ))}
          </div>
          {!disabled && images.length > 0 && (
            <IconButton
              icon='chevron-right'
              size={24}
              color='black500'
              className={customTwMerge(
                'h-24 w-24 flex-center rounded-r6 border border-white400 bg-white50',
                startIndex >= images.length - VISIBLE_COUNT && 'cursor-not-allowed opacity-50',
              )}
              onClick={handleNext}
              disabled={
                startIndex >= images.length - VISIBLE_COUNT || images.length <= VISIBLE_COUNT
              }
            />
          )}
        </div>
        <div className='flex gap-10'>
          <FilePicker ref={filePickerRef} onFileChange={handleFileChange} />
          <IconButton
            icon='image-plus'
            disabled={disabled}
            onClick={handleAddImage}
            color='black400'
            size={20}
          />
          <IconButton
            disabled={disabled}
            onClick={handleCapture}
            icon='selection-drag'
            color='black400'
            size={20}
          />
        </div>
      </div>
      {isImageViewerOpen && (
        <ImageViewer
          selectedIndex={selectedIndex}
          images={images.map((image) => (isLocalImage(image) ? image.blobUrl : image.url))}
          onClose={(e) => {
            setIsImageViewerOpen(false);
            e?.stopPropagation();
            e?.preventDefault();
          }}
        />
      )}
    </>
  );
}
