import * as React from "react";
import type { SVGProps } from "react";
const SvgKeyboardCloseList = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 87 64"
    {...props}
  >
    <rect width={87} height={64} fill="#F4F4F4" rx={10} />
    <path
      fill="#555"
      d="M11.22 20.738V9.026h2.748v.948h-1.44v9.816h1.44v.948zM22.679 8.606h1.596v11.148h-1.596zm-7.056 7.26h.984c1.968 0 3.54-.072 5.304-.372l.156 1.284c-1.8.324-3.432.384-5.46.384h-.984zm-.012-6.312h5.244v4.296h-3.612v2.496h-1.62V12.59h3.6v-1.764h-3.612zm14.579-.3h1.392v.804c0 2.412-1.716 4.548-4.596 5.112l-.684-1.32c2.508-.432 3.888-2.196 3.888-3.792zm.324 0h1.38v.804c0 1.632 1.368 3.36 3.888 3.792l-.684 1.32c-2.88-.564-4.584-2.664-4.584-5.112zm-4.44 7.824H36.13v1.296H26.074zm12.215-2.736h7.812v1.248H38.29zm-1.176 2.82H47.17v1.296H37.113zm1.176-7.74h7.716v1.284H39.91v3.984h-1.62zm1.128 2.448h6.336v1.236h-6.336zm18.594-3.252h1.596v6.216H58.01zm1.152 2.436h1.92v1.308h-1.92zm-7.884 2.052h.924c2.412 0 3.612-.06 4.944-.336l.156 1.26c-1.356.3-2.628.372-5.1.372h-.924zm0-3.804h4.932v1.26h-3.348v3.348h-1.584zm1.104 6.06h7.296v1.272h-5.7v2.388h-1.596zm0 2.988h7.5v1.26h-7.5zm17.171-9.732h1.608V19.73h-1.608zM66.278 9.77h1.584c0 3.396-1.152 6.18-5.1 8.088l-.828-1.272c3.192-1.536 4.344-3.552 4.344-6.528zm-3.756 0h4.512v1.26h-4.512zm10.499 10.968v-.948h1.44V9.974h-1.44v-.948h2.748v11.712z"
    />
    <rect
      width={23.708}
      height={23.708}
      x={11.485}
      y={33.146}
      fill="#404246"
      rx={2.969}
    />
    <rect
      width={23.708}
      height={23.708}
      x={11.485}
      y={33.146}
      stroke="#2C2D31"
      strokeWidth={2.969}
      rx={2.969}
    />
    <path
      fill="#fff"
      d="M16.061 49v-7.4h4.54v1.23h-3.06v1.71h2.59v1.25h-2.59v1.97h3.17V49zm7.633.14c-.75 0-1.58-.32-2.13-.78l.66-.93c.51.39.98.62 1.5.62.56 0 .82-.25.82-.6 0-.41-.59-.61-1.17-.83-.71-.28-1.5-.7-1.5-1.65 0-.99.8-1.7 2.08-1.7.81 0 1.45.33 1.91.69l-.67.88c-.39-.28-.79-.48-1.2-.48-.51 0-.74.22-.74.55 0 .4.51.56 1.11.77.74.28 1.55.66 1.55 1.69 0 .99-.77 1.77-2.22 1.77m5.72 0c-1.54 0-2.72-1.08-2.72-2.93 0-1.86 1.33-2.94 2.85-2.94.7 0 1.22.26 1.63.62l-.69.94c-.28-.24-.55-.36-.85-.36-.87 0-1.44.68-1.44 1.74 0 1.05.58 1.74 1.38 1.74.41 0 .79-.2 1.1-.45l.58.97c-.53.46-1.22.67-1.84.67"
    />
  </svg>
);
export default SvgKeyboardCloseList;
