import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import { handleVideoDialogOpen } from 'web/templates/CustomerChat/components/Manual/components/ContentsList/components/ContentsPanelFolderItem/components/ChatContentPanelFolderItem/components/HomcareVideoSelect/functions/handle-video-dialog-open';

type Props = {
  url: string;
  title: string;
  vchID?: string;
};

export default function VideoListCard({ url, title, vchID }: Props) {
  return (
    <div
      className='my-10 flex h-80 w-full cursor-pointer items-center rounded-r16 border border-white600 py-10 pr-6 pl-10 text-Body13'
      onClick={() => vchID && handleVideoDialogOpen(vchID, title)}>
      <img className='m-10 size-60 flex-shrink-0 rounded-r16' src={url} alt='Event Card' />
      <div className='mx-10 flex flex-grow flex-col'>
        <div className='line-clamp-2 text-Body13 text-black700'>{title}</div>
      </div>
      <Icon size={16} color={'black600'} name='chevron-right' className='flex-shrink-0' />
    </div>
  );
}
