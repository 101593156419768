import TextInput from '@afterdoc-design-system/components/Atoms/Input/TextInput';
import TextFieldSelectBoxDropdown from '@afterdoc-design-system/components/Molecules/TextSelect/TextFieldSelectBoxDropdown';
import { SHARED_UTILS } from '@shared-utils/utils';
import { useSuspenseQuery } from '@tanstack/react-query';
import MultipleTextFieldSelectBoxTagDropdown from 'afterdoc-design-system/components/Molecules/TextSelect/TextFieldSelectBoxTagDropdown/MultipleTextFieldSelectBoxTagDropdown';
import { Suspense } from 'react';
import { useFormContext } from 'react-hook-form';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type {
  ApiPatientsElDetailData,
  ApiServiceSettingsElCountriesData,
  ApiTreatmentTagsElParams,
} from 'web/apis/swaggers/swagger-docs';
import { GENDER_DISPLAY, GENDER_OPTIONS, VISIT_OPTIONS } from 'web/shared/constants/options';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import type { APIFormValues } from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/CareAndCustomerInfoInputForm/CareAndCustomerInfoInputForm.container';
import CountriesCodesListDropdown from './components/CountriesCodesListDropdown';

const fetchTreatmentTagsList = async (params: ApiTreatmentTagsElParams) => {
  const response = await apiClient.v3.apiTreatmentTagsEl(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

type CustomerInfoFormContainerProps = {
  countriesList: ApiServiceSettingsElCountriesData['data'];
} & ApiPatientsElDetailData['data'];

export default function CustomerInfoFormContainer({
  countriesList,
  ...patientInfo
}: CustomerInfoFormContainerProps) {
  const { isClosed, nationality, treatmentTags, countryCode } = patientInfo;

  const {
    register,
    setValue,
    getValues,
    formState: { errors, isSubmitted },
  } = useFormContext<APIFormValues>();

  const { hospitalID } = useSelectedHospitalInfo();

  const { data: treatmentTagsList } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiTreatmentTagsEl, { hospitalID }] as const,
    queryFn: ({ queryKey }) => fetchTreatmentTagsList(queryKey[1]),
  });

  return (
    <>
      <div className='grid grid-cols-2 gap-14'>
        <TextInput
          label={{
            isRequired: true,
            children: '고객명',
          }}
          placeholder='예) 홍길동'
          errorText={isSubmitted && (errors.name?.message as string)}
          hasError={isSubmitted && !!errors.name}
          className='col-span-2 w-full'
          disabled={isClosed}
          {...register('name', { required: '필수값입니다.' })}
        />
        <TextInput
          errorText={isSubmitted && (errors.chartNumber?.message as string)}
          hasError={isSubmitted && !!errors.chartNumber}
          label={{
            children: '차트번호',
          }}
          placeholder='예) 00000'
          disabled={isClosed}
          {...register('chartNumber', {
            maxLength: {
              value: 40000,
              message: '최대 40000자까지 입력 가능합니다.',
            },
          })}
        />
        <TextFieldSelectBoxDropdown
          disabled={isClosed}
          options={VISIT_OPTIONS}
          onSelect={(index) => {
            setValue('isFirstVisit', VISIT_OPTIONS[index] === '초진');
          }}
          selectedIndex={VISIT_OPTIONS.findIndex(
            (option) =>
              option ===
              (getValues('isFirstVisit') !== undefined
                ? getValues('isFirstVisit')
                  ? '초진'
                  : '재진'
                : undefined),
          )}
          label={{
            children: '초/재진',
          }}
          placeholder='선택'
        />
        <Suspense>
          <CountriesCodesListDropdown countryCode={countryCode} disabled={isClosed} />
        </Suspense>
        <TextInput
          disabled={isClosed}
          errorText={isSubmitted && (errors.phoneNumber?.message as string)}
          hasError={isSubmitted && !!errors.phoneNumber}
          label={{
            isRequired: true,
            children: '휴대폰번호',
          }}
          placeholder='예) 01012345678'
          {...register('phoneNumber', { required: '필수값입니다.' })}
        />
        <TextInput
          disabled={isClosed}
          errorText={isSubmitted && (errors.birthdate?.message as string)}
          hasError={isSubmitted && !!errors.birthdate}
          label={{
            children: '생년월일',
          }}
          placeholder='예) YYYY-MM-DD'
          maxLength={10}
          {...register('birthdate', {
            onChange: (e) => {
              let inputValue = e.target.value.replace(/[^0-9]/g, '');

              // YYYY-MM-DD 형식으로 자동 포맷팅
              if (inputValue.length >= 4) {
                inputValue = `${inputValue.slice(0, 4)}-${inputValue.slice(4)}`;
              }
              if (inputValue.length >= 7) {
                inputValue = `${inputValue.slice(0, 7)}-${inputValue.slice(7, 10)}`;
              }

              setValue('birthdate', inputValue, { shouldValidate: true });
            },
          })}
          onKeyDown={(e) => {
            const value = e.currentTarget.value;
            if (e.key === 'Backspace' && value.endsWith('-')) {
              e.preventDefault();
              // '-'를 제거하고 필드 업데이트
              e.currentTarget.value = value.slice(0, -1);
              register('birthdate').onChange({
                target: { value: value.slice(0, -1) },
              });
            }
          }}
        />
        <TextFieldSelectBoxDropdown
          disabled={isClosed}
          options={GENDER_OPTIONS.map((option) => GENDER_DISPLAY[option])}
          onSelect={(index) => {
            setValue('gender', GENDER_OPTIONS[index]);
          }}
          selectedIndex={GENDER_OPTIONS.findIndex((option) => option === getValues('gender'))}
          label={{
            children: '성별',
          }}
          placeholder='선택'
        />
        <MultipleTextFieldSelectBoxTagDropdown
          disabled={isClosed}
          placeholder={'예) 도수치료, 리프팅'}
          tagOptions={
            treatmentTagsList.treatmentTags?.map((tag) => ({
              name: tag.name,
              id: tag.tagId,
              color: tag.color,
            })) ?? []
          }
          onSelectedTagsChange={(tags) => {
            setValue(
              'treatmentTagIds',
              tags.map((tag) => tag.id),
            );
          }}
          defaultSelectedTags={treatmentTags?.map((tag) => ({
            name: tag.name,
            id: tag.tagId,
            color: tag.color,
          }))}
          label={{
            children: '치료태그',
          }}
          className='col-span-2'
          fieldWrapperClassName='max-h-[60px]'
        />
        <TextFieldSelectBoxDropdown
          disabled={isClosed}
          placeholder='선택'
          options={countriesList
            .filter((country) => country.countryCodeISOAlpha3 !== 'KOR')
            .map((country) => country.koreanCountryName ?? '')}
          onSelect={(index) => {
            setValue('nationalityId', countriesList[index].countryId);
          }}
          selectedIndex={
            countriesList
              .filter((country) => country.countryCodeISOAlpha3 !== 'KOR')
              .findIndex((country) => country.countryId === nationality?.id) ?? -1
          }
          label={{
            children: '국적',
          }}
          wrapperClassName='col-span-2'
        />
      </div>
    </>
  );
}
