import { modalService } from '@afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { overlayPageService } from '@afterdoc-design-system/components/Molecules/OverlayPage/OverlayPage.service';
import { useFormContext } from 'react-hook-form';
import { OVERLAY_PAGE_ID } from 'web/shared/constants/overlay-page-id';
import type { MarketingAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/components/RegisterMarketingAutomationDialogContent';
import { useResetAll } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/hooks/use-reset-all';
import { useUnsavedChangesWarning } from 'web/templates/Automation/containers/shared/hooks/use-unsaved-changes-warning';

export default function RegisterMarketingAutomationDialogHeader() {
  const {
    formState: { isDirty, defaultValues },
    getValues,
    reset,
    watch,
  } = useFormContext<MarketingAutomationAPIFormValues>();

  const { resetAll } = useResetAll({
    reset,
  });

  const handleConfirmLeave = () => {
    modalService.pop();
    overlayPageService.popById(OVERLAY_PAGE_ID['register-marketing-automation-dialog']);
  };

  const handleCancelLeave = () => {
    modalService.popById('unsaved-changes-warning');
  };

  const { showWarning } = useUnsavedChangesWarning<MarketingAutomationAPIFormValues>({
    formMethods: {
      isDirty,
      defaultValues: defaultValues as MarketingAutomationAPIFormValues,
      getValues,
      watch,
    },
    onReset: resetAll,
    onConfirm: handleConfirmLeave,
    onCancel: handleCancelLeave,
  });

  return (
    <div className='flex w-full items-center justify-between bg-white50 px-20 pt-20 pb-8'>
      <div className='w-fit text-Header16 text-black700'>마케팅자동화 등록</div>
      <button type='button' onClick={showWarning} className='text-Body14 text-black700'>
        닫기
      </button>
    </div>
  );
}
