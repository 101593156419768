import Pagination from '@afterdoc-design-system/components/Molecules/Pagination/Pagination';
import { memo } from 'react';

const ReservationPagination = ({
  totalPages,
  currentPage,
  handlePageChange,
}: {
  totalPages: number;
  currentPage: number;
  handlePageChange: (page: number) => void;
}) => {
  return (
    <Pagination totalPages={totalPages} defaultPage={currentPage} onPageChange={handlePageChange} />
  );
};

export default memo(ReservationPagination);
