import Checkbox from '@afterdoc-design-system/components/Atoms/Checkbox/Checkbox';
import Scrollbar from '@afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import FilledTag from '@afterdoc-design-system/components/Atoms/Tag/FilledTag';
import { SHARED_UTILS } from '@shared-utils/utils';
import { useSuspenseQuery } from '@tanstack/react-query';
import { Fragment, forwardRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type {
  ApiServiceSettingsElCountriesData,
  ApiServiceSettingsElCountriesParams,
} from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { filterWrapperClassName } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementEditableTableHeader/constants/class';
import type { EditableHeaderFilterState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/states/editable-header-filter';
import type { ReadOnlyHeaderFilterState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementReadOnlyTable/states/read-only-header-filter';
import type { UnTaggedHeaderFilterState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/UnTaggedCustomerManagement/containers/UnTaggedCustomerManagementEditableTable/states/editable-header-filter';

type CheckState = {
  [countryId: string]: boolean;
};

const fetchCountriesCodesList = async (params: ApiServiceSettingsElCountriesParams) => {
  const response = await apiClient.v3.apiServiceSettingsElCountries(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const CountryCodeFilter = forwardRef<HTMLDivElement>((_, ref) => {
  const { setValue, watch } = useFormContext<
    EditableHeaderFilterState | ReadOnlyHeaderFilterState | UnTaggedHeaderFilterState
  >();
  const countryCode = watch('countryCode');

  const { hospitalID } = useSelectedHospitalInfo();

  const { data: countriesCodesList } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiServiceSettingsElCountries, { hospitalID, limit: 240 }] as const,
    queryFn: ({ queryKey }) => fetchCountriesCodesList(queryKey[1]),
  });

  const [checkState, setCheckState] = useState<CheckState | undefined>(() => {
    if (!countryCode) return undefined;
    const initialState: CheckState = {};
    for (const cur of countryCode.split(',')) {
      initialState[cur] = true;
    }
    return initialState;
  });

  const handleCheckboxChange = (countryId: string) => {
    const newState = { ...checkState, [countryId]: !checkState?.[countryId] };
    setCheckState(newState);

    const selectedTags = Object.keys(newState).filter((key) => newState[key]);

    if (selectedTags.length === 0 || selectedTags.length === countriesCodesList.length) {
      setValue('countryCode', undefined);
    } else {
      setValue('countryCode', selectedTags.join(','));
    }
  };
  return (
    <div className={`${filterWrapperClassName} h-[200px] w-[300px]`} ref={ref}>
      <Scrollbar disabledX>
        <div className='p-16'>
          <div className='mb-10 text-Caption9 text-black200'>
            *체크한 값에 해당하는 고객만 표시됩니다.
          </div>
          {countriesCodesList.map((country: ApiServiceSettingsElCountriesData['data'][number]) => (
            <Fragment key={country.countryId}>
              {country.countryId && (
                <Checkbox
                  label={
                    <FilledTag
                      className='cursor-pointer'
                      maxTextLength='full'
                      tagSize='small'>{`${country.koreanCountryName}(${country.internationalDialingCodes})`}</FilledTag>
                  }
                  checked={!!checkState?.[country.countryId]}
                  onChange={() => {
                    if (!country.countryId) return;
                    handleCheckboxChange(country.countryId);
                  }}
                />
              )}
            </Fragment>
          ))}
        </div>
      </Scrollbar>
    </div>
  );
});

export default CountryCodeFilter;
