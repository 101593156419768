import Checkbox from '@afterdoc-design-system/components/Atoms/Checkbox/Checkbox';
import { useAtom } from 'jotai';
import { isCheckedImOutState } from '../../states/is-checked-im-out';

export default function CheckboxImOut() {
  const [isCheckedImOut, setIsCheckedImOut] = useAtom(isCheckedImOutState);

  return (
    <Checkbox
      checked={isCheckedImOut}
      className='cursor-pointer'
      labelProps={{ className: 'text-Body12 text-blue500' }}
      label='추가 후, 본인은 채팅방을 나가겠습니다.'
      onChange={(e) => setIsCheckedImOut(e.target.checked)}
    />
  );
}
