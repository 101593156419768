import IconButton from 'afterdoc-design-system/components/Atoms/Button/IconButton';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import { memo } from 'react';

interface TopFixedButtonProps {
  isPinned: boolean;
  onHandleValue: (value: boolean) => void;
}

function TopFixedButton({ isPinned, onHandleValue }: TopFixedButtonProps) {
  return (
    <div className='flex h-40 items-center gap-28'>
      <LabelText width={72} className='flex h-32 items-center'>
        상단 고정 여부
      </LabelText>
      <div className='h-full flex-center gap-10'>
        <IconButton
          icon={isPinned ? 'pinned' : 'pin'}
          size={24}
          onClick={() => onHandleValue(!isPinned)}
        />
        <span className='text-Body11 text-black500'>{isPinned ? '고정 함' : '고정 안함'}</span>
      </div>
    </div>
  );
}

export default memo(TopFixedButton);
