import TextButton from '@afterdoc-design-system/components/Atoms/Button/TextButton';
import Divider from '@afterdoc-design-system/components/Atoms/Divider/Divider';
import LabelText from '@afterdoc-design-system/components/Atoms/LabelText/LabelText';
import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import { useAtomValue } from 'jotai';
import { useCallback, useEffect, useRef } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import type { CounselAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/components/RegisterCounselAutomationDialogContent';
import { selectedMessageIndexState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-form';
import { originMessagesState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/origin-message';
import ResizableTextArea from 'web/templates/Automation/containers/Dialog/shared/components/ResizableTextArea/ResizableTextArea';

export default function SurveyContentForm() {
  const { control, setValue, watch, getValues } = useFormContext<CounselAutomationAPIFormValues>();
  const originMessages = useAtomValue(originMessagesState);
  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);

  const hasApprovedAlimtalkTemplate =
    originMessages[selectedMessageIndex]?.hasApprovedAlimtalkTemplate ?? false;
  const surveyAnswers = watch(`messages.${selectedMessageIndex}.content.survey.answers`);

  const answerRefs = useRef<Record<number, HTMLTextAreaElement>>({});

  const handleAddAnswer = useCallback(() => {
    const currentAnswers =
      getValues(`messages.${selectedMessageIndex}.content.survey.answers`) || [];

    if (currentAnswers.length >= 100) return;

    setValue(`messages.${selectedMessageIndex}.content.survey.answers`, [...currentAnswers, '']);

    setTimeout(() => {
      const newIndex = currentAnswers.length;
      if (answerRefs.current[newIndex]) {
        answerRefs.current[newIndex].focus();
      }
    }, 0);
  }, [getValues, setValue, selectedMessageIndex]);

  const handleRemoveAnswer = useCallback(
    (index: number) => {
      const currentAnswers =
        getValues(`messages.${selectedMessageIndex}.content.survey.answers`) || [];
      const updatedAnswers = currentAnswers.filter((_, idx) => idx !== index);

      setValue(`messages.${selectedMessageIndex}.content.survey.answers`, updatedAnswers);

      setTimeout(() => {
        const prevIndex = index > 0 ? index - 1 : 0;
        if (answerRefs.current[prevIndex]) {
          answerRefs.current[prevIndex].focus();
        }
      }, 0);
    },
    [getValues, setValue, selectedMessageIndex],
  );

  useEffect(() => {
    if (!surveyAnswers?.length) {
      setValue(`messages.${selectedMessageIndex}.content.survey.answers`, ['', '']);
    }
  }, [surveyAnswers, setValue, selectedMessageIndex]);

  return (
    <div className='mt-10'>
      {hasApprovedAlimtalkTemplate && (
        <div className='rounded-r10 bg-white200 px-16 py-8 text-Body10 text-black500'>
          설문 타입의 메시지는 알림톡을 발송할 수 없습니다.
        </div>
      )}
      <div className='mt-10 flex flex-col gap-10 rounded-r16 bg-white100 p-16'>
        <Controller
          name={`messages.${selectedMessageIndex}.content.survey.question`}
          control={control}
          render={({ field }) => (
            <div className='flex w-full gap-10'>
              <LabelText isRequired className='shrink-0' width={60}>
                질문
              </LabelText>

              <ResizableTextArea
                {...field}
                maxHeight={120}
                maxLength={1000}
                placeholder='내용을 입력해 주세요.'
              />
            </div>
          )}
        />

        <Divider type='line' />

        {surveyAnswers?.map((_, index) => {
          const disabled = index === 0 || index === 1;

          return (
            <div key={index} className='flex items-center gap-10'>
              <Controller
                name={`messages.${selectedMessageIndex}.content.survey.answers.${index}`}
                control={control}
                render={({ field }) => (
                  <div className='flex w-full gap-10'>
                    <LabelText isRequired className='shrink-0' width={60}>
                      {`답변${index + 1}`}
                    </LabelText>
                    <ResizableTextArea
                      {...field}
                      className='w-full'
                      placeholder='내용을 입력해 주세요.'
                      ref={(el) => {
                        if (el) {
                          answerRefs.current[index] = el;
                        }
                      }}
                    />
                  </div>
                )}
              />
              <TextButton
                onClick={() => handleRemoveAnswer(index)}
                disabled={disabled}
                className='whitespace-nowrap'
                iconProps={{
                  name: 'minus',
                  color: disabled ? 'disabled' : 'black200',
                  size: 16,
                }}>
                삭제
              </TextButton>
            </div>
          );
        })}

        <div className='ml-auto'>
          <button
            type='button'
            onClick={handleAddAnswer}
            disabled={(surveyAnswers?.length || 0) >= 100}
            className='flex gap-2 rounded-r6 bg-white400 py-4 pr-4 pl-8'>
            <span className='text-Body11 text-black400 '>추가</span>
            <Icon name='plus' color='black400' size={16} />
          </button>
        </div>
      </div>
    </div>
  );
}
