import PanelList from '@afterdoc-design-system/components/Atoms/PanelList/PanelList';
import { SHARED_UTILS } from '@shared-utils/utils';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { memo, useEffect, useRef, useState } from 'react';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ApiPatientsElUntaggedCountParams } from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { selectedSearchCriteriaState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/containers/SearchFilter/states/selected-search-criteria';
import { clickedPanelIndexState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/states/panel';
import { DEFAULT_PANEL_ITEMS } from '../constants/panel-items';

const fetchUnTaggedPatientsCount = async (params: ApiPatientsElUntaggedCountParams) => {
  const response = await apiClient.v3.apiPatientsElUntaggedCount(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

function CustomerManagementPanel() {
  const isFirstRender = useRef(true);
  const { hospitalID } = useSelectedHospitalInfo();

  const resetSelectedSearchCriteria = useResetAtom(selectedSearchCriteriaState);
  const [clickedPanelIndex, setClickedPanelIndex] = useAtom(clickedPanelIndexState);

  const [panelItems, setPanelItems] = useState(DEFAULT_PANEL_ITEMS);

  const { data } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiPatientsElUntaggedCount, { hospitalID }] as const,
    queryFn: ({ queryKey }) => fetchUnTaggedPatientsCount(queryKey[1]),
  });

  useEffect(() => {
    const updatedPanelItems = DEFAULT_PANEL_ITEMS.map((item, index) =>
      index === 1 ? { ...item, rightContent: data?.count ?? 0 } : item,
    );
    setPanelItems(updatedPanelItems);
  }, [data]);

  useEffect(() => {
    if (!isFirstRender.current) return;

    if (isFirstRender.current) {
      setClickedPanelIndex(0);
      isFirstRender.current = false;
    }
  }, [setClickedPanelIndex]);

  useEffect(() => {
    const updatedPanelItems = panelItems.map((item, index) => {
      if (clickedPanelIndex === undefined) {
        return { ...item, state: 'default' };
      }

      if (clickedPanelIndex === index) {
        return { ...item, state: 'focus' };
      }

      return { ...item, state: 'default' };
    }) as typeof panelItems;

    setPanelItems(updatedPanelItems);
  }, [clickedPanelIndex]);

  return (
    <PanelList
      layers={panelItems}
      onLayerClick={(index) => {
        setClickedPanelIndex(index);
        resetSelectedSearchCriteria();
      }}
    />
  );
}

export default memo(CustomerManagementPanel);
