import type { HospitalEventFileWriter } from '@apis/swaggers/swagger-docs';
import LayoutBox from '@shared/components/LayoutBox/LayoutBox';
import { useNavigationBlocker } from '@shared/hooks/use-navigation-blocker';
import { isServiceManagerNavigatorVisibleState } from '@shared/states/is-service-manager-navigator-visible';
import { Color } from '@tailwind-base/styles/color';
import EventManagePageFooter from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/components/EventManageDialog/components/EventManagePageFooter';
import { DEFAULT_EVENT_PARAMS } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/constants/default-event-params';
import { convertHospitalEventToParams } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/functions/convert-hospital-event-to-params';
import { eventCreateOrUpdateParamState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/states/event-create-or-update-param-state';
import type { CreateUpdateEventParamType } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/types/create-update-event-param-type';
import { useManageHandlers } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/hooks/use-manage-handlers';
import { useResetAll } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/hooks/use-reset-all';
import EventHomeCareEditInfo from '@templates/Content/containers/ContentManager/containers/EventHomeCare/shared/components/EventHomeCareEditInfo/EventHomeCareEditInfo';
import EventHomeCarePreview from '@templates/Content/containers/ContentManager/containers/EventHomeCare/shared/components/EventHomeCarePreview/EventHomeCarePreview';
import ActivationStatusSwitch from '@templates/Content/containers/ContentManager/containers/EventHomeCare/shared/components/Manage/ActivationStatusSwitch';
import DetailContentSelector from '@templates/Content/containers/ContentManager/containers/EventHomeCare/shared/components/Manage/DetailContentSelector/DetailContentSelector';
import EventHomeCareTitleInput from '@templates/Content/containers/ContentManager/containers/EventHomeCare/shared/components/Manage/EventHomeCareTitleInput';
import ExposurePeriodSelector from '@templates/Content/containers/ContentManager/containers/EventHomeCare/shared/components/Manage/ExposurePeriodSelector/ExposurePeriodSelector';
import IntroSummaryInput from '@templates/Content/containers/ContentManager/containers/EventHomeCare/shared/components/Manage/IntroSummaryInput';
import PriceSettingInput from '@templates/Content/containers/ContentManager/containers/EventHomeCare/shared/components/Manage/PriceSettingInput/PriceSettingInput';
import PushTargetSelector from '@templates/Content/containers/ContentManager/containers/EventHomeCare/shared/components/Manage/PushTargetSelector/PushTargetSelector';
import ThumbnailImagePicker from '@templates/Content/containers/ContentManager/containers/EventHomeCare/shared/components/Manage/ThumbnailImagePicker';
import TopFixedButton from '@templates/Content/containers/ContentManager/containers/EventHomeCare/shared/components/Manage/TopFixedButton';
import { detailImageTempFileState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/states/detail-image-temp-file-state';
import { thumbnailImageTempFileState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/states/thumbnail-image-temp-file-state';
import { contentEditState } from '@templates/Content/containers/ContentManager/states/content-edit-state';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import BaseLoading from 'afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import { toastService } from 'afterdoc-design-system/components/Atoms/Toast/Toast.service';
import Title from 'afterdoc-design-system/components/Molecules/Title/Title';
import Portal from 'afterdoc-design-system/shared/Portal/Portal';
import { useAtom, useAtomValue } from 'jotai';
import { isEqual } from 'lodash-es';
import { useEffect, useRef, useState } from 'react';

export default function EventManageDialogContainer() {
  const eventManageHandlers = useManageHandlers<CreateUpdateEventParamType>('event');
  const { resetAll } = useResetAll();

  const [eventCreateOrUpdateParam, setEventCreateOrUpdateParam] = useAtom(
    eventCreateOrUpdateParamState,
  );

  const contentEdit = useAtomValue(contentEditState);
  const isServiceManagerNavigatorVisible = useAtomValue(isServiceManagerNavigatorVisibleState);
  const thumbnailImageTempFile = useAtomValue(thumbnailImageTempFileState);
  const detailImageTempFile = useAtomValue(detailImageTempFileState);

  const [initCreateOrUpdateParam, setInitCreateOrUpdateParam] = useState<
    CreateUpdateEventParamType | undefined
  >(undefined);

  const representImagePathRef = useRef<string | undefined>();
  const detailInfoImageRef = useRef<string | undefined>();

  const [isInitialized, setIsInitialized] = useState(false);
  const [isModified, setIsModified] = useState(false);

  const {
    isActive = true,
    isPinned = false,
    title = '',
    description = '',
    price = 0,
    salePrice = 0,
    saleRate = 0,
    saleType = 'price',
    isLimitedTime = false,
    startDate = '',
    endDate = '',
    type = 'image',
    content,
  } = eventCreateOrUpdateParam;

  useEffect(() => {
    if (!contentEdit.id || contentEdit.data?.editType !== 'event') return;

    // 대표이미지
    if (representImagePathRef.current === undefined) {
      const representImage = contentEdit.data?.representImage;
      if (representImage?.paths && representImage.paths.length > 0) {
        representImagePathRef.current = representImage.paths[0];
      }
    }

    // 상세이미지
    if (detailInfoImageRef.current === undefined) {
      const fileIDs = contentEdit.data?.fileIDs;
      if (fileIDs && fileIDs.length > 0 && fileIDs[0].paths && fileIDs[0].paths.length > 0) {
        detailInfoImageRef.current = fileIDs[0].paths[0];
      }
    }
  }, [contentEdit.data]);

  useEffect(() => {
    if (contentEdit.id && contentEdit.data?.editType === 'event') {
      const selectedEventContent = contentEdit.data as HospitalEventFileWriter;
      const eventData = convertHospitalEventToParams(selectedEventContent);
      setEventCreateOrUpdateParam(eventData);
      setInitCreateOrUpdateParam(eventData);
    } else {
      setInitCreateOrUpdateParam(DEFAULT_EVENT_PARAMS);
    }
    setIsInitialized(true);
  }, [contentEdit]);

  useEffect(() => {
    return () => {
      resetAll('eventCreateOrUpdateParamState');
    };
  }, []);

  useEffect(() => {
    if (!initCreateOrUpdateParam || !eventCreateOrUpdateParam) {
      setIsModified(false);
      return;
    }

    const hasParamChanged = !isEqual(eventCreateOrUpdateParam, initCreateOrUpdateParam);

    const hasThumbnailChanged = !!thumbnailImageTempFile;
    const hasDetailImageUnchanged = !!detailImageTempFile;

    setIsModified(hasParamChanged || hasThumbnailChanged || hasDetailImageUnchanged);
  }, [
    initCreateOrUpdateParam,
    eventCreateOrUpdateParam,
    thumbnailImageTempFile,
    detailImageTempFile,
  ]);

  useNavigationBlocker({
    shouldBlock: isModified,
    onConfirm: () => {
      const message = `이벤트 ${contentEdit.id ? '수정' : '등록'}을 취소했습니다.`;
      toastService.errorMsg({ text: message });
    },
  });

  if (!isInitialized) {
    return (
      <div className='min-h-screen w-full flex-center'>
        <BaseLoading />
      </div>
    );
  }

  return (
    <Portal
      style={{
        position: 'fixed',
        top: 0,
        left: isServiceManagerNavigatorVisible ? '140px' : '70px',
        zIndex: 100,
        right: 0,
        bottom: 0,
        backgroundColor: Color.blueLight,
      }}>
      <div className='flex min-h-screen w-full flex-col'>
        <div className='mx-auto flex w-[1400px] flex-grow'>
          <div className='w-[1000px] bg-white50'>
            <Title title={`이벤트 ${contentEdit.id ? '수정' : '등록'}하기`} />
            <div className='h-[calc(100vh-120px)]'>
              <Scrollbar disabledX={true}>
                <div className='h-full px-100 py-40'>
                  {/*기본정보*/}
                  <LayoutBox direction='vertical' size={10}>
                    <ActivationStatusSwitch
                      isActive={isActive}
                      onHandleValue={eventManageHandlers.handleIsActiveChange}
                    />
                    <TopFixedButton
                      isPinned={isPinned}
                      onHandleValue={eventManageHandlers.handleIsPinnedChange}
                    />
                    <EventHomeCareTitleInput
                      title={title}
                      onHandleValue={eventManageHandlers.handleTitleChange}
                    />
                    <PriceSettingInput
                      price={price}
                      salePrice={salePrice}
                      saleRate={saleRate}
                      saleType={saleType}
                      onHandlePriceValue={eventManageHandlers.handlePriceChange}
                      onHandleSalePriceValue={eventManageHandlers.handleSalePriceChange}
                      onHandleSaleRateValue={eventManageHandlers.handleSaleRateChange}
                      onHandleSaleTypeValue={eventManageHandlers.handleSaleTypeChange}
                    />
                    <Divider type='line' />
                  </LayoutBox>
                  {/*노출기간*/}
                  <LayoutBox noStyle>
                    <ExposurePeriodSelector
                      isLimitedTime={isLimitedTime}
                      startDate={startDate}
                      endDate={endDate}
                      onHandleIsLimitedTimeValue={eventManageHandlers.handleIsLimitedTimeChange}
                      onHandleStartDateValue={eventManageHandlers.handleStartDateChange}
                      onHandleEndDateValue={eventManageHandlers.handleEndDateChange}
                    />
                  </LayoutBox>
                  {/*푸시타겟*/}
                  <LayoutBox noStyle>{!contentEdit.id && <PushTargetSelector />}</LayoutBox>
                  {/*한줄소개글*/}
                  <LayoutBox noStyle>
                    <IntroSummaryInput
                      description={description}
                      onHandleValue={eventManageHandlers.handleDescriptionChange}
                    />
                  </LayoutBox>
                  {/*대표이미지*/}
                  <LayoutBox noStyle>
                    <ThumbnailImagePicker imageUrl={representImagePathRef.current} />
                  </LayoutBox>
                  {/*상세정보*/}
                  <LayoutBox noStyle>
                    <DetailContentSelector
                      type={type}
                      content={content}
                      imagePath={detailInfoImageRef.current}
                      onHandleContentTypeValue={eventManageHandlers.handleContentTypeChange}
                      onHandleContentValue={eventManageHandlers.handleContentInputChange}
                    />
                  </LayoutBox>
                  {/*작성자/수정자 정보*/}
                  <LayoutBox noStyle>{contentEdit.id && <EventHomeCareEditInfo />}</LayoutBox>
                </div>
              </Scrollbar>
            </div>
          </div>
          <EventHomeCarePreview
            representImagePath={representImagePathRef.current}
            detailInfoImagePath={detailInfoImageRef.current}
            previewType={'event'}
          />
        </div>
        <EventManagePageFooter isModified={isModified} />
      </div>
    </Portal>
  );
}
