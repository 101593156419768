import { useChatMessageContext } from '../ChatMessageContext';
import MessageTitle from './components/MessageTitle';

export default function MarketingImageMessage() {
  const { fileIDs, isMine, messageWidthStyle } = useChatMessageContext();
  if (fileIDs === undefined) return null;
  return (
    <div
      className={`rounded-r10 bg-white50 px-16 py-10 shadow-modal ${isMine ? 'bg-[rgba(255,245,208,1)]' : 'bg-white50'}`}
      style={messageWidthStyle}>
      <MessageTitle title='이벤트 알림' />
      {fileIDs?.map(
        ({ paths, createdAt }) =>
          paths && <img key={createdAt} src={paths[0]} alt='marketing' className='h-full w-full' />,
      )}
    </div>
  );
}
