import FilledTag from '@afterdoc-design-system/components/Atoms/Tag/FilledTag';
import NoData from '@afterdoc-design-system/components/Molecules/NoData/NoData';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { Suspense } from 'react';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import { usePatientDetailInfo } from 'web/shared/hooks/use-get-patient-detail-info';
import { smartDoctorAxiosInstance } from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/SmartDoctor/api/axios';
import { smartDoctorTokenState } from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/SmartDoctor/state/token';
import { TABLE_HEADERS } from '../constants/headers';

interface Reservation {
  id: {
    customerNumber: string;
    seqNo: number;
  };
  label: {
    id: number;
    name: string;
    color: string;
  };
  customerName: string;
  subjectCode: string;
  departmentCode: string;
  departmentName: string;
  chargeDoctorId: string;
  chargeDoctorName: string;
  consultantId: string;
  reservationDate: string;
  reservationStartTime: string;
  reservationEndTime: string;
  reservationMemo: string;
  etcMemo: string;
  relatedToClinic: boolean;
  prescriptions: Prescription[];
  externalReservationFrom: string | null;
}

interface Prescription {
  prescriptionCode: string;
  prescriptionName: string;
}

interface ReservationsResponse {
  reservations: Reservation[];
}

interface ReservationRequestParams {
  name: string;
  reservationDateWithinStart: string;
  reservationDateWithinEnd: string;
  cellPhone: string;
  token: string;
}

// TODO : 임시로 넣어둔 값들
const reservationDateWithinStart = '2024-06-30';
const reservationDateWithinEnd = '2024-08-31';

const getSmartDoctorReservationHistory = async ({
  name,
  reservationDateWithinStart,
  reservationDateWithinEnd,
  cellPhone,
  token,
}: ReservationRequestParams) => {
  const response = await smartDoctorAxiosInstance.get<ReservationsResponse>(
    `/reservations?reservationDateWithinStart=${reservationDateWithinStart}&reservationDateWithinEnd=${reservationDateWithinEnd}&name=${name}&cellPhone=${cellPhone}`,
    {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  return response.data;
};

export default function SmartDoctorReservationHistoryTable() {
  return (
    <table className='h-full bg-white text-Body12'>
      <colgroup>
        <col className='w-[98px]' />
        <col className='w-[198px]' />
        <col className='w-[178px]' />
        <col className='w-[98px]' />
        <col />
        <col className='w-[98px]' />
      </colgroup>
      <TableHeader />
      <Suspense>
        <TableBody />
      </Suspense>
    </table>
  );
}

const TableHeader = () => {
  return (
    <thead>
      <tr>
        {TABLE_HEADERS.map((header) => (
          <th
            key={header}
            className='h-fit whitespace-nowrap border-line-table border-b bg-table px-12 py-10 text-left text-Header14 text-black700'>
            {header}
          </th>
        ))}
      </tr>
    </thead>
  );
};

const TableBody = () => {
  const smartDoctorToken = useAtomValue(smartDoctorTokenState);
  const { name, phoneNumber } = usePatientDetailInfo();

  const { data } = useQuery({
    queryKey: [
      QUERY_KEY.smartDoctorReservationHistory,
      {
        name,
        reservationDateWithinStart,
        reservationDateWithinEnd,
        cellPhone: phoneNumber,
        token: smartDoctorToken,
      },
    ] as const,
    queryFn: ({ queryKey }) => getSmartDoctorReservationHistory(queryKey[1]),
    enabled: !!smartDoctorToken,
  });

  if (!data || data?.reservations.length === 0) {
    return (
      <tr>
        <td colSpan={TABLE_HEADERS.length}>
          <div className='flex h-[300px] w-full items-center justify-center'>
            <NoData
              iconProps={{
                name: 'calendar-blank-outline',
                size: 48,
                color: 'white400',
              }}
              title='등록한 예약이 없습니다.'
              subTitle='스마트닥터에서 등록한 예약을 확인할 수 있습니다.'
            />
          </div>
        </td>
      </tr>
    );
  }

  return (
    <tbody className='truncate'>
      {data.reservations.map((reservation, index) => {
        return (
          <tr key={index}>
            <td className='border-line-table border-b px-12 py-10'>
              {reservation.label.name ? (
                <FilledTag bgColor={reservation.label.color}>{reservation.label.name}</FilledTag>
              ) : (
                '-'
              )}
            </td>
            <td className='border-line-table border-b px-12 py-10'>
              {reservation.reservationDate ?? '-'}
            </td>
            <td className='border-line-table border-b px-12 py-10'>
              {reservation.departmentName ?? '-'}
            </td>
            <td className='border-line-table border-b px-12 py-10'>
              {reservation.chargeDoctorName ?? '-'}
            </td>
            <td className='border-line-table border-b px-12 py-10'>
              {reservation.reservationMemo ?? '-'}
            </td>
            <td className='border-line-table border-b px-12 py-10'>
              {reservation.consultantId ?? '-'}
            </td>
          </tr>
        );
      })}
    </tbody>
  );
};
