import type {
  FetchPreviousPageOptions,
  InfiniteData,
  InfiniteQueryObserverResult,
} from '@tanstack/react-query';
import { debounce } from 'lodash-es';
import { type MutableRefObject, useEffect } from 'react';
import type { ChatFileAndContentIDs } from 'web/apis/swaggers/swagger-docs';

type UseTopScrollObserverProps = {
  hasPreviousPage: boolean;
  isFetchingPreviousPage: boolean;
  fetchPreviousPage: (
    options?: FetchPreviousPageOptions,
  ) => Promise<InfiniteQueryObserverResult<InfiniteData<ChatFileAndContentIDs[], unknown>, Error>>;
  isLoading: boolean;
  chatContainerRef: MutableRefObject<HTMLDivElement | null>;
  loadPreviuseMoreRef: MutableRefObject<HTMLDivElement | null>;
  setPreviousScrollHeight: React.Dispatch<React.SetStateAction<number>>;
};

//아래의 부분은 .withDebounce를 사용하기 위한 실험적 Function prototype 확장
declare global {
  interface Function {
    withDebounce(options: { wait: number }): Function;
    withSetTimeout(delay: number): void;
  }
}

const extendFunctionPrototypeForDebounce = () => {
  if (!Function.prototype.withDebounce) {
    Function.prototype.withDebounce = function ({ wait = 1000 }) {
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      return debounce((...args: any[]) => {
        return this.apply(this, args);
      }, wait);
    };
  }
  if (!Function.prototype.withSetTimeout) {
    Function.prototype.withSetTimeout = function (delay) {
      // biome-ignore lint/suspicious/noExplicitAny: <explanation>
      return setTimeout((...args: any[]) => {
        this.apply(this, args);
      }, delay);
    };
  }
};

extendFunctionPrototypeForDebounce();

export const useTopScrollObserver = ({
  isFetchingPreviousPage,
  fetchPreviousPage,
  isLoading,
  chatContainerRef,
  loadPreviuseMoreRef,
  setPreviousScrollHeight,
}: UseTopScrollObserverProps) => {
  useEffect(() => {
    const observer = new IntersectionObserver(
      async (entries) => {
        for (const entry of entries) {
          if (chatContainerRef.current && entry.isIntersecting) {
            if (entry.target === loadPreviuseMoreRef.current) {
              setPreviousScrollHeight(chatContainerRef.current.scrollHeight);

              await fetchPreviousPage.withDebounce({ wait: 1000 })();
            }
          }
        }
      },
      {
        root: chatContainerRef.current,
        rootMargin: '5px',
        threshold: 0.9,
      },
    );

    if (loadPreviuseMoreRef.current) {
      observer.observe(loadPreviuseMoreRef.current);
    }

    return () => {
      if (loadPreviuseMoreRef.current) {
        observer.unobserve(loadPreviuseMoreRef.current);
      }
    };
  }, [
    isLoading,
    fetchPreviousPage,
    chatContainerRef.current,
    loadPreviuseMoreRef.current,
    setPreviousScrollHeight,
  ]);
};
