import Divider from '@afterdoc-design-system/components/Atoms/Divider/Divider';
import DashedTag from '@afterdoc-design-system/components/Atoms/Tag/DashedTag';
import FilledTag from '@afterdoc-design-system/components/Atoms/Tag/FilledTag';
import Toggle from '@afterdoc-design-system/components/Atoms/Toggle/Toggle';
import Title from '@afterdoc-design-system/components/Molecules/Title/Title';
import { Color } from '@tailwind-base/styles/color';
import { useSetAtom } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import type { AlgorithmSummary, MatchedTreatmentTag } from 'web/apis/swaggers/swagger-docs';
import { OVERLAY_PAGE_ID } from 'web/shared/constants/overlay-page-id';
import { useOpenAutomationDialog } from 'web/shared/hooks/overlayPage/use-open-automation-dialog';
import RegisterCounselAutomationDialogContainer from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/RegisterCounselAutomationDialog.container';
import { COUNSEL_TAB_ITEMS } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/constants/counsel-tab-items';
import { messageModeState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-mode';
import { selectedCounselAutomationTabState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/states/selected-counsel-automation-tab-state';
import CardItemLayout from 'web/templates/Automation/containers/Main/shared/components/CardItemLayout';
import StatisticItem from 'web/templates/Automation/containers/Main/shared/components/StatisticItem';
import { useToggleAlgorithm } from 'web/templates/Automation/containers/Main/shared/hooks/use-toggle-algorithm';
import AutomationZoomInOutViewerContainer from 'web/templates/Automation/containers/Viewer/AutomationZoomInOutViewer/AutomationZoomInOutViewer.container';
import { useDeleteAlgorithm } from 'web/templates/Automation/containers/shared/hooks/use-delete-algorithm';
import './CounselCardItem.scss';

interface CounselCardItemProps {
  consultationAlgorithm: AlgorithmSummary;
}

export default function CounselCardItem({ consultationAlgorithm }: CounselCardItemProps) {
  const {
    _id: algorithmId,
    name,
    treatmentTags,
    isActive,
    targetCount,
    responseCount,
    readCount,
  } = consultationAlgorithm;

  const containerRef = useRef<HTMLDivElement>(null);
  const [visibleTags, setVisibleTags] = useState<
    { tag: MatchedTreatmentTag; maxTextLength: number }[]
  >([]);
  const [hiddenTagsCount, setHiddenTagsCount] = useState(0);

  const setSelectedAutomationTab = useSetAtom(selectedCounselAutomationTabState);
  const setMessageMode = useSetAtom(messageModeState);

  const openAutomationDialog = useOpenAutomationDialog();
  const { isChecked, onToggle } = useToggleAlgorithm({
    algorithmId,
    targetCount,
    initialState: isActive,
    type: 'CONSULTATION',
  });

  const onClickEditButton = () => {
    openAutomationDialog(
      <RegisterCounselAutomationDialogContainer />,
      OVERLAY_PAGE_ID['register-counsel-automation-dialog'],
    );

    setMessageMode({
      algorithmId,
      mode: 'EDIT',
    });
    setSelectedAutomationTab(COUNSEL_TAB_ITEMS[2].value);
  };

  const onClickCard = () => {
    openAutomationDialog(
      <AutomationZoomInOutViewerContainer
        algorithmId={algorithmId}
        onClickEditButton={onClickEditButton}
        type='CONSULTATION'
      />,
      OVERLAY_PAGE_ID['counsel-automation-zoom-in-out-viewer'],
    );
  };

  useEffect(() => {
    if (!containerRef.current || !treatmentTags) return;

    const containerWidth = containerRef.current.offsetWidth;
    let usedWidth = 0;
    const tagsToShow: { tag: MatchedTreatmentTag; maxTextLength: number }[] = [];
    let remainingCount = 0;

    const measureDiv = document.createElement('div');
    measureDiv.style.position = 'absolute';
    measureDiv.style.visibility = 'hidden';
    measureDiv.className = 'filled-tag-class';
    document.body.appendChild(measureDiv);

    for (let i = 0; i < treatmentTags.length; i++) {
      const tag = treatmentTags[i];
      measureDiv.innerText = tag.categoryName;
      let maxTextLength = tag.categoryName.length;

      let tagWidth = measureDiv.offsetWidth + 12;

      while (usedWidth + tagWidth > containerWidth && maxTextLength > 3) {
        maxTextLength--;
        measureDiv.innerText = tag.categoryName.slice(0, maxTextLength);
        tagWidth = measureDiv.offsetWidth + 12;
      }

      if (usedWidth + tagWidth <= containerWidth) {
        tagsToShow.push({ tag, maxTextLength });
        usedWidth += tagWidth;
      } else {
        remainingCount = treatmentTags.length - i;
        break;
      }
    }

    document.body.removeChild(measureDiv);

    setVisibleTags(tagsToShow);
    setHiddenTagsCount(remainingCount);
  }, [treatmentTags]);

  return (
    <CardItemLayout onClick={onClickCard} className='flex flex-col justify-between gap-y-16'>
      <div>
        <Title
          title={name}
          titleClassName='line-clamp-1'
          trailingChildren={
            <div onClick={(e) => e.stopPropagation()}>
              <Toggle checked={isChecked} defaultChecked={isChecked} onChange={onToggle} />
            </div>
          }
          wrapperClassName='p-0 items-center'
        />
        <div
          ref={containerRef}
          className='mt-10 flex h-30 items-center rounded-r6 bg-white200 px-10 py-6'>
          {visibleTags.length > 0 ? (
            <div className='flex items-center gap-4'>
              {visibleTags.map(({ tag, maxTextLength }) => (
                <FilledTag key={tag._id} bgColor={tag.color} maxTextLength={maxTextLength}>
                  {tag.categoryName}
                </FilledTag>
              ))}
              {hiddenTagsCount > 0 && (
                <DashedTag bgColor={Color.transparent} tagSize='small'>
                  {`${hiddenTagsCount}+`}
                </DashedTag>
              )}
            </div>
          ) : (
            <span className='text-Body12'>-</span>
          )}
        </div>
      </div>
      <div className='flex items-center'>
        <StatisticItem label='대상자' value={targetCount} unit='명' />
        <Divider type='line' direction='vertical' className='mx-10' />
        <StatisticItem label='조회수' value={readCount} unit='건' />
        <Divider type='line' direction='vertical' className='mx-10' />
        <StatisticItem label='응답수' value={responseCount} unit='건' />
      </div>
      <DeleteAndCopyButtons algorithmId={algorithmId} />
    </CardItemLayout>
  );
}

interface DeleteAndCopyButtonsProps {
  algorithmId: string;
}

const DeleteAndCopyButtons = ({ algorithmId }: DeleteAndCopyButtonsProps) => {
  const { handleDeleteAlgorithm } = useDeleteAlgorithm({
    type: 'CONSULTATION',
  });
  const openAutomationDialog = useOpenAutomationDialog();

  const setMessageMode = useSetAtom(messageModeState);
  const setSelectedAutomationTab = useSetAtom(selectedCounselAutomationTabState);

  const onClickCopyButton = () => {
    openAutomationDialog(
      <RegisterCounselAutomationDialogContainer />,
      OVERLAY_PAGE_ID['register-counsel-automation-dialog'],
    );

    setMessageMode({
      algorithmId,
      mode: 'COPY',
    });
    setSelectedAutomationTab(COUNSEL_TAB_ITEMS[0].value);
  };

  return (
    <div className='flex w-full gap-10' onClick={(e) => e.stopPropagation()}>
      <button
        className='h-22 w-full rounded-r6 border border-white700 bg-white50 text-Body11 text-white700'
        type='button'
        onClick={() => {
          handleDeleteAlgorithm(algorithmId);
        }}>
        삭제
      </button>
      <button
        className='h-22 w-full rounded-r6 border border-blue500 bg-white50 text-Body11 text-blue500'
        type='button'
        onClick={onClickCopyButton}>
        복제
      </button>
    </div>
  );
};
