import TextInput from '@afterdoc-design-system/components/Atoms/Input/TextInput';
import { useAtomValue } from 'jotai';
import { useFormContext } from 'react-hook-form';
import type { CounselAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/components/RegisterCounselAutomationDialogContent';
import { selectedMessageIndexState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-form';

export default function MessageNameForm() {
  const { setValue, watch } = useFormContext<CounselAutomationAPIFormValues>();

  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);

  return (
    <TextInput
      onChange={(e) => {
        setValue(`messages.${selectedMessageIndex}.name`, e.target.value);
      }}
      value={watch(`messages.${selectedMessageIndex}.name`) || ''}
      label={{
        isRequired: true,
        children: '메시지명',
      }}
    />
  );
}
