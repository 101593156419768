import Toggle from '@afterdoc-design-system/components/Atoms/Toggle/Toggle';
import { useEffect, useState } from 'react';
import type { TreatmentTagForFolder } from 'web/apis/swaggers/swagger-docs';

interface TreatmentTagRightToggleProps {
  tag: TreatmentTagForFolder;
  isActive: TreatmentTagForFolder['isActive'];
  isFolderActive?: boolean;
  onClickUpdateTreatmentTagActivation: (isActive: boolean, tag: TreatmentTagForFolder) => void;
}

export default function TreatmentTagRightToggle({
  tag,
  isActive,
  isFolderActive,
  onClickUpdateTreatmentTagActivation,
}: TreatmentTagRightToggleProps) {
  const [isActivated, setIsActivated] = useState(isActive);

  useEffect(() => {
    setIsActivated(isActive);
  }, [isActive]);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Toggle
        toggleSize='small'
        checked={isActivated}
        disabled={!isFolderActive}
        onChange={(e) => {
          const newIsActive = e.target.checked;
          onClickUpdateTreatmentTagActivation(newIsActive, tag);
        }}
      />
    </div>
  );
}
