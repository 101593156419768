import OutlinedButton from '@afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import ParentDropdown from '@templates/CustomerChat/components/ParentDropDown/ParentDropdown';
import { getRedTextIndices } from '@templates/CustomerChat/components/ParentDropDown/functions/get-red-text-indeices';
import { useDropdown } from '@templates/CustomerChat/components/ParentDropDown/hooks/use-dropdown';
import { useDropdownData } from '@templates/CustomerChat/components/ParentDropDown/hooks/use-dropdown-data';
import { usePatient } from '../../../../hooks/use-patient';
import { makePatientInfoStringDisplay } from './functions/makePatientInfoStringDisplay';

export default function PatientTitle() {
  const { isFirstVisit, name, chartNumber, isJoinedUserToAfterDoc, hasKakaoLinkage } = usePatient();
  const { data, isAuthoriztionControlPatientChatting } = useDropdownData();
  const { isDropdownOpen, handleDropdown } = useDropdown();

  const redTextIndices = getRedTextIndices(data, isAuthoriztionControlPatientChatting ?? false);

  const chartNumberDisplay = makePatientInfoStringDisplay({
    type: 'chartNumber',
    payload: {
      chartNumber,
    },
  });
  const isFirstVisitDisplay = makePatientInfoStringDisplay({
    type: 'isFirstVisit',
    payload: {
      isFirstVisit,
    },
  });

  return (
    <div className='mt-5 flex justify-between'>
      <div className='ml-12 flex flex-row-center gap-10'>
        {isFirstVisit !== null ? (
          <div className='h-20 w-28 rounded-[5px] bg-blueLight pt-1 text-center text-Header12 text-blue500'>
            {isFirstVisitDisplay}
          </div>
        ) : null}
        <div className='flex flex-center gap-4'>
          <div
            className='text-Header16 text-black700 hover:cursor-pointer'
            onClick={handleDropdown}>
            {name}
            {isDropdownOpen && (
              <ParentDropdown
                options={data}
                redTextIndices={redTextIndices}
                handleToggle={handleDropdown}
              />
            )}
          </div>
          <div className='text-Body10'>{chartNumberDisplay}</div>
        </div>

        <div className='flex flex-center gap-2'>
          {isJoinedUserToAfterDoc && <Icon name='afterdoc' />}
          {hasKakaoLinkage && <Icon name='kakaotalk' />}
        </div>
      </div>
      <div>{!isJoinedUserToAfterDoc && <OutlinedButton>고객등록</OutlinedButton>}</div>
    </div>
  );
}
