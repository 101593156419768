import { useAtomValue, useSetAtom } from 'jotai';
import { useEffect } from 'react';
import useDownloader from 'react-use-downloader';
import type { File } from 'web/apis/swaggers/swagger-docs';
import { bytesToMB } from 'web/shared/utils/bytesToMB';
import {
  chatFileDownloadHistoryAtom,
  chatFileProgressAtom,
  deleteFileProgressAtom,
  setFileProgressAtom,
} from '../../../states/chatFileProgress';
import { useChatMessageContext } from '../ChatMessageContext';
import { MESSAGE_TYPE_CODE } from '../constants/message';
import { RenderingDownloadIcon } from './components/RenderingDownloadIcon';

export default function FileDownloadContainer({ fileID }: { fileID: File }) {
  if (!fileID.aws) return null;
  const { type } = useChatMessageContext();
  const isMockingMessage = type === MESSAGE_TYPE_CODE.FILE_MOCKING;

  const { download, percentage, isInProgress, error, cancel } = useDownloader();

  const chatFileDownloadHistory = useAtomValue(chatFileDownloadHistoryAtom);
  const chatFileProgress = useAtomValue(chatFileProgressAtom);
  const updateFileProgress = useSetAtom(setFileProgressAtom);
  const deleteFileProgress = useSetAtom(deleteFileProgressAtom);

  const { fileName, Location } = fileID.aws[0];

  const fileSize = fileID.aws[0]?.fileSize;
  const fileSizeMB = bytesToMB(fileSize);
  const fileProgress = chatFileProgress.get(fileName);
  const isComplete = chatFileDownloadHistory?.get(fileName)?.isComplete ?? false;
  const triggerCancel = fileProgress?.triggerCancel ?? false;
  const triggerReload = fileProgress?.triggerReload ?? false;

  useEffect(() => {
    if (isInProgress) {
      updateFileProgress({ fileName, progress: percentage, error });
    }
  }, [isInProgress, fileName, percentage, error]);

  useEffect(() => {
    if (triggerCancel) {
      cancel();
      deleteFileProgress(fileName);
    }
  }, [triggerCancel, deleteFileProgress]);

  //Only Reload download
  useEffect(() => {
    if (triggerReload) {
      download(Location, fileName);
    }
  }, [triggerReload]);

  return (
    <div className='line-clamp-2 flex-center justify-between'>
      <div className='flex-center'>
        <div
          className='mr-10 h-36 w-36 cursor-pointer rounded-r10 bg-white100 p-6'
          onClick={() => !isMockingMessage && download(Location, fileName)}>
          <RenderingDownloadIcon isComplete={isComplete} percentage={percentage} />
        </div>
        <div className='text-Body13'>{fileName}</div>
      </div>
      <div className='text-Body12 text-black200'>{fileSizeMB}MB</div>
    </div>
  );
}
