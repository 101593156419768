import LabelText from '@afterdoc-design-system/components/Atoms/LabelText/LabelText';
import TextFieldSelectBoxDropdown from '@afterdoc-design-system/components/Molecules/TextSelect/TextFieldSelectBoxDropdown';
import dayjs from 'dayjs';
import { useAtomValue, useSetAtom } from 'jotai';
import { useCallback, useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import type { MarketingAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/components/RegisterMarketingAutomationDialogContent';
import {
  selectedContentTabIndexState,
  selectedMessageIndexState,
  selectedMessageState,
} from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/message-form';
import { generateTimeIntervalArray } from 'web/templates/Automation/containers/shared/functions/generate-time-interval-array';
import DaySelector from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/AfterDoc/ReservationHistory/containers/components/DaySelector';

export default function SendDateTimeForm() {
  const { watch, setValue, getValues } = useFormContext<MarketingAutomationAPIFormValues>();

  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);
  const setSelectedMessage = useSetAtom(selectedMessageState);
  const setSelectedContentTabIndex = useSetAtom(selectedContentTabIndexState);

  const startDay = watch('sendingSchedule.startDay');
  const sendingDateTime = watch(`messages.${selectedMessageIndex}.sendingDateTime`);
  const disabledBeforeDate = dayjs().toDate();

  useEffect(() => {
    if (sendingDateTime && dayjs(startDay).isAfter(dayjs(sendingDateTime))) {
      setValue('sendingSchedule.startDay', sendingDateTime);
    }
  }, [sendingDateTime, setValue, startDay]);

  useEffect(() => {
    if (!sendingDateTime) return;

    const messages = getValues('messages');

    const originalMessage = messages[selectedMessageIndex];
    const sortedMessages = messages.sort((a, b) => {
      if (!a.sendingDateTime || !b.sendingDateTime) return 0;
      return new Date(a.sendingDateTime).getTime() - new Date(b.sendingDateTime).getTime();
    });

    const newSelectedIndex = sortedMessages.findIndex(
      (message) => message.sendingDateTime === originalMessage.sendingDateTime,
    );
    setValue('messages', sortedMessages);
    if (newSelectedIndex !== -1) {
      setSelectedMessage({
        index: newSelectedIndex,
        ...sortedMessages[newSelectedIndex],
      });
      setSelectedContentTabIndex((prev) => ({
        ...prev,
        [newSelectedIndex]: prev[selectedMessageIndex],
      }));
    }
  }, [sendingDateTime, getValues, setValue]);

  return (
    <div className='mt-20'>
      <LabelText width='fit-content' isRequired>
        발송시점
      </LabelText>
      <div className='mt-10 flex items-center gap-8'>
        <DaySelector
          onSelect={(date) => {
            const hour = dayjs(sendingDateTime).hour();
            const minute = dayjs(sendingDateTime).minute();

            setValue(
              `messages.${selectedMessageIndex}.sendingDateTime`,
              dayjs(date).set('hour', hour).set('minute', minute).toISOString(),
            );

            if (dayjs(startDay).isBefore(dayjs(sendingDateTime))) {
              setValue('sendingSchedule.startDay', date.toISOString());
            }
          }}
          selectedDate={dayjs(sendingDateTime).toDate()}
          hasDayChangeButton={false}
          width={124}
          disableBeforeDate={disabledBeforeDate}
        />
        <TimeOfDayDropdown />
        <span className='text-Body13 text-black500'>에 발송합니다.</span>
      </div>
    </div>
  );
}

interface CustomDropdownProps {
  options: string[];
  width: number;
}

const CustomDropdown = ({ options, width }: CustomDropdownProps) => {
  const { setValue, getValues } = useFormContext<MarketingAutomationAPIFormValues>();
  const selectedMessage = useAtomValue(selectedMessageState);
  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);

  const messageLists = getValues('messages');
  const sendingDateTime = getValues(`messages.${selectedMessageIndex}.sendingDateTime`);

  const customScrollHandler = useCallback((index: number) => index - 2, []);

  const onSelect = useCallback(
    (index: number) => {
      if (messageLists && selectedMessage) {
        const hour = Number(options[index].split(':')[0]);
        const minute = Number(options[index].split(':')[1]);

        setValue(
          `messages.${selectedMessage.index}.sendingDateTime`,
          dayjs(sendingDateTime).set('hour', hour).set('minute', minute).toISOString(),
        );
      }
    },
    [messageLists, options, selectedMessage, setValue, sendingDateTime],
  );

  const getIndex = useCallback(() => {
    if (!selectedMessage) return 0;
    const value = messageLists[selectedMessage.index]?.sendingDateTime;
    const HHmm = dayjs(value).format('HH:mm');
    return options.indexOf(HHmm) !== -1 ? options.indexOf(HHmm) : 0;
  }, [messageLists, options, selectedMessage]);

  if (!messageLists.length) return null;

  return (
    <TextFieldSelectBoxDropdown
      options={options}
      onSelect={onSelect}
      selectedIndex={getIndex()}
      focusedIndex={getIndex()}
      width={width}
      customFocusScrollHandler={customScrollHandler}
      customSelectedScrollHandler={customScrollHandler}
    />
  );
};

const TimeOfDayDropdown = () => {
  const options = useRef(generateTimeIntervalArray({ startMinute: 0 }));
  return <CustomDropdown options={options.current} width={100} />;
};
