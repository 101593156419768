import { useAtom, useAtomValue } from 'jotai';
import { useEffect, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import type { CounselAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/components/RegisterCounselAutomationDialogContent';
import { actualTextLengthState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/states/actual-text-length';

import SendContentFormHeader from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/FormLayout/SendContentFormHeader';
import SendContentFormTab from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/FormLayout/SendContentFormTab';
import EventContentForm from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/EventOrHomecareContentForm/EventOrHomecareContentForm';
import ImageContentForm from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/ImageContentForm';
import SurveyContentForm from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/SurveyContentForm';
import TextContentForm from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/TextContentForm';
import VideoContentForm from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/VideoContentForm';
import WorkoutVideoContentForm from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/WorkoutVideoContentForm/WorkoutVideoContentForm';
import {
  COUNSEL_REPLACEABLE_TEXTS,
  MAX_LENGTH,
} from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/constants/replaceable-texts';
import { useKakaoCheckWhetherEditOrNot } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/hooks/use-kakao-check-whether-edit-or-not';
import {
  selectedContentTabIndexState,
  selectedMessageIndexState,
} from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-form';
import { messageModeState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-mode';
import { originMessagesState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/origin-message';
import ReplaceableTextLists from 'web/templates/Automation/containers/Dialog/shared/components/ReplaceableTextLists/ReplaceableTextLists';

export default function SendContentForm() {
  const isInitializing = useRef(false);
  const textRef = useRef<HTMLTextAreaElement>(null);

  const { watch, reset } = useFormContext<CounselAutomationAPIFormValues>();

  const originMessages = useAtomValue(originMessagesState);
  const messageMode = useAtomValue(messageModeState);
  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);
  const selectedContentTabIndex = useAtomValue(selectedContentTabIndexState);

  const hasApprovedAlimtalkTemplate =
    originMessages[selectedMessageIndex]?.hasApprovedAlimtalkTemplate ?? false;
  const sendingType = watch(`messages.${selectedMessageIndex}.sendingType`);

  const { handleFormClick } = useKakaoCheckWhetherEditOrNot({
    reset,
    hasApprovedAlimtalkTemplate,
  });

  useEffect(() => {
    isInitializing.current = true;

    if (messageMode.mode !== 'EDIT' || !isInitializing.current || !hasApprovedAlimtalkTemplate)
      return;

    const handleGlobalClick = (e: MouseEvent) => {
      handleFormClick();

      e.stopPropagation();
      e.preventDefault();

      document.removeEventListener('click', handleGlobalClick, true);
    };

    document.addEventListener('click', handleGlobalClick, true);

    return () => {
      document.removeEventListener('click', handleGlobalClick, true);
      isInitializing.current = false;
    };
  }, [hasApprovedAlimtalkTemplate, handleFormClick, messageMode.mode]);

  if (sendingType === 'SURVEY') {
    return (
      <>
        {/* 내용 헤더 */}
        <SendContentFormHeader />

        {/* 전송내용 - 설문 */}
        <SurveyContentForm />
      </>
    );
  }

  return (
    <>
      {/* 내용 헤더 */}
      <SendContentFormHeader />

      {/* 내용 탭 (텍스트, 이미지, 동영상, 홈케어콘텐츠, 이벤트) */}
      <SendContentFormTab />

      {/* 전송내용 - 텍스트 */}
      {selectedContentTabIndex[selectedMessageIndex] === 0 && <TextContentForm ref={textRef} />}

      {/* 전송내용 - 이미지 */}
      {selectedContentTabIndex[selectedMessageIndex] === 1 && <ImageContentForm ref={textRef} />}

      {/* 전송내용 - 동영상 */}
      {selectedContentTabIndex[selectedMessageIndex] === 2 && <VideoContentForm ref={textRef} />}

      {/* 전송내용 - 홈케어콘텐츠 */}
      {selectedContentTabIndex[selectedMessageIndex] === 3 && (
        <WorkoutVideoContentForm ref={textRef} />
      )}

      {/* 전송내용 - 이벤트 */}
      {selectedContentTabIndex[selectedMessageIndex] === 4 && <EventContentForm ref={textRef} />}

      {/* 치환 문자 */}
      <SendContentFormReplaceableTextLists textRef={textRef} />
    </>
  );
}

interface SendContentFormReplaceableTextListsProps {
  textRef: React.RefObject<HTMLTextAreaElement>;
}

const SendContentFormReplaceableTextLists = ({
  textRef,
}: SendContentFormReplaceableTextListsProps) => {
  const { getValues, setValue } = useFormContext<CounselAutomationAPIFormValues>();

  const [actualTextLength, setActualTextLength] = useAtom(actualTextLengthState);
  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);

  const handleInsertReplaceText = (text: string, length: number) => {
    if (actualTextLength + length < MAX_LENGTH) {
      setValue(
        `messages.${selectedMessageIndex}.content.text`,
        (getValues(`messages.${selectedMessageIndex}.content.text`) ?? '') + text,
      );

      setActualTextLength((prev) => prev + length);
      textRef.current?.focus();
    }
  };

  return (
    <ReplaceableTextLists
      replaceableTexts={COUNSEL_REPLACEABLE_TEXTS}
      handleInsertReplaceText={handleInsertReplaceText}
    />
  );
};
