import { modalService } from '@afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { overlayPageService } from '@afterdoc-design-system/components/Molecules/OverlayPage/OverlayPage.service';
import { useCallback, useEffect, useState } from 'react';
import type { UseFormReset } from 'react-hook-form';
import type { AlgorithmWithMessagesInput } from 'web/apis/swaggers/swagger-docs';
import { OVERLAY_PAGE_ID } from 'web/shared/constants/overlay-page-id';
import { useResetAll } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/hooks/use-reset-all';

interface UseKakaoCheckWhetherEditOrNotProps {
  reset: UseFormReset<AlgorithmWithMessagesInput>;
  hasApprovedAlimtalkTemplate?: boolean;
}

export const useKakaoCheckWhetherEditOrNot = ({
  reset,
  hasApprovedAlimtalkTemplate = false,
}: UseKakaoCheckWhetherEditOrNotProps) => {
  const { resetAll } = useResetAll({
    reset,
  });

  const [isAbleEditEvenIfKakaoLinkage, setIsAbleEditEvenIfKakaoLinkage] = useState(false);

  const handleFormClick = useCallback(() => {
    if (isAbleEditEvenIfKakaoLinkage) {
      modalService.defaultWarning({
        title: '카카오 알림톡 승인된 메시지입니다.',
        contents:
          '내용을 수정하실 경우 알림톡 승인이 거부될 수 있습니다.\n승인이 완료되기 전까지 애프터닥 앱에 가입한 사람들에게만 수정된 메시지가 전달됩니다.\n내용을 계속 수정하시려면 ‘네’ 버튼을 클릭해주세요.',
        onConfirm: () => {
          modalService.pop();
          setIsAbleEditEvenIfKakaoLinkage(false);
        },
        onCancel: () => {
          modalService.pop();
          overlayPageService.popById(OVERLAY_PAGE_ID['register-counsel-automation-dialog']);
          resetAll();
        },
      });
    }
  }, [isAbleEditEvenIfKakaoLinkage, resetAll]);

  useEffect(() => {
    setIsAbleEditEvenIfKakaoLinkage(hasApprovedAlimtalkTemplate);
  }, [hasApprovedAlimtalkTemplate]);

  return {
    handleFormClick,
  };
};
