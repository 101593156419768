import { Color } from '@tailwind-base/styles/color';

const generateClassName = (width: number, additionalClasses?: string) => {
  return `w-[${String(width)}px] px-12 py-10 ${additionalClasses ? additionalClasses : ''}`;
};

export default function AccountListTableBodyAccountListTableHead() {
  return (
    <>
      <thead className='text-left text-black700'>
        <tr
          className='border-b text-Header14'
          style={{
            backgroundColor: Color.table,
            borderBottomColor: Color['line-table'],
          }}>
          <th className={generateClassName(145)}>직책/직급</th>
          <th className={generateClassName(145)}>이름</th>
          <th className={generateClassName(134)}>아이디</th>
          <th className={generateClassName(150)}>권한</th>
          <th className={generateClassName(100)}>기본 상담사</th>
          <th className={generateClassName(132)}>수정/삭제</th>
        </tr>
      </thead>
    </>
  );
}
