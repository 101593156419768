import TextButton from '@afterdoc-design-system/components/Atoms/Button/TextButton';
import TextArea from '@afterdoc-design-system/components/Atoms/Input/TextArea/TextArea';
import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import { dialogService } from '@afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import dayjs from 'dayjs';
import { Suspense } from 'react';
import { usePatientDetailInfo } from 'web/shared/hooks/use-get-patient-detail-info';
import ManagementDirectionDialogContainer from './ManagementDirectionDialog.container';

export default function ManagementDirection() {
  const { managementDirection } = usePatientDetailInfo();

  const bottomText =
    managementDirection && dayjs(managementDirection.finalEditedAt).isValid()
      ? `${dayjs(managementDirection.finalEditedAt).format('YYYY-MM-DD HH:mm')}/${managementDirection.finalEditorName}`
      : undefined;

  return (
    <div className='flex h-full w-full flex-col gap-4'>
      <div className='flex w-full items-start justify-between'>
        <div className='flex flex-col gap-4'>
          <div className='text-Header14 text-black700'>관리방향</div>
          <div className='mb-16 text-Body12 text-black500'>
            원장님의 관리방향(지시사항)을 입력할 수 있습니다.
          </div>
        </div>
        <TextButton
          onClick={() => {
            dialogService.push(
              <Suspense>
                <ManagementDirectionDialogContainer />
              </Suspense>,
              {
                wrapperClassName: 'h-[800px] mt-0 mb-0',
                width: '1000px',
                id: 'management-direction-dialog',
                titleProps: {
                  title: '관리방향',
                },
                hasCloseButton: true,
                onClose: () => {
                  dialogService.popById('management-direction-dialog');
                },
              },
            );
          }}
          iconProps={{
            name: 'arrow-right',
            size: 16,
            color: 'black200',
          }}
          className='whitespace-nowrap'>
          전체보기
        </TextButton>
      </div>
      <TextArea
        value={managementDirection?.content}
        placeholder='고객 상담 시, 참고 사항을 기록해둘 수 있습니다.'
        leftBottomText={bottomText}
        disabled={true}
        showRightBottomButton={false}
        additionalRightBottomButton={
          managementDirection?.images && managementDirection.images.length > 0 ? (
            <div className='flex flex-row items-center gap-6'>
              <Icon name='image-filter' size={16} color='black200' />
              <div className='text-Body10Bold text-black200'>이미지 첨부됨</div>
            </div>
          ) : undefined
        }
      />
    </div>
  );
}
