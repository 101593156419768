import { overlayPageService } from '@afterdoc-design-system/components/Molecules/OverlayPage/OverlayPage.service';
import { SHARED_UTILS } from '@shared-utils/utils';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type {
  ApiAutomationsElCommonAlgorithmParams,
  CommonAlgorithmInput,
} from 'web/apis/swaggers/swagger-docs';
import { useNavigationBlocker } from 'web/shared/hooks/use-navigation-blocker';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import EditCommonAutomationDialogContent from 'web/templates/Automation/containers/Dialog/EditCommonAutomationDialog/components/EditCommonAutomationDialogContent/EditCommonAutomationDialogContent';
import EditCommonAutomationDialogHeader from 'web/templates/Automation/containers/Dialog/EditCommonAutomationDialog/components/EditCommonAutomationDialogHeader';

const fetchCommonAutomationInfo = async (params: ApiAutomationsElCommonAlgorithmParams) => {
  const response = await apiClient.v3.apiAutomationsElCommonAlgorithm(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export default function EditCommonAutomationDialogContainer() {
  const { hospitalID } = useSelectedHospitalInfo();

  const { data } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiAutomationsElCommonAlgorithm, { hospitalID }] as const,
    queryFn: ({ queryKey }) => fetchCommonAutomationInfo(queryKey[1]),
  });

  const memoizedDefaultValues = useMemo(() => {
    return {
      hospitalID,
      content: data.content,
      isActive: data.isActive,
      sendTime: data.sendTime,
    };
  }, [data.content, data.isActive, data.sendTime, hospitalID]);

  const methods = useForm<CommonAlgorithmInput>({
    mode: 'onChange',
    defaultValues: memoizedDefaultValues,
  });

  useNavigationBlocker({
    shouldBlock: methods.formState.isDirty,
    onConfirm: () => {
      methods.reset();
      overlayPageService.popById('edit-common-algorithm-dialog');
    },
  });

  return (
    <FormProvider {...methods}>
      <div className='relative h-screen w-full'>
        <div className='mx-auto w-[1166px] bg-white50'>
          <EditCommonAutomationDialogHeader />
          <EditCommonAutomationDialogContent consultationAlgorithms={data.consultationAlgorithms} />
        </div>
      </div>
    </FormProvider>
  );
}
