import type { TempChatRoomHServiceLastMessageUser } from '../hooks/use-chattinglist';

export const filteredUnReadChattings = (
  chatRoomHServiceLastMessageUsers: TempChatRoomHServiceLastMessageUser[],
  loginUserId: string,
) => {
  const filteredChats = chatRoomHServiceLastMessageUsers.filter(({ info }) => {
    const myUnReadTarget = info.find(({ userID }) => userID === loginUserId) ?? { count: 0 };
    const { count: unreadCount } = myUnReadTarget;
    return unreadCount > 0;
  });

  return filteredChats;
};
