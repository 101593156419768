import LabelText from '@afterdoc-design-system/components/Atoms/LabelText/LabelText';
import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';

export const CustomerReservationEmpty = () => {
  return (
    <div className='flex h-full w-full flex-col items-center justify-center'>
      <div className='mb-4'>
        <Icon name='calendar-blank-outline' color='white600' size={48} />
      </div>
      <div>
        <LabelText textClassName='text-Header14'>예약 정보가 없습니다.</LabelText>
      </div>
    </div>
  );
};
