import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useFormContext } from 'react-hook-form';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import type { CounselAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/components/RegisterCounselAutomationDialogContent';
import { actualTextLengthState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/components/SendContentForm/states/actual-text-length';
import { COUNSEL_MESSAGE_SETTING_TAB_ITEMS } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/containers/MessageContentForm/constants/tab';
import {
  selectedContentTabIndexState,
  selectedMessageIndexState,
} from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-form';

export default function SendContentFormTab() {
  const { setValue } = useFormContext<CounselAutomationAPIFormValues>();

  const [selectedContentTabIndex, setSelectedContentTabIndex] = useAtom(
    selectedContentTabIndexState,
  );
  const setActualTextLength = useSetAtom(actualTextLengthState);
  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);

  const { usingVideoContent, usingHospitalEvent } = useSelectedHospitalInfo();

  const handleChangeTabIndex = (index: number) => {
    const prevTabIndex = selectedContentTabIndex[selectedMessageIndex];

    if (prevTabIndex === index) {
      return;
    }

    // 이미지에서 다른 탭으로 이동할 때, 이미지 내용 초기화
    if (prevTabIndex === 1) {
      setValue(`messages.${selectedMessageIndex}.content.image`, undefined);
    }

    // 동영상에서 다른 탭으로 이동할 때, 동영상 내용 초기화
    if (prevTabIndex === 2) {
      setValue(`messages.${selectedMessageIndex}.content.video`, undefined);
    }

    // 홈케어콘텐츠에서 다른 탭으로 이동할 때, 홈케어콘텐츠 내용 초기화
    if (prevTabIndex === 3) {
      setValue(`messages.${selectedMessageIndex}.content.workoutVideo`, undefined);
    }

    // 이벤트에서 다른 탭으로 이동할 때, 이벤트 및 홈케어 내용 초기화
    if (prevTabIndex === 4) {
      setValue(`messages.${selectedMessageIndex}.content.event`, undefined);
      setValue(`messages.${selectedMessageIndex}.content.homecareContent`, undefined);
    }

    setActualTextLength(0);
    setValue(`messages.${selectedMessageIndex}.content.text`, undefined);
    setSelectedContentTabIndex({
      ...selectedContentTabIndex,
      [selectedMessageIndex]: index,
    });
  };

  return (
    <div className='mt-10 flex'>
      {COUNSEL_MESSAGE_SETTING_TAB_ITEMS.filter((item) => {
        if (item === '홈케어콘텐츠') {
          return usingVideoContent;
        }

        if (item === '이벤트') {
          return usingHospitalEvent;
        }

        return true;
      }).map((item, index) => (
        <div key={item}>
          <button
            type='button'
            onClick={() => {
              handleChangeTabIndex(index);
            }}
            className={customTwMerge(
              'py-8 pr-16 pl-20 text-Header12',
              selectedContentTabIndex[selectedMessageIndex] === index
                ? 'text-black700'
                : 'text-black200',
            )}>
            {item}
          </button>
        </div>
      ))}
    </div>
  );
}
