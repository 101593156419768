import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';

interface CustomToolbarProps {
  disabled?: boolean;
}

export default function CustomToolbar({ disabled }: CustomToolbarProps) {
  return (
    <div id='toolbar'>
      <span className='ql-formats'>
        <button type='button' className='ql-undo' disabled={disabled}>
          <Icon name='undo' size={20} color='white800' />
        </button>
        <button type='button' className='ql-redo' disabled={disabled}>
          <Icon name='redo' size={20} color='white800' />
        </button>
        <button type='button' className='ql-bold' disabled={disabled} />
        <button type='button' className='ql-italic' disabled={disabled} />
        <button type='button' className='ql-underline' disabled={disabled} />
        <button type='button' className='ql-strike' disabled={disabled} />
        <button type='button' className='ql-list' value='ordered' disabled={disabled} />
        <button type='button' className='ql-list' value='bullet' disabled={disabled} />
        <button type='button' className='ql-image' disabled={disabled} />
        <button type='button' className='ql-header' value='1' disabled={disabled} />
        <button type='button' className='ql-header' value='2' disabled={disabled} />
        <button type='button' className='ql-header' value='3' disabled={disabled} />
        <button type='button' className='ql-blockquote' disabled={disabled} />
        <button type='button' className='ql-horizontal' disabled={disabled}>
          <Icon name='horizontal' size={20} color='white800' />
        </button>
      </span>
    </div>
  );
}
