import { apiClient } from 'web/apis/instances/api-client';

export const postChatroomCounselerAdd = async ({
  chatRoomID,
  selectedUserIDs,
}: { chatRoomID: string; selectedUserIDs: string[] }) => {
  const params = {
    chatRoomID,
    counselors: selectedUserIDs,
  };
  const { data } = await apiClient.v3.apiChatroomElCounselorAdd(params);
  return data;
};
