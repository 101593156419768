import { useAtom, useAtomValue } from 'jotai';
import { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import type { MarketingAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/components/RegisterMarketingAutomationDialogContent';
import SendContentFormHeader from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/containers/MessageContentForm/components/SendContentForm/components/FormLayout/SendContentFormHeader';
import SendContentFormTab from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/containers/MessageContentForm/components/SendContentForm/components/FormLayout/SendContentFormTab';
import ImageContentForm from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/ImageContentForm';
import TextContentForm from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/containers/MessageContentForm/components/SendContentForm/components/Forms/TextContentForm';
import {
  MARKETING_REPLACEABLE_TEXTS,
  MAX_LENGTH,
} from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/containers/MessageContentForm/components/SendContentForm/constants/replaceable-texts';
import { actualTextLengthState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/containers/MessageContentForm/components/SendContentForm/states/actual-text-length';
import {
  selectedContentTabIndexState,
  selectedMessageIndexState,
} from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/message-form';
import ReplaceableTextLists from 'web/templates/Automation/containers/Dialog/shared/components/ReplaceableTextLists/ReplaceableTextLists';

export default function SendContentForm() {
  const textRef = useRef<HTMLTextAreaElement>(null);

  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);
  const selectedContentTabIndex = useAtomValue(selectedContentTabIndexState);

  return (
    <>
      {/* 내용 헤더 */}
      <SendContentFormHeader />

      {/* 내용 탭 (텍스트, 이미지) */}
      <SendContentFormTab />

      {/* 전송내용 - 텍스트 */}
      {selectedContentTabIndex[selectedMessageIndex] === 0 && <TextContentForm ref={textRef} />}

      {/* 전송내용 - 이미지 */}
      {selectedContentTabIndex[selectedMessageIndex] === 1 && <ImageContentForm ref={textRef} />}

      {/* 치환 문자 */}
      <SendContentFormReplaceableTextLists textRef={textRef} />
    </>
  );
}

interface SendContentFormReplaceableTextListsProps {
  textRef: React.RefObject<HTMLTextAreaElement>;
}

const SendContentFormReplaceableTextLists = ({
  textRef,
}: SendContentFormReplaceableTextListsProps) => {
  const { getValues, setValue } = useFormContext<MarketingAutomationAPIFormValues>();

  const [actualTextLength, setActualTextLength] = useAtom(actualTextLengthState);
  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);

  const handleInsertReplaceText = (text: string, length: number) => {
    if (actualTextLength + length < MAX_LENGTH) {
      setValue(
        `messages.${selectedMessageIndex}.content.text`,
        (getValues(`messages.${selectedMessageIndex}.content.text`) ?? '') + text,
      );

      setActualTextLength((prev) => prev + length);
      textRef.current?.focus();
    }
  };

  return (
    <ReplaceableTextLists
      replaceableTexts={MARKETING_REPLACEABLE_TEXTS}
      handleInsertReplaceText={handleInsertReplaceText}
    />
  );
};
