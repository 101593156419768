import HoverTooltip from '@afterdoc-design-system/components/Atoms/Tooltip/HoverTooltip';
import { Color } from '@tailwind-base/styles/color';
import { memo, useMemo } from 'react';
import { getRandomColor } from 'web/shared/utils/get-random-color';
import { useChatRoomInfoOne } from 'web/templates/CustomerChat/components/ChattingRoom/hooks/use-chatroom-info-one';
import { USER_TYPE_CODE } from '../ChattingContent/components/ChattingMessage/constants/message';
import { makingTooltipNames } from './functions/making-tooltip-names';

type ColorLiteral = (typeof Color)[keyof typeof Color];

interface TooltipContainerProps {
  text: string;
  children: React.ReactNode;
}

function TooltipContainer({ text = '', children }: TooltipContainerProps) {
  return (
    <div
      className='absolute top-201 right-21'
      // style={{ zIndex: Z_INDEXS.ChattingTooltip }}
    >
      <HoverTooltip
        show={true}
        text={text}
        position='bottomLeft'
        wrapperProps={{
          className: 'cursor-default',
        }}>
        {children}
      </HoverTooltip>
    </div>
  );
}

const ChattingParticipant = memo(
  ({ text, bgColor }: { text: string; bgColor?: ColorLiteral }) => {
    return (
      <div
        className='h-24 w-24 rounded-r6 border border-white50 text-center text-Header14 text-white50'
        style={{ backgroundColor: bgColor || getRandomColor() }}>
        {text}
      </div>
    );
  },
  (prevProps, nextProps) => prevProps.text === nextProps.text,
);

export default function ChattingParticipants() {
  const { participants } = useChatRoomInfoOne();
  const participantsWithoutPatient = participants.filter(
    (participant) => Number(participant.type) !== USER_TYPE_CODE.PATIENT,
  );
  const participantsWithRealName = participantsWithoutPatient.map((participant) => ({
    ...participant,
    realName: participant?.realName || participant?.name,
  }));
  const paricipantsLength = participants.length;
  const tooltipNames = useMemo(() => {
    return makingTooltipNames(participantsWithRealName);
  }, [participants]);

  switch (paricipantsLength) {
    case 0:
      return null;
    case 1:
      return (
        <TooltipContainer text={tooltipNames}>
          <ChattingParticipant text={participantsWithRealName[0]?.realName[0]} />
        </TooltipContainer>
      );
    case 2:
      return (
        <TooltipContainer text={tooltipNames}>
          <div className='flex'>
            <div className='absolute right-20'>
              <ChattingParticipant text={participantsWithRealName[0]?.realName[0]} />
            </div>
            <div>
              <ChattingParticipant text={participantsWithRealName[1]?.realName[0]} />
            </div>
          </div>
        </TooltipContainer>
      );
    default: // 3명 이상
      return (
        <TooltipContainer text={tooltipNames}>
          <div className='flex'>
            <div className='absolute right-50'>
              <ChattingParticipant text={participantsWithRealName[0]?.realName[0]} />
            </div>
            <div className='absolute right-30'>
              <ChattingParticipant text={participantsWithRealName[1]?.realName[0]} />
            </div>
            <div>
              <ChattingParticipant
                text={`+${participantsWithRealName.length - 2}`}
                bgColor={Color.black700}
              />
            </div>
          </div>
        </TooltipContainer>
      );
  }
}
