import UserInfoUpdateDialog from '@templates/UserInfoSetting/containers/AccountSetting/components/UserInfoUpdateDialog/UserInfoUpdateDialog';
import type { UserUpdateFieldType } from '@templates/UserInfoSetting/containers/AccountSetting/types/user-update-field-type';
import OutlinedButton from 'afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import TextInput, {
  type TextInputLabelTextProps,
} from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import Title from 'afterdoc-design-system/components/Molecules/Title/Title';

export type InfoKey = 'id' | 'password' | 'phone';

const labelProps: TextInputLabelTextProps = {
  width: 60,
  position: 'horizontal',
  className: 'flex items-center flex-shrink-0',
  children: '',
};

interface AccountSettingsSectionProps {
  id: string;
  phone: string;
  isMasterOrAdmin: boolean;
}

const titleMap: Record<InfoKey, string> = {
  id: '아이디 변경',
  password: '비밀번호 변경',
  phone: '휴대폰번호 변경',
};

export default function AccountSettingsSection({
  id,
  phone,
  isMasterOrAdmin,
}: AccountSettingsSectionProps) {
  const handleChangeButtonClick = (key: InfoKey) => {
    dialogService.push(<UserInfoUpdateDialog infoKey={key} />, {
      id: 'user-info-update-dialog',
      titleProps: {
        title: titleMap[key],
      },
      onClose: () => {},
      width: 380,
      wrapperClassName: 'h-fit mt-0 mb-0 overflow-visible',
    });
  };

  return (
    <>
      {isMasterOrAdmin && (
        <div className='flex flex-col gap-10 px-80 py-20'>
          <Title
            title='내 계정 설정'
            subTitle={`내 계정 설정은 '마스터, 관리자' 계정만 가능합니다. 직원분들의 계정은 [병원 설정] - [전체 계정 관리]에서 관리해 주세요.`}
          />
          <div className='flex flex-col gap-20 px-20 pt-10 pb-20'>
            <div className='flex gap-20'>
              <div className='flex w-1/2 items-center gap-20'>
                <TextInput
                  disabled
                  label={{ ...labelProps, children: '아이디' }}
                  className='flex-grow'
                  value={id}
                />
                <OutlinedButton className='h-30 w-88' onClick={() => handleChangeButtonClick('id')}>
                  변경하기
                </OutlinedButton>
              </div>
              <div className='flex w-1/2 items-center gap-20'>
                <TextInput
                  disabled
                  label={{ ...labelProps, children: '비밀번호' }}
                  className='flex-grow'
                  value='**********'
                />
                <OutlinedButton
                  className='h-30 w-88'
                  onClick={() => handleChangeButtonClick('password')}>
                  변경하기
                </OutlinedButton>
              </div>
            </div>
            <div className='flex items-center gap-20'>
              <TextInput
                disabled
                label={{ ...labelProps, children: '휴대폰번호' }}
                placeholder={'예) 01012345678'}
                className='flex-grow'
                value={phone}
              />
              <OutlinedButton
                className='h-30 w-88'
                onClick={() => handleChangeButtonClick('phone')}>
                변경하기
              </OutlinedButton>
            </div>
          </div>
        </div>
      )}
    </>
  );
}
