import { useAtomValue } from 'jotai';
import ProgressItem from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/components/FileDownloadProgress/ProgressItem';
import { Z_INDEXS } from 'web/templates/CustomerChat/constants/layout';
import { chatFileProgressAtom } from '../ChattingContainer/components/ChattingContent/states/chatFileProgress';

export default function ProgressContent() {
  const chatFileProgress = useAtomValue(chatFileProgressAtom);
  const keysIsFileNames = Array.from(chatFileProgress.keys()); // IterableIterator를 배열로 변환

  return (
    <div className='absolute bottom-0 h-auto w-full' style={{ zIndex: Z_INDEXS.ChattingTooltip }}>
      {keysIsFileNames.map((FileName: string) => {
        const { progress, error } = chatFileProgress.get(FileName) ?? { progress: 0 };

        return (
          <ProgressItem
            key={FileName}
            progress={progress}
            fileName={FileName}
            error={error ?? null}
          />
        );
      })}
    </div>
  );
}
