import { useChatMessageContext } from '../ChatMessageContext';
import TextContentMessage from './TextContentMessage/TextContentMessage';
import ListCard from './components/EventListCard';
import MessageTitle from './components/MessageTitle';

export default function HaftercareMessage() {
  const { content, isMine, messageWidthStyle, haftercareID } = useChatMessageContext();
  if (haftercareID === null) return null; //TODO - 혹시몰라 넣어놓은 방어 로직
  if (haftercareID === undefined) return null;
  const representImage = haftercareID?.representImage;
  const paths = representImage?.paths;
  const url = paths ? paths[0] : '';

  const { title, price, saleRate, salePrice } = haftercareID;
  if (
    title === undefined ||
    price === undefined ||
    saleRate === undefined ||
    salePrice === undefined
  )
    return null;
  const priceAfterSale = price - salePrice;
  return (
    <div className='flex flex-col items-end'>
      {content && <TextContentMessage />}
      <div
        className={`mt-10 rounded-r10 px-16 py-10 shadow-modal ${isMine ? 'bg-[rgba(255,245,208,1)]' : 'bg-white50'}`}
        style={messageWidthStyle}>
        <MessageTitle title='이벤트' />
        <ListCard
          url={url}
          description={title}
          originalPrice={price}
          eventPrice={priceAfterSale}
          percentage={saleRate}
        />
      </div>
    </div>
  );
}
