import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import { makeChattingInfoStringDisplay } from '../../functions/makeChattingInfoStringDisplay';

type Props = {
  url: string;
  description: string;
  originalPrice: number;
  eventPrice: number;
  percentage: number;
  isEvent?: boolean;
};

export default function EventListCard({
  url,
  description,
  originalPrice,
  eventPrice,
  percentage,
  isEvent,
}: Props) {
  const displayOriginalPrice = makeChattingInfoStringDisplay({
    type: 'price',
    payload: { num: originalPrice },
  });
  const displaySalePrice = makeChattingInfoStringDisplay({
    type: 'price',
    payload: { num: eventPrice },
  });
  return (
    <div className='flex h-100 w-full items-center rounded-r16 border border-white600 bg-white50 py-10 pr-6 pl-10 text-Body13'>
      <img className='m-10 size-60 flex-shrink-0 rounded-r16' src={url} alt='Event Card' />
      <div className='mx-10 flex flex-grow flex-col'>
        <div className='line-clamp-2 text-Body13 text-black700'>{description}</div>
        <div className='text-Body12 text-black200 line-through'>{displayOriginalPrice}</div>
        <div className='flex flex-row items-center'>
          <div className='mr-2 text-Header14 text-black700'>{displaySalePrice}</div>
          <div className='ml-8 text-Header14 text-red500'>{percentage}%</div>
        </div>
      </div>
      <Icon size={16} color={'black600'} name='chevron-right' className='flex-shrink-0' />
    </div>
  );
}
