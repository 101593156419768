import { SHARED_UTILS } from '@shared-utils/utils';
import { useQuery } from '@tanstack/react-query';
import { apiClient } from 'web/apis/instances/api-client';
import type { ApiManualsElSingleParams } from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';

const getManualSingle = async (query: ApiManualsElSingleParams) => {
  const { data } = await apiClient.v3.apiManualsElSingle(query);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export const useGetImagesList = ({ treatmentTagId }: { treatmentTagId: string }) => {
  const { hospitalID } = useSelectedHospitalInfo();
  const query = {
    hospitalID,
    treatmentTagId,
  };
  const { data, error, isLoading } = useQuery({
    queryKey: ['manual_images_list', JSON.stringify(query)],
    queryFn: () => getManualSingle(query),
  });

  const { cautionImages } = data || {};

  if (!cautionImages) return { data: [], error, isLoading };

  return {
    data: cautionImages,
    error,
    isLoading,
  };
};
