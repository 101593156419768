import ButtonTab from '@afterdoc-design-system/components/Atoms/Tabs/components/ButtonTab';
import Tabs from '@afterdoc-design-system/components/Atoms/Tabs/components/Tabs';
import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { useAtom, useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { MARKETING_TAB_ITEMS } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/constants/marketing-tab-items';
import { messageModeState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/message-mode';
import { selectedMarketingAutomationTabState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/states/selected-marketing-automation-tab-state';

export default function RegisterMarketingAutomationDialogTabs() {
  const {
    getValues,
    formState: { isDirty },
  } = useFormContext();

  const messageMode = useAtomValue(messageModeState);
  const isForNoTreatmentTags = getValues('isForNoTreatmentTags');
  const targetTreatmentTags = getValues('targetTreatmentTags');

  const [marketingAutomationTabItems, setMarketingAutomationTabItems] =
    useState(MARKETING_TAB_ITEMS);
  const [selectedAutomationTab, setSelectedAutomationTab] = useAtom(
    selectedMarketingAutomationTabState,
  );

  const onChangeTab = (value: (typeof MARKETING_TAB_ITEMS)[number]['value']) => {
    if (selectedAutomationTab === 0 && value !== 0) {
      if (
        (messageMode.mode === 'EDIT' && !isDirty) ||
        !getValues('name')?.length ||
        (!targetTreatmentTags?.length && !isForNoTreatmentTags)
      ) {
        return toastService.errorMsg({ text: '현재 페이지의 필수정보를 모두 입력해 주세요.' });
      }
    }
    return setSelectedAutomationTab(value);
  };

  useEffect(() => {
    if (messageMode.mode === 'EDIT') {
      setMarketingAutomationTabItems((prev) => {
        return prev.map((item) => {
          if (item.value === 0 || item.value === 1) {
            return {
              ...item,
              disabled: true,
            };
          }

          return item;
        });
      });
    }
  }, [messageMode]);

  return (
    <Tabs value={selectedAutomationTab} onChange={onChangeTab} className='w-[700px]'>
      {marketingAutomationTabItems.map(({ label, value, disabled }) => {
        return (
          <ButtonTab
            value={value}
            label={label}
            key={value}
            className='border-b border-b-white400'
            disabled={disabled}>
            {label}
          </ButtonTab>
        );
      })}
    </Tabs>
  );
}
