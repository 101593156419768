import TextArea from '@afterdoc-design-system/components/Atoms/Input/TextArea/TextArea';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { usePatientDetailInfo } from 'web/shared/hooks/use-get-patient-detail-info';
import { smartDoctorAxiosInstance } from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/SmartDoctor/api/axios';
import { smartDoctorTokenState } from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/SmartDoctor/state/token';

const _getSmartDoctorPatientInfo = async ({
  name,
  cellPhone,
}: {
  name: string;
  cellPhone: string;
}) => {
  const response = await smartDoctorAxiosInstance.get(
    `/customers?name=${name}&cellPhone=${cellPhone}`,
  );
  return response.data;
};

const getSmartDoctorReservationUserNote = async ({
  cellPhone,
  token,
}: {
  cellPhone: string;
  token: string;
}) => {
  const response = await smartDoctorAxiosInstance.get(`/customers/${cellPhone}`, {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  });
  return response.data;
};

interface SmartDoctorCustomerMemoContainerProps {
  isRemoved: boolean;
}

export default function SmartDoctorCustomerMemoContainer({
  isRemoved,
}: SmartDoctorCustomerMemoContainerProps) {
  const smartDoctorToken = useAtomValue(smartDoctorTokenState);

  const { phoneNumber } = usePatientDetailInfo();

  const { data: memoData } = useQuery({
    queryKey: [
      'smartDoctorCustomerMemoHistory',
      {
        cellPhone: phoneNumber,
        token: smartDoctorToken,
      },
    ],
    queryFn: ({
      queryKey,
    }: {
      queryKey: [
        'smartDoctorCustomerMemoHistory',
        {
          cellPhone: string;
          token: string;
        },
      ];
    }) => getSmartDoctorReservationUserNote(queryKey[1]),
    enabled: !!smartDoctorToken && !isRemoved,
  });

  return (
    <div className='flex w-full flex-col gap-4'>
      <div className='mb-16 text-Header14 text-black500'>스마트닥터 고객메모</div>
      <TextArea
        className='border-none'
        inputWrapperClassName='bg-disabled'
        inputClassName='disabled:bg-disabled'
        height={100}
        disabled={true}
        isShowBottom={false}
        value={memoData?.content ?? '스마트닥터에 등록된 고객메모가 없습니다.'}
      />
    </div>
  );
}
