import LoadingSpinner from '@afterdoc-design-system/components/Atoms/Loading/LoadingSpinner';
import Scrollbar from '@afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { SHARED_UTILS } from '@shared-utils/utils';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { memo, useEffect, useMemo, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type {
  ApiPatientsElTemporaryListOrSearchParams,
  ApiServiceSettingsElCountriesData,
  ApiServiceSettingsElToBeDisplayedPatientsFieldsData,
  ApiTreatmentTagsElData,
} from 'web/apis/swaggers/swagger-docs';
import FullLoading from 'web/shared/components/FullLoading/FullLoading';
import { KOREA_COUNTRY_CODE_ID } from 'web/shared/constants/country-code-id';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import CustomerManagementEditableTableFooter from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementEditableTableBody/components/CustomerManagementEditableTableFooter';
import { editableHeaderFilterState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/states/editable-header-filter';
import { searchTextState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/containers/SearchFilter/states/search';
import { selectedSearchCriteriaState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/containers/SearchFilter/states/selected-search-criteria';
import { useIntersectionObserver } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/hooks/use-intersection-observer';
import { useVirtualScroll } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/hooks/use-virtual-scroll';
import CustomerManagementEditableTableBody from '../components/CustomerManagementEditableTableBody/CustomerManagementEditableTableBody';
import type { CustomerManagementTableRowArr } from '../types/table';

const TOTAL_ROW_COUNT = 50;

const fetchPatientsTemporaryList = async ({
  pageParam = undefined,
  ...params
}: {
  pageParam?: string;
} & ApiPatientsElTemporaryListOrSearchParams) => {
  const response = await apiClient.v3.apiPatientsElTemporaryListOrSearch({
    cursor: pageParam,
    ...params,
  });
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const createInitialRows = (
  rowsLength: number,
  lastOrder: number,
): CustomerManagementTableRowArr => {
  return Array.from({ length: rowsLength }, (_, index) => ({
    order: lastOrder + index + 1,
  }));
};

type TableContainerProps = {
  isExpanded: boolean;
  countriesList: ApiServiceSettingsElCountriesData['data'];
  treatmentTags: ApiTreatmentTagsElData['data']['treatmentTags'];
} & ApiServiceSettingsElToBeDisplayedPatientsFieldsData['data'];

function TableContainer({
  isExpanded,
  countriesList,
  treatmentTags,
  ...usingColumns
}: TableContainerProps) {
  const { setValue, watch } = useFormContext<{
    rows: CustomerManagementTableRowArr;
  }>();

  const { hospitalID } = useSelectedHospitalInfo();
  const containerHeight = useMemo(() => {
    return isExpanded ? window.innerHeight - 240 : window.innerHeight - 196;
  }, [isExpanded]);
  const rowHeight = useRef(40);
  const loadMoreRef = useRef<HTMLDivElement | null>(null);
  const rows = watch('rows');
  const virtualScrollData = useVirtualScroll(rows, rowHeight.current, containerHeight, 5);

  const editableHeaderFilter = useAtomValue(editableHeaderFilterState);
  const searchText = useAtomValue(searchTextState);
  const selectedSearchCriteria = useAtomValue(selectedSearchCriteriaState);

  const params = useMemo(() => {
    return {
      hospitalID,
      limit: 50,
      chartNumber: selectedSearchCriteria === 'chartNumber' ? searchText : undefined,
      patientName: selectedSearchCriteria === 'patientName' ? searchText : undefined,
      phone: selectedSearchCriteria === 'phoneNumber' ? searchText : undefined,
      birth: selectedSearchCriteria === 'birthdate' ? searchText : undefined,
      ...editableHeaderFilter,
      isFirstVisit:
        editableHeaderFilter.isFirstVisit === 'all' ? undefined : editableHeaderFilter.isFirstVisit,
    };
  }, [editableHeaderFilter, hospitalID, searchText, selectedSearchCriteria]);

  const { data, fetchNextPage, hasNextPage, isFetchingNextPage, isError, isLoading, isRefetching } =
    useInfiniteQuery({
      queryKey: [QUERY_KEY.apiPatientsElTemporaryListOrSearch, JSON.stringify(params)],
      queryFn: ({ pageParam }: { pageParam?: string }) =>
        fetchPatientsTemporaryList({
          pageParam,
          ...params,
        }),
      getNextPageParam: (lastPage) => lastPage.nextCursor || undefined,
      initialPageParam: undefined,
      select: (data) => {
        const allPatients = data.pages.flatMap(
          (page) => page.patients,
        ) as CustomerManagementTableRowArr;

        const maxOrder = Math.max(...allPatients.map((patient) => patient.order || 0));

        const existingOrders = new Set(allPatients.map((patient) => patient.order));

        for (let i = 1; i <= maxOrder; i++) {
          if (!existingOrders.has(i)) {
            allPatients.push({
              order: i,
              name: '',
              patientId: '',
              countryCode: {
                id: KOREA_COUNTRY_CODE_ID,
                koreanCountryName: '한국',
                internationalDialingCodes: '+82',
              },
              treatmentTags: [],
            });
          }
        }

        const sortedPatients = allPatients.sort((a, b) => (a.order || 0) - (b.order || 0));

        return {
          ...data,
          pages: [{ ...data.pages[0], patients: sortedPatients }],
        };
      },
    });

  const handleAddRows = (newRows: CustomerManagementTableRowArr) => {
    setValue('rows', [...rows, ...newRows]);
  };

  const addNew50Rows = () => {
    const new50Rows: CustomerManagementTableRowArr = Array.from(
      { length: TOTAL_ROW_COUNT },
      (_, index) => ({
        order: rows.length + index + 1,
      }),
    );
    handleAddRows(new50Rows);
  };

  const scrollContainerRef = useRef<
    (HTMLDivElement & { scrollTo: (index: number, behavior?: ScrollBehavior) => void }) | null
  >(null);

  useIntersectionObserver({
    target: loadMoreRef.current,
    onIntersect: fetchNextPage,
    enabled: hasNextPage && !isError && !isLoading,
    root: scrollContainerRef.current,
    rootMargin: '0px 0px 100px 0px',
  });

  useEffect(() => {
    if (!data || !data.pages) return;

    const allPatients = data.pages
      .flatMap((page) => page.patients)
      .filter((patient) => patient !== undefined);

    const totalCount = data.pages[0].totalCount;

    if (totalCount > 0) {
      const rowsToFill =
        Math.ceil(totalCount / TOTAL_ROW_COUNT) * TOTAL_ROW_COUNT - allPatients.length;

      setValue('rows', [
        ...allPatients,
        ...createInitialRows(rowsToFill, allPatients[allPatients.length - 1]?.order ?? 0),
      ]);
    } else {
      setValue('rows', createInitialRows(TOTAL_ROW_COUNT, 0));
    }
  }, [data, isRefetching]);

  useEffect(() => {
    if (searchText && searchText.length > 0 && !data && !isLoading) {
      toastService.errorMsg({
        text: '검색 결과가 없습니다.',
      });
    }
  }, [searchText, data, isLoading]);

  if (isLoading && !data) {
    return <FullLoading />;
  }

  return (
    <div>
      <Scrollbar
        onScroll={(scrollTop) => virtualScrollData.handleScroll(scrollTop)}
        style={{ height: `${containerHeight}px` }}>
        <div style={{ height: `${virtualScrollData.totalHeight}px`, position: 'relative' }}>
          <table
            className='w-full table-fixed'
            style={{
              transform: `translateY(${virtualScrollData.offsetY}px)`,
            }}>
            <CustomerManagementEditableTableBody
              countriesList={countriesList}
              treatmentTagsList={treatmentTags}
              visibleRows={virtualScrollData.visibleRows}
              {...usingColumns}
            />
            <CustomerManagementEditableTableFooter handleAddRow={addNew50Rows} {...usingColumns} />
          </table>
          <div ref={loadMoreRef} className='absolute bottom-0 left-0 h-1 w-full' />
        </div>
      </Scrollbar>

      {isFetchingNextPage && (
        <div className='flex w-full justify-center'>
          <LoadingSpinner />
        </div>
      )}
    </div>
  );
}

export default memo(TableContainer);
