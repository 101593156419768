import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';

type ManualToggleButtonProps = {
  isMenualOpen: boolean;
  onClick: () => void;
};

export default function ManualToggleButton({ isMenualOpen, onClick }: ManualToggleButtonProps) {
  return (
    <div
      className='absolute top-[50%] left-[-17px] h-53 w-32 flex-center flex-col-center translate-y-1/2 transform cursor-pointer rounded-r10 border border-white600 bg-white50'
      // style={{ zIndex: Z_INDEXS.ManualToggleButton }}
      onClick={onClick}
    >
      <Icon name={isMenualOpen ? 'chevron-double-left' : 'chevron-double-right'} size={20} />
      <div className='text-center text-Body10 text-black500'>{isMenualOpen ? '닫기' : '열기'}</div>
    </div>
  );
}
