import { RED_TEXT_AUTH_CONTROL, RED_TEXT_DEFAULT } from '../constants/dropdown';

export function getRedTextIndices(data: string[], isAuthoriztionControlPatientChatting: boolean) {
  return data
    .map((option, index) => {
      if (isAuthoriztionControlPatientChatting)
        return RED_TEXT_AUTH_CONTROL.includes(option) ? index : -1;

      return RED_TEXT_DEFAULT.includes(option) ? index : -1;
    })
    .filter((index) => index !== -1);
}
