import { overlayPageService } from '@afterdoc-design-system/components/Molecules/OverlayPage/OverlayPage.service';
import { useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { OVERLAY_PAGE_ID } from 'web/shared/constants/overlay-page-id';
import { useNavigationBlocker } from 'web/shared/hooks/use-navigation-blocker';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import RegisterCounselAutomationDialogContent, {
  type CounselAutomationAPIFormValues,
} from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/components/RegisterCounselAutomationDialogContent';
import RegisterCounselAutomationDialogHeader from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/components/RegisterCounselAutomationDialogHeader';
import { useResetAll } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/hooks/use-reset-all';

export default function RegisterCounselAutomationDialogContainer() {
  const { hospitalID } = useSelectedHospitalInfo();

  const memoizedDefaultValues: CounselAutomationAPIFormValues = useMemo(
    () =>
      ({
        hospitalID,
        name: '',
        targetTreatmentTags: undefined,
        toBeAppliedNationalityIds: undefined,
        commonAlgorithm: {
          enabled: false,
          sendingDaysAfterStart: 60,
        },
        messages: [],
        type: 'CONSULTATION',
      }) as const,
    [hospitalID],
  );

  const methods = useForm<CounselAutomationAPIFormValues>({
    mode: 'onChange',
    defaultValues: memoizedDefaultValues,
  });

  const {
    formState: { isDirty },
    reset,
  } = methods;

  const { resetAll } = useResetAll({
    reset,
    defaultValues: memoizedDefaultValues,
  });

  const onCloseModal = () => {
    overlayPageService.popById(OVERLAY_PAGE_ID['register-counsel-automation-dialog']);
  };

  useNavigationBlocker({
    shouldBlock: isDirty,
    onConfirm: () => {
      resetAll();
      onCloseModal();
    },
  });

  return (
    <FormProvider {...methods}>
      <RegisterCounselAutomationDialogHeader />
      <RegisterCounselAutomationDialogContent />
    </FormProvider>
  );
}
