import { useChatMessageContext } from '../ChatMessageContext';
import TextContentMessage from './TextContentMessage/TextContentMessage';
import MessageTitle from './components/MessageTitle';
import VideoListCard from './components/VideoListCard';

export default function OldHomecareMessage() {
  const { content, videoContentIDs, isMine, messageWidthStyle } = useChatMessageContext();
  if (videoContentIDs === undefined) return null;
  return (
    <div className='flex flex-col gap-10'>
      {content && <TextContentMessage />}
      <div
        className={`rounded-r10 px-16 py-10 shadow-modal ${isMine ? 'bg-[rgba(255,245,208,1)]' : 'bg-white50'}`}
        style={messageWidthStyle}>
        <MessageTitle title='운동 영상' />
        {videoContentIDs?.map(({ thumbnailFileID, title }) => {
          if (!thumbnailFileID || !thumbnailFileID.paths || !title) return null;
          return (
            <VideoListCard
              key={thumbnailFileID.paths[0]}
              url={thumbnailFileID.paths[0]}
              title={title}
            />
          );
        })}
      </div>
    </div>
  );
}
