import ReservationHistoryTable from './components/ReservationHistoryTable/ReservationHistoryTable';
import TitleAndSubText from './components/ReservationHistoryTitleAndSubText';

export default function ReservationHistoryContainer() {
  return (
    <>
      <TitleAndSubText />
      <ReservationHistoryTable />
    </>
  );
}
