import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { useState } from 'react';
import ImageViewer from 'web/shared/components/ImageViewer/ImageViewer';

interface ImageContentProps {
  images: string[];
}

export default function ImageContent({ images }: ImageContentProps) {
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const onClickImage = (index: number) => {
    setIsImageViewerOpen(true);
    setSelectedImageIndex(index);
  };

  return (
    <>
      <div className='flex flex-center gap-4'>
        <div className='cursor-pointer' onClick={() => onClickImage(0)}>
          <img
            src={images[0]}
            alt='이미지'
            className={customTwMerge(
              'rounded-r16 bg-white50 object-cover shadow-modal',
              images.length > 1 ? 'h-[96px] w-[127px]' : 'h-[200px] w-[260px]',
            )}
          />
        </div>
        {images.length > 1 && (
          <div
            className='relative h-[96px] w-[127px] cursor-pointer rounded-r16'
            onClick={() => onClickImage(1)}>
            <img
              src={images[1]}
              alt='이미지'
              className={customTwMerge(' h-full w-full rounded-r16 object-cover shadow-modal')}
            />
            <div className='absolute inset-0 rounded-r16 bg-black opacity-50 ' />
            <div className='absolute inset-0 flex items-center justify-center'>
              <div className='flex h-28 w-81 items-center justify-center gap-6 rounded-r16 bg-black800 bg-opacity-50 text-Body11 text-white50'>
                <Icon name='image-filter' color='white50' size={16} />총 {images.length}장
              </div>
            </div>
          </div>
        )}
      </div>
      {isImageViewerOpen && (
        <ImageViewer
          selectedIndex={selectedImageIndex}
          images={images}
          onClose={() => setIsImageViewerOpen(false)}
        />
      )}
    </>
  );
}
