import TextInput from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import { memo } from 'react';

interface PopupCreateDateLabelProps {
  date: string;
}

function PopupCreateDateLabel({ date }: PopupCreateDateLabelProps) {
  return (
    <div className='min-h-40 flex-center gap-24 py-4'>
      <LabelText width={76} className='flex h-32 items-center'>
        등록일자
      </LabelText>
      <TextInput className='h-full flex-grow' disabled value={date} onChange={() => {}} />
    </div>
  );
}

export default memo(PopupCreateDateLabel);
