import Divider from '@afterdoc-design-system/components/Atoms/Divider/Divider';
import Toggle from '@afterdoc-design-system/components/Atoms/Toggle/Toggle';
import Title from '@afterdoc-design-system/components/Molecules/Title/Title';
import { Suspense } from 'react';
import type { CommonAlgorithm } from 'web/apis/swaggers/swagger-docs';
import { OVERLAY_PAGE_ID } from 'web/shared/constants/overlay-page-id';
import { useOpenAutomationDialog } from 'web/shared/hooks/overlayPage/use-open-automation-dialog';
import EditCommonAutomationDialogContainer from 'web/templates/Automation/containers/Dialog/EditCommonAutomationDialog/EditCommonAutomationDialog.container';
import CardItemLayout from 'web/templates/Automation/containers/Main/shared/components/CardItemLayout';
import StatisticItem from 'web/templates/Automation/containers/Main/shared/components/StatisticItem';
import { useToggleAlgorithm } from 'web/templates/Automation/containers/Main/shared/hooks/use-toggle-algorithm';

interface CommonAutomationCardItemProps {
  commonAlgorithm?: CommonAlgorithm;
}

export default function CommonAutomationCardItem({
  commonAlgorithm,
}: CommonAutomationCardItemProps) {
  if (!commonAlgorithm) {
    return <CardItemLayout className='col-span-1 h-[195px]' />;
  }

  const {
    _id: algorithmId,
    isActive,
    appliedAlgorithms,
    totalResponseCount,
    totalReadCount,
    totalTargetCount,
  } = commonAlgorithm;

  const openAutomationDialog = useOpenAutomationDialog();
  const { isChecked, onToggle } = useToggleAlgorithm({
    algorithmId,
    targetCount: totalTargetCount,
    initialState: isActive,
    type: 'COMMON',
  });

  const onClickCard = () => {
    openAutomationDialog(
      <Suspense>
        <EditCommonAutomationDialogContainer />
      </Suspense>,
      OVERLAY_PAGE_ID['edit-common-algorithm-dialog'],
    );
  };

  return (
    <CardItemLayout onClick={onClickCard} className='flex flex-col'>
      <Title
        title='공통 재내원 메시지'
        subTitle='관리자동화가 적용된 고객에게 발송되는 재내원 안내 메시지'
        trailingChildren={
          <div onClick={(e) => e.stopPropagation()}>
            <Toggle checked={isChecked} defaultChecked={isChecked} onChange={onToggle} />
          </div>
        }
        wrapperClassName='py-0 px-0 items-start'
      />
      <div className='mt-10 flex flex-col gap-20'>
        <div className='flex h-[30px] items-center rounded-r6 bg-white200 px-16 py-6'>
          {appliedAlgorithms && appliedAlgorithms.length > 0 ? (
            <span className='truncate text-Body12'>{appliedAlgorithms.join(', ')}</span>
          ) : (
            <span className='text-Body12'>-</span>
          )}
        </div>
        <div className='flex w-full items-center'>
          <StatisticItem label='대상자' value={totalTargetCount} unit='명' />
          <Divider type='line' direction='vertical' className='mx-10' />
          <StatisticItem label='조회수' value={totalReadCount} unit='건' />
          <Divider type='line' direction='vertical' className='mx-10' />
          <StatisticItem label='응답수' value={totalResponseCount} unit='건' />
        </div>
      </div>
    </CardItemLayout>
  );
}
