import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { AUTHORIZATION_ITEMS } from '@templates/HospitalSetting/containers/AllAccountSetting/constants/authorization-items';
import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import { fullDimmedLoadingService } from 'afterdoc-design-system/components/Atoms/Loading/FullDimmedLoading/FullDimmedLoading.service';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { type SubmitHandler, useFormContext } from 'react-hook-form';
import { SHARED_UTILS } from 'utils/utils';

type UpdateHospitalAccountFormValues = Parameters<
  typeof apiClient.v3.apiHospitalsAccountsElUpdate
>[0];

const updateHospitalAccount = async (params: UpdateHospitalAccountFormValues) => {
  const response = await apiClient.v3.apiHospitalsAccountsElUpdate(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export default function EditAccountButton() {
  const queryClient = useQueryClient();
  const { handleSubmit, setError } = useFormContext<UpdateHospitalAccountFormValues>();

  const mutation = useMutation({
    mutationFn: (params: UpdateHospitalAccountFormValues) => updateHospitalAccount(params),
    onMutate: () => fullDimmedLoadingService.on(),
    onSettled: () => fullDimmedLoadingService.off(),
    onSuccess: () => handleMutationSuccess(),
    onError: (error) => handleMutationError(error),
  });

  const handleMutationSuccess = () => {
    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY.apiHospitalsAccountsElFind],
    });
    // TODO 서버에서 푸시 발송됨 > 관련 계정 로그아웃 처리
    dialogService.pop();
  };

  const handleMutationError = (error: Error) => {
    if (SHARED_UTILS.api.isApiErrorWithCode(error, 400)) {
      setError('id', { type: 'manual', message: '이미 동일한 아이디가 있습니다.' });
    }
  };

  const validateFields = (data: UpdateHospitalAccountFormValues) => {
    const { id, realName, password, jobType, authorizationType } = data;
    const errors: { field: keyof UpdateHospitalAccountFormValues }[] = [];

    if (!realName) {
      errors.push({ field: 'name' });
    }

    if (!id || id.length < 3) {
      errors.push({ field: 'id' });
    }

    if (!!password && password.length < 3) {
      errors.push({ field: 'password' });
    }

    if (!jobType) {
      errors.push({ field: 'jobType' });
    }

    if (authorizationType !== '마스터') {
      if (!authorizationType || !AUTHORIZATION_ITEMS.some((item) => item === authorizationType)) {
        errors.push({ field: 'authorizationType' });
      }
    }

    if (errors.length > 0) {
      for (const error of errors) {
        setError(error.field, { type: 'manual' });
      }
      return false;
    }

    return true;
  };

  const onSubmit: SubmitHandler<UpdateHospitalAccountFormValues> = (data) => {
    if (!validateFields(data)) {
      return;
    }
    const body = Object.fromEntries(
      Object.entries(data).filter(([key, value]) => key !== 'password' || !!value),
    ) as UpdateHospitalAccountFormValues;

    mutation.mutate(body);
  };

  return (
    <div className='flex-center gap-10'>
      <ContainedButton btnColor='secondary' onClick={dialogService.pop}>
        취소
      </ContainedButton>
      <ContainedButton type='submit' onClick={handleSubmit(onSubmit)}>
        저장
      </ContainedButton>
    </div>
  );
}
