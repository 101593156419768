import { fullDimmedLoadingService } from '@afterdoc-design-system/components/Atoms/Loading/FullDimmedLoading/FullDimmedLoading.service';
import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import DownloadFooter from '@afterdoc-design-system/components/Molecules/DownloadFooter/DownloadFooter';
import { SHARED_UTILS } from '@shared-utils/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { useFormContext } from 'react-hook-form';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { unTaggedHeaderFilterState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/UnTaggedCustomerManagement/containers/UnTaggedCustomerManagementEditableTable/states/editable-header-filter';
import { searchTextState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/containers/SearchFilter/states/search';
import { selectedSearchCriteriaState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/containers/SearchFilter/states/selected-search-criteria';
import type { UnTaggedCustomerManagementAPIFormValues } from '../../types/table';

const postUnTaggedPatientsInfo = async (params: UnTaggedCustomerManagementAPIFormValues) => {
  const response = await apiClient.v3.apiPatientsElUntaggedTarget(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export default function UnTaggedCustomerManagementDownloadFooter() {
  const { hospitalID } = useSelectedHospitalInfo();

  const queryClient = useQueryClient();
  const { getValues, reset } = useFormContext<{ rows: UnTaggedCustomerManagementAPIFormValues }>();

  const unTaggedHeaderFilter = useAtomValue(unTaggedHeaderFilterState);
  const searchText = useAtomValue(searchTextState);
  const selectedSearchCriteria = useAtomValue(selectedSearchCriteriaState);

  const postUnTaggedPatientsInfoMutation = useMutation({
    mutationFn: postUnTaggedPatientsInfo,
    onMutate: () => {
      fullDimmedLoadingService.on();
    },
    onSuccess: () => {
      fullDimmedLoadingService.off();
      toastService.successMsg({ text: '고객 정보를 업데이트 했습니다.' });
      queryClient.invalidateQueries({
        queryKey: [
          QUERY_KEY.apiPatientsElListOrSearchForUntagged,
          JSON.stringify({
            hospitalID,
            limit: 50,
            chartNumber: selectedSearchCriteria === 'chartNumber' ? searchText : undefined,
            patientName: selectedSearchCriteria === 'patientName' ? searchText : undefined,
            phone: selectedSearchCriteria === 'phoneNumber' ? searchText : undefined,
            birth: selectedSearchCriteria === 'birthdate' ? searchText : undefined,
            ...unTaggedHeaderFilter,
            isFirstVisit:
              unTaggedHeaderFilter.isFirstVisit === 'all'
                ? undefined
                : unTaggedHeaderFilter.isFirstVisit,
          }),
        ],
      });
      reset();
    },
    onError: (error) => {
      console.error('error', error);
      toastService.errorMsg({ text: '고객 정보 업데이트를 실패했습니다.' });
      fullDimmedLoadingService.off();
    },
  });

  const handleSave = () => {
    const formData = getValues();

    const updatedValues = formData.rows
      .map((item) => {
        return {
          patientId: item.patientId,
          treatmentTagIds: item.treatmentTagIds,
          nationalityId: item.nationalityId,
        };
      })
      .filter((item) => item.treatmentTagIds.length > 0 || item.nationalityId);

    postUnTaggedPatientsInfoMutation.mutate(updatedValues);
  };

  return (
    <DownloadFooter onSave={handleSave} saveButton='저장하기' className='sticky right-0 bottom-0' />
  );
}
