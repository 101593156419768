import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';

interface ManualTreatmentTagContentProps {
  tagName: string;
  tagColor: string;
}

export default function ManualTreatmentTagContent({
  tagName,
  tagColor,
}: ManualTreatmentTagContentProps) {
  return (
    <div className='flex gap-6 py-28'>
      <Icon name='tag' size={24} customColor={tagColor} />
      <div className='text-Header16'>{tagName}</div>
    </div>
  );
}
