import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import type { ReactNode } from 'react';
import type { ContentSubLayer } from 'web/templates/Content/containers/ContentPanel/constants/tab-items';
import { usePatient } from 'web/templates/CustomerChat/hooks/use-patient';
import type { ManusFolders } from '../hooks/use-manus-folders';
import TagDetailPanel from './ManualPanelFolderItem/components/TagDetailPanel/TagDetailPanel';

export type ParentItem = {
  title: string;
  isOpen: boolean;
  items: ContentSubLayer[];
};

interface ContentPanelFolderProps {
  id: string;
  parentItem: ManusFolders;
  children: ReactNode;
  handleClick: ({ id }: { id: string }) => void;
  isOpen: boolean;
}

export default function ManualPanelFolder({
  id,
  parentItem,
  children,
  isOpen,
  handleClick,
}: ContentPanelFolderProps) {
  const { title, type, color: tagColor } = parentItem;
  const { treatmentTags } = usePatient();

  const treatmentTagsIds = treatmentTags?.map((tag) => tag.tagId);
  const isInTreatmentTagsIds = treatmentTagsIds?.includes(id);
  const isFolder = type === 'folder';

  const isTagOpen = !isFolder && isOpen;
  return (
    <div>
      <div
        className={`flex h-40 cursor-pointer items-center justify-start gap-10 px-20 ${isInTreatmentTagsIds && 'bg-purple100'}`}
        onClick={() => handleClick({ id })}>
        <Icon name={isOpen ? 'chevron-down' : 'chevron-right'} size={16} />
        <Icon
          name={isFolder ? 'folder-outline' : 'tag'}
          size={20}
          color={isFolder ? 'white700' : 'transparent'}
          customColor={tagColor}
        />
        <span className='line-clamp-1 w-[300px] text-Header14 text-black500 '>{title}</span>
      </div>
      {isTagOpen && <TagDetailPanel treatmentTagId={id} />}
      {children}
    </div>
  );
}
