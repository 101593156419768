import { useChatMessageContext } from '../ChatMessageContext';
import { MESSAGE_TYPE_CODE } from '../constants/message';
import AlgorithmdVideoMessage from './AlgorithmdVideoMessage';
import AlgorythmSuveyMessage from './AlgorythmSuveyMessage';
import ElectronEventMessage from './ElectronEventMessage';
import EventMessage from './EventMessage';
import FileMessage from './FileMessage';
import HaftercareMessage from './HaftercareMessage';
import ImageMessage from './ImageMessage';
import MarketingImageMessage from './MarketingImageMessage';
import MockingImageMessage from './MockingImageMessage';
import NoticeMessage from './NoticeMessage';
import OldHomecareMessage from './OldHomecareMessage';
import OldVideoMessage from './OldVideoMessage';
import ReservationMessage from './ReservationMessage';
import SystemContentMessage from './SystemContentMessage';
import TextContentMessage from './TextContentMessage/TextContentMessage';
import VideoMessage from './VideoMessage';

export default function Message() {
  const { type } = useChatMessageContext();
  if (type === undefined) return null;

  switch (type) {
    case 100:
    case 20100:
    case 12100:
    case 11100:
    case MESSAGE_TYPE_CODE.TEXT_MOCKING:
      return <TextContentMessage />;
    case 410:
    case 12410:
      return <VideoMessage />;
    case 11400:
      return <AlgorithmdVideoMessage />;
    case 400:
    case 12400:
      return <OldVideoMessage />;
    case 500:
    case MESSAGE_TYPE_CODE.FILE_MOCKING:
      return <FileMessage />;
    case 600:
      return <ReservationMessage />;
    case 801:
      return <NoticeMessage />;
    case 700:
    case 701:
    case 11700:
    case 12700:
      return <EventMessage />;
    case 710:
      return <HaftercareMessage />;
    case 11410:
      return <OldHomecareMessage />;
    case 711:
      return <ElectronEventMessage />;
    case 13900:
    case 13910:
      return <AlgorythmSuveyMessage />;
    case 20300:
      return <MarketingImageMessage />;
    case 300:
    case 12300:
    case 11300:
      return <ImageMessage />;
    case MESSAGE_TYPE_CODE.IMAGE_MOCKING:
      return <MockingImageMessage />;
    case 40000:
      return <SystemContentMessage />;
    default:
      return <TextContentMessage />;
  }
}
