import ContainedButton from '@afterdoc-design-system/components/Atoms/Button/ContainedButton';
import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import DownloadFooter from '@afterdoc-design-system/components/Molecules/DownloadFooter/DownloadFooter';
import { modalService } from '@afterdoc-design-system/components/Molecules/Modal/Modal.service';
import ProgressBar from '@afterdoc-design-system/components/Molecules/ProgressBar/ProgressBar';
import Portal from '@afterdoc-design-system/shared/Portal/Portal';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import useFileDownloadWithProgress from 'web/shared/hooks/files/use-file-download-with-progress';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { useSaveUser } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementExcelFooter/hooks/use-save-user';
import { useUploadExcel } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementExcelFooter/hooks/use-upload-excel';
import { isTableTemporarySavingState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/states/save';

export default function CustomerManagementExcelFooter() {
  const isTableTemporarySaving = useAtomValue(isTableTemporarySavingState);

  // 엑셀 업로드
  const { onUpload, fileInputRef } = useUploadExcel();
  // 사용자 정보 최종 저장
  const { handleSaveFinalUserInfo } = useSaveUser();

  const { cancelDownload, openFileFolder, progress, startDownload, status } =
    useFileDownloadWithProgress();

  const [ExcelJS, setExcelJS] = useState<typeof import('exceljs') | null>(null);
  const { name } = useSelectedHospitalInfo();
  const handleDownload = async () => {
    if (!ExcelJS) return toastService.errorMsg({ text: '엑셀 파일 다운로드에 실패했습니다.' });

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('고객등록 양식');

    // A컬럼 너비
    worksheet.getColumn(1).width = 15;
    // B컬럼 너비
    worksheet.getColumn(2).width = 15;
    // C컬럼 너비
    worksheet.getColumn(3).width = 15;

    // 첫 번째 행 높이
    worksheet.getRow(1).height = 40;

    worksheet.mergeCells('A1:B1');
    worksheet.mergeCells('A2:C2');

    // 들어가는 데이터
    worksheet.getCell('A1').value = '고객 등록 양식';
    worksheet.getCell('A2').value = '*양식을 임의로 수정하면 업로드에 실패할 수 있습니다.';
    worksheet.getCell('A3').value = '고객명*';
    worksheet.getCell('B3').value = '휴대폰번호*';
    worksheet.getCell('A4').value = '홍길동';
    worksheet.getCell('B4').value = '01012345678';

    // 폰트 설정
    worksheet.getCell('A1').font = { name: 'Arial', bold: true, size: 20 };
    worksheet.getCell('A2').font = {
      name: 'Arial',
      size: 10,
      color: { argb: 'FF0000' },
    };
    worksheet.getCell('A3').font = { name: 'Arial', size: 10, bold: true };
    worksheet.getCell('B3').font = { name: 'Arial', size: 10, bold: true };
    worksheet.getCell('A4').font = { name: 'Arial', size: 10 };
    worksheet.getCell('B4').font = { name: 'Arial', size: 10 };
    worksheet.getCell('B4').numFmt = '@';

    for (let i = 5; i <= 300; i++) {
      worksheet.getCell(`A${i}`).value = '';
      worksheet.getCell(`A${i}`).font = { name: 'Arial', size: 10 };
      worksheet.getCell(`B${i}`).value = '';
      worksheet.getCell(`B${i}`).font = { name: 'Arial', size: 10 };
      worksheet.getCell(`B${i}`).numFmt = '@';
    }

    // align 설정
    worksheet.getRow(1).alignment = { vertical: 'middle' };

    worksheet.getColumn(1).alignment = { horizontal: 'left' };
    worksheet.getColumn(2).alignment = { horizontal: 'left' };

    try {
      if (window.electron) {
        const buffer = await workbook.xlsx.writeBuffer();
        const blob = new Blob([buffer], {
          type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        });

        const fileName = `${name}_고객등록 양식.xlsx`;
        if (window.electron) {
          startDownload(blob, fileName, [{ name: 'Excel Files', extensions: ['xlsx'] }]);
        }
      }
    } catch (error) {
      console.error('엑셀 파일 다운로드 에러 발생 :', error);
      toastService.errorMsg({ text: '엑셀 파일 다운로드에 실패했습니다.' });
    }
  };

  useEffect(() => {
    import('exceljs/dist/exceljs.min.js').then((module) => {
      setExcelJS(module as typeof import('exceljs'));
    });
  }, []);

  return (
    <>
      <input
        ref={fileInputRef}
        type='file'
        accept='.xlsx,.csv'
        className='hidden'
        onChange={onUpload}
      />
      <DownloadFooter
        downloadButton='엑셀 양식 다운로드'
        onDownload={handleDownload}
        uploadButton='엑셀 업로드'
        onUpload={() => {
          modalService.defaultWarning({
            id: 'upload-excel-modal',
            title: '업로드한 고객정보로 교체할까요?',
            contents: (
              <>
                <b>엑셀 업로드시 현재 입력된 고객정보가 사라집니다.</b>
                {
                  "\n입력된 고객정보를 유지하고 싶으신 경우, 입력된 정보를 저장한 후 엑셀 업로드를 진행해 주세요.\n엑셀 업로드를 계속 진행하시려면 '네'를 선택해 주세요."
                }
              </>
            ),
            onConfirm: () => {
              fileInputRef.current?.click();
              modalService.popById('upload-excel-modal');
            },
          });
        }}
        customRightButton={
          <div className='flex items-center gap-10'>
            <div className='flex gap-4'>
              {isTableTemporarySaving && (
                <span className='text-Body13 text-black200'>임시 저장 중..</span>
              )}
            </div>
            <ContainedButton btnColor='blue' onClick={handleSaveFinalUserInfo}>
              확인
            </ContainedButton>
          </div>
        }
        className='sticky right-0 bottom-0'
      />
      {!!status && (
        <Portal
          style={{
            position: 'fixed',
            zIndex: 10000,
            right: '40px',
            bottom: '40px',
            width: '630px',
          }}>
          <ProgressBar
            fileName={`${name}_고객등록 양식.xlsx`}
            status={status}
            percentage={progress}
            onCancelClickCallback={(e) => {
              e.preventDefault();
              e.stopPropagation();

              cancelDownload();
            }}
            onFileOpenClickCallback={(e) => {
              e.preventDefault();
              e.stopPropagation();

              openFileFolder();
            }}
            shouldAutoHide={true}
            onReloadClickCallback={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleDownload();
            }}
          />
        </Portal>
      )}
    </>
  );
}
