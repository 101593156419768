import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type {
  ApiAutomationsElAffectedPatientCountWhenTreatmentTagDeletedParams,
  TreatmentTag,
} from '@apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from '@shared/hooks/use-selected-hospital-info';
import { useQuery } from '@tanstack/react-query';
import { treatmentTagsState } from '@templates/HospitalSetting/containers/ServiceSetting/containers/TreatmentTag/states/hospital-treatment-tags';
import OutlinedButton from 'afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import { modalService } from 'afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { useSetAtom } from 'jotai/index';
import { SHARED_UTILS } from 'utils/utils';

const fetchPatientCountOnTagDeletion = async (
  params: ApiAutomationsElAffectedPatientCountWhenTreatmentTagDeletedParams,
) => {
  const response =
    await apiClient.v3.apiAutomationsElAffectedPatientCountWhenTreatmentTagDeleted(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const removeTagById = (
  tagId: string,
  setTreatmentTags: (updater: (tags: TreatmentTag[]) => TreatmentTag[]) => void,
) => {
  setTreatmentTags((prev) => prev.filter((tag) => tag.tagId !== tagId));
};

const showDeletionWarningModal = (
  affectedPatientCount: number,
  tagId: string,
  setTreatmentTags: (updater: (tags: TreatmentTag[]) => TreatmentTag[]) => void,
) => {
  modalService.defaultWarning({
    title: '치료태그를 삭제할까요?',
    contents: (
      <span className='text-Body12 text-black500'>
        해당 태그가 입력된{' '}
        <span className='text-red500'>{affectedPatientCount}명 고객의 관리자동화가 종료</span>
        됩니다.
      </span>
    ),
    onConfirm: () => {
      removeTagById(tagId, setTreatmentTags);
      modalService.pop();
    },
  });
};

export default function TagRemoveButton(treatmentTag: TreatmentTag) {
  const { tagId } = treatmentTag;
  const { hospitalID } = useSelectedHospitalInfo();
  const setTreatmentTags = useSetAtom(treatmentTagsState);

  const { refetch } = useQuery({
    queryKey: [
      QUERY_KEY.apiAutomationsElAffectedPatientCountWhenTreatmentTagDeleted,
      { hospitalID, treatmentTagId: tagId },
    ] as const,
    enabled: false,
    staleTime: 0,
    gcTime: 0,
    queryFn: ({ queryKey }) => fetchPatientCountOnTagDeletion(queryKey[1]),
  });

  const handleClick = async () => {
    if (tagId.startsWith('temp')) {
      removeTagById(tagId, setTreatmentTags);
    } else {
      try {
        const { data } = await refetch();
        if (data) {
          showDeletionWarningModal(data.affectedPatientCount, tagId, setTreatmentTags);
        }
      } catch (error) {
        console.info('Failed to fetch affected patient count:', error);
      }
    }
  };

  return (
    <OutlinedButton btnColor='secondary' onClick={handleClick}>
      삭제
    </OutlinedButton>
  );
}
