import { popupCreateOrUpdateParamState } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/state/popup-create-or-update-param-state';
import {
  selectedPopupEditContentTextErrorAtom,
  selectedPopupEditTitleErrorAtom,
} from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/state/popup-edit-error-state';
import TextArea from 'afterdoc-design-system/components/Atoms/Input/TextArea/TextArea';
import TextInput from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import { useAtom } from 'jotai';
import { useSetAtom } from 'jotai/index';
import { type ChangeEvent, memo, useEffect } from 'react';

interface PopupContentTextInputProps {
  title?: string;
  content?: string;
  isDisabled: boolean;
}

function PopupContentTextInput({ title, content, isDisabled }: PopupContentTextInputProps) {
  const [popupTitleError, setPopupTitleError] = useAtom(selectedPopupEditTitleErrorAtom);
  const [contentTextError, setContentTextError] = useAtom(selectedPopupEditContentTextErrorAtom);

  const setPopupCreateOrUpdateParam = useSetAtom(popupCreateOrUpdateParamState);

  const clearErrorState = () => {
    setPopupTitleError(null);
    setContentTextError(null);
  };

  const handleTitleInput = (e: ChangeEvent<HTMLInputElement>) => {
    setPopupTitleError(null);
    setPopupCreateOrUpdateParam((prev) => ({ ...prev, title: e.target.value }));
  };

  const handlePopupContentInput = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setContentTextError(null);
    setPopupCreateOrUpdateParam((prev) => ({ ...prev, content: e.target.value }));
  };

  useEffect(() => {
    clearErrorState();
  }, []);

  return (
    <>
      <div className='flex min-h-40 items-start gap-24 py-4'>
        <LabelText isRequired width={76} className='flex h-32 items-center'>
          팝업 제목
        </LabelText>
        <div className='flex flex-grow flex-col gap-4'>
          <TextInput
            className='h-full flex-grow'
            maxLength={200}
            value={title || ''}
            onChange={handleTitleInput}
            disabled={isDisabled}
            placeholder='제목을 입력해 주세요.'
          />
          {!!popupTitleError && <span className='text-Body10 text-red500'>{popupTitleError}</span>}
        </div>
      </div>

      <div className='flex min-h-40 items-start gap-24 py-4'>
        <LabelText isRequired width={76} className='flex h-32 items-center'>
          팝업 내용
        </LabelText>
        <div className='flex flex-grow flex-col gap-4'>
          <TextArea
            onSave={() => {}}
            placeholder='내용을 입력해 주세요.'
            height={300}
            width={700}
            className='w-full'
            maxLength={5000}
            onChange={handlePopupContentInput}
            disabled={isDisabled}
            isShowBottom={false}
            value={content || ''}
          />
          {!!contentTextError && (
            <span className='text-Body10 text-red500'>{contentTextError}</span>
          )}
        </div>
      </div>
    </>
  );
}

export default memo(PopupContentTextInput);
