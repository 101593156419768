// components/ChattingEmptyState/ChattingEmptyState.tsx
import Logo from '@afterdoc-design-system/components/Foundations/Logo/Logo';

interface ChatRoomIDNullProps {
  minWidth: number;
}

export default function ChatRoomIDNull({ minWidth }: ChatRoomIDNullProps) {
  return (
    <div
      className='h-full w-full flex-center flex-col gap-16 bg-blue50'
      style={{ minWidth: `${minWidth}px` }}>
      <Logo name='LogoColorSymbol' size={48} />
      <span className='text-Header16 text-black500'>상담 고객을 선택해주세요.</span>
    </div>
  );
}
