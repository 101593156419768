import dayjs from 'dayjs';

type Payload = {
  [key: string]: string | null | number | boolean | undefined;
  timestamp?: string;
  num?: number;
  userName?: string;
  userRealName?: string;
};

const displayChattingCreated = (payload: Payload): string => {
  const { timestamp } = payload;

  if (timestamp && typeof timestamp === 'string') {
    const korTime = dayjs(new Date(timestamp));
    return korTime.format('A h:mm');
  }
  return '';
};

const displayNotiCreated = (payload: Payload): string => {
  const { timestamp } = payload;
  if (timestamp && typeof timestamp === 'string') {
    const korTime = dayjs(new Date(timestamp));
    return korTime.format('YYYY-MM-DD');
  }
  return '';
};

const displayPrice = (payload: Payload): string => {
  const { num } = payload;

  return `${num?.toLocaleString() ?? ''}원`;
};

const rightUserName = (payload: Payload): string => {
  const { userName, userRealName } = payload;
  if (!userRealName && typeof userName === 'string') {
    return userName;
  }
  if (!userName && !userRealName) {
    return '';
  }
  return `${userName}(${userRealName})`;
};

// Initialize the map with display functions
const infoDisplayMap = new Map<string, (payload: Payload) => string>([
  ['chattingCreated', displayChattingCreated],
  ['notiCreated', displayNotiCreated],
  ['price', displayPrice],
  ['rightUserName', rightUserName],
]);

export const makeChattingInfoStringDisplay = ({
  type,
  payload,
}: { type: string; payload: Payload }): string => {
  const displayFunction = infoDisplayMap.get(type);

  if (displayFunction) {
    return displayFunction(payload);
  }
  return '';
};
