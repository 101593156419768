import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { useQueryClient } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import { useChatMessageContext } from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/components/ChattingMessage/ChatMessageContext';
import { isBookmarkMapState } from 'web/templates/CustomerChat/states/is-bookmark-map';
import Bookmark from './Bookmark';
import Bookmarkempty from './Bookmarkempty';

type postUpdateBookmarkParams = {
  messageId: string;
  isBookmark: boolean;
};

export const postUpdateBookmark = async ({ messageId, isBookmark }: postUpdateBookmarkParams) => {
  const body = {
    _id: messageId,
    isBookmark,
  };
  const response = await apiClient.v3.apiChatElUpdateBookmark(body);
  return response;
};

export default function BookmarkToggle({ isRightMessage }: { isRightMessage: boolean }) {
  const queryClient = useQueryClient();

  const { _id: messageId, isBookmark: initialBookmark } = useChatMessageContext();
  // const { refetch } = useInfiniteChatIsBookmarkData();

  const [bookmarkState, setBookmarkState] = useAtom(isBookmarkMapState);

  // 메시지별로 상태가 관리되도록 현재 messageId에 해당하는 상태를 가져오고, 없으면 initialBookmark로 설정
  const isBookmark = bookmarkState[messageId] ?? initialBookmark;

  const handleBookmarkClick = async () => {
    const bookmarkName = !isBookmark ? '등록' : '해제';
    try {
      await postUpdateBookmark({ messageId, isBookmark: !isBookmark });
      setBookmarkState((prev) => ({
        ...prev,
        [messageId]: !isBookmark,
      }));

      await queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.apiChatElFind],
      });

      toastService.successMsg({ text: `북마크를 ${bookmarkName} 했습니다.` });
    } catch (error) {
      console.error('Failed to update bookmark:', error);
      toastService.errorMsg({ text: `북마크를 ${bookmarkName}를 실패 했습니다.` });
    }
  };

  const viewBox = isRightMessage ? '0 0 13 24' : '0 0 24 24';

  return isBookmark ? (
    <Bookmark className='cursor-pointer' viewBox={viewBox} onClick={handleBookmarkClick} />
  ) : (
    <Bookmarkempty
      className='cursor-pointer opacity-0 transition-opacity duration-200 group-hover:opacity-100'
      onClick={handleBookmarkClick}
      viewBox={viewBox}
    />
  );
}
