import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import Toggle from '@afterdoc-design-system/components/Atoms/Toggle/Toggle';
import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import TextFieldSelectBoxDropdown from '@afterdoc-design-system/components/Molecules/TextSelect/TextFieldSelectBoxDropdown';
import { SHARED_UTILS } from '@shared-utils/utils';
import { useMutation } from '@tanstack/react-query';
import { useEffect, useRef, useState } from 'react';
import { apiClient } from 'web/apis/instances/api-client';
import type {
  ApplyingCommonAlgorithmHandlerBodyRequest,
  CommonAlgorithm,
} from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { generateDaysArray } from 'web/templates/Automation/containers/shared/functions/generate-days-array';

const postApplyingCommonAlgorithm = async (params: ApplyingCommonAlgorithmHandlerBodyRequest) => {
  const response = await apiClient.v3.applyingCommonAlgorithmHandler(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

interface ConsultationAlgorithmItemProps {
  consultationAlgorithm: Exclude<CommonAlgorithm['consultationAlgorithms'], undefined>[0];
}

export default function ConsultationAlgorithmItem({
  consultationAlgorithm: { isCommonAlgorithmEnabled, sendingDaysAfterStart, _id, name },
}: ConsultationAlgorithmItemProps) {
  const { hospitalID } = useSelectedHospitalInfo();

  const [isChecked, setIsChecked] = useState(true);
  const dropdownOptions = useRef<string[]>(
    generateDaysArray({
      endDay: 365,
    }),
  );

  const [isDropdownToggled, setIsDropdownToggled] = useState(false);

  const postApplyingCommonAlgorithmMutation = useMutation({
    mutationFn: postApplyingCommonAlgorithm,
  });

  const customFocusScrollHandler = (focusedIndex: number) => focusedIndex - 2;
  const customSelectedScrollHandler = (selectedIndex: number) => selectedIndex - 2;

  useEffect(() => {
    setIsChecked(isCommonAlgorithmEnabled ?? false);
  }, [isCommonAlgorithmEnabled]);

  return (
    <div key={_id} className='flex w-full justify-between'>
      <div>
        <div className='py-7 text-Body12 text-black200'>{name}</div>
        <div className='flex items-center gap-10'>
          <Icon name='arrow-right-bottom' size={20} color='black200' />
          <TextFieldSelectBoxDropdown
            width={100}
            options={dropdownOptions.current}
            onSelect={(dropdownIndex) => {
              setIsDropdownToggled(!isDropdownToggled);
              postApplyingCommonAlgorithmMutation.mutate(
                {
                  consultationAlgorithms: [
                    {
                      _id,
                      sendingDaysAfterStart: dropdownIndex + 1,
                      isCommonAlgorithmEnabled: isChecked,
                    },
                  ],
                  hospitalID,
                },
                {
                  onSuccess: (data) => {
                    if (data?.isAppliedSuccess) {
                      toastService.successMsg({
                        text: '자동화가 변경되었습니다.',
                      });
                    }
                  },
                },
              );
            }}
            disabled={!isChecked}
            selectedIndex={(sendingDaysAfterStart ?? 60) - 1}
            focusedIndex={(sendingDaysAfterStart ?? 60) - 1}
            customFocusScrollHandler={customFocusScrollHandler}
            customSelectedScrollHandler={customSelectedScrollHandler}
          />
          <div className='text-Body12 text-black200'>에 발송합니다.</div>
        </div>
      </div>
      <Toggle
        toggleSize='small'
        checked={isChecked}
        onChange={(e) => {
          setIsChecked(e.target.checked);
          postApplyingCommonAlgorithmMutation.mutate(
            {
              consultationAlgorithms: [
                {
                  _id,
                  sendingDaysAfterStart: sendingDaysAfterStart ?? 60,
                  isCommonAlgorithmEnabled: e.target.checked,
                },
              ],
              hospitalID,
            },
            {
              onSuccess: (data) => {
                if (data?.isAppliedSuccess) {
                  toastService.successMsg({
                    text: '자동화가 변경되었습니다.',
                  });
                }
              },
            },
          );
        }}
      />
    </div>
  );
}
