import { useUserInfo } from 'web/shared/hooks/use-user-info';
import { AUTH_CONTROL_OPTIONS, OPTIONS } from '../constants/dropdown';

export function useDropdownData() {
  const { authorizationTypeID } = useUserInfo();
  const isAuthoriztionControlPatientChatting = authorizationTypeID?.canControlPatientChatting;

  const data = isAuthoriztionControlPatientChatting ? AUTH_CONTROL_OPTIONS : OPTIONS;

  return { data, isAuthoriztionControlPatientChatting };
}
