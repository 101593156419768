import LayoutBox from '@shared/components/LayoutBox/LayoutBox';
import { useNavigationBlocker } from '@shared/hooks/use-navigation-blocker';
import { useUserInfo } from '@shared/hooks/use-user-info';
import { isServiceManagerNavigatorVisibleState } from '@shared/states/is-service-manager-navigator-visible';
import { Color } from '@tailwind-base/styles/color';
import PopupContentImage from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/components/PopupManageDialog/components/PopupContentImage';
import PopupContentTextInput from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/components/PopupManageDialog/components/PopupContentTextInput';
import PopupCreateDateLabel from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/components/PopupManageDialog/components/PopupCreateDateLabel';
import PopupManagePageFooter from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/components/PopupManageDialog/components/PopupManagePageFooter';
import PopupNameInput from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/components/PopupManageDialog/components/PopupNameInput';
import PopupNoticePicker from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/components/PopupManageDialog/components/PopupNoticePicker/PopupNoticePicker';
import PopupPeriodSelector from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/components/PopupManageDialog/components/PopupPeriodSelector/PopupPeriodSelector';
import PopupPreview from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/components/PopupManageDialog/components/PopupPreview';
import PopupTargetSelector from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/components/PopupManageDialog/components/PopupTargetSelector';
import PopupTypeRadio from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/components/PopupManageDialog/components/PopupTypeRadio';
import { DEFAULT_POPUP_PARAMS } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/constants/default-popup-params';
import { convertHospitalPopupToParams } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/functions/convert-hospital-popup-to-params';
import { popupCreateOrUpdateParamState } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/state/popup-create-or-update-param-state';
import { popupEditErrorState } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/state/popup-edit-error-state';
import type { CreateUpdatePopupParamType } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/types/create-update-popup-param-type';
import { contentEditState } from '@templates/Content/containers/ContentManager/states/content-edit-state';
import Divider from 'afterdoc-design-system/components/Atoms/Divider/Divider';
import BaseLoading from 'afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import Title from 'afterdoc-design-system/components/Molecules/Title/Title';
import Portal from 'afterdoc-design-system/shared/Portal/Portal';
import dayjs from 'dayjs';
import { useAtom, useAtomValue } from 'jotai/index';
import { useResetAtom } from 'jotai/utils';
import { isEqual } from 'lodash-es';
import { useEffect, useMemo, useRef, useState } from 'react';

export default function PopupManageDialogContainer() {
  const [popupCreateOrUpdateParam, setPopupCreateOrUpdateParams] = useAtom(
    popupCreateOrUpdateParamState,
  );
  const { authorizationTypeID } = useUserInfo();

  const contentEdit = useAtomValue(contentEditState);
  const isServiceManagerNavigatorVisible = useAtomValue(isServiceManagerNavigatorVisibleState);

  const resetPopupCreateOrUpdateParam = useResetAtom(popupCreateOrUpdateParamState);
  const resetPopupEditError = useResetAtom(popupEditErrorState);

  const [initCreateOrUpdateParam, setInitCreateOrUpdateParam] = useState<
    CreateUpdatePopupParamType | undefined
  >(undefined);

  const [isInitialized, setIsInitialized] = useState(false);
  const [isModified, setIsModified] = useState(false);

  const contentImageRef = useRef<string | undefined>();

  const canUpsertContent = !!authorizationTypeID?.canUpsertContent;

  const { hospitalNoticeID, name, startDate, endDate, popupType, filter, title, content } =
    popupCreateOrUpdateParam;

  const formattedDate = useMemo(() => {
    if (contentEdit.id) {
      const editData = contentEdit.data;
      if (editData?.editType === 'popup' && editData.createdAt) {
        return dayjs(editData.createdAt).isValid()
          ? dayjs(editData.createdAt).format('YYYY-MM-DD')
          : '-';
      }
      return '-';
    }
    return dayjs().format('YYYY-MM-DD');
  }, [contentEdit]);

  useEffect(() => {
    if (contentEdit.id && contentEdit.data?.editType === 'popup') {
      const popupData = convertHospitalPopupToParams(contentEdit.data);
      setPopupCreateOrUpdateParams(popupData);
      setInitCreateOrUpdateParam(popupData);
    } else {
      setInitCreateOrUpdateParam(DEFAULT_POPUP_PARAMS);
    }
    setIsInitialized(true);
  }, [contentEdit]);

  useEffect(() => {
    if (!contentEdit.id || contentEdit.data?.editType !== 'popup') return;
    if (contentImageRef.current === undefined) {
      const { fileIDs } = contentEdit.data;
      if (fileIDs && fileIDs.length > 0) {
        if (fileIDs[0].paths && fileIDs[0].paths.length > 0) {
          contentImageRef.current = fileIDs[0].paths[0];
        }
      }
    }
  }, [contentEdit.data]);

  useEffect(() => {
    const isEquals = isEqual(initCreateOrUpdateParam, popupCreateOrUpdateParam);
    setIsModified(!isEquals);
  }, [initCreateOrUpdateParam, popupCreateOrUpdateParam]);

  useEffect(() => {
    return () => {
      resetPopupCreateOrUpdateParam();
      resetPopupEditError();
    };
  }, []);

  useNavigationBlocker({
    shouldBlock: isModified,
  });

  if (!isInitialized) {
    return (
      <div className='min-h-screen w-full flex-center'>
        <BaseLoading />
      </div>
    );
  }

  return (
    <Portal
      style={{
        position: 'fixed',
        top: 0,
        left: isServiceManagerNavigatorVisible ? '140px' : '70px',
        zIndex: 100,
        right: 0,
        bottom: 0,
        backgroundColor: Color.blueLight,
      }}>
      <div className='flex min-h-screen w-full flex-col'>
        <div className='mx-auto flex w-[1400px]'>
          <div className='w-[1000px] bg-white50'>
            <Title title={`팝업 ${contentEdit.id ? '수정' : '등록'}하기`} />
            <div className='h-[calc(100vh-120px)]'>
              <Scrollbar disabledX>
                <div className='h-full w-full px-100 py-40'>
                  <LayoutBox direction='vertical' size={10} width={800}>
                    <PopupCreateDateLabel date={formattedDate} />
                    <PopupNameInput name={name} isDisabled={!canUpsertContent} />
                    <PopupPeriodSelector
                      startDate={startDate}
                      endDate={endDate}
                      isDisabled={!canUpsertContent}
                    />
                    <Divider type='line' />
                  </LayoutBox>
                  <LayoutBox noStyle>
                    <PopupTargetSelector filter={filter} isDisabled={!canUpsertContent} />
                    <Divider type='line' />
                  </LayoutBox>
                  <LayoutBox noStyle>
                    <PopupNoticePicker
                      hospitalNoticeID={hospitalNoticeID}
                      isDisabled={!canUpsertContent}
                    />
                    <Divider type='line' />
                  </LayoutBox>
                  <LayoutBox direction='vertical' size={10} className='my-10'>
                    <PopupTypeRadio type={popupType} isDisabled={!canUpsertContent} />
                    {popupType === 'text' && (
                      <PopupContentTextInput
                        title={title}
                        content={content}
                        isDisabled={!canUpsertContent}
                      />
                    )}
                    {popupType === 'image' && (
                      <PopupContentImage
                        contentImage={contentImageRef.current}
                        isDisabled={!canUpsertContent}
                      />
                    )}
                  </LayoutBox>
                </div>
              </Scrollbar>
            </div>
          </div>
          <PopupPreview
            popupType={popupType}
            title={title}
            content={content}
            contentImage={contentImageRef.current}
          />
        </div>
        <PopupManagePageFooter isModified={isModified} />
      </div>
    </Portal>
  );
}
