type CreateTopPositionParams = {
  top: number;
  dropdownHeight: number;
  position: string;
};

export const createTopPosition = ({ top, dropdownHeight, position }: CreateTopPositionParams) => {
  const adjustedTopPosition = position === 'top' ? -dropdownHeight : 0;
  const calculatedTopPosition = top + adjustedTopPosition;

  return calculatedTopPosition;
};
