import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type { ServiceSettingSimple, TreatmentTag } from '@apis/swaggers/swagger-docs';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { serviceSettingState } from '@templates/HospitalSetting/containers/ServiceSetting/containers/TreatmentTag/states/service-setting-state';
import type { PatientsField } from '@templates/HospitalSetting/types/PatientsField';
import { useAtomValue } from 'jotai';
import { selectAtom } from 'jotai/utils';
import { isEqual } from 'lodash-es';
import { SHARED_UTILS } from 'utils/utils';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';

type RemoveTagParams = NonNullable<Parameters<typeof apiClient.v3.apiTreatmentTagsElRemove>[0]>;
type UpdateTagParams = NonNullable<Parameters<typeof apiClient.v3.apiTreatmentTagsElUpdate>[0]>;
type CreateTagParams = NonNullable<Parameters<typeof apiClient.v3.apiTreatmentTagsElCreate>[0]>;

const updateHospitalServiceSetting = async (params: ServiceSettingSimple) => {
  const response = await apiClient.v3.apiServiceSettingsElUpdate(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const removeTreatmentTag = async (params: RemoveTagParams) => {
  const response = await apiClient.v3.apiTreatmentTagsElRemove(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const updateTreatmentTag = async (params: UpdateTagParams) => {
  const response = await apiClient.v3.apiTreatmentTagsElUpdate(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const createTreatmentTag = async (params: CreateTagParams[]) => {
  const response = await apiClient.v3.multipleTreatmentTagCreationHandler({ tags: params });
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const getParams = <T extends CreateTagParams | UpdateTagParams>(
  tag: TreatmentTag,
  basicPalette: string[] | undefined,
  hospitalID: string,
) => {
  const params: CreateTagParams | UpdateTagParams = tag.tagId.startsWith('temp')
    ? ({} as CreateTagParams)
    : ({ tcID: tag.tagId } as UpdateTagParams);

  params.categoryName = tag.name ?? '';
  params.hospitalID = hospitalID;

  const basicPaletteIndex = basicPalette?.findIndex((palette) => palette === tag.color) ?? -1;

  if (basicPaletteIndex >= 0) {
    params.paletteIndex = basicPaletteIndex;
    params.isUsingCustomColor = false;
  } else {
    params.customColor = tag.color;
    params.isUsingCustomColor = true;
  }
  return params as T;
};

const serviceSettingSelectionAtom = selectAtom(serviceSettingState, (setting) => ({
  id: setting?._id,
  basicPalette: setting?.colorPalette?.basicPalette,
}));

export const useServiceSettingUpdate = () => {
  const { hospitalID } = useSelectedHospitalInfo();
  const queryClient = useQueryClient();

  const serviceSettingSelection = useAtomValue(serviceSettingSelectionAtom);
  const updateServiceSettingMutation = useMutation({
    mutationFn: (params: ServiceSettingSimple) => updateHospitalServiceSetting(params),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.apiServiceSettingsElToBeDisplayedPatientsFields],
      });
    },
  });

  const removeTreatmentTagMutation = useMutation({
    mutationFn: (params: RemoveTagParams) => removeTreatmentTag(params),
  });
  const updateTreatmentTagMutation = useMutation({
    mutationFn: (params: UpdateTagParams) => updateTreatmentTag(params),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiTreatmentTagsEl, { hospitalID }] });
    },
  });
  const createTreatmentTagMutation = useMutation({
    mutationFn: (params: CreateTagParams[]) => createTreatmentTag(params),
    onSuccess: (data) => {
      if (data.isCreatedSuccess) {
        queryClient.invalidateQueries({
          queryKey: [
            QUERY_KEY.apiManualsElFolders,
            {
              hospitalID,
            },
          ],
        });
      }
    },
  });

  const hasInvalidOrDuplicateTreatmentTags = (treatmentTags: TreatmentTag[]) => {
    const idSet = new Set<string>();
    for (const tag of treatmentTags.slice(1)) {
      if (!tag.name) {
        return true;
      }
      idSet.add(tag.name);
    }
    return treatmentTags.length - 1 !== idSet.size;
  };

  const updatePatientField = async (initialValue: PatientsField, targetValue: PatientsField) => {
    const { usingFieldNames, ...filteredParams } = targetValue;
    if (!isEqual(initialValue, targetValue)) {
      return await updateServiceSettingMutation.mutateAsync({
        _id: serviceSettingSelection?.id ?? '',
        hospitalID,
        ...filteredParams,
      });
    }
  };

  const syncTreatmentTags = async (initialValue: TreatmentTag[], targetValue: TreatmentTag[]) => {
    if (!isEqual(initialValue, targetValue)) {
      const createdTags = [];
      const updatedTags = [];
      const removedTags = [];

      for (const tag of targetValue.slice(1)) {
        if (tag.tagId.startsWith('temp')) {
          createdTags.push(tag);
        } else {
          const originalObj = initialValue.find((initTag) => initTag.tagId === tag.tagId);
          if (originalObj && !isEqual(originalObj, tag)) {
            updatedTags.push(tag);
          }
        }
      }

      for (const initTag of initialValue) {
        const existsInTarget = targetValue.some((tag) => tag.tagId === initTag.tagId);
        if (!existsInTarget) {
          removedTags.push(initTag);
        }
      }

      if (removedTags.length > 0) {
        await Promise.all(
          removedTags.map((tag) => {
            return removeTreatmentTagMutation.mutateAsync({ tcID: tag.tagId });
          }),
        );
      }

      if (updatedTags.length > 0) {
        await Promise.all(
          updatedTags.map((tag) => {
            return updateTreatmentTagMutation.mutateAsync(
              getParams(tag, serviceSettingSelection.basicPalette, hospitalID),
            );
          }),
        );
      }

      if (createdTags.length > 0) {
        const paramsArray: CreateTagParams[] = createdTags
          .reverse()
          .map((tag) => getParams(tag, serviceSettingSelection.basicPalette, hospitalID));

        await createTreatmentTagMutation.mutateAsync(paramsArray);
      }

      await Promise.all([
        queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiTreatmentTagsEl] }),
        queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiServiceSettingsElFindOne] }),
      ]);
    }
  };

  return {
    hasInvalidOrDuplicateTreatmentTags,
    updatePatientField,
    syncTreatmentTags,
  };
};
