import type { ChatFileAndContentIDs } from 'web/apis/swaggers/swagger-docs';
import ChattingMessage from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/components/ChattingMessage/ChattingMessage';
import DateDivider from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/components/DateDivider/DateDivider';
import { filterLastMessage } from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/functions/filter-last-message';
import { useChatRoomPatientUnreadCount } from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/hooks/use-chat-room-patient-unread-count';
import { useDateDivider } from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/hooks/use-date-divider';
import { SORT_WAY } from 'web/templates/CustomerChat/components/ChattingRoom/constants/message-sort-way';
import { USER_TYPE_CODE } from './components/ChattingMessage/constants/message';
import { useRefetchOnMessagesChange } from './hooks/use-refetch-on-messages-change';

type ChattingContentProps = {
  messages: Array<ChatFileAndContentIDs & { isUnread: boolean; status: string }>;
};

export default function ChattingContent({ messages }: ChattingContentProps) {
  const { refetch } = useChatRoomPatientUnreadCount();
  const sendMessagePosition = SORT_WAY === -1 ? 0 : messages.length - 1;

  const { shouldShowDate } = useDateDivider();

  useRefetchOnMessagesChange({ refetch });

  return (
    <>
      {messages.map(
        (
          {
            _id,
            isBookmark,
            chatRoomID,
            sentAt,
            isTranslated,
            createdAt,
            content,
            videoContentIDs,
            userID,
            userName,
            type,
            userType,
            fileIDs,
            heventID,
            userRealName,
            haftercareID,
            hnoticeID,
            translatedContent,
            retranslatedContent,
            isUnread,
            status,
          },
          index,
        ) => {
          const dividerLineDate = shouldShowDate({ index, createdAt });

          const isLeft = userType === USER_TYPE_CODE.PATIENT; // 1000: 환자

          const isLastMessage = filterLastMessage(sendMessagePosition, index);

          return (
            <div key={`${_id}-${index + 1}`} className='flex flex-col gap-y-20 pb-20'>
              <ChattingMessage
                _id={_id}
                chatRoomID={chatRoomID}
                sentAt={sentAt}
                isBookmark={isBookmark}
                isUnread={isUnread}
                isLeft={isLeft}
                isLastMessage={isLastMessage}
                index={index}
                isTranslated={isTranslated}
                content={content}
                createdAt={createdAt ?? ''}
                userID={userID}
                userName={userName}
                type={type}
                userType={userType}
                userRealName={userRealName}
                fileIDs={fileIDs}
                hnoticeID={hnoticeID}
                heventID={heventID}
                videoContentIDs={videoContentIDs}
                haftercareID={haftercareID}
                translatedContent={translatedContent}
                retranslatedContent={retranslatedContent}
                status={status}
              />

              {dividerLineDate && <DateDivider dividerLineDate={dividerLineDate} />}
            </div>
          );
        },
      )}
    </>
  );
}
