import ContainedButton from '@afterdoc-design-system/components/Atoms/Button/ContainedButton';
import Radio from '@afterdoc-design-system/components/Atoms/Radio/Radio';
import { RadioGroup as DesignSystemRadioGroup } from '@afterdoc-design-system/components/Atoms/Radio/RadioGroup';
import Dialog from '@afterdoc-design-system/components/Molecules/Dialog/Dialog';
import Portal from '@afterdoc-design-system/shared/Portal/Portal';
import { useAtom, useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import type { MessageInput } from 'web/apis/swaggers/swagger-docs';
import type { CounselAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/components/RegisterCounselAutomationDialogContent';
import { MESSAGE_OPTIONS } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/constants/counsel-message-options';
import {
  selectedContentTabIndexState,
  selectedMessageState,
} from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-form';
import { showMessageTypeDialogState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-type-dialog';

interface MessageTypeRadioContentProps {
  type: 'create' | 'edit';
  onCloseMessageTypeDialog: () => void;
}

export default function MessageTypeDialog({ ...props }: MessageTypeRadioContentProps) {
  const setShowMessageTypeDialog = useSetAtom(showMessageTypeDialogState);

  return (
    <Portal>
      <Dialog
        id='message-type-dialog'
        wrapperClassName='h-fit'
        contents={<RadioGroup {...props} />}
        titleProps={{
          title: '발송타입',
        }}
        width={420}
        hasCloseButton={false}
        onClose={() => {
          setShowMessageTypeDialog((prev) => ({
            ...prev,
            isShow: false,
          }));
        }}
      />
    </Portal>
  );
}

interface RadioOptionProps {
  id: MessageInput['sendingType'];
  label: string;
  description: string;
  checkedId: MessageInput['sendingType'];
  value: MessageInput['sendingType'];
}

const RadioOption = ({ id, label, description }: Omit<RadioOptionProps, 'value' | 'checkedId'>) => {
  return (
    <div>
      <Radio
        wrapperClassName='py-0'
        label={<div className='text-Header14'>{label}</div>}
        id={id}
        value={id}
      />
      <p className='mt-2 whitespace-pre-wrap pl-34 text-Body12 text-black200'>{description}</p>
    </div>
  );
};

interface RadioGroupProps extends MessageTypeRadioContentProps {}

const RadioGroup = ({ type, onCloseMessageTypeDialog }: RadioGroupProps) => {
  const { getValues, setValue } = useFormContext<CounselAutomationAPIFormValues>();

  const resetSelectedContentTabIndex = useResetAtom(selectedContentTabIndexState);

  const [selectedMessage, setSelectedMessage] = useAtom(selectedMessageState);
  const setSelectedContentTabIndex = useSetAtom(selectedContentTabIndexState);

  const [checkedId, setCheckedId] = useState<MessageInput['sendingType']>(
    selectedMessage?.sendingType || 'BASIC',
  );

  const handleUpdateMessageSendingType = () => {
    if (type === 'edit') {
      const prevMessages = getValues('messages') || [];

      if (!prevMessages.length || !selectedMessage) return;

      setSelectedMessage({
        ...selectedMessage,
        sendingType: checkedId,
      });
      setSelectedContentTabIndex((prev) => {
        return {
          ...prev,
          [selectedMessage.index]: 0,
        };
      });

      setValue(`messages.${selectedMessage.index}.sendingType`, checkedId);
      setValue(`messages.${selectedMessage.index}.content`, {
        text: '',
      });
    }

    if (type === 'create') {
      const prevMessages = getValues('messages') || [];
      const newMessage: MessageInput = {
        content: {
          text: undefined,
        },
        name: `메시지 ${prevMessages.length + 1}`,
        daysAfter: (prevMessages[prevMessages.length - 1]?.daysAfter || 0) + 1,
        timeOfDay: '08:30',
        sendingType: checkedId,
        showConsultationGuide: false,
      };

      setSelectedMessage({
        ...newMessage,
        index: prevMessages.length,
      });
      setSelectedContentTabIndex((prev) => ({
        ...prev,
        [prevMessages.length]: 0,
      }));
      setValue('messages', [...prevMessages, newMessage]);
    }

    onCloseMessageTypeDialog();
  };

  useEffect(() => {
    if (type === 'create') {
      setCheckedId('BASIC');
    } else if (selectedMessage && type === 'edit') {
      setCheckedId(selectedMessage.sendingType);
    }
  }, [type, selectedMessage]);

  return (
    <>
      <div className='flex flex-col gap-10 px-20 py-5'>
        <DesignSystemRadioGroup
          name='messageSendingType'
          value={checkedId}
          onChange={(value) => setCheckedId(value as MessageInput['sendingType'])}>
          {MESSAGE_OPTIONS.map((option) => (
            <RadioOption
              key={option.id}
              id={option.id}
              description={option.description}
              label={option.label}
            />
          ))}
        </DesignSystemRadioGroup>
      </div>
      <div className='flex w-full flex-col gap-8 p-20'>
        <ContainedButton
          className='w-full'
          btnColor='blue'
          onClick={handleUpdateMessageSendingType}>
          선택
        </ContainedButton>
        <ContainedButton className='w-full' btnColor='secondary' onClick={onCloseMessageTypeDialog}>
          닫기
        </ContainedButton>
      </div>
    </>
  );
};
