import Checkbox from '@afterdoc-design-system/components/Atoms/Checkbox/Checkbox';
import FilledTag from '@afterdoc-design-system/components/Atoms/Tag/FilledTag';
import { forwardRef, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { filterWrapperClassName } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/components/CustomerManagementEditableTableHeader/constants/class';
import type { EditableHeaderFilterState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/states/editable-header-filter';
import type { ReadOnlyHeaderFilterState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementReadOnlyTable/states/read-only-header-filter';
import type { UnTaggedHeaderFilterState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/UnTaggedCustomerManagement/containers/UnTaggedCustomerManagementEditableTable/states/editable-header-filter';

const GenderFilter = forwardRef<HTMLDivElement>((_, ref) => {
  const { setValue, watch } = useFormContext<
    EditableHeaderFilterState | ReadOnlyHeaderFilterState | UnTaggedHeaderFilterState
  >();
  const gender = watch('gender');

  const [checkState, setCheckState] = useState<{ MALE: boolean; FEMALE: boolean }>({
    MALE: gender === 'MALE',
    FEMALE: gender === 'FEMALE',
  });

  const handleCheckboxChange = (gender: 'MALE' | 'FEMALE') => {
    const newState = { ...checkState, [gender]: !checkState[gender] };
    setCheckState(newState);

    if (newState.MALE && !newState.FEMALE) {
      setValue('gender', 'MALE');
    } else if (!newState.MALE && newState.FEMALE) {
      setValue('gender', 'FEMALE');
    } else {
      setValue('gender', undefined);
    }
  };

  return (
    <div className={`${filterWrapperClassName} w-[188px]`} ref={ref}>
      <div className='p-16'>
        <div className='mb-10 text-Caption9 text-black200'>
          *체크한 값에 해당하는 고객만 표시됩니다.
        </div>
        <Checkbox
          label={
            <FilledTag className='cursor-pointer' tagSize='small'>
              남성
            </FilledTag>
          }
          checked={checkState.MALE}
          onChange={() => handleCheckboxChange('MALE')}
        />
        <Checkbox
          label={
            <FilledTag className='cursor-pointer' tagSize='small'>
              여성
            </FilledTag>
          }
          checked={checkState.FEMALE}
          onChange={() => handleCheckboxChange('FEMALE')}
        />
      </div>
    </div>
  );
});

export default GenderFilter;
