import LabelText from '@afterdoc-design-system/components/Atoms/LabelText/LabelText';
import Radio from '@afterdoc-design-system/components/Atoms/Radio/Radio';
import { useAtomValue } from 'jotai';
import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import TextEditor from 'web/shared/components/TextEditor/TextEditor';
import type { CounselAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/components/RegisterCounselAutomationDialogContent';
import { selectedMessageIndexState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-form';

export default function ShowCounsellingOrNotForm() {
  const { setValue, watch } = useFormContext<CounselAutomationAPIFormValues>();

  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);

  const consultationGuide = watch(`messages.${selectedMessageIndex}.consultationGuide`);
  const showConsultationGuide = watch(`messages.${selectedMessageIndex}.showConsultationGuide`);

  const handleUpdateConsultationGuide = useCallback(
    (value: string) => {
      setValue(`messages.${selectedMessageIndex}.consultationGuide`, value);
    },
    [selectedMessageIndex, setValue],
  );

  return (
    <>
      <div className='mt-20 flex w-full items-center justify-between'>
        <LabelText width='fit-content' isRequired>
          상담가이드
        </LabelText>
        <span className='text-Body10 text-black200'>
          고객의 응답에 대한 상담가이드를 설정하여, 응대시 참고할 수 있습니다.
        </span>
      </div>
      <div className='mt-10 flex flex-col gap-6'>
        <Radio
          label='사용 안 함'
          checked={!showConsultationGuide}
          onChange={() => {
            setValue(`messages.${selectedMessageIndex}.showConsultationGuide`, false);
            setValue(`messages.${selectedMessageIndex}.consultationGuide`, undefined);
          }}
        />
        <Radio
          label='사용함'
          checked={showConsultationGuide}
          onChange={() => {
            setValue(`messages.${selectedMessageIndex}.showConsultationGuide`, true);
          }}
        />
        {showConsultationGuide && (
          <TextEditor
            height={150}
            value={consultationGuide}
            onChange={handleUpdateConsultationGuide}
          />
        )}
      </div>
    </>
  );
}
