import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type { ApiNoticeHospitalElCountParams } from '@apis/swaggers/swagger-docs';
import NoticeListBody from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Notice/components/NoticeList/components/NoticeListBody/NoticeListBody';
import {
  noticeListKeywordAtom,
  noticeListQueryKeyState,
} from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Notice/states/notice-list-query-key-state';
import { noticeTotalCountState } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Notice/states/notice-total-count-state';
import { NOTICE_LIST_COLUMNS } from '@templates/Content/containers/ContentManager/containers/NoticePopup/constants/notice-popup-list-columns';
import NoticePopupListSection from '@templates/Content/containers/ContentManager/containers/NoticePopup/shared/components/NoticePopupListSection/NoticePopupListSection';
import ContentListCommonFooter from '@templates/Content/containers/ContentManager/shared/components/ContentListCommonFooter/ContentListCommonFooter';
import ContentSearchHeader from '@templates/Content/containers/ContentManager/shared/components/ContentSearchHeader/ContentSearchHeader';
import { useResetAtom } from 'jotai/utils';
import React, { useEffect } from 'react';
import { SHARED_UTILS } from 'utils/utils';

const fetchNoticeCount = async (params: ApiNoticeHospitalElCountParams) => {
  const response = await apiClient.v3.apiNoticeHospitalElCount(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export default function NoticeListContainer() {
  const resetNoticeListQueryKey = useResetAtom(noticeListQueryKeyState);

  useEffect(() => resetNoticeListQueryKey(), []);

  return (
    <div className='flex min-h-screen w-full flex-col'>
      <div className='mx-auto w-[1150px] flex-col-center flex-grow bg-white50'>
        <ContentSearchHeader
          queryKey={QUERY_KEY.apiNoticeHospitalElCount}
          fetchCount={fetchNoticeCount}
          totalCountState={noticeTotalCountState}
          keywordState={noticeListKeywordAtom}
          inputPlaceholder='공지사항 제목으로 검색해 주세요.'
        />
        <div className='flex h-full w-full flex-grow flex-col'>
          <NoticePopupListSection
            columns={NOTICE_LIST_COLUMNS}
            queryKeyState={noticeListQueryKeyState}
            totalCountState={noticeTotalCountState}>
            <NoticeListBody />
          </NoticePopupListSection>
        </div>
      </div>
      <ContentListCommonFooter type={'notice'} />
    </div>
  );
}
