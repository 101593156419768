import Title from 'afterdoc-design-system/components/Molecules/Title/Title';

const TITLE = '치료태그';
const SUB_TITLE = '등록된 치료태그를 바탕으로 관리자동화 및 원내 매뉴얼을 관리할 수 있습니다.';

export default function TreatmentTagTitle() {
  return (
    <>
      <Title title={TITLE} subTitle={SUB_TITLE} />
    </>
  );
}
