import TextArea from '@afterdoc-design-system/components/Atoms/Input/TextArea/TextArea';
import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { SHARED_UTILS } from '@shared-utils/utils';
import { useMutation, useQueryClient, useSuspenseQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useSetAtom } from 'jotai';
import type React from 'react';
import { useEffect, useMemo, useState } from 'react';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ApiV2ReservationusernoteElFindParams } from 'web/apis/swaggers/swagger-docs';
import { usePatientDetailInfo } from 'web/shared/hooks/use-get-patient-detail-info';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { isThereSomeThingToSaveState } from 'web/shared/states/is-there-some-thing-to-save';

const getReservationUserNote = async (params: ApiV2ReservationusernoteElFindParams) => {
  const response = await apiClient.v3.apiV2ReservationusernoteElFind(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const postReservationUserNote = async (
  params: ApiV2ReservationusernoteElFindParams,
  text: string,
) => {
  const body = {
    ...params,
    content: text,
  };
  const response = await apiClient.v3.apiV2ReservationusernoteElUpdate(body);
  return response;
};

export default function TreatmentHistoryContainer() {
  const { hospitalID } = useSelectedHospitalInfo();
  const { patientId } = usePatientDetailInfo();

  const params: ApiV2ReservationusernoteElFindParams = useMemo(
    () => ({
      hospitalID,
      userID: patientId,
      hserviceID: patientId,
    }),
    [hospitalID, patientId],
  );

  const queryClient = useQueryClient();

  const { data } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiV2ReservationusernoteElFind, params] as const,
    queryFn: ({ queryKey }) => getReservationUserNote(queryKey[1]),
  });

  const mutation = useMutation({
    mutationFn: (text: string) => postReservationUserNote(params, text),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.apiV2ReservationusernoteElFind, params],
      });
      toastService.successMsg({ text: '치료내역을 저장했습니다.' });
      setOriginalText(text);
    },
    onError: (error) => {
      console.error('Error saving memo:', error);
      toastService.errorMsg({ text: '치료내역 저장을 실패했습니다.' });
    },
  });

  const { content, updatedAt, modifierID } = data[0];

  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(false);
  const [text, setText] = useState<string>();
  const [originalText, setOriginalText] = useState<string>();

  const setIsThereSomeThingToSave = useSetAtom(isThereSomeThingToSaveState);

  const bottomText = dayjs(updatedAt).isValid()
    ? `${dayjs(updatedAt).format('YYYY-MM-DD HH:mm')}/${modifierID?.realName ?? '-'}`
    : undefined;

  const handleSave = () => {
    mutation.mutate(text ?? '');
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  };

  const handleInput = (event: React.FormEvent<HTMLTextAreaElement>) => {
    setText(event.currentTarget.value);
  };

  useEffect(() => {
    if (!data) return;
    setText(content);
    setOriginalText(content);
  }, [data]);

  useEffect(() => {
    if (!text || !content) return;
    setIsSaveButtonDisabled(text.trim().length === 0 || text === content);
  }, [text, content]);

  useEffect(() => {
    if (text !== originalText) {
      setIsThereSomeThingToSave((prev) => ({
        ...prev,
        CUSTOMER_MANAGEMENT: {
          ...prev.CUSTOMER_MANAGEMENT,
          treatmentHistory: true,
        },
      }));
    } else {
      setIsThereSomeThingToSave((prev) => ({
        ...prev,
        CUSTOMER_MANAGEMENT: {
          ...prev.CUSTOMER_MANAGEMENT,
          treatmentHistory: false,
        },
      }));
    }
  }, [text, originalText, setIsThereSomeThingToSave]);

  return (
    <div className='flex h-full w-full flex-col gap-4'>
      <div className='mb-16 text-Header14 text-black500'>치료내역</div>
      <TextArea
        height={100}
        placeholder='치료내역을 입력해 주세요.'
        onSave={handleSave}
        onChange={handleChange}
        onInput={handleInput}
        isShowBottom={true}
        leftBottomText={bottomText}
        value={text}
        isSaveButtonDisabled={isSaveButtonDisabled}
      />
    </div>
  );
}
