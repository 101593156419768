import TextButton from '@afterdoc-design-system/components/Atoms/Button/TextButton';
import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import dayjs from 'dayjs';
import { useSetAtom } from 'jotai';
import type { ChatFileAndContentIDs } from 'web/apis/swaggers/swagger-docs';
import { postUpdateBookmark } from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/components/ChattingMessage/components/BookmarkToggle/BookmarkToggle';
import { useInfiniteChatIsBookmarkData } from 'web/templates/CustomerChat/components/SupportBot/hooks/use-infinite-chat-is-bookmark-data';
import { isBookmarkMapState } from 'web/templates/CustomerChat/states/is-bookmark-map';
import { displayTextContent } from '../../ChattingList/functions/displayTextContent';

type ChattingListItemProps = Pick<
  ChatFileAndContentIDs,
  | 'sentAt'
  | 'userRealName'
  | 'content'
  | 'isBookmark'
  | 'type'
  | 'isTranslated'
  | 'translatedContent'
> & { id: string; isLeft: boolean };

export default function ChatIsBookmarkListItem({
  id,
  content,
  sentAt,
  userRealName,
  type,
  isTranslated,
  translatedContent,
  isLeft,
}: ChattingListItemProps) {
  const setBookmarkState = useSetAtom(isBookmarkMapState);
  const { refetch } = useInfiniteChatIsBookmarkData();

  const formatedUpdatedAt = dayjs(sentAt).format('YYYY-MM-DD');
  const formatedUpdatedAtWithName = `${formatedUpdatedAt} ${userRealName}`;

  const handleBookmarkRemove = async () => {
    try {
      await postUpdateBookmark({ messageId: id, isBookmark: false });
      refetch();
      setBookmarkState((prev) => ({
        ...prev,
        [id]: false,
      }));
      toastService.successMsg({ text: '북마크를 해제 했습니다.' });
    } catch (error) {
      console.error(error);
      toastService.errorMsg({ text: '북마크 해제를 실패 했습니다.' });
    }
  };

  const defaultCallback = () => (!isLeft && isTranslated ? translatedContent : content) ?? '';

  return (
    <div
      key={id}
      className='mx-16 mb-10 flex h-60 flex-center cursor-pointer flex-row justify-between rounded-r16 border border-white600 p-12'>
      <div className='flex w-auto flex-col'>
        <span className='line-clamp-1 h-20 text-Body12 text-black700'>
          {displayTextContent(type, defaultCallback)}
        </span>
        <span className='h-16 content-end text-Caption9 text-black200'>
          {formatedUpdatedAtWithName}
        </span>
      </div>
      <TextButton
        className='h-22 flex-shrink-0 flex-grow-0 basis-[49px] rounded-r6 bg-white100 pl-13'
        onClick={handleBookmarkRemove}>
        해제
      </TextButton>
    </div>
  );
}
