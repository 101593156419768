import ButtonTab from '@afterdoc-design-system/components/Atoms/Tabs/components/ButtonTab';
import Tabs from '@afterdoc-design-system/components/Atoms/Tabs/components/Tabs';
import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { SHARED_UTILS } from '@shared-utils/utils';
import { useAtom, useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { apiClient } from 'web/apis/instances/api-client';
import type {
  TargetableNationalitiesHandlerParams,
  TargetableNationalitiesHandlerResponse,
} from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import type { CounselAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/components/RegisterCounselAutomationDialogContent';
import { COUNSEL_TAB_ITEMS } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/constants/counsel-tab-items';
import { messageModeState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-mode';
import { selectedCounselAutomationTabState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/states/selected-counsel-automation-tab-state';

const getTargetableNationalities = async (params: TargetableNationalitiesHandlerParams) => {
  const response = await apiClient.v3.targetableNationalitiesHandler(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export default function RegisterCounselAutomationDialogTabs() {
  const {
    getValues,
    formState: { isDirty },
  } = useFormContext<CounselAutomationAPIFormValues>();
  const { hospitalID } = useSelectedHospitalInfo();

  const messageMode = useAtomValue(messageModeState);
  const targetTreatmentTags = getValues('targetTreatmentTags');
  const toBeAppliedNationalityIds = getValues('toBeAppliedNationalityIds');

  const [counselAutomationTabItems, setCounselAutomationTabItems] = useState(COUNSEL_TAB_ITEMS);
  const [targetableNationalities, setTargetableNationalities] =
    useState<TargetableNationalitiesHandlerResponse['data']>();
  const [selectedCounselAutomationTab, setCounselSelectedAutomationTab] = useAtom(
    selectedCounselAutomationTabState,
  );

  const fetchTargetableNationalities = async () => {
    try {
      const response = await getTargetableNationalities({
        hospitalID,
        treatmentTagId: targetTreatmentTags?.join(','),
      });
      setTargetableNationalities(response);
    } catch (error) {
      console.error('Failed to fetch nationalities:', error);
    }
  };

  const onChangeTab = (value: (typeof COUNSEL_TAB_ITEMS)[number]['value']) => {
    if (selectedCounselAutomationTab === 0 && value !== 0) {
      if (
        !!(messageMode.mode === 'EDIT' && !isDirty) ||
        !!(targetableNationalities && targetableNationalities.length > 0) ||
        !!(targetableNationalities && targetableNationalities[0]._id === '-1') ||
        !!(toBeAppliedNationalityIds && toBeAppliedNationalityIds.length > 0)
      ) {
        if (!getValues('name')?.length || !targetTreatmentTags?.length) {
          toastService.errorMsg({ text: '현재 페이지의 필수정보를 모두 입력해 주세요.' });
          return;
        }
        setCounselSelectedAutomationTab(value);
        return;
      }
      toastService.errorMsg({ text: '현재 설정된 치료태그로 설정된 자동화가 이미 존재합니다.' });
    } else {
      setCounselSelectedAutomationTab(value);
    }
  };

  useEffect(() => {
    if (messageMode.mode === 'EDIT') {
      setCounselAutomationTabItems((prev) => {
        return prev.map((item) => {
          if (item.value === 0 || item.value === 1) {
            return {
              ...item,
              disabled: true,
            };
          }

          return item;
        });
      });
    }
  }, [messageMode]);

  useEffect(() => {
    if (selectedCounselAutomationTab === 0) {
      fetchTargetableNationalities();
    }
  }, [selectedCounselAutomationTab, hospitalID, targetTreatmentTags]);

  return (
    <Tabs value={selectedCounselAutomationTab} onChange={onChangeTab} className='w-[700px]'>
      {counselAutomationTabItems.map(({ label, value, disabled }) => (
        <ButtonTab
          value={value}
          label={label}
          key={value}
          className='border-b border-b-white400'
          disabled={disabled}>
          {label}
        </ButtonTab>
      ))}
    </Tabs>
  );
}
