import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import type { ChatFileAndContentIDs } from 'web/apis/swaggers/swagger-docs';
import { useChattingList } from 'web/templates/CustomerChat/components/ChattingList/hooks/use-chattinglist';
import { useSearchInput } from 'web/templates/CustomerChat/components/ChattingList/hooks/use-search-input';
import { channelIntegrationInfoState } from 'web/templates/CustomerChat/components/ChattingList/states/channel-integration-info-state';
import { useInfiniteChatData } from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/hooks/use-infinite-chat-data';
import { useInfiniteMessageIDChatData } from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/hooks/use-infinite-messageID-chat-data';
import type {
  ChattingResponse,
  ImageChattingResponse,
  TextChattingResponse,
} from 'web/templates/CustomerChat/types';
import { FILE_TYPE, KAKAO_FILES_TYPE_FOR_API } from '../../ChattingSending/constants/types-codes';
import {
  MESSAGE_TYPE_CODE,
  USER_TYPE_CODE,
} from '../components/ChattingContent/components/ChattingMessage/constants/message';
import { useChatRoomPatientUnreadCount } from '../components/ChattingContent/hooks/use-chat-room-patient-unread-count';
import { useHandleChatSocket } from '../components/ChattingContent/hooks/use-handle-chat-socket';
import { chattingRequestState } from '../states/chatting-request';

export type DataResponse =
  | ChattingResponse
  | TextChattingResponse
  | ImageChattingResponse
  | ChatFileAndContentIDs;

export function useConditionalChatData(
  selectedMessageId: string | null,
  chatRoomID: string | null,
) {
  const [resMessages, setResMessages] = useState<Array<DataResponse>>([]);

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    setResMessages([]);
  }, [chatRoomID]);

  const { roomType } = useAtomValue(channelIntegrationInfoState);
  const { keyword } = useSearchInput();

  const { refetch: refetchChattingList } = useChattingList({ keyword });
  const { refetch: unreadCountRefetch } = useChatRoomPatientUnreadCount();
  const { res } = useHandleChatSocket();
  const req = useAtomValue(chattingRequestState);
  const {
    data,
    fetchNextPage: fetchPreviousPage,
    hasNextPage: hasPreviousPage,
    isFetchingNextPage: isFetchingPreviousPage,
    isLoading,
    refetch,
  } = useInfiniteChatData();

  const infiniteMessageIDChatData = useInfiniteMessageIDChatData({ selectedMessageId });

  useEffect(() => {
    if (res) {
      const { userType } = res;
      const isLeft = userType === USER_TYPE_CODE.PATIENT; // 1000: 환자

      unreadCountRefetch();

      if (isLeft) {
        const updateMessage = (prev: Array<DataResponse>) => {
          // 기존 메시지 중 현재 메시지와 ID가 일치하는 메시지를 찾음
          const existingMessageIndex = prev.findIndex((item) => item._id === res._id);

          if (existingMessageIndex !== -1) {
            prev[existingMessageIndex] = res;
            return [...prev];
          }

          // 다른 경우에도 ID가 일치하는 메시지가 있는지 다시 확인
          const secondaryIndex = prev.findIndex((item) => item._id === res._id);
          if (secondaryIndex !== -1) {
            prev[secondaryIndex] = res;
            return [...prev];
          }

          // 일치하는 메시지가 없으면 현재 메시지를 추가
          return [res, ...prev];
        };

        if (res.isTranslated) {
          setResMessages(updateMessage);
        } else {
          setResMessages((prev) => [res, ...prev]);
        }
        return;
      }

      switch (roomType) {
        case 'ikakao': {
          if (res.isTranslated) {
            setResMessages((prev) => {
              const idx = prev.findIndex((item) => {
                return item.content === res.content && item.type === MESSAGE_TYPE_CODE.TEXT_MOCKING;
              });
              if (idx !== -1) {
                prev[idx] = res;
                return [...prev];
              }
              return [...prev];
              // return [res, ...prev];
            });
          } else {
            setResMessages((prev) => {
              // prev에서 반복을 돌며 _id가 같은 것이 있으면 교체
              const idx = prev.findIndex((item) => item._id === res._id);
              if (idx !== -1) {
                prev[idx] = res;
                return [...prev];
              }
              // return [...prev];
              return [res, ...prev];
            });
          }
          break;
        }
        case 'patient': {
          setResMessages((prev) => {
            const idx = prev.findIndex((item) => {
              return item.content === res.content && item.type === MESSAGE_TYPE_CODE.TEXT_MOCKING;
            });
            if (idx !== -1) {
              prev[idx] = res;
              return [...prev];
            }

            return [...prev];
            // return [res, ...prev];
          });
          break;
        }
        default:
          setResMessages((prev) => {
            return [res, ...prev];
          });
          break;
      }
    }
    refetchChattingList();
  }, [res]);

  useEffect(() => {
    if (req) {
      switch (req.type) {
        case 'ikakao': {
          const payload = req.payload as ChattingResponse;
          setResMessages((prev) => {
            return [payload, ...prev];
          });
          break;
        }
        case KAKAO_FILES_TYPE_FOR_API: {
          const payloads = req.payload as ImageChattingResponse[];
          setResMessages((prev) => {
            //만약 prev에 payloads의 자식인 payload의 같은 _id가 있다면 payloadsd에서 제거
            const filteredPayloads = payloads.filter(
              (payload) => !prev.some((item) => item._id === payload._id),
            );
            return [...filteredPayloads, ...prev];
          });
          break;
        }
        case FILE_TYPE: {
          const payload = req.payload as ImageChattingResponse;
          setResMessages((prev) => {
            return [payload, ...prev];
          });
          break;
        }
        case 'text': {
          const payload = req.payload as TextChattingResponse;
          setResMessages((prev) => {
            return [payload, ...prev];
          });
          break;
        }
        default:
          break;
      }
    }
  }, [req]);

  if (!selectedMessageId) {
    return {
      data: [...resMessages, ...data],
      refetch,
      fetchPreviousPage,
      hasPreviousPage,
      isFetchingPreviousPage,
      isLoading,
      hasNextPage: null,
      isFetchingNextPage: false,
      fetchNextPage: () => {},
    };
  }

  return {
    ...infiniteMessageIDChatData,
  };
}
