import TextFieldSelectBoxDropdown from '@afterdoc-design-system/components/Molecules/TextSelect/TextFieldSelectBoxDropdown';
import { useCallback, useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import type { CommonAlgorithmInput } from 'web/apis/swaggers/swagger-docs';
import { generateTimeIntervalArray } from 'web/templates/Automation/containers/shared/functions/generate-time-interval-array';

export default function MessageSendTime() {
  const { setValue, getValues } = useFormContext<CommonAlgorithmInput>();
  const options = useRef(generateTimeIntervalArray({ startMinute: 0 }));

  const sendTime = getValues('sendTime');
  const onSelect = (index: number) => {
    setValue('sendTime', options.current[index]);
  };

  const getIndex = useCallback(() => {
    const index = options.current.findIndex((option) => option === sendTime);
    return index !== -1 ? index : 0;
  }, [sendTime]);

  const customScrollHandler = useCallback((index: number) => index - 2, []);

  return (
    <TextFieldSelectBoxDropdown
      label={{
        children: '발송시각',
        isRequired: true,
      }}
      width={100}
      options={options.current}
      manualInput={false}
      onSelect={onSelect}
      selectedIndex={getIndex()}
      focusedIndex={getIndex()}
      customFocusScrollHandler={customScrollHandler}
      customSelectedScrollHandler={customScrollHandler}
    />
  );
}
