import { SHARED_UTILS } from '@shared-utils/utils';
import { type InfiniteData, useInfiniteQuery, useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type {
  ApiChatroomElCountParams,
  ApiChatroomElFindParams,
  ChatRoomHServiceLastMessageUsers,
  HService,
  UserNameAndType,
} from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { useUserInfo } from 'web/shared/hooks/use-user-info';
import { TABS } from 'web/templates/CustomerChat/constants/tab';
import { selectedChattingListTabState } from 'web/templates/CustomerChat/states/selected-chatting-list-tab';

export interface InfoItem {
  userID: string;
  count: number;
  title: string;
}

interface TempHService extends HService {
  hasKakaoLinkage: boolean;
}
export interface TempChatRoomHServiceLastMessageUser extends ChatRoomHServiceLastMessageUsers {
  users: UserNameAndType[];
  userID: UserNameAndType;
  c_UnreadCount: number;
  info: InfoItem[];
  counselorID: string;
  parentHServiceID: TempHService;
}

const getChatRoomLength = async (query: ApiChatroomElCountParams) => {
  const { data: ingLength } = await apiClient.v3.apiChatroomElCount({
    ...query,
    isCounselInProgress: true,
  });

  const { data: totalLength } = await apiClient.v3.apiChatroomElCount({
    ...query,
  });

  const data = {
    ingLength: SHARED_UTILS.api.checkApiResponse(ingLength),
    totalLength: SHARED_UTILS.api.checkApiResponse(totalLength),
  };
  return data;
};

const getChatRoom = async (query: ApiChatroomElFindParams) => {
  const { data } = await apiClient.v3.apiChatroomElFind(query);
  return SHARED_UTILS.api.checkApiResponse(data) as TempChatRoomHServiceLastMessageUser[];
};

const INTERVAL_NUM = 20;

export const useChattingList = ({
  keyword,
  sortOption,
}: { keyword?: string; sortOption?: string }) => {
  const selectedTab = useAtomValue(selectedChattingListTabState);
  const isCounselInProgress = selectedTab === TABS.IN_PROGRESS;
  const { hospitalID } = useSelectedHospitalInfo();
  const { userId: userID } = useUserInfo();

  const defaultChattingListQuery = {
    hospitalID,
    participant: userID,
    limit: INTERVAL_NUM,
    way: -1,
    isActive: true,
    isDoctor: false,
    ...(keyword && { keyword }),
  };

  const { data: lengthResult } = useQuery({
    queryKey: [QUERY_KEY.apiChatroomElCount, defaultChattingListQuery] as const,
    queryFn: ({ queryKey }) => getChatRoomLength({ ...queryKey[1] }),
  });

  const chattingListQuery = {
    ...defaultChattingListQuery,
    ...(isCounselInProgress && { isCounselInProgress }),
    key: sortOption === '최근 상담순' ? 'lastMessageAt' : 'lastUserChatDate',
  };

  const {
    data: chatRoomHServiceLastMessageUsers = [] as unknown as InfiniteData<
      TempChatRoomHServiceLastMessageUser[]
    >,

    isFetched,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  } = useInfiniteQuery<TempChatRoomHServiceLastMessageUser[]>({
    queryKey: [QUERY_KEY.apiChatroomElFind, chattingListQuery] as const,
    queryFn: ({ queryKey, pageParam }) => {
      const { skip, limit } = pageParam as { skip: number; limit: number };
      return getChatRoom({
        ...(queryKey[1] as ApiChatroomElFindParams),
        ...{ skip, limit },
      });
    },
    getNextPageParam: (lastPage, pages) => {
      if (!lastPage?.length) return undefined; // 더 이상 페이지가 없음을 의미

      const pagesNum = pages.length;
      return { skip: pagesNum * INTERVAL_NUM, limit: INTERVAL_NUM };
    },
    initialPageParam: { skip: 0, limit: INTERVAL_NUM },
  });

  const newUsers = chatRoomHServiceLastMessageUsers?.pages?.flat() ?? [];

  return {
    lengthResult: lengthResult ?? { ingLength: 0, totalLength: 0 },
    chatRoomHServiceLastMessageUsers: newUsers,
    isFetched,
    refetch,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading,
  };
};
