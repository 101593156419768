import { apiClient } from 'web/apis/instances/api-client';

export const postChatroomUserRemove = async ({
  chatRoomID,
  userIDs,
}: { chatRoomID: string; userIDs: string[] }) => {
  const params = {
    chatRoomID,
    userIDs,
  };
  try {
    const response = await apiClient.v3.apiChatroomElUserRemove(params);

    return response;
  } catch (error) {
    console.error(error);
  }
};
