import { dialogService } from '@afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { startTransition } from 'react';
import WorkoutVideoDetailContentDialog from 'web/templates/Automation/containers/Viewer/AutomationZoomInOutViewer/components/components/MessageCardItem/components/WorkoutVideoContent/components/WorkoutVideoDetailContentDialog/WorkoutVideoDetailContentDialog';

export const handleVideoDialogOpen = (id: string, title: string) => {
  startTransition(() => {
    dialogService.push(<WorkoutVideoDetailContentDialog videoContentHospitalID={id} />, {
      hasCloseButton: true,
      titleProps: {
        title: title,
      },
    });
  });
};
