import TextButton from '@afterdoc-design-system/components/Atoms/Button/TextButton';
import HoverTooltip from '@afterdoc-design-system/components/Atoms/Tooltip/HoverTooltip';
import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import { modalService } from '@afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { useAtomValue, useSetAtom } from 'jotai';
import { useFormContext } from 'react-hook-form';
import type { CounselAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/components/RegisterCounselAutomationDialogContent';
import { selectedMessageIndexState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-form';
import { showMessageTypeDialogState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-type-dialog';
import { originMessagesState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/origin-message';

export default function AutomationMessageHeader() {
  const originMessages = useAtomValue(originMessagesState);
  const { getValues } = useFormContext<CounselAutomationAPIFormValues>();
  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);

  const hasApprovedAlimtalkTemplate =
    originMessages[selectedMessageIndex]?.hasApprovedAlimtalkTemplate ?? false;
  const rejectedAlimtalkTemplateReason =
    originMessages[selectedMessageIndex]?.rejectedReasonForAlimtalkTemplate ?? '';
  const content = getValues(`messages.${selectedMessageIndex}.content`);
  const messageName = getValues(`messages.${selectedMessageIndex}.name`);
  const messageDaysAfter = getValues(`messages.${selectedMessageIndex}.daysAfter`);

  const setShowMessageTypeDialog = useSetAtom(showMessageTypeDialogState);

  const onClickMessageTypeChange = () => {
    if (content.text && content.text.length > 0) {
      modalService.defaultWarning({
        id: 'message-type-change-warning',
        title: '발송타입을 변경하시겠어요?',
        contents: '변경시 입력된 내용이 사라집니다.',
        onConfirm: () => {
          setShowMessageTypeDialog({
            type: 'edit',
            isShow: true,
          });
          modalService.popById('message-type-change-warning');
        },
      });
    } else {
      setShowMessageTypeDialog({
        type: 'edit',
        isShow: true,
      });
    }
  };

  return (
    <div className='flex w-full justify-between py-10'>
      <div className='flex items-center gap-10'>
        {hasApprovedAlimtalkTemplate &&
          (rejectedAlimtalkTemplateReason && rejectedAlimtalkTemplateReason.length > 0 ? (
            <HoverTooltip
              text={
                '안녕하세요. 카카오톡 알림톡 검수 담당자입니다.\n\n알림톡은 수신자의 액션을 기반한 정보성 메시지에 한하여 발송 가능합니다.\n허나 해당 메시지는 수신자가 요청하지 않은 내용으로 광고성 및 공지성 메시지에 해당함에 따라 알림톡 발송 불가합니다.\n\n더 자세한 알림톡 검수 가이드는 딜러사를 통해 확인 부탁드립니다.\n감사합니다.'
              }
              className='max-w-[800px] px-10 py-2'
              position='bottomRight'>
              <Icon name='info-circle-outline' color='black200' size={16} />
            </HoverTooltip>
          ) : (
            <Icon name='kakaotalk' color='black200' size={20} />
          ))}
        <div className='flex items-center gap-20 truncate'>
          <div className='rounded-r6 border border-white600 bg-white50 px-10 py-4 text-Header14'>
            {messageDaysAfter}일차
          </div>
          <div className='truncate text-Header16 text-black500'>{messageName}</div>
        </div>
      </div>
      <TextButton
        onClick={onClickMessageTypeChange}
        className='whitespace-nowrap'
        iconProps={{
          name: 'chevron-right',
          size: 16,
          color: 'white700',
        }}>
        메시지 타입 변경
      </TextButton>
    </div>
  );
}
