import BaseLoading from '@afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import Title from '@afterdoc-design-system/components/Molecules/Title/Title';
import { Suspense } from 'react';
import ErrorBoundary from 'web/shared/components/ErrorBoundary/ErrorBoundary';
import OnGoingConsultingAutomationCardsList from './components/OnGoingConsultingAutomationCardsList/OnGoingConsultingAutomationCardsList';

export default function OngoingConsultingAutomationContainer() {
  return (
    <>
      <Title title='적용 중인 관리자동화' />
      <div className='h-[calc(100vh-222px)]'>
        <ErrorBoundary>
          <Suspense
            fallback={
              <div className='h-[calc(100vh-222px)] w-[360px] flex-center'>
                <BaseLoading />
              </div>
            }>
            <OnGoingConsultingAutomationCardsList />
          </Suspense>
        </ErrorBoundary>
      </div>
    </>
  );
}
