import TextInput from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import { memo } from 'react';

interface NoticeCreateDateLabelProps {
  date: string;
}
function NoticeCreateDateLabel({ date }: NoticeCreateDateLabelProps) {
  return (
    <div className='flex-center gap-24'>
      <LabelText width={76} className='flex h-40 items-center'>
        등록일자
      </LabelText>
      <TextInput className='h-full flex-grow' disabled value={date} onChange={() => {}} />
    </div>
  );
}

export default memo(NoticeCreateDateLabel);
