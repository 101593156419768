import NoData from 'afterdoc-design-system/components/Molecules/NoData/NoData';

type ContentType = 'event' | 'homeCare' | 'notice' | 'popup';

interface ListNoDataProps {
  isSearchQueryEntered: boolean;
  type: ContentType;
}

const commonTextResources = {
  searchResultTitle: '검색결과가 없습니다.',
  searchResultSubTitle: '검색어를 다시 입력해보세요.',
};

const textResources = {
  event: {
    ...commonTextResources,
    defaultTitle: '등록된 이벤트가 없습니다.',
    defaultSubTitle:
      "아래 ‘이벤트 등록하기'를 눌러 이벤트를 등록해 보세요.\n고객에게 이벤트를 안내할 수 있습니다.",
  },
  homeCare: {
    ...commonTextResources,
    defaultTitle: '등록된 상품이 없습니다.',
    defaultSubTitle: "아래 ‘상품 등록하기'를 눌러 상품을 등록해 보세요",
  },
  notice: {
    ...commonTextResources,
    defaultTitle: '등록한 공지사항이 없습니다.',
    defaultSubTitle: "아래 ‘공지사항 등록하기'를 눌러 공지사항을 등록해 보세요.",
  },
  popup: {
    ...commonTextResources,
    defaultTitle: '등록한 팝업이 없습니다.',
    defaultSubTitle:
      "아래 ‘팝업 등록하기'를 눌러 팝업을 등록해 보세요.\n고객에게 공지사항을 안내할 수 있습니다.",
  },
};

const getTextResources = (type: ContentType, isSearchQueryEntered: boolean) => {
  const { searchResultTitle, searchResultSubTitle, defaultTitle, defaultSubTitle } =
    textResources[type];

  return {
    title: isSearchQueryEntered ? searchResultTitle : defaultTitle,
    subTitle: isSearchQueryEntered ? searchResultSubTitle : defaultSubTitle,
  };
};

export default function ContentNoData({ isSearchQueryEntered, type }: ListNoDataProps) {
  const { title, subTitle } = getTextResources(type, isSearchQueryEntered);

  return (
    <div className='h-full flex-center'>
      <NoData
        iconProps={{
          name: 'warning',
          size: 48,
          color: 'white600',
        }}
        title={title}
        subTitle={subTitle}
      />
    </div>
  );
}
