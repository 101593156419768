import { SHARED_UTILS } from '@shared-utils/utils';
import { useQuery } from '@tanstack/react-query';
import { useAtom } from 'jotai';
import { useEffect, useRef, useState } from 'react';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type {
  ApiServiceSettingsElFindOneParams,
  ApiVideocontentcategoryElFindParams,
  VideoContentCategoryParentInfos,
} from 'web/apis/swaggers/swagger-docs';
import FullLoading from 'web/shared/components/FullLoading/FullLoading';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import ContentPanelFolder from 'web/templates/Content/containers/ContentPanel/components/ContentPanelFolder';
import {
  CONTENT_PANEL_ITEMS,
  type ContentLayer,
  type ContentSubLayer,
} from 'web/templates/Content/containers/ContentPanel/constants/tab-items';
import { contentPanelLayerState } from 'web/templates/Content/containers/ContentPanel/states/content-panel-layer-state';
import '../../styles/ManualContainer.scss';
import ChatContentPanelFolderItem from './components/ContentsPanelFolderItem/components/ChatContentPanelFolderItem/ChatContentPanelFolderItem';

export interface ContentFolders {
  id: string;
  title: string;
  items: { key: string; text: string; type: string; isOpen: boolean }[];
}
[];

const fetchServiceSetting = async (params: ApiServiceSettingsElFindOneParams) => {
  const response = await apiClient.v3.apiServiceSettingsElFindOne(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const fetchVideoContentCategories = async (params: ApiVideocontentcategoryElFindParams) => {
  const response = await apiClient.v3.apiVideocontentcategoryElFind(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const createContentItem = (category: VideoContentCategoryParentInfos): ContentSubLayer => ({
  key: category._id ?? '',
  text: category.categoryName ?? '',
  data: category,
});

export default function ContentsList() {
  const [categories, setCategories] = useState<VideoContentCategoryParentInfos[]>([]);
  const [contentPanelLayer, setContentPanelLayer] = useAtom(contentPanelLayerState);
  const manualListContainerRef = useRef<HTMLDivElement>(null);

  const { hospitalID } = useSelectedHospitalInfo();

  const { data: serviceSettings, isLoading } = useQuery({
    queryKey: [QUERY_KEY.apiServiceSettingsElFindOne, { hospitalID }] as const,
    queryFn: ({ queryKey }) => fetchServiceSetting(queryKey[1]),
  });

  const addCategoryToLayer = (
    layers: ContentLayer[],
    category: VideoContentCategoryParentInfos,
    parentCategoryName: string,
  ): ContentLayer[] => {
    const existingLayer = layers.find((layer) => layer.title === parentCategoryName);

    const contentItem = createContentItem(category);

    if (existingLayer) {
      existingLayer.items.push(contentItem);
    } else {
      layers.push({
        title: parentCategoryName,
        isOpen: true,
        items: [contentItem],
      });
    }

    return layers;
  };

  useEffect(() => {
    const fetchData = async () => {
      if (serviceSettings?.usingAutoSendVideo) {
        const uniqueParentCategoryIDs = [
          ...new Set(
            serviceSettings.usingAutoSendVideo
              .map((video) => video._id)
              .filter((id): id is string => id !== undefined),
          ),
        ];

        if (uniqueParentCategoryIDs.length > 0) {
          const data = await fetchVideoContentCategories({
            isTop: false,
            isParent: true,
            'parentCategoryIDs[]': uniqueParentCategoryIDs,
          });
          setCategories(data);
          return;
        }
      }
      setContentPanelLayer(CONTENT_PANEL_ITEMS);
    };
    fetchData();
  }, [serviceSettings]);

  const buildContentPanelItems = (
    categories: VideoContentCategoryParentInfos[],
  ): ContentLayer[] => {
    const layers: ContentLayer[] = [];
    for (const category of categories) {
      for (const parent of category.parentCategoryIDs ?? []) {
        addCategoryToLayer(layers, category, parent.categoryName ?? '');
      }
    }
    return layers;
  };

  useEffect(() => {
    if (categories.length > 0) {
      const contentPanelItems = buildContentPanelItems(categories);

      setContentPanelLayer([...contentPanelItems, ...CONTENT_PANEL_ITEMS]);
    }
  }, [categories]);

  if (isLoading) return <FullLoading />;

  return (
    <div className='h-full'>
      <div id='manual-container' ref={manualListContainerRef} className='select-none'>
        {contentPanelLayer.map((parentItem, folderIndex) => (
          <ContentPanelFolder
            key={parentItem.title}
            folderIndex={folderIndex}
            folderIconColor='white700'>
            {parentItem.items.map((childItem, itemIndex) => (
              <ChatContentPanelFolderItem
                key={childItem.key}
                id={childItem.key}
                categoryTitle={childItem.text}
                folderIndex={folderIndex}
                itemIndex={itemIndex}
              />
            ))}
          </ContentPanelFolder>
        ))}
      </div>
    </div>
  );
}
