import { QUERY_KEY } from '@apis/swaggers/query-key';
import { useUserInfo } from '@shared/hooks/use-user-info';
import EventPushAlarmDialog from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/components/EventList/components/EventListBody/components/EventPushAlarmDialog/EventPushAlarmDialog';
import { useHospitalEvents } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/hooks/use-hospital-event';
import EventHomeCarePreviewDialog from '@templates/Content/containers/ContentManager/containers/EventHomeCare/shared/components/EventHomeCarePreviewDialog/EventHomeCarePreviewDialog';
import ContainedButton from 'afterdoc-design-system/components/Atoms/Button/ContainedButton';
import OutlinedButton from 'afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import BaseLoading from 'afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import { dialogService } from 'afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { Suspense } from 'react';

interface EventActionPanelProps {
  eventID?: string;
}

export default function EventActionPanel({ eventID }: EventActionPanelProps) {
  const { removeEventAction } = useHospitalEvents();

  const { authorizationTypeID } = useUserInfo();

  const handleDetailButtonClick = () => {
    if (!eventID) return;

    dialogService.push(
      <Suspense
        fallback={
          <div className='h-full w-[400px] flex-center'>
            <BaseLoading />
          </div>
        }>
        <EventHomeCarePreviewDialog
          id={eventID}
          queryKey={QUERY_KEY.apiEventHospitalEventElFindOne}
        />
      </Suspense>,
      {
        id: 'event-home-care-preview-detail-dialog',
        hasCloseButton: true,
        wrapperClassName: 'h-[880px]',
        titleProps: {
          title: '이벤트 상세보기',
        },
      },
    );
  };

  const handlePushAlarmButtonClick = () => {
    if (!eventID) return;

    dialogService.push(<EventPushAlarmDialog hospitalEventID={eventID} />, {
      id: 'event-push-alarm-dialog',
      hasCloseButton: true,
      titleProps: {
        title: '푸시알림',
      },
      wrapperClassName: 'h-[545px]',
    });
  };

  const handleRemoveButtonClick = () => {
    if (!eventID) return;
    removeEventAction(eventID);
  };

  return (
    <div className='flex flex-grow items-center justify-end gap-6'>
      <OutlinedButton
        className='h-22 w-70'
        buttonSize='small'
        btnColor='secondary'
        onClick={handleDetailButtonClick}>
        상세보기
      </OutlinedButton>
      {!!authorizationTypeID?.canUpsertContent && (
        <ContainedButton
          className='h-22 w-70'
          buttonSize='small'
          btnColor='purple'
          onClick={handlePushAlarmButtonClick}>
          푸시알림
        </ContainedButton>
      )}
      {!!authorizationTypeID?.canDeleteContent && (
        <ContainedButton
          className='h-22 w-50'
          buttonSize='small'
          btnColor='secondary'
          onClick={handleRemoveButtonClick}>
          삭제
        </ContainedButton>
      )}
    </div>
  );
}
