import { makeChattingInfoStringDisplay } from './functions/makeChattingInfoStringDisplay';

type MessageTimestampProps = {
  createdAt: string | undefined;
};

export default function MessageTimestamp({ createdAt }: MessageTimestampProps) {
  const displayCreated = makeChattingInfoStringDisplay({
    type: 'chattingCreated',
    payload: { timestamp: createdAt },
  });
  return <span className='text-Caption9 text-black200'>{displayCreated}</span>;
}
