import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { type InputHTMLAttributes, forwardRef, useCallback, useRef, useState } from 'react';

interface TableInputProps extends InputHTMLAttributes<HTMLInputElement> {
  hasError?: boolean;
  isFocused?: boolean;
  isSelected?: boolean;
  onDelete?: () => void;
}

const TableInput = forwardRef<HTMLInputElement, TableInputProps>(
  ({ isFocused, isSelected, hasError, onDelete, value, ...props }, ref) => {
    const [isComposing, setIsComposing] = useState(false);

    const handleCompositionStart = useCallback(() => {
      setIsComposing(true);
    }, []);

    const handleCompositionEnd = useCallback(() => {
      setIsComposing(false);
    }, []);

    const localRef = useRef<HTMLInputElement | null>(null);

    const handleKeyDown = useCallback(
      (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (isComposing) {
          e.stopPropagation();
          e.preventDefault();
        }

        if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
          e.preventDefault();
        }

        if (isSelected && !isFocused) {
          if (e.key === 'Delete') {
            const inputElement = localRef.current;
            if (inputElement) {
              inputElement.value = '';
              inputElement.dispatchEvent(new Event('input', { bubbles: true }));
            }

            if (onDelete) {
              onDelete();
            }
          }

          if (e.key === 'Backspace') {
            e.preventDefault();
          }
        }

        if (!isFocused) {
          e.preventDefault();
        }
      },
      [isFocused, isSelected, onDelete, isComposing],
    );

    return (
      <input
        ref={(el) => {
          localRef.current = el;
          if (typeof ref === 'function') {
            ref(el);
          } else if (ref) {
            (ref as React.MutableRefObject<HTMLInputElement | null>).current = el;
          }
        }}
        onCompositionStart={handleCompositionStart}
        onCompositionEnd={handleCompositionEnd}
        className={customTwMerge(
          'w-full px-12 py-11 text-Body12 placeholder-white600 outline-none placeholder:text-white600 group-hover:bg-blueLight',
          (isFocused || isSelected) && 'bg-blue50',
          hasError && 'border border-red500',
          !isFocused && 'hidden-caret',
          props.className,
        )}
        onKeyDown={handleKeyDown}
        value={value ?? ''}
        readOnly={!isFocused}
        {...props}
      />
    );
  },
);

TableInput.displayName = 'TableInput';

export default TableInput;
