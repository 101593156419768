import { popupContentTempFileState } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/state/popup-content-temp-file-state';
import CheckboxDefault from 'afterdoc-design-system/assets/icons/CheckboxDefault';
import { BgNoticePopupPreview } from 'afterdoc-design-system/assets/images';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import NoData from 'afterdoc-design-system/components/Molecules/NoData/NoData';
import Title from 'afterdoc-design-system/components/Molecules/Title/Title';
import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';

interface PopupPreviewProps {
  popupType: string;
  title?: string;
  content?: string;
  contentImage?: string;
}
export default function PopupPreview({
  popupType,
  title,
  content,
  contentImage,
}: PopupPreviewProps) {
  const popupContentTempFile = useAtomValue(popupContentTempFileState);
  const [contentImageSrc, setContentImageSrc] = useState(contentImage);

  useEffect(() => {
    const url = popupContentTempFile?.url;
    if (url) {
      setContentImageSrc(url);
    }
  }, [popupContentTempFile?.url]);

  return (
    <div className='flex w-[400px] flex-col bg-blue50'>
      <Title
        title='고객 화면 미리보기'
        subTitle='고객의 화면에서 해당 팝업이 어떻게 보이는지 미리 확인할 수 있습니다.'
      />
      <div className='relative mt-16 max-h-[800px] flex-grow px-16'>
        <BgNoticePopupPreview />
        <div className='absolute top-0 right-0 bottom-0 left-0 px-40 py-100'>
          <div className='flex h-full flex-col overflow-hidden rounded-r16 bg-white50'>
            <div className='flex-center border-white600 border-b py-16'>
              <span className='font-bold text-[#222222] text-[18px] leading-[27px]'>공지사항</span>
            </div>
            <div className='flex-grow'>
              <PopupContentDetailInfo
                type={popupType}
                title={title}
                content={content}
                src={contentImageSrc}
              />
            </div>
            <div className='flex-center border-white600 border-t px-16 py-20'>
              <div className='flex flex-grow items-center'>
                <CheckboxDefault className='mr-12 h-20 w-20' />
                <span className='font-regular text-[#A3A3A3] text-[16px] leading-[24px]'>
                  오늘은 그만보기
                </span>
              </div>
              <span className='font-bold text-[#222222] text-[16px] leading-[24px]'>닫기</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const PopupContentDetailInfo = ({
  type,
  title,
  content,
  src,
}: {
  type: string;
  title?: string;
  content?: string;
  src?: string;
}) => {
  if (type === 'text') {
    return (
      <div className='flex h-full w-full flex-col gap-16 px-20 py-16'>
        <Scrollbar disabledX>
          <div className='flex flex-col gap-16'>
            <span className='break-words font-bold text-[#222222] text-[18px] leading-[27px]'>
              {title || '제목을 입력해 주세요.'}
            </span>
            <span className='flex-grow whitespace-pre-wrap break-words font-regular text-[#222222] text-[16px] leading-[24px] '>
              {content || '내용을 입력해 주세요.'}
            </span>
          </div>
        </Scrollbar>
        <div className='flex-center rounded-[42px] border border-[#329EF1] py-14'>
          <span className='font-bold text-[#329EF1] text-[16px] leading-[24px]'>자세히 보기</span>
        </div>
      </div>
    );
  }
  if (type === 'image') {
    if (src) {
      return (
        <div className='h-full w-full'>
          <img
            src={src}
            alt='popupContentImage'
            className='h-full w-full object-cover'
            style={{ aspectRatio: '170 / 238' }}
          />
        </div>
      );
    }
    return <ImageEmpty />;
  }
};

const ImageEmpty = () => {
  return (
    <div className='h-full w-full flex-center'>
      <NoData
        iconProps={{
          name: 'warning',
          size: 48,
          color: 'white600',
        }}
        className='my-auto whitespace-pre-wrap'
        title='이미지를 등록해주세요.'
      />
    </div>
  );
};
