import LabelText from '@afterdoc-design-system/components/Atoms/LabelText/LabelText';

export const NoCautionImages = () => {
  return (
    <div className='flex h-[138px] w-full flex-col items-center justify-center'>
      <div className='flex flex-col-center'>
        <LabelText textClassName='text-Body13 py-60'>등록된 이미지가 없습니다.</LabelText>
      </div>
    </div>
  );
};
