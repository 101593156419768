import Scrollbar from '@afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import DashedTag from '@afterdoc-design-system/components/Atoms/Tag/DashedTag';
import FilledTag from '@afterdoc-design-system/components/Atoms/Tag/FilledTag';
import HoverComponent from '@afterdoc-design-system/components/Atoms/Tooltip/HoverComponent';
import NoData from '@afterdoc-design-system/components/Molecules/NoData/NoData';
import { Color } from '@tailwind-base/styles/color';
import { Suspense } from 'react';
import { usePatientDetailInfo } from 'web/shared/hooks/use-get-patient-detail-info';

const OtherTagsCountTag = ({
  tags,
}: {
  tags: {
    tagName: string;
    color: string;
  }[];
}) => {
  return (
    <HoverComponent
      content={
        <div className='h-[92px] w-[326px] rounded-r16 bg-white50 p-10 shadow-modal'>
          <Scrollbar disabledX>
            <div className='flex flex-wrap gap-6'>
              {tags.slice(7).map((tag, index) => (
                <FilledTag
                  key={`${tag.tagName}-${index}`}
                  bgColor={tag.color ? tag.color : Color.white400}
                  tagSize='big'>
                  {tag.tagName}
                </FilledTag>
              ))}
            </div>
          </Scrollbar>
        </div>
      }
      position='bottomRight'>
      {tags.length - 7 > 0 && (
        <DashedTag tagSize='small' className='flex cursor-pointer'>
          {`${tags.length - 7}`}+
        </DashedTag>
      )}
    </HoverComponent>
  );
};

const NoApplyingAutomationData = () => {
  return (
    <div className='flex-full-center'>
      <NoData
        iconProps={{
          name: 'robot-excited-outline',
          size: 48,
          color: 'white600',
        }}
        title='적용 중인 알고리즘이 없습니다.'
      />
    </div>
  );
};

const ApplyingAutomationTagsWithTitle = () => {
  const { appliedAutomation } = usePatientDetailInfo();

  if (!appliedAutomation) {
    return <NoApplyingAutomationData />;
  }

  const { tagsHaveFolder, tagsHaveNoFolder } = appliedAutomation;

  return (
    <>
      <div className='rounded-r16 border border-white600 bg-white50 pb-16'>
        <div className='border-b border-b-white600 px-20 pt-16 pb-10'>
          {/* 적용 중인 관리자동화명 */}
          <div className='text-Header14'>{appliedAutomation?.algorithmName}</div>
          {/* 적용 중인 국적 */}
          <div className='mt-4 text-Body13 text-black300'>
            {appliedAutomation.appliedNationalityNames}
          </div>
        </div>
        <div className='h-[112px] w-full px-16 pt-10'>
          <Scrollbar>
            <div className='flex flex-col gap-12'>
              {tagsHaveFolder.map((target) => {
                return (
                  <div key={target.folderID} className='flex flex-col gap-8'>
                    <div className='text-Header12 text-black500'>{target.folderName}</div>
                    <div className='flex flex-wrap gap-8'>
                      {target.tags.map((tag) => {
                        return (
                          <FilledTag
                            key={tag.tagId}
                            bgColor={tag.tagColor}
                            className='rounded-8 bg-blue50 px-12 py-4 text-Body13 text-black500'>
                            {tag.tagName}
                          </FilledTag>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
              {tagsHaveNoFolder.length > 0 && (
                <>
                  <div className='mt-8 text-Header12'>폴더없음</div>
                  <div className='flex flex-wrap gap-8'>
                    {tagsHaveNoFolder.map((tag) => {
                      return (
                        <FilledTag
                          key={tag.tagId}
                          bgColor={tag.tagColor}
                          className='rounded-8 bg-blue50 px-12 py-4 text-Body13 text-black500'>
                          {tag.tagName}
                        </FilledTag>
                      );
                    })}
                  </div>
                </>
              )}
            </div>
          </Scrollbar>
        </div>
      </div>
    </>
  );
};

export default function ApplyingAutomation() {
  return (
    <div className='flex h-full flex-col gap-4'>
      <div className='text-Header14 text-black700'>적용 중인 관리자동화</div>
      <div className='mb-16 text-Body12 text-black500'>
        해당 고객에게 적용된 치료관리 자동화입니다.
      </div>
      <Suspense>
        <ApplyingAutomationTagsWithTitle />
      </Suspense>
    </div>
  );
}
