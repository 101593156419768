import { SHARED_UTILS } from '@shared-utils/utils';
import { useQuery } from '@tanstack/react-query';
import { useEffect, useMemo, useState } from 'react';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ApiManualsElFoldersParams, ManualFolderList } from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { usePatient } from 'web/templates/CustomerChat/hooks/use-patient';
import { findFirstOpenIndex } from '../components/ManualPanelFolderItem/functions/find-first-open-index';

const getManualsFolders = async (query: ApiManualsElFoldersParams) => {
  const { data } = await apiClient.v3.apiManualsElFolders(query);
  return SHARED_UTILS.api.checkApiResponse(data) as ManualFolderList;
};

export interface SubLayer {
  key: string;
  text: string;
  color: string;
  isOpen: boolean;
}

export interface Layer {
  title: string;
  isOpen: boolean;
  items: SubLayer[];
}

export interface ManusFolders extends Layer {
  id: string;
  type: string;
  color: string;
}

export const useManusFolders = ({ keyword }: { keyword: string }) => {
  const { hospitalID } = useSelectedHospitalInfo();
  const { treatmentTags } = usePatient();

  const treatmentTagsIds = useMemo(
    () => treatmentTags?.map((tag) => tag.tagId) ?? [],
    [treatmentTags],
  );

  const { data, isLoading } = useQuery({
    queryKey: [
      QUERY_KEY.apiManualsElFolders,
      { hospitalID, ...(keyword && { folderOrTagName: keyword }), onlyActive: true },
    ] as const,
    queryFn: ({ queryKey }) => getManualsFolders({ ...queryKey[1] }),
    enabled: !!hospitalID,
  });

  const [newData, setNewData] = useState<ManusFolders[]>([]);

  useEffect(() => {
    if (data) {
      const checkIsOpen = (id: string) => {
        return treatmentTagsIds.includes(id);
      };

      const manues: ManusFolders[] =
        data?.foldersHasTags?.map((folder) => {
          const isAnyTagOpen = folder?.treatmentTags?.some((tag) => checkIsOpen(tag._id)) ?? false;

          return {
            id: folder._id,
            type: 'folder',
            color: '',
            title: folder.name,
            isOpen: isAnyTagOpen,
            items:
              folder?.treatmentTags?.map((tag) => ({
                key: tag._id,
                text: tag.categoryName,
                color: tag.color,
                isOpen: checkIsOpen(tag._id),
              })) ?? [],
          };
        }) ?? [];

      const unassignedTags =
        data?.unassignedTags?.map((tag) => {
          return {
            id: tag._id,
            type: 'tag',
            color: tag.color,
            title: tag.categoryName,
            isOpen: checkIsOpen(tag._id),
            items: [],
          };
        }) ?? [];

      setNewData([...manues, ...unassignedTags]);
    }
  }, [data, treatmentTagsIds]);

  const firstOpenIndex = findFirstOpenIndex(newData);

  return {
    data: newData,
    firstOpenIndex,
    isLoading,
  };
};
