import { atomWithReset } from 'jotai/utils';
import type { ApiPatientsElListOrSearchForUntaggedParams } from 'web/apis/swaggers/swagger-docs';

export interface UnTaggedHeaderFilterState {
  isFirstVisit: ApiPatientsElListOrSearchForUntaggedParams['isFirstVisit'] | 'all';
  countryCode: ApiPatientsElListOrSearchForUntaggedParams['countryCode'];
  gender: ApiPatientsElListOrSearchForUntaggedParams['gender'];
  treatmentCategories: ApiPatientsElListOrSearchForUntaggedParams['treatmentCategories'];
  nationality: ApiPatientsElListOrSearchForUntaggedParams['nationality'];
}

export const unTaggedHeaderFilterState = atomWithReset<UnTaggedHeaderFilterState>({
  isFirstVisit: undefined,
  countryCode: undefined,
  gender: undefined,
  treatmentCategories: undefined,
  nationality: undefined,
});
