import { SHARED_UTILS } from '@shared-utils/utils';
import { useMutation } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';
import { debounce } from 'lodash-es';
import { type ReactNode, useEffect, useMemo, useRef } from 'react';
import { Controller, useFormContext, useWatch } from 'react-hook-form';
import { apiClient } from 'web/apis/instances/api-client';
import type {
  ApiServiceSettingsElCountriesData,
  ApiServiceSettingsElToBeDisplayedPatientsFieldsData,
  ApiTreatmentTagsElData,
} from 'web/apis/swaggers/swagger-docs';
import { KOREA_COUNTRY_CODE_ID } from 'web/shared/constants/country-code-id';
import {
  GENDER_DISPLAY,
  GENDER_OPTIONS,
  VISIT_DISPLAY,
  VISIT_OPTIONS,
} from 'web/shared/constants/options';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import type {
  CustomerManagementTableRowArr,
  CustomerManagementTemporaryAPIFormValues,
} from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/types/table';
import MultipleTagsSelectBoxTagDropdownForTable from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/components/MultipleTagsSelectBoxTagDropdownForTable/MultipleTagsSelectBoxTagDropdownForTable';
import SingleTagSelectBoxTagDropdownForTable from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/components/SingleTagSelectBoxTagDropdownForTable/SingleTagSelectBoxTagDropdownForTable';
import TableInput from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/components/TableInput';
import useKeyboardNavigation from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/hooks/use-keyboard-navigation';
import { isTableTemporarySavingState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/states/save';
import './CustomerManagementEditableTableBody.scss';

type SelectedCell = {
  row: number;
  col: number;
};

type FocusedCell = {
  row: number | null;
  col: number | null;
};

const renderIsSelected = (rowIndex: number, colIndex: number, selectedCell: SelectedCell) => {
  return selectedCell.row === rowIndex && selectedCell.col === colIndex;
};

const renderIsFocused = (rowIndex: number, colIndex: number, focusedCell: FocusedCell) => {
  return focusedCell.row === rowIndex && focusedCell.col === colIndex;
};

const getTableCellProps = (
  rowIndex: number,
  colIndex: number,
  selectedCell: SelectedCell,
  onClick: () => void,
  className?: string,
) => ({
  className: `group border-line-table border-y border-r text-Body12 hover:bg-blueLight ${
    renderIsSelected(rowIndex, colIndex, selectedCell)
      ? '-outline-offset-1 bg-blue50 outline outline-1 outline-blue500'
      : ''
  } ${className}`,
  onClick,
});

const postPatientsTemporaryInfo = async (
  params: Parameters<typeof apiClient.v3.apiPatientsElTemporary>[0],
) => {
  const response = await apiClient.v3.apiPatientsElTemporary(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

type CustomerManagementEditableTableBodyProps = {
  countriesList: ApiServiceSettingsElCountriesData['data'];
  treatmentTagsList: ApiTreatmentTagsElData['data']['treatmentTags'];
  visibleRows: CustomerManagementTableRowArr;
} & ApiServiceSettingsElToBeDisplayedPatientsFieldsData['data'];

export default function CustomerManagementEditableTableBody({
  countriesList,
  treatmentTagsList,
  visibleRows,
  ...usingColumns
}: CustomerManagementEditableTableBodyProps) {
  const {
    usingChartNumber,
    usingFirstVisitStartDate,
    usingCountryCode,
    usingBirthDay,
    usingGender,
    usingTreatmentTag,
    usingNationality,
  } = usingColumns;

  const { hospitalID } = useSelectedHospitalInfo();

  const {
    control,
    formState: { errors, isSubmitted },
    setValue,
  } = useFormContext<{
    rows: CustomerManagementTableRowArr;
  }>();

  const mutation = useMutation({
    mutationFn: (params: Parameters<typeof postPatientsTemporaryInfo>[0]) =>
      postPatientsTemporaryInfo(params),
    onError: (error) => {
      console.error('임시 고객정보 저장 에러 발생 :', error);
    },
    onSettled: () => {
      setIsTableTemporarySaving(false);
    },
  });

  const isInitializingRef = useRef(false);
  const inputRefs = useRef<Array<Array<HTMLInputElement | HTMLDivElement | null>>>([]);

  const setInputRef = (
    rowIndex: number,
    colIndex: number,
    element: HTMLInputElement | HTMLDivElement | null,
  ) => {
    if (!inputRefs.current[rowIndex]) {
      inputRefs.current[rowIndex] = [];
    }
    inputRefs.current[rowIndex][colIndex] = element;
  };
  const setIsTableTemporarySaving = useSetAtom(isTableTemporarySavingState);

  const rows = useWatch({ name: 'rows', control });
  const {
    selectedCell,
    focusedCell,
    handleCellClick,
    resetFocusedCell,
    handleChangeFocusedCell,
    handleChangeSelectedCell,
  } = useKeyboardNavigation({
    maxRows: rows.length,
    ...usingColumns,
  });
  const countriesListWithDialingCodes = useMemo(() => {
    return countriesList.map((item) => {
      return {
        name: `${SHARED_UTILS.text.truncateText(item.koreanCountryName ?? '', 10)}(+${item.internationalDialingCodes})`,
        id: item.countryId,
      };
    });
  }, [countriesList]);

  const columnsToRender = useMemo(
    () => [
      // 고객명
      { visible: true },
      // 차트번호
      { visible: usingChartNumber },
      // 초/재진
      { visible: usingFirstVisitStartDate },
      // 국가번호
      { visible: usingCountryCode },
      // 휴대폰번호
      { visible: true },
      // 생년월일
      { visible: usingBirthDay },
      // 성별
      { visible: usingGender },
      // 치료태그
      { visible: usingTreatmentTag },
      // 국적
      { visible: usingNationality },
    ],
    [
      usingChartNumber,
      usingFirstVisitStartDate,
      usingCountryCode,
      usingBirthDay,
      usingGender,
      usingTreatmentTag,
      usingNationality,
    ],
  );

  const activeColumns = useMemo(
    () =>
      columnsToRender
        .map((col, index) => (col.visible ? index : null))
        .filter((index) => index !== null),
    [columnsToRender],
  );

  const debouncedPostFormData = debounce((value: CustomerManagementTemporaryAPIFormValues) => {
    const updatedValues = value
      .map((row) => {
        const updatedRow = { ...row };

        const { countryCodeId, hospitalID, order, ...otherFields } = updatedRow;

        const allOtherFieldsUndefined = Object.values(otherFields).every(
          (value) => value === undefined,
        );

        return (countryCodeId !== KOREA_COUNTRY_CODE_ID || !allOtherFieldsUndefined) &&
          row !== undefined &&
          Object.values(row).some((value) => value !== undefined)
          ? { ...updatedRow, hospitalID }
          : undefined;
      })
      .filter((row) => row !== undefined);

    if (updatedValues.length === 0) return;

    const sortedValues = updatedValues.sort((a, b) => (a.order ?? 0) - (b.order ?? 0));

    const firstOrder = sortedValues[0]?.order ?? 0;
    const maxOrder = Math.max(...sortedValues.map((row) => row?.order ?? 0));

    const filledValues = [];

    for (let i = firstOrder; i <= maxOrder; i++) {
      const existingRow = sortedValues.find((row) => row?.order === i);

      if (existingRow) {
        filledValues.push(existingRow);
      } else {
        filledValues.push({ hospitalID, order: i, name: '' });
      }
    }

    if (filledValues.length > 0) {
      mutation.mutate(filledValues);
    }
  }, 300);

  useEffect(() => {
    console.log('rows', rows);
  }, [rows]);

  useEffect(() => {
    if (!rows.length) return;

    if (isInitializingRef.current) {
      isInitializingRef.current = false;
      return;
    }

    const filteredRows = rows.filter((row) => {
      const { order, countryCode, ...otherFields } = row;

      return Object.values(otherFields).some(
        (value) => value !== undefined && value !== null && value !== '',
      );
    });

    if (filteredRows.length > 0) {
      const rowsWithOrder: CustomerManagementTemporaryAPIFormValues = filteredRows.map(
        (
          {
            birthdate,
            chartNumber,
            countryCode,
            gender,
            isFirstVisit,
            name,
            nationality,
            order,
            phoneNumber,
            treatmentTags,
          },
          index,
        ) => {
          return {
            hospitalID,
            order: order ?? index + 1,
            birthdate,
            chartNumber,
            gender,
            isFirstVisit,
            name,
            phoneNumber,
            countryCodeId: countryCode?.id ?? KOREA_COUNTRY_CODE_ID,
            nationalityId: nationality?.id,
            treatmentTagIds: treatmentTags?.map((tag) => tag?.tagId ?? tag),
          };
        },
      );

      debouncedPostFormData(rowsWithOrder);
      setIsTableTemporarySaving(true);
    }

    return () => {
      debouncedPostFormData.cancel();
      setIsTableTemporarySaving(false);
    };
  }, [rows]);

  useEffect(() => {
    // 선택된 셀이 없을 경우
    if (selectedCell.row === null || selectedCell.col === null) return;

    // 선택된 셀과 포커스된 셀이 일치할 때 포커스 설정
    const inputElement = inputRefs.current[selectedCell.row]?.[selectedCell.col];

    if (inputElement) {
      if (focusedCell.row === selectedCell.row && focusedCell.col === selectedCell.col) {
        inputElement.classList.remove('hidden-caret');
        inputElement.focus();
      } else {
        inputElement.classList.add('hidden-caret');
        inputElement.blur();
      }
    }
  }, [selectedCell, focusedCell, inputRefs]);

  return (
    <tbody>
      {visibleRows.map((row) => {
        const { order, isFirstVisit, countryCode, treatmentTags, gender, nationality } = row;

        return (
          <tr key={order}>
            <td
              key={order}
              className='w-58 border-line-table border-y border-r px-12 py-10 text-Body12'>
              {order}
            </td>
            {activeColumns.map((colIndex, index) => {
              const rowOrder = order ?? 0;
              switch (colIndex) {
                case 0:
                  return (
                    <TableCell
                      key={`name-${rowOrder - 1}`}
                      onClick={() => handleCellClick(rowOrder - 1, index)}
                      selectedCell={selectedCell}
                      rowIndex={rowOrder - 1}
                      colIndex={index}
                      className='w-[128px]'>
                      <Controller
                        name={`rows.${rowOrder - 1}.name`}
                        control={control}
                        render={({ field }) => (
                          <TableInput
                            {...field}
                            ref={(el) => setInputRef(rowOrder - 1, index, el)}
                            isSelected={renderIsSelected(rowOrder - 1, index, selectedCell)}
                            isFocused={renderIsFocused(rowOrder - 1, index, focusedCell)}
                            type='text'
                            placeholder='예) 홍길동'
                            hasError={isSubmitted && !!errors.rows?.[rowOrder - 1]?.name}
                          />
                        )}
                      />
                    </TableCell>
                  );

                case 1:
                  return (
                    <TableCell
                      key={`chartNumber-${rowOrder - 1}`}
                      onClick={() => handleCellClick(rowOrder - 1, index)}
                      rowIndex={rowOrder - 1}
                      colIndex={index}
                      selectedCell={selectedCell}
                      className='w-[130px]'>
                      <Controller
                        name={`rows.${rowOrder - 1}.chartNumber`}
                        control={control}
                        render={({ field }) => (
                          <TableInput
                            {...field}
                            ref={(el) => setInputRef(rowOrder - 1, index, el)}
                            onDelete={() => {
                              setValue(`rows.${rowOrder - 1}.chartNumber`, '');
                            }}
                            isFocused={renderIsFocused(rowOrder - 1, index, focusedCell)}
                            isSelected={renderIsSelected(rowOrder - 1, index, selectedCell)}
                            type='text'
                            placeholder='예) 00000'
                            hasError={isSubmitted && !!errors.rows?.[rowOrder - 1]?.chartNumber}
                          />
                        )}
                      />
                    </TableCell>
                  );

                case 2:
                  return (
                    <TableCell
                      key={`isFirstVisit-${rowOrder - 1}`}
                      onClick={() => handleCellClick(rowOrder - 1, index)}
                      rowIndex={rowOrder - 1}
                      selectedCell={selectedCell}
                      colIndex={index}
                      className='w-[100px]'>
                      <Controller
                        name={`rows.${rowOrder - 1}.isFirstVisit`}
                        control={control}
                        render={({ field }) => (
                          <SingleTagSelectBoxTagDropdownForTable
                            {...field}
                            width={120}
                            onRemoveTag={() => {
                              handleChangeFocusedCell(rowOrder - 1, index);
                              handleChangeSelectedCell(rowOrder - 1, index);
                            }}
                            ref={(el) => setInputRef(rowOrder - 1, index, el)}
                            placeholder='선택'
                            defaultSelectedTag={
                              isFirstVisit !== undefined
                                ? {
                                    name: isFirstVisit
                                      ? VISIT_DISPLAY.FIRST_VISIT
                                      : VISIT_DISPLAY.REVISIT,
                                  }
                                : undefined
                            }
                            isFocused={renderIsFocused(rowOrder - 1, index, focusedCell)}
                            isSelected={renderIsSelected(rowOrder - 1, index, selectedCell)}
                            tagOptions={VISIT_OPTIONS.map((option) => ({
                              name: option,
                              id: option,
                            }))}
                            onSelectedTagChange={(tag) => {
                              if (!tag) {
                                field.onChange(undefined);
                                return;
                              }
                              field.onChange(tag.name === VISIT_DISPLAY.FIRST_VISIT);
                            }}
                            handleClickOutside={() => {
                              resetFocusedCell();
                            }}
                          />
                        )}
                      />
                    </TableCell>
                  );

                case 3:
                  return (
                    <TableCell
                      key={`countryCode-${rowOrder - 1}`}
                      onClick={() => handleCellClick(rowOrder - 1, index)}
                      rowIndex={rowOrder - 1}
                      colIndex={index}
                      selectedCell={selectedCell}
                      className='w-[113px]'>
                      <Controller
                        name={`rows.${rowOrder - 1}.countryCode`}
                        control={control}
                        render={({ field }) => (
                          <SingleTagSelectBoxTagDropdownForTable
                            {...field}
                            width={166}
                            ref={(el) => setInputRef(rowOrder - 1, index, el)}
                            placeholder='선택'
                            isChangeOnly
                            isFocused={renderIsFocused(rowOrder - 1, index, focusedCell)}
                            isSelected={renderIsSelected(rowOrder - 1, index, selectedCell)}
                            maxTextLength='full'
                            defaultSelectedTag={
                              countryCode
                                ? {
                                    name: `${countryCode.koreanCountryName}(+${countryCode.internationalDialingCodes})`,
                                    id: countryCode.id,
                                  }
                                : {
                                    name: '한국(+82)',
                                    id: KOREA_COUNTRY_CODE_ID,
                                  }
                            }
                            tagOptions={countriesListWithDialingCodes}
                            onSelectedTagChange={(tag) => {
                              if (!tag) {
                                field.onChange(undefined);
                                return;
                              }
                              field.onChange({
                                name: tag.name,
                                id: tag.id,
                              });
                            }}
                            handleClickOutside={() => {
                              resetFocusedCell();
                            }}
                          />
                        )}
                      />
                    </TableCell>
                  );

                case 4:
                  return (
                    <TableCell
                      key={`phoneNumber-${rowOrder - 1}`}
                      onClick={() => handleCellClick(rowOrder - 1, index)}
                      rowIndex={rowOrder - 1}
                      selectedCell={selectedCell}
                      colIndex={index}
                      className='w-[130px]'>
                      <Controller
                        name={`rows.${rowOrder - 1}.phoneNumber`}
                        control={control}
                        render={({ field }) => (
                          <TableInput
                            {...field}
                            ref={(el) => setInputRef(rowOrder - 1, index, el)}
                            onDelete={() => {
                              setValue(`rows.${rowOrder - 1}.phoneNumber`, '');
                            }}
                            isFocused={renderIsFocused(rowOrder - 1, index, focusedCell)}
                            isSelected={renderIsSelected(rowOrder - 1, index, selectedCell)}
                            type='number'
                            placeholder='예) 01012345678'
                            hasError={isSubmitted && !!errors.rows?.[rowOrder - 1]?.phoneNumber}
                          />
                        )}
                      />
                    </TableCell>
                  );

                case 5:
                  return (
                    <TableCell
                      key={`birthdate-${rowOrder - 1}`}
                      onClick={() => handleCellClick(rowOrder - 1, index)}
                      rowIndex={rowOrder - 1}
                      selectedCell={selectedCell}
                      colIndex={index}
                      className='w-[112px]'>
                      <Controller
                        name={`rows.${rowOrder - 1}.birthdate`}
                        control={control}
                        render={({ field }) => (
                          <TableInput
                            {...field}
                            ref={(el) => setInputRef(rowOrder - 1, index, el)}
                            value={field.value}
                            onBlur={field.onBlur}
                            maxLength={10}
                            isFocused={renderIsFocused(rowOrder - 1, index, focusedCell)}
                            isSelected={renderIsSelected(rowOrder - 1, index, selectedCell)}
                            type='text'
                            placeholder='예) YYYY-MM-DD'
                            onChange={(e) => {
                              let inputValue = e.target.value.replace(/[^0-9]/g, '');
                              if (inputValue.length >= 4) {
                                inputValue = `${inputValue.slice(0, 4)}-${inputValue.slice(4)}`;
                              }
                              if (inputValue.length >= 7) {
                                inputValue = `${inputValue.slice(0, 7)}-${inputValue.slice(7, 10)}`;
                              }
                              field.onChange(inputValue);
                            }}
                            onKeyDown={(e) => {
                              const value = e.currentTarget.value;

                              if (e.key === 'Delete') {
                                field.onChange('');
                                setValue(`rows.${rowOrder - 1}.birthdate`, '');
                              }

                              if (e.key === 'Backspace') {
                                if (value.endsWith('-')) {
                                  e.preventDefault();
                                  field.onChange(value.slice(0, -1));
                                }
                              }
                            }}
                          />
                        )}
                      />
                    </TableCell>
                  );

                case 6:
                  return (
                    <TableCell
                      key={`gender-${rowOrder - 1}`}
                      onClick={() => handleCellClick(rowOrder - 1, index)}
                      rowIndex={rowOrder - 1}
                      selectedCell={selectedCell}
                      colIndex={index}
                      className='w-[80px]'>
                      <Controller
                        name={`rows.${rowOrder - 1}.gender`}
                        control={control}
                        render={({ field }) => (
                          <SingleTagSelectBoxTagDropdownForTable
                            {...field}
                            width={120}
                            onRemoveTag={() => {
                              handleChangeFocusedCell(rowOrder - 1, index);
                              handleChangeSelectedCell(rowOrder - 1, index);
                            }}
                            ref={(el) => setInputRef(rowOrder - 1, index, el)}
                            placeholder='선택'
                            isFocused={renderIsFocused(rowOrder - 1, index, focusedCell)}
                            isSelected={renderIsSelected(rowOrder - 1, index, selectedCell)}
                            defaultSelectedTag={
                              gender !== undefined
                                ? {
                                    name: GENDER_DISPLAY[gender],
                                    id: gender,
                                  }
                                : undefined
                            }
                            tagOptions={GENDER_OPTIONS.map((option) => {
                              return {
                                name: GENDER_DISPLAY[option],
                                id: option,
                              };
                            })}
                            onSelectedTagChange={(tag) => {
                              if (!tag) {
                                field.onChange(undefined);
                                return;
                              }

                              field.onChange(
                                tag.name === GENDER_DISPLAY.MALE
                                  ? GENDER_OPTIONS[0]
                                  : GENDER_OPTIONS[1],
                              );
                            }}
                            handleClickOutside={() => {
                              resetFocusedCell();
                            }}
                          />
                        )}
                      />
                    </TableCell>
                  );

                case 7:
                  return (
                    <TableCell
                      key={`treatmentTags-${rowOrder - 1}`}
                      onClick={() => handleCellClick(rowOrder - 1, index)}
                      rowIndex={rowOrder - 1}
                      selectedCell={selectedCell}
                      colIndex={index}
                      className='min-w-[150px]'>
                      <Controller
                        name={`rows.${rowOrder - 1}.treatmentTags`}
                        control={control}
                        render={({ field }) => (
                          <MultipleTagsSelectBoxTagDropdownForTable
                            {...field}
                            ref={(el) => setInputRef(rowOrder - 1, index, el)}
                            isFocused={renderIsFocused(rowOrder - 1, index, focusedCell)}
                            isSelected={renderIsSelected(rowOrder - 1, index, selectedCell)}
                            placeholder={'예) 도수치료, 리프팅'}
                            onRemoveTag={() => {
                              handleChangeFocusedCell(rowOrder - 1, index);
                              handleChangeSelectedCell(rowOrder - 1, index);
                            }}
                            tagOptions={
                              treatmentTagsList?.map((tag) => ({
                                name: tag.name,
                                id: tag.tagId,
                                color: tag.color,
                              })) ?? []
                            }
                            defaultSelectedTags={treatmentTags?.map((tag) => ({
                              name: tag.name,
                              id: tag.tagId,
                              color: tag.color,
                            }))}
                            onSelectedTagsChange={(tags) => {
                              field.onChange(tags.map((tag) => tag.id));
                            }}
                            handleClickOutside={() => {
                              resetFocusedCell();
                            }}
                          />
                        )}
                      />
                    </TableCell>
                  );

                case 8:
                  return (
                    <TableCell
                      key={`nationality-${rowOrder - 1}`}
                      onClick={() => handleCellClick(rowOrder - 1, index)}
                      rowIndex={rowOrder - 1}
                      selectedCell={selectedCell}
                      colIndex={index}
                      className='w-[140px]'>
                      <Controller
                        name={`rows.${rowOrder - 1}.nationality`}
                        control={control}
                        render={({ field }) => (
                          <SingleTagSelectBoxTagDropdownForTable
                            {...field}
                            width={140}
                            onRemoveTag={() => {
                              handleChangeFocusedCell(rowOrder - 1, index);
                              handleChangeSelectedCell(rowOrder - 1, index);
                            }}
                            ref={(el) => setInputRef(rowOrder - 1, index, el)}
                            placeholder='선택'
                            isFocused={renderIsFocused(rowOrder - 1, index, focusedCell)}
                            isSelected={renderIsSelected(rowOrder - 1, index, selectedCell)}
                            defaultSelectedTag={
                              nationality !== undefined
                                ? {
                                    name: nationality.koreanCountryName,
                                  }
                                : undefined
                            }
                            tagOptions={countriesList.map((item) => ({
                              name: item.koreanCountryName ?? '',
                              id: item.countryId,
                            }))}
                            onSelectedTagChange={(tag) => {
                              if (tag?.id) {
                                field.onChange({
                                  name: tag.name,
                                  id: tag.id,
                                });
                              } else {
                                field.onChange(undefined);
                              }
                            }}
                            handleClickOutside={() => {
                              resetFocusedCell();
                            }}
                          />
                        )}
                      />
                    </TableCell>
                  );

                default:
                  return null;
              }
            })}
          </tr>
        );
      })}
    </tbody>
  );
}

const TableCell = ({
  rowIndex,
  colIndex,
  selectedCell,
  onClick,
  children,
  className,
}: {
  rowIndex: number;
  colIndex: number;
  selectedCell: SelectedCell;
  onClick: () => void;
  children: ReactNode;
  className?: string;
}) => (
  <td {...getTableCellProps(rowIndex, colIndex, selectedCell, onClick, className)}>{children}</td>
);
