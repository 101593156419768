import { atom } from 'jotai';
import type { ErrorMessage } from 'react-use-downloader/dist/types';

// 파일 진행 상태와 완료 플래그를 포함하는 객체의 타입 정의
type FileProgress = {
  progress: number;
  error?: ErrorMessage | null;
  triggerCancel?: boolean;
  triggerReload?: boolean;
};
type FileDownloadHistory = {
  isComplete: boolean;
};
type FileDownloadHistoryMap = Map<string, FileDownloadHistory>;
type FileProgressMap = Map<string, FileProgress>;

// Map 자료구조를 초기값으로 가지는 atom 생성
export const chatFileProgressAtom = atom<FileProgressMap>(new Map());
export const chatFileDownloadHistoryAtom = atom<FileDownloadHistoryMap>(new Map());

export const setFileProgressAtom = atom(
  null, // 읽기 전용이므로 null
  (
    get,
    set,
    {
      fileName,
      progress = 0,
      error = null,
      triggerCancel = false,
      triggerReload = false,
    }: {
      fileName: string;
      progress?: number;
      error?: ErrorMessage | null;
      triggerCancel?: boolean;
      triggerReload?: boolean;
    },
  ) => {
    const currentMap = new Map(get(chatFileProgressAtom)); // 기존 Map 복사
    const currentHistoryMap = new Map(get(chatFileDownloadHistoryAtom)); // 기존 Map 복사

    const fileProgress = currentMap.get(fileName) || {
      progress: 0,
      error: null,
      triggerCancel: false,
      triggerReload: false,
    }; // 파일 진행 상태 가져오기
    const fileDownloadHistory = currentMap.get(fileName) || {
      isComplete: false,
    }; // 파일 진행 상태 가져오기

    // 진행 상태 업데이트
    const updatedProgress: FileProgress = {
      ...fileProgress,
      progress,
      error,
      triggerCancel,
      triggerReload,
    };
    const updatedFileDownloadHistory: FileDownloadHistory = {
      ...fileDownloadHistory,
      isComplete: progress >= 100, // 진행 상태가 100%이면 isComplete 플래그를 true로 설정
    };

    currentMap.set(fileName, updatedProgress); // Map에 업데이트된 진행 상태와 완료 플래그 설정
    currentHistoryMap.set(fileName, updatedFileDownloadHistory); // Map에 업데이트된 진행 상태와 완료 플래그 설정

    set(chatFileProgressAtom, currentMap); // atom 업데이트
    set(chatFileDownloadHistoryAtom, currentHistoryMap); // atom 업데이트
  },
);

// deleteFileProgressAtom은 map에서 fileName 키 오브젝트를 삭제하는 atom
export const deleteFileProgressAtom = atom(null, (get, set, fileName: string) => {
  const currentMap = new Map(get(chatFileProgressAtom)); // 기존 Map 복사
  currentMap.delete(fileName); // fileName에 해당하는 진행 상태 삭제
  set(chatFileProgressAtom, currentMap); // atom 업데이트
});
