import TextFieldSelectBoxDropdown from '@afterdoc-design-system/components/Molecules/TextSelect/TextFieldSelectBoxDropdown';
import { SHARED_UTILS } from '@shared-utils/utils';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useFormContext } from 'react-hook-form';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ApiServiceSettingsElCountriesParams } from 'web/apis/swaggers/swagger-docs';
import type { usePatientDetailInfo } from 'web/shared/hooks/use-get-patient-detail-info';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import type { APIFormValues } from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/CareAndCustomerInfoInputForm/CareAndCustomerInfoInputForm.container';

const fetchCountriesCodesList = async (params: ApiServiceSettingsElCountriesParams) => {
  const response = await apiClient.v3.apiServiceSettingsElCountries(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

interface CountriesCodesListDropdownProps {
  countryCode: ReturnType<typeof usePatientDetailInfo>['countryCode'];
  disabled?: boolean;
}

function CountriesCodesListDropdown({
  countryCode,
  disabled = false,
}: CountriesCodesListDropdownProps) {
  const { hospitalID } = useSelectedHospitalInfo();
  const { setValue } = useFormContext<APIFormValues>();

  const { data } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiServiceSettingsElCountries, { hospitalID, limit: 240 }] as const,
    queryFn: ({ queryKey }) => fetchCountriesCodesList(queryKey[1]),
  });

  return (
    <TextFieldSelectBoxDropdown
      disabled={disabled}
      options={data.map((item) => `${item.koreanCountryName}(${item.internationalDialingCodes})`)}
      onSelect={(index) => {
        setValue('countryCodeId', data[index].countryId);
      }}
      selectedIndex={data.findIndex((item) => item.countryId === countryCode.id)}
      label={{
        isRequired: true,
        children: '국가번호',
      }}
      placeholder='선택'
      defaultValue={data.find((item) => item.countryCodeISOAlpha3 === 'KOR')?.koreanCountryName}
    />
  );
}

export default CountriesCodesListDropdown;
