import axios from 'axios';
import * as URL from './urls';

const getToken = () => {
  const tokenString = localStorage.getItem('token') as string;
  if (tokenString === undefined) return;
  return JSON.parse(tokenString);
};
const getHeaders = () => {
  return {
    'Content-Type': 'application/json',
    Authorization: getToken(),
  };
};
const getALToken = () => {
  const infos = JSON.parse(localStorage.getItem('hospitalInfos') ?? '{}');
  const currentHospitalID = JSON.parse(localStorage.getItem('selectedHospitalID') ?? '{}');
  const info = infos.find((h: { _id: string | null }) => h._id == currentHospitalID);
  const token = info?.integrationSetting?.clientToken ?? '';
  return `Bearer ${token}`;
};
const getALHeaders = () => {
  return {
    'Content-Type': 'application/json',
    Authorization: getALToken(),
  };
};

export const findUserWithCustomerName = async (name: string) => {
  return await axios({
    method: 'get',
    url: URL.FIND_USER_NAME,
    params: { name: name },
    headers: getALHeaders(),
  });
};
export const findUserWithPhoneNumber = async (phoneNumber: string) => {
  return await axios({
    method: 'get',
    url: URL.FIND_USER_NAME,
    params: { cellPhone: phoneNumber },
    headers: getALHeaders(),
  });
};
export const findUserWithCustomerNumber = async (customerNumber: string) => {
  const url = URL.FIND_USER_CUSTOMER_NUMBER.replaceAll('{customerNumber}', customerNumber);
  return await axios({
    method: 'get',
    url: url,
    headers: getALHeaders(),
  });
};

export const findUserWithALCustomerNumber = async (hospitalID: string, customerNumber: string) => {
  return await axios({
    method: 'get',
    url: URL.FIND_USER_ALCUSTOMER_NUMBER,
    params: { hospitalID: hospitalID, customerNumber: customerNumber },
    headers: getHeaders(),
  });
};
export const updateUserWithALCustomerNumber = async (
  hserviceID: string,
  ALCustomerNumber: string | null,
  ALCustomerNumbers: (string | undefined)[] | undefined,
) => {
  return await axios({
    method: 'post',
    url: URL.UPDATE_HSERVICE_CUSTOMER_NUMBER,
    data: {
      hserviceID: hserviceID,
      ALCustomerNumber: ALCustomerNumber,
      ALCustomerNumbers: ALCustomerNumbers,
    },
    headers: getHeaders(),
  });
};
