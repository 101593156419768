import { SHARED_UTILS } from '@shared-utils/utils';
import { useMutation } from '@tanstack/react-query';
import { apiClient } from 'web/apis/instances/api-client';

export const updateTreatmentTags = async (
  params: Parameters<typeof apiClient.v3.apiTreatmentTagsElUpdate>[0],
) => {
  const response = await apiClient.v3.apiTreatmentTagsElUpdate(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export const useMutateTreatmentTags = () => {
  const updateTreatmentTagsMutation = useMutation({
    mutationFn: updateTreatmentTags,
  });

  return {
    updateTreatmentTagsMutation,
  };
};
