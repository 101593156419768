import { Color } from '@tailwind-base/styles/color';
import React, { Fragment, useMemo } from 'react';
import { v4 as uuidv4 } from 'uuid';
import { MESSAGE_TEXT_MAX_LENGTH, urlRegex } from '../../constants/message';

type ContentTextProps = {
  parts: string[];
  textColor?: string;
  isTruncate?: boolean; // Truncate 기능을 제어하는 flag
};

function ContentText({ parts, textColor = Color.black700, isTruncate = true }: ContentTextProps) {
  const truncatedParts = useMemo(() => {
    if (!isTruncate) return parts;

    let totalLength = 0;

    return parts.reduce<string[]>((acc, part) => {
      if (totalLength + part.length > MESSAGE_TEXT_MAX_LENGTH) {
        const remainingLength = MESSAGE_TEXT_MAX_LENGTH - totalLength;
        if (remainingLength > 0) {
          acc.push(`${part.slice(0, remainingLength)}...`);
        }
        return acc;
      }
      acc.push(part);
      totalLength += part.length;
      return acc;
    }, []);
  }, [parts, isTruncate]);

  return (
    <div
      className={`whitespace-pre-wrap break-all text-Body12 ${textColor}`}
      style={{ color: textColor }}>
      {truncatedParts.map((part, index) => {
        if (urlRegex.test(part)) {
          const href = part.startsWith('www') ? `http://${part}` : part;

          return (
            <a
              key={uuidv4()}
              href={href}
              className='text-blue500 underline'
              target='_blank'
              rel='noopener noreferrer'>
              {part}
            </a>
          );
        }

        return <Fragment key={uuidv4()}>{part}</Fragment>;
      })}
    </div>
  );
}

export default React.memo(ContentText);
