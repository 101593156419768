import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type { ApiEventHospitalEventElCountParams } from '@apis/swaggers/swagger-docs';
import EventListBody from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/components/EventList/components/EventListBody/EventListBody';
import {
  eventListKeywordAtom,
  eventListQueryKeyState,
} from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/states/event-list-query-key-state';
import { eventTotalCountState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/Event/states/event-total-count-state';
import { EVENT_LIST_COLUMNS } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/constants/event-home-care-list-columns';
import EventHomeCareListSection from '@templates/Content/containers/ContentManager/containers/EventHomeCare/shared/components/EventHomeCareList/EventHomeCareListSection';
import ContentListCommonFooter from '@templates/Content/containers/ContentManager/shared/components/ContentListCommonFooter/ContentListCommonFooter';
import ContentSearchHeader from '@templates/Content/containers/ContentManager/shared/components/ContentSearchHeader/ContentSearchHeader';
import { useResetAtom } from 'jotai/utils';
import React, { useEffect } from 'react';
import { SHARED_UTILS } from 'utils/utils';

const fetchEventCount = async (params: ApiEventHospitalEventElCountParams) => {
  const response = await apiClient.v3.apiEventHospitalEventElCount(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export default function EventListContainer() {
  const resetEventListQueryKey = useResetAtom(eventListQueryKeyState);

  useEffect(() => resetEventListQueryKey(), []);

  return (
    <div className='flex min-h-screen w-full flex-col'>
      <div className='mx-auto w-[1150px] flex-col-center flex-grow bg-white50'>
        <ContentSearchHeader
          queryKey={QUERY_KEY.apiEventHospitalEventElCount}
          fetchCount={fetchEventCount}
          totalCountState={eventTotalCountState}
          keywordState={eventListKeywordAtom}
          inputPlaceholder='이벤트 제목으로 검색해 주세요.'
        />
        <div className='flex h-full w-full flex-grow flex-col'>
          <EventHomeCareListSection
            columns={EVENT_LIST_COLUMNS}
            queryKeyState={eventListQueryKeyState}
            totalCountState={eventTotalCountState}>
            <EventListBody />
          </EventHomeCareListSection>
        </div>
      </div>
      <ContentListCommonFooter type={'event'} />
    </div>
  );
}
