import IconButton from '@afterdoc-design-system/components/Atoms/Button/IconButton';
import TextButton from '@afterdoc-design-system/components/Atoms/Button/TextButton';
import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import HoverTooltip from '@afterdoc-design-system/components/Atoms/Tooltip/HoverTooltip';
import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import { dialogService } from '@afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import { modalService } from '@afterdoc-design-system/components/Molecules/Modal/Modal.service';
import Title from '@afterdoc-design-system/components/Molecules/Title/Title';
import { SHARED_UTILS } from '@shared-utils/utils';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Suspense, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import ALIntegrationEdit from 'web/shared/components/ALIntegrationEdit/ALIntegrationEdit';
import { useHandleCustomerInfo } from 'web/shared/hooks/overlayPage/use-handle-customer-detail-info';
import { usePatientDetailInfo } from 'web/shared/hooks/use-get-patient-detail-info';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { useUserInfo } from 'web/shared/hooks/use-user-info';
import ReInvitationButton from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/CustomerInfoTitle/components/ReInvitationButton';

const postApprovalChatroomCreate = async (
  params: Parameters<typeof apiClient.v3.apiApprovalChatroomElCreate>[0],
) => {
  const response = await apiClient.v3.apiApprovalChatroomElCreate(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const postChatroomUserAdd = async (
  params: Parameters<typeof apiClient.v3.apiChatroomElUserAdd>[0],
) => {
  const response = await apiClient.v3.apiChatroomElUserAdd(params);
  return response;
};

const postCustomerDelete = async (
  params: Parameters<typeof apiClient.v3.apiHservicesElSuspend>[0],
) => {
  const response = await apiClient.v3.apiHservicesElSuspend(params);
  return response;
};

export default function CustomerInfoTitleContainer() {
  const queryClient = useQueryClient();

  const { hospitalID, integrationSetting } = useSelectedHospitalInfo();
  const { userId, authorizationTypeID } = useUserInfo();

  // 전능연동된 병원
  const isSmartDoctorLinkedHospital =
    integrationSetting &&
    integrationSetting.isRemoved === false &&
    integrationSetting.clientToken &&
    integrationSetting.clientToken.length > 0;

  const {
    // 고객 id
    patientId,
    // 고객명
    name,
    // 애프터닥 가입 여부
    isJoinedUserToAfterDoc,
    // 카카오톡 연동 여부
    hasKakaoLinkage,
    // 스마트닥터 연동 여부
    hasSmartDoctorLinkage,
    // 이용제외 여부
    isClosed,
    // 채팅방 정보
    chatRoom: { id: chatRoomID, users, counselors },
  } = usePatientDetailInfo();
  const navigate = useNavigate();

  const { closeCustomerInfo } = useHandleCustomerInfo(patientId);

  const deleteCustomerMutation = useMutation({
    mutationFn: (params: { hserviceID: string; closeReason: string }) => postCustomerDelete(params),
    onSuccess: ({ data: { code } }) => {
      if (code === 0) {
        toastService.successMsg({
          text: '고객정보를 삭제했습니다.',
        });
        closeCustomerInfo();
        queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiPatientsElListOrSearch] });
        queryClient.invalidateQueries({ queryKey: [QUERY_KEY.apiPatientsElCount, { hospitalID }] });
        modalService.popById('interrupt-customer');
      } else {
        toastService.errorMsg({
          text: '고객정보 삭제를 실패했습니다.',
        });
      }
    },
    onError: (error) => {
      console.warn('error', error);
      toastService.errorMsg({
        text: '고객정보 삭제를 실패했습니다.',
      });
    },
  });

  const postApprovalChatroomCreateMutation = useMutation({
    mutationFn: (params: Parameters<typeof apiClient.v3.apiApprovalChatroomElCreate>[0]) =>
      postApprovalChatroomCreate(params),
    onError: (error) => {
      console.warn('error', error);
    },
  });

  const mutationPostChatroomUserAdd = useMutation({
    mutationFn: (params: { chatRoomID: string; userIDs: string[] }) => postChatroomUserAdd(params),
    onError: (error) => {
      console.warn('error', error);
    },
  });

  const handleBackClick = () => {
    closeCustomerInfo();
  };

  const onChatClick = () => {
    // Case 1. 상담 최고 권한 있음
    if (authorizationTypeID?.canControlPatientChatting) {
      // 고객 상담 - 해당 환자의 채팅방 화면으로 점프
      return navigate(`/customer-chat?chatRoomId=${chatRoomID}&patientId=${patientId}`);
    }

    // Case 2. 상담 최고 권한 없음, 해당 환자의 채팅방에 참여중인 경우
    if (!authorizationTypeID?.canControlPatientChatting) {
      if (users.findIndex((user) => user === userId) !== -1) {
        // chatRoom.users 의 _id 목록에 로그인한 사용자의 userID 가 있는지 대조.
        //  해당 환자의 채팅방 화면으로 점프
        return navigate(`/customer-chat?chatRoomId=${chatRoomID}&patientId=${patientId}`);
      }

      // Case 2-1. 상담 최고 권한 없음, 해당 환자의 채팅방에 참여하고 있지 않은 경우
      // chatRoom.counselors의 배열 값이 비어있지 않은지 체크
      if (counselors.length > 0) {
        // 담당자가 존재하는 경우
        modalService.defaultSuccess({
          id: 'chat-join',
          title: '채팅 참여',
          buttonType: 'BOTH',
          buttonText: ['취소', '확인'],
          onConfirm: () => {
            postApprovalChatroomCreateMutation.mutate({
              chatRoomID,
              hospitalID,
              type: 'join',
            });
          },
          onCancel: () => {
            modalService.popById('chat-join');
          },
          contents: '참여하지 않은 채팅입니다. 채팅에 참여하시겠습니까?',
        });
      } else {
        // Case 2-2. 상담 최고 권한 없음, 해당 환자의 채팅방에 참여하고 있지않으나, 담당자가 비어있는 경우.
        // 담당자가 없는 경우
        modalService.defaultSuccess({
          id: 'chat-join',
          title: '채팅 참여',
          buttonType: 'BOTH',
          buttonText: ['취소', '확인'],
          onConfirm: () => {
            mutationPostChatroomUserAdd.mutate({
              chatRoomID,
              userIDs: [userId],
            });
          },
          onCancel: () => {
            modalService.popById('chat-join');
          },
          contents: '참여하지 않은 채팅입니다. 채팅에 참여하시겠습니까?',
        });
      }
    }
  };

  const onDeleteCustomer = () => {
    deleteCustomerMutation.mutate({
      hserviceID: patientId,
      closeReason: '고객 삭제',
    });
  };

  const onOpenALIntegrationEdit = () => {
    dialogService.push(<ALIntegrationEdit patientId={patientId} dialogId={'ALIntegrationEdit'} />, {
      width: 'auto',
      id: 'ALIntegrationEdit',
    });
  };

  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        modalService.popById('leave-without-save');
      }
    };

    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);

  return (
    <div className='flex items-center gap-6'>
      <IconButton icon='chevron-left' size={24} onClick={handleBackClick} />
      <div className='w-full'>
        <Title
          wrapperClassName='px-0 pb-0'
          title={`${name}님의 세부정보`}
          adjacentChildren={{
            children: (
              <>
                {isClosed ? (
                  <div className='flex items-center gap-8 rounded-r10 bg-disabled py-[3.5px] pr-10 pl-6'>
                    <Icon name='warning' color='black500' size={16} />
                    <span className='text-Body12 text-black500'>이용중단</span>
                  </div>
                ) : (
                  <div className='flex items-center gap-8'>
                    <HoverTooltip
                      portalZindex={9999}
                      text={`애프터닥 ${isJoinedUserToAfterDoc ? '가입' : '미가입'} 고객입니다.`}
                      position='bottomRight'
                      wrapperProps={{
                        className: 'cursor-default',
                      }}>
                      {isJoinedUserToAfterDoc ? (
                        <Icon name='afterdoc' />
                      ) : (
                        <Icon name='afterdoc-non' />
                      )}
                    </HoverTooltip>
                    {hasKakaoLinkage && (
                      <HoverTooltip
                        text='카카오톡으로 유입된 고객입니다.'
                        portalZindex={9999}
                        position='bottomRight'
                        wrapperProps={{
                          className: 'cursor-default',
                        }}>
                        <Icon name='kakaotalk' />
                      </HoverTooltip>
                    )}
                    {isSmartDoctorLinkedHospital &&
                      (hasSmartDoctorLinkage ? (
                        <button
                          type='button'
                          className='flex items-center gap-4 rounded-r6 border border-white600 bg-white50 px-5 py-3'
                          onClick={() => {
                            onOpenALIntegrationEdit();
                          }}>
                          <HoverTooltip
                            text='스마트닥터에 계정 연동된 고객입니다.'
                            portalZindex={9999}
                            position='bottomRight'>
                            <Icon name='smart-doctor' size={17} />
                          </HoverTooltip>
                          <Icon name='chevron-right' size={16} color='black200' />
                        </button>
                      ) : (
                        <button
                          type='button'
                          className='flex items-center gap-4 rounded-r6 border border-white600 bg-white50 px-5 py-3'
                          onClick={() => {
                            onOpenALIntegrationEdit();
                          }}>
                          <Icon name='smart-doctor-non' size={17} />
                          <Icon name='chevron-right' size={16} color='black200' />
                        </button>
                      ))}
                  </div>
                )}
              </>
            ),
            position: 'right',
          }}
          trailingChildren={
            <div className='flex gap-40'>
              <Suspense>
                <ReInvitationButton />
              </Suspense>
              {!!authorizationTypeID?.canBanPatientToService && (
                <TextButton
                  onClick={() => {
                    modalService.defaultWarning({
                      id: 'interrupt-customer',
                      title: `${name}님을 이용중단할까요?`,
                      contents: (
                        <div className='whitespace-pre-wrap'>
                          {
                            '이용중단 시 채팅 및 예약 내역을 확인할 수 없으며,\n고객이 사용중인 앱 서비스의 이용이 제한됩니다.\n\n삭제를 계속하시려면 ‘네’를 선택해 주세요.'
                          }
                        </div>
                      ),
                      onConfirm: onDeleteCustomer,
                    });
                  }}>
                  이용중단
                </TextButton>
              )}
            </div>
          }
        />
        <button
          className='mt-2 flex items-center gap-4 py-2'
          disabled={isClosed}
          type='button'
          onClick={onChatClick}>
          <Icon name='chat-bubble-outline' color={isClosed ? 'disabled' : 'blue500'} size={20} />
          <span
            className={customTwMerge('text-Body13', isClosed ? 'text-disabled' : 'text-blue500')}>
            상담하기
          </span>
        </button>
      </div>
    </div>
  );
}
