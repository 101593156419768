import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type { ApiEventHospitalAftercareElCountParams } from '@apis/swaggers/swagger-docs';
import HomeCareListBody from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/HomeCare/components/HomeCareList/components/HomeCareListBody/HomeCareListBody';
import {
  homeCareListKeywordAtom,
  homeCareListQueryKeyState,
} from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/HomeCare/states/home-care-list-query-key-state';
import { homeCareTotalCountState } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/components/HomeCare/states/home-care-total-count-state';
import { HOME_CARE_LIST_COLUMNS } from '@templates/Content/containers/ContentManager/containers/EventHomeCare/constants/event-home-care-list-columns';
import EventHomeCareListSection from '@templates/Content/containers/ContentManager/containers/EventHomeCare/shared/components/EventHomeCareList/EventHomeCareListSection';
import ContentListCommonFooter from '@templates/Content/containers/ContentManager/shared/components/ContentListCommonFooter/ContentListCommonFooter';
import ContentSearchHeader from '@templates/Content/containers/ContentManager/shared/components/ContentSearchHeader/ContentSearchHeader';
import { useResetAtom } from 'jotai/utils';
import React, { useEffect } from 'react';
import { SHARED_UTILS } from 'utils/utils';

const fetchHomeCareCount = async (params: ApiEventHospitalAftercareElCountParams) => {
  const response = await apiClient.v3.apiEventHospitalAftercareElCount(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export default function HomeCareListContainer() {
  const resetHomeCareListQueryKey = useResetAtom(homeCareListQueryKeyState);

  useEffect(() => resetHomeCareListQueryKey(), []);

  return (
    <div className='flex min-h-screen w-full flex-col'>
      <div className='mx-auto w-[1150px] flex-col-center flex-grow bg-white50'>
        <ContentSearchHeader
          queryKey={QUERY_KEY.apiEventHospitalAftercareElCount}
          fetchCount={fetchHomeCareCount}
          totalCountState={homeCareTotalCountState}
          keywordState={homeCareListKeywordAtom}
          inputPlaceholder='홈케어 상품 제목으로 검색해 주세요.'
        />
        <div className='flex h-full w-full flex-grow flex-col'>
          <EventHomeCareListSection
            columns={HOME_CARE_LIST_COLUMNS}
            queryKeyState={homeCareListQueryKeyState}
            totalCountState={homeCareTotalCountState}>
            <HomeCareListBody />
          </EventHomeCareListSection>
        </div>
      </div>
      <ContentListCommonFooter type={'homeCare'} />
    </div>
  );
}
