import IconButton from '@afterdoc-design-system/components/Atoms/Button/IconButton';
import { useRef } from 'react';
import type { UserNameAndType } from 'web/apis/swaggers/swagger-docs';
import ChildDropdown from '../../../ParentDropDown/components/ChildDropdown/ChildDropdown';
import { useNextDropdown } from '../../../ParentDropDown/hooks/use-next-dropdown';
import { reorderUsersForCounselor } from '../../functions/reorderUsersForCounselor';

type ParticipantsChipProps = {
  users: UserNameAndType[];
  counselorID: string | undefined;
  targetChatRoomID: string;
};

const POSITION_OFFSET = 410;
const POSITION_DEFUALT = -50;

export default function ParticipantsChip({ users, counselorID }: ParticipantsChipProps) {
  const reorderedUsers = reorderUsersForCounselor(users);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const options = reorderedUsers.map((user) => user.name);
  const defaultIndex = counselorID ? reorderedUsers.findIndex(({ _id }) => _id === counselorID) : 0;

  const {
    nextDropdownPosition,
    isNextDropdownOpen,
    updateNextDropdownPosition,
    handleIsNextDropdownOpen,
  } = useNextDropdown();

  const handleDropdown = () => {
    const parentRect = dropdownRef.current?.getBoundingClientRect();
    const left = parentRect ? POSITION_OFFSET - parentRect.left - 70 : POSITION_DEFUALT;

    updateNextDropdownPosition({ top: 5, left });
    handleIsNextDropdownOpen();
  };

  return (
    <div className='w-auto max-w-[120px] rounded-[12px] border border-blue100 px-6 py-4'>
      <div
        className='ml-4 flex-center justify-between gap-3'
        onClick={handleDropdown}
        ref={dropdownRef}>
        <span className='line-clamp-1 text-Body11 text-blue500'>{options[defaultIndex]}</span>
        <IconButton
          icon={isNextDropdownOpen ? 'chevron-up' : 'chevron-down'}
          size={16}
          color='blue500'
        />
      </div>
      {isNextDropdownOpen && (
        <ChildDropdown
          triggerPosition={nextDropdownPosition}
          handleToggle={handleDropdown}
          isOnMouseLeave={true}
        />
      )}
    </div>
  );
}
