import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import ProgressBar from '@afterdoc-design-system/components/Molecules/ProgressBar/ProgressBar';
import Portal from '@afterdoc-design-system/shared/Portal/Portal';
import { v4 as uuidv4 } from 'uuid';
import type { AlgorithmMessage } from 'web/apis/swaggers/swagger-docs';
import useFileDownloadWithProgress from 'web/shared/hooks/files/use-file-download-with-progress';
import { extractFileName } from 'web/shared/utils/fileUpload/functions/extract-file-name';

interface VideoContentProps {
  video: NonNullable<NonNullable<AlgorithmMessage['content']>['video']>;
}

export default function VideoContent({ video }: VideoContentProps) {
  const fileNameParts = video.fileName?.split('.');
  const fileBaseName = fileNameParts?.slice(0, -1).join('.');
  const fileExtension = fileNameParts?.[fileNameParts.length - 1];

  const { cancelDownload, openFileFolder, progress, status, startDownload } =
    useFileDownloadWithProgress();

  const handleDownload = () => {
    if (!video.url) return;
    const fileName = extractFileName(video.url) ?? `image-${uuidv4()}`;
    startDownload(video.url, fileName, [{ name: 'Videos', extensions: ['mp4', 'mov', 'avi'] }]);
  };

  return (
    <>
      <button
        className='w-full rounded-r10 bg-white50 px-16 py-10 shadow-modal'
        type='button'
        onClick={handleDownload}>
        <div className='flex items-center justify-between'>
          <div className='flex items-center gap-10'>
            <div className='rounded-r10 border border-white400 bg-white100 p-6'>
              <Icon name='tray-download' size={24} color='black100' />
            </div>
            <div className='flex text-Body13 text-black200'>
              <span className='w-full max-w-[100px] truncate'>{fileBaseName}</span>
              <span>.{fileExtension}</span>
            </div>
          </div>
          <div className='whitespace-nowrap text-Body12 text-black200'>
            {video.fileSize &&
              Number.parseFloat((video.fileSize / (1024 * 1024)).toFixed(2)).toString()}{' '}
            MB
          </div>
        </div>
      </button>
      {!!status && (
        <Portal
          style={{
            position: 'fixed',
            zIndex: 10000,
            right: '40px',
            bottom: '40px',
            width: '630px',
          }}>
          <ProgressBar
            fileName='test'
            status={status}
            percentage={progress}
            onCancelClickCallback={(e) => {
              e.preventDefault();
              e.stopPropagation();

              cancelDownload();
            }}
            onFileOpenClickCallback={(e) => {
              e.preventDefault();
              e.stopPropagation();

              openFileFolder();
            }}
            shouldAutoHide={true}
            onReloadClickCallback={(e) => {
              e.preventDefault();
              e.stopPropagation();

              handleDownload();
            }}
          />
        </Portal>
      )}
    </>
  );
}
