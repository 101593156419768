import type { IconType } from 'afterdoc-design-system/components/Foundations/Icon/Icon';

export const ROUTES_PATH = {
  HOME: '/',
  ERROR: '/error',
  ADMIN_CHAT: '/admin-chat',
  CUSTOMER_CHAT: '/customer-chat',
  CUSTOMER_MANAGEMENT: '/customer-management',
  RESERVATION_MANAGEMENT: '/reservation-management',
  AUTOMATION: '/automation',
  CONTENT: '/content',
  HOSPITAL_MANUAL: '/hospital-manual',
  HOSPITAL_SETTING: '/hospital-setting',
  USER_INFO_SETTINGS: '/user-info-settings',
} as const;

export const MENU_ITEMS: {
  nonActiveIcon: IconType;
  activeIcon: IconType;
  title: string;
  hasLine: boolean;
  key: (typeof ROUTES_PATH)[keyof typeof ROUTES_PATH];
  isDev?: boolean;
}[] = [
  {
    nonActiveIcon: 'human-greeting-proximity',
    activeIcon: 'human-greeting-proximity',
    title: '관리자 채팅',
    hasLine: true,
    key: ROUTES_PATH.ADMIN_CHAT,
  },
  {
    nonActiveIcon: 'chat-bubble-outline',
    activeIcon: 'chat-bubble',
    title: '고객상담',
    hasLine: false,
    key: ROUTES_PATH.CUSTOMER_CHAT,
  },
  {
    nonActiveIcon: 'account-view-outline',
    activeIcon: 'account-view',
    title: '고객관리',
    hasLine: false,
    key: ROUTES_PATH.CUSTOMER_MANAGEMENT,
  },
  {
    nonActiveIcon: 'calendar-blank-outline',
    activeIcon: 'calendar-blank-outline',
    title: '예약 관리',
    hasLine: true,
    key: ROUTES_PATH.RESERVATION_MANAGEMENT,
  },
  {
    nonActiveIcon: 'auto-fix-high',
    activeIcon: 'auto-fix-high',
    title: '자동화',
    hasLine: false,
    key: ROUTES_PATH.AUTOMATION,
  },
  {
    nonActiveIcon: 'human-male-board-poll',
    activeIcon: 'human-male-board-poll',
    title: '콘텐츠',
    hasLine: true,
    key: ROUTES_PATH.CONTENT,
  },
  {
    nonActiveIcon: 'book-cog-outline',
    activeIcon: 'book-cog',
    title: '원내 매뉴얼',
    hasLine: false,
    key: ROUTES_PATH.HOSPITAL_MANUAL,
  },
  {
    nonActiveIcon: 'cog-outline',
    activeIcon: 'cog',
    title: '병원설정',
    hasLine: false,
    key: ROUTES_PATH.HOSPITAL_SETTING,
  },
] as const;
