import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import NoData from '@afterdoc-design-system/components/Molecules/NoData/NoData';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { useMemo } from 'react';
import type {
  ImageType,
  LocalImage,
  ServerImage,
} from 'web/shared/hooks/files/images/use-upload-multiple-images';

interface DisplayImagesProps {
  messageImage?: string;
}

export default function DisplayImages({ messageImage }: DisplayImagesProps) {
  const parsedImages: ImageType[] | undefined = useMemo(() => {
    if (!messageImage) return undefined;
    try {
      return JSON.parse(messageImage);
    } catch {
      return undefined;
    }
  }, [messageImage]);

  if (!parsedImages?.length) {
    return (
      <div className='flex flex-col gap-10 rounded-[12px] border-2 border-white50 bg-white100 px-8 py-22'>
        <NoData
          title='이미지를 등록해주세요'
          iconProps={{
            name: 'warning',
            size: 48,
            color: 'white600',
          }}
          className='[&>#title]:text-black200'
        />
      </div>
    );
  }

  const firstImageUrl =
    parsedImages.length > 0
      ? Object.keys(parsedImages[0]).includes('blobUrl')
        ? (parsedImages[0] as LocalImage).blobUrl
        : (parsedImages[0] as ServerImage).url
      : '';
  const secondImageUrl =
    parsedImages.length > 1
      ? Object.keys(parsedImages[1]).includes('blobUrl')
        ? (parsedImages[1] as LocalImage).blobUrl
        : (parsedImages[1] as ServerImage).url
      : '';

  return (
    <div className='flex flex-center gap-4'>
      <img
        src={firstImageUrl}
        alt='이미지'
        className={customTwMerge(
          'rounded-r16 bg-white50 object-cover shadow-modal',
          parsedImages.length > 1 ? 'h-[104px] w-[110px]' : 'h-[209px] w-[234px]',
        )}
      />
      {parsedImages.length > 1 && (
        <div className='relative h-[104px] w-[110px] rounded-r16 '>
          <img
            src={secondImageUrl}
            alt='이미지'
            className={customTwMerge(' h-full w-full rounded-r16 object-cover shadow-modal')}
          />
          <div className='absolute inset-0 rounded-r16 bg-black opacity-50 ' />
          <div className='absolute inset-0 flex items-center justify-center'>
            <div className='flex h-28 w-81 items-center justify-center gap-6 rounded-r16 bg-black800 bg-opacity-50 text-Body11 text-white50'>
              <Icon name='image-filter' color='white50' size={16} />총 {parsedImages.length}장
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
