import type { Hospital } from '@apis/swaggers/swagger-docs';
import type { HospitalInfoErrorType } from '@templates/HospitalSetting/types/hospital-info-error-type';

export const validateCustomerMessageSendParams = (params: Hospital) => {
  const errors: HospitalInfoErrorType = {
    shortName: null,
    operatingHours: null,
    address: null,
    inquiryMethod: null,
    autoGreeting: null,
    basicMessage: null,
    resendMessage: null,
    reservationMessage: null,
  };

  const { smsBasicMessageEdit, smsResendMessageEdit, smsReservationEdit } = params;

  const emptyMessageWarning = '내용을 입력해 주세요.';
  const missingLinkWarning = '{{링크}}는 필수값으로 입력된 내용에 포함되어야 합니다.';
  const requiredLink = '{{링크}}';

  const validateMessage = (
    message: string | undefined,
    messageType: keyof HospitalInfoErrorType,
  ) => {
    if (!message) {
      errors[messageType] = emptyMessageWarning;
    } else if (!message.includes(requiredLink)) {
      errors[messageType] = missingLinkWarning;
    }
  };

  validateMessage(smsBasicMessageEdit, 'basicMessage');
  validateMessage(smsResendMessageEdit, 'resendMessage');
  validateMessage(smsReservationEdit, 'reservationMessage');

  return Object.keys(errors).reduce((acc, key) => {
    const typedKey = key as keyof HospitalInfoErrorType;
    if (errors[typedKey] !== null) {
      acc[typedKey] = errors[typedKey];
    }
    return acc;
  }, {} as HospitalInfoErrorType);
};
