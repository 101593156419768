import ContainedButton from '@afterdoc-design-system/components/Atoms/Button/ContainedButton';
import TextButton from '@afterdoc-design-system/components/Atoms/Button/TextButton';
import Scrollbar from '@afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { dialogService } from '@afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import ProgressBar from '@afterdoc-design-system/components/Molecules/ProgressBar/ProgressBar';
import Title from '@afterdoc-design-system/components/Molecules/Title/Title';
import Portal from '@afterdoc-design-system/shared/Portal/Portal';
import { SHARED_UTILS } from '@shared-utils/utils';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { useSuspenseQuery } from '@tanstack/react-query';
import type { ChartOptions } from 'chart.js';
import { ArcElement, type ChartData, Chart as ChartJS, Legend, Tooltip } from 'chart.js';
import chartDataLabels from 'chartjs-plugin-datalabels';
import dayjs from 'dayjs';
import { josa } from 'es-hangul';
import { useEffect, useMemo, useState } from 'react';
import { Pie } from 'react-chartjs-2';
import { v4 as uuidv4 } from 'uuid';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type {
  AlgorithmMessage,
  ApiAutomationsElAlgorithmsResponsesParams,
} from 'web/apis/swaggers/swagger-docs';
import useFileDownloadWithProgress from 'web/shared/hooks/files/use-file-download-with-progress';
import { DIALOG_ID } from 'web/templates/Automation/containers/Viewer/AutomationZoomInOutViewer/components/components/MessageCardItem/components/SurveyContent/constants/dialog-id';

ChartJS.register(ArcElement, Tooltip, Legend, chartDataLabels);

const fetchSurveyResponses = async (params: ApiAutomationsElAlgorithmsResponsesParams) => {
  const response = await apiClient.v3.apiAutomationsElAlgorithmsResponses(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

interface SurveyDetailContentDialogProps {
  messageId: NonNullable<AlgorithmMessage['id']>;
  survey: NonNullable<NonNullable<AlgorithmMessage['content']>['survey']>;
}

export default function SurveyDetailContentDialog({
  messageId,
  survey,
}: SurveyDetailContentDialogProps) {
  const { data } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiAutomationsElAlgorithmsResponses, { messageId }] as const,
    queryFn: ({ queryKey }) => fetchSurveyResponses(queryKey[1]),
  });

  const [ExcelJS, setExcelJS] = useState<typeof import('exceljs') | null>(null);
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState<number | null>(null);

  const answerCounts = data.reduce(
    (counts, response) => {
      counts[response.answerIndex] = (counts[response.answerIndex] || 0) + 1;
      return counts;
    },
    {} as Record<number, number>,
  );

  const pieData: ChartData<'pie', number[], string> = {
    labels: survey.answers?.map((answer, index) => `[답변${index + 1}] ${answer}`) || [],
    datasets: [
      {
        data: Object.values(answerCounts),
        backgroundColor: survey.answers?.map((_, index) => {
          if (index === 0) return '#1056BF';
          if (index === 1) return '#33A8DA';
          return '#86D1E9';
        }),
        borderWidth: 1,
      },
    ],
  };

  const pieOptions: ChartOptions<'pie'> = {
    plugins: {
      legend: {
        display: false,
      },
      datalabels: {
        color: '#fff',
        formatter: (value: number) => {
          const percentage = ((value / totalResponses) * 100).toFixed(0);
          return `${percentage}%`;
        },
      },
    },
  };

  const filteredData = useMemo(
    () =>
      selectedAnswerIndex
        ? data.filter((item) => item.answerIndex + 1 === selectedAnswerIndex)
        : data,
    [selectedAnswerIndex, data],
  );

  const totalResponses = data.length;
  const selectedResponsesCount = filteredData.length;
  const selectedPercentage = ((selectedResponsesCount / totalResponses) * 100).toFixed(0);

  const handleLegendClick = (answerIndex: number) => {
    setSelectedAnswerIndex(answerIndex === selectedAnswerIndex ? null : answerIndex);
  };

  const { cancelDownload, openFileFolder, progress, startDownload, status } =
    useFileDownloadWithProgress();

  const handleDownload = async () => {
    if (!ExcelJS) return toastService.errorMsg({ text: '엑셀 파일 다운로드에 실패했습니다.' });
    if (!window?.electron) return alert('엑셀 다운로드는 일렉트론 앱에서만 가능합니다.');

    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('설문 응답 결과');

    worksheet.getColumn(1).width = 20;
    worksheet.getColumn(2).width = 15;
    worksheet.getColumn(3).width = 15;
    worksheet.getColumn(4).width = 10;
    worksheet.getColumn(5).width = 15;
    worksheet.getColumn(6).width = 15;
    worksheet.getColumn(7).width = 10;

    worksheet.mergeCells('A1:G1');
    const messageRow = worksheet.getCell('A1');
    messageRow.value = `메시지명: ${data[0]?.algorithmMessageName}`;
    messageRow.font = { name: 'Arial', bold: true, size: 14, color: { argb: 'FFFFFFFF' } };
    messageRow.alignment = { vertical: 'middle', horizontal: 'center' };
    messageRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF111111' },
    };
    worksheet.getRow(1).height = 25;

    const headerRow = worksheet.addRow([
      '응답일',
      '고객명',
      '차트번호',
      '성별',
      '생년월일',
      '휴대폰번호',
      '응답',
    ]);
    headerRow.font = { bold: true, color: { argb: 'FF222222' } };
    headerRow.alignment = { horizontal: 'left', vertical: 'middle' };
    headerRow.fill = {
      type: 'pattern',
      pattern: 'solid',
      fgColor: { argb: 'FF7F7F7F' },
    };
    headerRow.eachCell((cell) => {
      cell.border = {
        top: { style: 'thin' },
        bottom: { style: 'thin' },
        left: { style: 'thin' },
        right: { style: 'thin' },
      };
    });

    for (const item of data) {
      const row = worksheet.addRow([
        new Date(item.answeredAt).toLocaleString(),
        item.patientName,
        item.chartNumber,
        item.gender === 'M' ? '남' : '여',
        item.birth?.replace(/-/g, '').slice(2),
        item.phone,
        `[답변 ${item.answerIndex + 1}] ${survey.answers?.[item.answerIndex]}`,
      ]);

      row.alignment = { vertical: 'middle', horizontal: 'left' };
      row.eachCell((cell) => {
        cell.border = {
          top: { style: 'thin' },
          bottom: { style: 'thin' },
          left: { style: 'thin' },
          right: { style: 'thin' },
        };
      });
    }

    try {
      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], {
        type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      });

      const fileName = `${data[0]?.algorithmMessageName}_설문_응답_결과.xlsx`;
      if (window.electron) {
        startDownload(blob, fileName, [{ name: 'Excel Files', extensions: ['xlsx'] }]);
      }
    } catch (error) {
      console.error('엑셀 파일 생성 에러 발생:', error);
      toastService.errorMsg({ text: '엑셀 파일 생성에 실패했습니다.' });
    }
  };

  useEffect(() => {
    import('exceljs/dist/exceljs.min.js').then((module) => {
      setExcelJS(module as typeof import('exceljs'));
    });
  }, []);

  return (
    <>
      <Title
        title='설문 응답 결과'
        wrapperClassName='w-full'
        adjacentChildren={{
          children: (
            <TextButton
              disabled={!ExcelJS || !data.length}
              onClick={handleDownload}
              textColor='blue500'
              className='text-Body14'>
              결과 엑셀 다운로드
            </TextButton>
          ),
          position: 'right',
          className: 'w-full justify-between items-center',
        }}
      />

      <div className='mt-10 h-[618px] w-full bg-white100'>
        <Scrollbar>
          <div className='bg-white50 px-20'>
            <div className='flex items-center gap-10 rounded-r10 border-1 border-white400 bg-white100 px-20 py-10'>
              <div className='text-Header14 text-black500'>메시지명</div>
              <div className='text-Body13 text-black500'>{data[0]?.algorithmMessageName}</div>
            </div>
          </div>

          <div className='flex items-center justify-center bg-white50 px-20'>
            <div className='p-20'>
              {totalResponses > 0 ? (
                <div className='h-[150px] w-[150px]'>
                  <Pie data={pieData} options={pieOptions} />
                </div>
              ) : (
                <div className='h-[150px] w-[150px] flex-center rounded-full bg-white500 text-Body12'>
                  0%
                </div>
              )}
            </div>
            <div className='ml-4 w-1/2'>
              {pieData.labels?.map((label, index) => (
                <div
                  key={uuidv4()}
                  className='mb-1 flex cursor-pointer items-center'
                  onClick={() => handleLegendClick(index + 1)}>
                  <div
                    className='mr-8 h-12 w-12 shrink-0'
                    style={{
                      backgroundColor: (pieData.datasets?.[0].backgroundColor as string[])[index],
                      border: selectedAnswerIndex === index + 1 ? '2px solid #000' : 'none',
                    }}
                  />
                  <span
                    className={customTwMerge(
                      'truncate text-Body12 text-black500',
                      selectedAnswerIndex === index + 1 ? 'font-bold' : 'font-normal',
                    )}>
                    {label}
                  </span>
                </div>
              ))}
            </div>
          </div>

          <div className='bg-white100 pt-10'>
            <div className='mx-auto w-fit rounded-r100 bg-white50 px-20 py-12 text-Header14 text-black500'>
              <TitleText
                selectedAnswerIndex={selectedAnswerIndex}
                selectedResponsesCount={selectedResponsesCount}
                selectedPercentage={selectedPercentage}
                totalResponses={totalResponses}
              />
            </div>
            <ul className='flex flex-col gap-y-12 py-10'>
              {filteredData.map((response) => (
                <li key={response.patientId} className='px-20 py-4'>
                  <span className='flex w-full items-center justify-between'>
                    <span>
                      <span className='text-Header14 text-black700'>{response.patientName}</span>
                      {response.chartNumber && (
                        <span className='ml-4 text-Body11 text-black500'>
                          ({response.chartNumber})
                        </span>
                      )}
                    </span>
                    <span className='flex items-center gap-x-10'>
                      <span className='rounded-r6 bg-white300 px-8 text-Body12 text-black500'>
                        {response.gender === 'M' ? '남' : '여'}/
                        {dayjs(response.birth).format('YYYY-MM-DD')}
                      </span>
                      <span className='rounded-r6 bg-white300 px-8 text-Body12 text-black500'>
                        {response.phone}
                      </span>
                    </span>
                  </span>
                </li>
              ))}
            </ul>
          </div>
        </Scrollbar>
      </div>
      <div className='mx-auto px-10 py-20'>
        <ContainedButton onClick={() => dialogService.popById(DIALOG_ID)} btnColor='secondary'>
          닫기
        </ContainedButton>
      </div>
      {!!status && (
        <Portal
          style={{
            position: 'fixed',
            zIndex: 10000,
            right: '40px',
            bottom: '40px',
            width: '630px',
          }}>
          <ProgressBar
            fileName='test'
            status={status}
            percentage={progress}
            onCancelClickCallback={(e) => {
              e.preventDefault();
              e.stopPropagation();

              cancelDownload();
            }}
            onFileOpenClickCallback={(e) => {
              e.preventDefault();
              e.stopPropagation();

              openFileFolder();
            }}
            shouldAutoHide={true}
            onReloadClickCallback={(e) => {
              e.preventDefault();
              e.stopPropagation();

              handleDownload();
            }}
          />
        </Portal>
      )}
    </>
  );
}

const numberToHangul = (num: number): string => {
  const units = ['', '일', '이', '삼', '사', '오', '육', '칠', '팔', '구'];
  const tens = ['', '십', '이십', '삼십', '사십', '오십', '육십', '칠십', '팔십', '구십'];

  if (num < 10) return units[num];
  if (num < 100) return tens[Math.floor(num / 10)] + units[num % 10];
  return `${num}`;
};

interface TitleTextProps {
  selectedAnswerIndex: number | null;
  selectedResponsesCount: number;
  selectedPercentage: string;
  totalResponses: number;
}

const TitleText = ({
  selectedAnswerIndex,
  selectedResponsesCount,
  selectedPercentage,
  totalResponses,
}: TitleTextProps) => {
  if (selectedAnswerIndex) {
    const suffix = josa(`${numberToHangul(selectedAnswerIndex)}`, '으로/로').replace(
      /^.*?(으로|로)$/,
      '$1',
    );
    const textColor =
      selectedAnswerIndex === 1 ? '#1056BF' : selectedAnswerIndex === 2 ? '#33A8DA' : '#86D1E9';

    return (
      <>
        <span
          style={{
            color: textColor,
          }}>
          답변{selectedAnswerIndex}
        </span>
        <span>{suffix} 응답한 고객</span>&nbsp;
        <span
          style={{
            color: textColor,
          }}>
          {selectedResponsesCount}명({selectedPercentage}%)
        </span>
      </>
    );
  }

  if (totalResponses === 0) {
    return (
      <>
        <span>응답한 고객</span>&nbsp;
        <span
          style={{
            color: '#1056BF',
          }}>
          0명
        </span>
      </>
    );
  }

  return (
    <>
      <span>전체 고객</span>&nbsp;
      <span>{totalResponses}명</span>
    </>
  );
};
