import SearchInput from '@afterdoc-design-system/components/Atoms/Input/SearchInput';
import { josa } from 'es-hangul';
import { useAtomValue, useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { type KeyboardEvent, useEffect, useRef, useState } from 'react';
import { clickedPanelIndexState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/states/panel';
import { selectedCardInfoState } from 'web/templates/CustomerManagement/containers/ControlPanel/containers/OngoingConsultingAutomation/state/selected-card-info';
import { CUSTOMER_SEARCH_FIELD_OPTIONS } from '../constants/dropdown-items';
import { searchTextState } from '../states/search';
import {
  selectedSearchCriteriaIndexState,
  selectedSearchCriteriaState,
} from '../states/selected-search-criteria';
import { tabsState } from '../states/tabs';

export default function TableSearchInput() {
  const inputRef = useRef<HTMLInputElement>(null);

  const resetSearchText = useResetAtom(searchTextState);

  const setSearchText = useSetAtom(searchTextState);
  const setSelectedSearchCriteriaState = useSetAtom(selectedSearchCriteriaState);

  const selectedSearchCriteriaIndex = useAtomValue(selectedSearchCriteriaIndexState);
  const selectedTab = useAtomValue(tabsState);
  const selectedCardInfo = useAtomValue(selectedCardInfoState);
  const clickedPanelIndex = useAtomValue(clickedPanelIndexState);

  const [localSearchText, setLocalSearchText] = useState('');

  const handleKeyDown = async (e: KeyboardEvent) => {
    e.stopPropagation();

    if (!inputRef.current) return;

    if (e.key === 'Enter' && inputRef.current.value.length > 0) {
      setSearchText(inputRef.current.value);
      setSelectedSearchCriteriaState(
        CUSTOMER_SEARCH_FIELD_OPTIONS[selectedSearchCriteriaIndex].key,
      );
    }
  };

  const handleDelete = () => {
    setLocalSearchText('');
    resetSearchText();
    inputRef.current?.focus();
  };

  useEffect(() => {
    // 탭 변경 시 검색어 초기화
    setLocalSearchText('');
    resetSearchText();
  }, [selectedTab, clickedPanelIndex, selectedCardInfo, selectedSearchCriteriaIndex]);

  useEffect(() => {
    if (!localSearchText.length) {
      resetSearchText();
    }
  }, [localSearchText]);

  return (
    <SearchInput
      id='customer-management-search-input'
      // TODO : placeholder 변경
      placeholder={`${josa(CUSTOMER_SEARCH_FIELD_OPTIONS[selectedSearchCriteriaIndex].value, '으로/로')} 검색`}
      className='w-full'
      value={localSearchText}
      onChange={(e) => {
        setLocalSearchText(e.target.value);
      }}
      onKeyDown={handleKeyDown}
      onDelete={handleDelete}
      ref={inputRef}
    />
  );
}
