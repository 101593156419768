import * as React from "react";
import type { SVGProps } from "react";
const SvgHoverKeyboardCloseList = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 470 170"
    {...props}
  >
    <rect width={470} height={170} fill="#222" fillOpacity={0.2} rx={16} />
    <rect
      width={449.1}
      height={149.1}
      x={10.45}
      y={10.45}
      fill="#E6E6E6"
      rx={8.55}
    />
    <rect
      width={449.1}
      height={149.1}
      x={10.45}
      y={10.45}
      stroke="#D1D1D1"
      strokeWidth={0.9}
      rx={8.55}
    />
    <rect
      width={16.875}
      height={16.917}
      x={20.688}
      y={51.73}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={20.688}
      y={25.79}
      fill="#007AFF"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={58.938}
      y={25.79}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={145.562}
      y={25.79}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={231.062}
      y={25.79}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={78.063}
      y={25.79}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={164.688}
      y={25.79}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={250.188}
      y={25.79}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={97.188}
      y={25.79}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={183.812}
      y={25.79}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={269.312}
      y={25.79}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={116.312}
      y={25.79}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={202.938}
      y={25.79}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={288.438}
      y={25.79}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={19.125}
      height={16.917}
      x={20.688}
      y={128.421}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={19.125}
      height={16.917}
      x={215.312}
      y={128.421}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={19.125}
      height={16.917}
      x={45.438}
      y={128.421}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={19.125}
      height={16.917}
      x={238.938}
      y={128.421}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={19.125}
      height={16.917}
      x={70.188}
      y={128.421}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={19.125}
      height={16.917}
      x={262.562}
      y={128.421}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={19.125}
      height={16.917}
      x={286.188}
      y={128.421}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={115.875}
      height={17.083}
      x={94.938}
      y={128.421}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={25.313}
      height={16.917}
      x={20.688}
      y={70.902}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={30.375}
      height={16.917}
      x={20.688}
      y={90.075}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={40.5}
      height={16.917}
      x={20.688}
      y={109.248}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={39.813}
      y={51.73}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={48.25}
      y={70.902}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={53.313}
      y={90.075}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={63.438}
      y={109.248}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={82.563}
      y={109.248}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={101.688}
      y={109.248}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={120.812}
      y={109.248}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={139.938}
      y={109.248}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={159.062}
      y={109.248}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={178.188}
      y={109.248}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={197.312}
      y={109.248}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={216.438}
      y={109.248}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={235.562}
      y={109.248}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={50.625}
      height={16.917}
      x={254.688}
      y={109.248}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={72.438}
      y={90.075}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={91.563}
      y={90.075}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={110.688}
      y={90.075}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={129.812}
      y={90.075}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={148.938}
      y={90.075}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={168.062}
      y={90.075}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={187.188}
      y={90.075}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={206.312}
      y={90.075}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={225.438}
      y={90.075}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={244.562}
      y={90.075}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={263.688}
      y={90.075}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={67.375}
      y={70.902}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={86.5}
      y={70.902}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={105.625}
      y={70.902}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={124.75}
      y={70.902}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={143.875}
      y={70.902}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={163}
      y={70.902}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={182.125}
      y={70.902}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={201.25}
      y={70.902}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={220.375}
      y={70.902}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={239.5}
      y={70.902}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={258.625}
      y={70.902}
      fill="#999"
      rx={3.6}
    />
    <path
      fill="#999"
      fillRule="evenodd"
      d="M305.312 74.502a3.6 3.6 0 0 0-3.6-3.6H281.35a3.6 3.6 0 0 0-3.6 3.6v9.943a3.375 3.375 0 0 0 3.375 3.375 3.375 3.375 0 0 1 3.375 3.375v12.198a3.6 3.6 0 0 0 3.6 3.6h13.612a3.6 3.6 0 0 0 3.6-3.6V74.502"
      clipRule="evenodd"
    />
    <rect
      width={16.875}
      height={16.917}
      x={58.938}
      y={51.73}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={78.063}
      y={51.73}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={97.188}
      y={51.73}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={116.312}
      y={51.73}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={135.438}
      y={51.73}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={154.562}
      y={51.73}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={173.688}
      y={51.73}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={192.812}
      y={51.73}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={211.938}
      y={51.73}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={231.062}
      y={51.73}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={250.188}
      y={51.73}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={313.188}
      y={51.73}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={313.188}
      y={25.79}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={376.188}
      y={51.73}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={376.188}
      y={90.075}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={313.188}
      y={70.902}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={376.188}
      y={70.902}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={376.188}
      y={109.248}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={36}
      height={16.917}
      x={376.188}
      y={128.421}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={313.188}
      y={128.421}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={332.312}
      y={51.73}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={332.312}
      y={25.79}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={395.312}
      y={51.73}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={395.312}
      y={90.075}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={332.312}
      y={109.248}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={332.312}
      y={70.902}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={395.312}
      y={70.902}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={395.312}
      y={109.248}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={332.312}
      y={128.421}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={351.438}
      y={51.73}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={351.438}
      y={25.79}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={414.438}
      y={51.73}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={433.562}
      y={51.73}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={36.09}
      x={433.562}
      y={70.902}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={36.09}
      x={433.562}
      y={109.248}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={414.438}
      y={90.075}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={351.438}
      y={70.902}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={414.438}
      y={70.902}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={414.438}
      y={109.248}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={414.438}
      y={128.421}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={16.875}
      height={16.917}
      x={351.438}
      y={128.421}
      fill="#999"
      rx={3.6}
    />
    <rect
      width={36}
      height={16.917}
      x={269.312}
      y={51.73}
      fill="#999"
      rx={3.6}
    />
  </svg>
);
export default SvgHoverKeyboardCloseList;
