import ContainedButton from '@afterdoc-design-system/components/Atoms/Button/ContainedButton';
import OutlinedButton from '@afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import Icon, {
  type IconColorType,
  type IconType,
  type SpecificIconSizes,
} from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import DownloadFooter from '@afterdoc-design-system/components/Molecules/DownloadFooter/DownloadFooter';
import { overlayPageService } from '@afterdoc-design-system/components/Molecules/OverlayPage/OverlayPage.service';
import { SHARED_UTILS } from '@shared-utils/utils';
import { Color } from '@tailwind-base/styles/color';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useRef, useState } from 'react';
import {
  type ReactZoomPanPinchRef,
  TransformComponent,
  TransformWrapper,
} from 'react-zoom-pan-pinch';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type {
  AlgorithmWithMessagesInput,
  ApiAutomationsElAlgorithmsMessagesParams,
  SingleAlgorithmHandlerParams,
} from 'web/apis/swaggers/swagger-docs';
import { OVERLAY_PAGE_ID } from 'web/shared/constants/overlay-page-id';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import AlgorithmInfoBox from 'web/templates/Automation/containers/Viewer/AutomationZoomInOutViewer/components/components/AlgorithmInfoBox';
import { MessageCardItem } from 'web/templates/Automation/containers/Viewer/AutomationZoomInOutViewer/components/components/MessageCardItem/MessageCardItem';
import ZoomInOutController from 'web/templates/Automation/containers/Viewer/AutomationZoomInOutViewer/components/components/ZoomInOutController';
import { useDeleteAlgorithm } from 'web/templates/Automation/containers/shared/hooks/use-delete-algorithm';

const fetchAutomationsElAlgorithmsMessages = async (
  params: ApiAutomationsElAlgorithmsMessagesParams,
) => {
  const response = await apiClient.v3.apiAutomationsElAlgorithmsMessages(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const fetchSingleAlgorithmInfo = async (params: SingleAlgorithmHandlerParams) => {
  const response = await apiClient.v3.singleAlgorithmHandler(params);
  return response.data.data;
};

interface AutomationZoomInOutViewerContentProps {
  algorithmId: string;
  type: Exclude<AlgorithmWithMessagesInput['type'], 'COMMON'>;
  onClickEditButton: () => void;
}

export default function AutomationZoomInOutViewerContent({
  algorithmId,
  onClickEditButton,
  type,
}: AutomationZoomInOutViewerContentProps) {
  const { hospitalID } = useSelectedHospitalInfo();

  const transformRef = useRef<ReactZoomPanPinchRef>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [scale, setScale] = useState(100);

  const { data: messageListsData } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiAutomationsElAlgorithmsMessages, { algorithmId, hospitalID }] as const,
    queryFn: ({ queryKey }) => fetchAutomationsElAlgorithmsMessages(queryKey[1]),
  });

  const { data: algorithmData } = useSuspenseQuery({
    queryKey: [
      QUERY_KEY.singleAlgorithmHandler,
      {
        algorithmId,
        hospitalID,
      },
    ] as const,
    queryFn: ({ queryKey }) => {
      const algorithmId = queryKey[1].algorithmId;
      if (!algorithmId) return;

      return fetchSingleAlgorithmInfo({
        algorithmId,
        hospitalID,
      });
    },
  });

  const { handleDeleteAlgorithm } = useDeleteAlgorithm({
    type,
    onSuccessCb:
      type === 'CONSULTATION'
        ? () => overlayPageService.popById(OVERLAY_PAGE_ID['counsel-automation-zoom-in-out-viewer'])
        : () =>
            overlayPageService.popById(OVERLAY_PAGE_ID['marketing-automation-zoom-in-out-viewer']),
  });

  const clearSelection = () => {
    if (!window.getSelection) return;
    const selection = window.getSelection();
    if (!selection) return;
    if (selection.empty) {
      selection.empty();
    } else if (selection.removeAllRanges) {
      selection.removeAllRanges();
    }
  };

  const handleBackgroundClick = (e: React.MouseEvent) => {
    if (e.target === e.currentTarget) {
      clearSelection();
    }
  };

  const handleChangeScale = (newScale: number) => {
    setScale(newScale);
  };

  const handleOnZoom = (ref: ReactZoomPanPinchRef) => {
    const currentScale = ref.state.scale;
    let calculatedScale = Math.round(currentScale * 100);
    if (calculatedScale < 5) calculatedScale = 5;
    setScale(calculatedScale);
  };

  if (!algorithmData || !messageListsData) return null;

  return (
    <div className='relative h-[calc(100vh-50px)] w-full'>
      <MessageTypeIndicators type={type} />
      <AlgorithmInfoBox type={type} {...algorithmData} />
      <TransformWrapper
        initialScale={1}
        minScale={0.05}
        maxScale={1}
        ref={transformRef}
        limitToBounds={true}
        onZoom={handleOnZoom}
        panning={{ disabled: scale === 0 }}
        centerZoomedOut={true}
        alignmentAnimation={{ sizeX: 0, sizeY: 0 }}>
        {() => (
          <>
            <div className='mb-4 flex justify-center' />
            <TransformComponent
              wrapperStyle={{
                width: '100%',
                height: '100%',
              }}>
              <div
                ref={containerRef}
                onClick={handleBackgroundClick}
                className='flex min-h-[calc(100vh-400px)] w-full items-start py-[200px] pr-[300px] pl-[100px]'>
                {messageListsData.map((item, index) => {
                  return (
                    <MessageCardItem
                      key={item.id}
                      type={type}
                      isLast={index === messageListsData.length - 1}
                      {...item}
                    />
                  );
                })}
              </div>
            </TransformComponent>
          </>
        )}
      </TransformWrapper>
      <ZoomInOutController
        scale={scale}
        onChangeScale={handleChangeScale}
        transformRef={transformRef}
        containerRef={containerRef}
      />
      <DownloadFooter
        customRightButton={
          <div className='flex w-full justify-between' onClick={(e) => e.stopPropagation()}>
            <OutlinedButton
              btnColor='secondary'
              onClick={() => {
                if (!algorithmId) return;
                handleDeleteAlgorithm(algorithmId);
              }}>
              삭제
            </OutlinedButton>
            <ContainedButton onClick={onClickEditButton}>메시지 수정</ContainedButton>
          </div>
        }
        className='sticky right-0 bottom-0'
      />
    </div>
  );
}

interface MessageTypeIndicatorsProps {
  type: Exclude<AlgorithmWithMessagesInput['type'], 'COMMON'>;
}

const MessageTypeIndicators = ({ type }: MessageTypeIndicatorsProps) => {
  return (
    <div className='absolute top-20 right-40 flex gap-10'>
      <MessageIndicator
        iconProps={{
          name: 'kakaotalk',
          color: 'black200',
          size: 17,
        }}
        label={type === 'CONSULTATION' ? '알림톡 승인' : '친구톡 전송'}
      />
      {type === 'CONSULTATION' && (
        <>
          <MessageIndicator bgColor={Color.white900} label='기본' />
          <MessageIndicator bgColor={Color.blue500} label='필수' />
          <MessageIndicator bgColor={Color.red500} label='설문' />
          <MessageIndicator bgColor={Color.purple500} label='공통 재내원 메시지' />
        </>
      )}
    </div>
  );
};

interface MessageIndicatorProps<T extends IconType = IconType> {
  label: string;
  iconProps?: {
    name: IconType;
    color: IconColorType;
    size: SpecificIconSizes<T>;
  };
  bgColor?: (typeof Color)[keyof typeof Color];
}

const MessageIndicator = ({ iconProps, label, bgColor }: MessageIndicatorProps) => {
  return (
    <div className='flex items-center gap-6'>
      {iconProps ? (
        <Icon {...iconProps} />
      ) : (
        <div
          className={'h-6 w-6 rounded-full border border-white50'}
          style={{ backgroundColor: bgColor }}
        />
      )}
      <div className='text-Body13 text-black200'>{label}</div>
    </div>
  );
};
