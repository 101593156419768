import { atom } from 'jotai';
import { atomWithReset, selectAtom } from 'jotai/utils';
import type { MessageInput } from 'web/apis/swaggers/swagger-docs';

export const selectedMessageState = atomWithReset<
  | (MessageInput & {
      index: number;
    })
  | undefined
>(undefined);
export const selectedMessageIndexState = selectAtom(
  selectedMessageState,
  (state) => state?.index ?? -1,
);
export const selectedContentTabIndexState = atomWithReset<{ [index: number]: number }>({
  0: 0,
});
export const sendTypeState = atom<MessageInput['sendingType']>('BASIC');
