import { SHARED_UTILS } from '@shared-utils/utils';
import { useQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ApiPatientsElDetailParams } from 'web/apis/swaggers/swagger-docs';
import { chatRoomIDAtom } from '../states/chatRoomID';
import { hserviceIDValueState } from '../states/hserviceID-value';

export const getPatient = async (query: ApiPatientsElDetailParams) => {
  const { data } = await apiClient.v3.apiPatientsElDetail(query);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export const usePatient = () => {
  const chatRoomID = useAtomValue(chatRoomIDAtom);
  const thatHospitalPatientId = useAtomValue(hserviceIDValueState);
  const query = {
    patientId: thatHospitalPatientId,
    chatRoomID,
  };
  const { data, error, isLoading } = useQuery({
    queryKey: [QUERY_KEY.apiPatientsElDetail, query] as const,
    queryFn: ({ queryKey }) => getPatient(queryKey[1]),
    enabled: !!thatHospitalPatientId && !!chatRoomID,
  });

  const {
    name,
    patientId,
    isFirstVisit,
    chartNumber,
    isJoinedUserToAfterDoc,
    hasKakaoLinkage,
    gender,
    birthdate,
    phoneNumber,
    countryCode,
    treatmentTags,
    nationality,
  } = data || {};

  return {
    isFirstVisit,
    patientId,
    name,
    chartNumber,
    isJoinedUserToAfterDoc,
    hasKakaoLinkage,
    gender,
    birthdate,
    phoneNumber,
    countryCode,
    treatmentTags,
    nationalityKoreanCountryName: nationality?.koreanCountryName,
    error,
    isLoading,
  };
};
