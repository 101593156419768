import { atomWithReset } from 'jotai/utils';
import type { ApiPatientsElListOrSearchParams } from 'web/apis/swaggers/swagger-docs';

export interface ReadOnlyHeaderFilterState {
  isFirstVisit: ApiPatientsElListOrSearchParams['isFirstVisit'] | 'all';
  countryCode: ApiPatientsElListOrSearchParams['countryCode'];
  gender: ApiPatientsElListOrSearchParams['gender'];
  treatmentCategories: ApiPatientsElListOrSearchParams['treatmentCategories'];
  nationality: ApiPatientsElListOrSearchParams['nationality'];
}

export const readOnlyHeaderFilterState = atomWithReset<ReadOnlyHeaderFilterState>({
  isFirstVisit: undefined,
  countryCode: undefined,
  gender: undefined,
  treatmentCategories: undefined,
  nationality: undefined,
});
