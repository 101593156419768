import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useFormContext } from 'react-hook-form';
import type { MarketingAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/components/RegisterMarketingAutomationDialogContent';
import { actualTextLengthState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/containers/MessageContentForm/components/SendContentForm/states/actual-text-length';
import { MARKETING_MESSAGE_SETTING_TAB_ITEMS } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/containers/MessageContentForm/constants/tab';
import {
  selectedContentTabIndexState,
  selectedMessageIndexState,
} from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/message-form';

export default function SendContentFormTab() {
  const { resetField } = useFormContext<MarketingAutomationAPIFormValues>();

  const [selectedContentTabIndex, setSelectedContentTabIndex] = useAtom(
    selectedContentTabIndexState,
  );
  const setActualTextLength = useSetAtom(actualTextLengthState);
  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);

  const handleChangeTabIndex = (index: number) => {
    const prevTabIndex = selectedContentTabIndex[selectedMessageIndex];

    if (prevTabIndex === index) {
      return;
    }

    // 이미지에서 다른 탭으로 이동할 때, 이미지 내용 초기화
    if (prevTabIndex === 1) {
      resetField(`messages.${selectedMessageIndex}.content.image`);
    }

    setActualTextLength(0);
    resetField(`messages.${selectedMessageIndex}.content.text`);
    setSelectedContentTabIndex({
      ...selectedContentTabIndex,
      [selectedMessageIndex]: index,
    });
  };

  return (
    <div className='mt-10 flex'>
      {MARKETING_MESSAGE_SETTING_TAB_ITEMS.map((item, index) => (
        <div key={item}>
          <button
            type='button'
            onClick={() => {
              handleChangeTabIndex(index);
            }}
            className={customTwMerge(
              'py-8 pr-16 pl-20 text-Header12',
              selectedContentTabIndex[selectedMessageIndex] === index
                ? 'text-black700'
                : 'text-black200',
            )}>
            {item}
          </button>
        </div>
      ))}
    </div>
  );
}
