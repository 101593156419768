import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import type { TabValues } from 'web/templates/CustomerChat/components/SupportBot/SupportBot';
import { TABS } from 'web/templates/CustomerChat/components/SupportBot/constants/tab';

type NoSuportBotContentsProps = {
  selectedTab: TabValues;
};

export default function NoSuportBotContents({ selectedTab }: NoSuportBotContentsProps) {
  const displayContents = selectedTab === TABS.BOOKMARK ? '북마크된 채팅이' : '등록한 가이드가';

  return (
    <div className='h-full w-full flex-center flex-col gap-16'>
      <Icon name='warning' size={48} color='white600' />
      <span className='text-Header14 text-black500'>{displayContents} 없습니다.</span>
    </div>
  );
}
