import { useAtom, useAtomValue } from 'jotai';
import { useEffect, useRef } from 'react';
import ChattingContainer from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/ChattingContainer';
import ChattingParticipants from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingParticipants/ChattingParticipants';
import ProgressContent from 'web/templates/CustomerChat/components/ChattingRoom/components/ProgressContent/ProgressContent';
import { chattingWidthAtom } from 'web/templates/CustomerChat/states/chattingWidth';
import { chatRoomIDAtom } from '../../states/chatRoomID';
import ChatRoomIDNull from './components/ChatRoomIDNull/ChatRoomIDNull';
import ChattingSending from './components/ChattingSending/ChattingSending';
import CustomerInfoTop from './components/CustomerInfoTop/CustomerInfoTop';

export default function ChattingRoom({
  minWidth,
  isMenualOpen,
}: { minWidth: number; isMenualOpen: boolean }) {
  const chatRoomID = useAtomValue(chatRoomIDAtom);

  const divRef = useRef<HTMLDivElement>(null);
  const [chattingWidth, setChattingWidth] = useAtom(chattingWidthAtom);

  const updateWidth = () => {
    if (divRef.current) {
      setChattingWidth(divRef.current?.offsetWidth);
    }
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    updateWidth();
    window.addEventListener('resize', updateWidth);

    return () => {
      window.removeEventListener('resize', updateWidth);
    };
  }, [isMenualOpen]);

  if (chatRoomID === null) return <ChatRoomIDNull minWidth={minWidth} />;

  return (
    <div className='flex h-full w-full flex-col space-y-6' style={{ minWidth: `${minWidth}px` }}>
      <CustomerInfoTop />

      <div
        ref={divRef}
        className='relative flex h-full w-full flex-col space-y-6 overflow-y-auto border-white400 border-y bg-blueLight'
        style={{ minWidth: `${minWidth}px`, position: 'relative' }}>
        {/* {chattingWidth && <p>Dynamic width: {chattingWidth}px</p>} */}

        <ChattingContainer chatRoomID={chatRoomID} />
        <ChattingParticipants />

        <ProgressContent />
      </div>

      <ChattingSending />
    </div>
  );
}
