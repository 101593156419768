import BigImage from '@afterdoc-design-system/components/Atoms/BigImage/BigImage';
import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import { useAtom, useAtomValue } from 'jotai';
import { forwardRef, useEffect } from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import FilePicker from 'web/shared/components/FilePicker/FilePicker';
import LayoutBox from 'web/shared/components/LayoutBox/LayoutBox';
import {
  type ImageType,
  isLocalImage,
  useUploadMultipleImages,
} from 'web/shared/hooks/files/images/use-upload-multiple-images';
import type { MarketingAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/components/RegisterMarketingAutomationDialogContent';
import { MAX_LENGTH } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/containers/MessageContentForm/components/SendContentForm/constants/replaceable-texts';
import { useTextAreaWithMaxLength } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/containers/MessageContentForm/components/SendContentForm/hooks/use-text-area-with-max-length';
import { actualTextLengthState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/containers/MessageContentForm/components/SendContentForm/states/actual-text-length';
import { selectedMessageIndexState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/message-form';
import { originMessagesState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/origin-message';
import ResizableTextArea from 'web/templates/Automation/containers/Dialog/shared/components/ResizableTextArea/ResizableTextArea';

const MAX_FILE_SIZE_MB = 5;
const MAX_IMAGES_LENGTH = 1;

const ImageContentForm = forwardRef<HTMLTextAreaElement>((_, ref) => {
  const { setValue, getValues, watch } = useFormContext<MarketingAutomationAPIFormValues>();

  const originMessages = useAtomValue(originMessagesState);
  const [actualTextLength, setActualTextLength] = useAtom(actualTextLengthState);
  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);
  const {
    images,
    setImages,
    handleFileChange,
    handleRemoveImage,
    handleAttachSingleImage,
    filePickerRef,
  } = useUploadMultipleImages({
    maxFileSizeMB: MAX_FILE_SIZE_MB,
    maxImages: MAX_IMAGES_LENGTH,
  });

  const hasApprovedAlimtalkTemplate =
    originMessages[selectedMessageIndex]?.hasApprovedAlimtalkTemplate ?? false;
  const messageContentText = watch(`messages.${selectedMessageIndex}.content.text`);
  const { handleTextAreaChange } = useTextAreaWithMaxLength({ setActualTextLength });
  const handleAutomationRemoveImage = (index: number) => {
    handleRemoveImage(index);

    if (images.length === 1) {
      setValue(`messages.${selectedMessageIndex}.content.image`, undefined);
    }
  };

  useEffect(() => {
    if (images && images.length > 0) {
      const stringifiedImages = JSON.stringify(images);
      setValue(`messages.${selectedMessageIndex}.content.image`, stringifiedImages);
    }
  }, [images]);

  useEffect(() => {
    const image = getValues(`messages.${selectedMessageIndex}.content.image`);

    if (image) {
      try {
        const imageContent: ImageType[] = JSON.parse(image);
        setImages(imageContent);
      } catch (error) {
        console.error('Error parsing image content:', error);
        setImages([]);
      }
    } else {
      setImages([]);
    }
  }, [selectedMessageIndex]);

  return (
    <LayoutBox direction='vertical' size={20} width='100%'>
      {hasApprovedAlimtalkTemplate && (
        <div className='rounded-r10 bg-white200 px-16 py-8 text-Body10 text-black500'>
          텍스트를 제외한 다른 타입의 메시지는 알림톡을 발송할 수 없습니다.
        </div>
      )}
      <div className='mt-10 flex flex-wrap gap-10'>
        <FilePicker ref={filePickerRef} onFileChange={handleFileChange} />
        {images.map((image, index) => (
          <div className='relative' key={image.id}>
            <img
              src={isLocalImage(image) ? image.blobUrl : image.url}
              alt={`Content-${image.id}`}
              className='h-[96px] w-[170px] rounded-r10 border border-white600 object-cover'
            />
            <button
              onClick={() => handleAutomationRemoveImage(index)}
              className='absolute-center flex items-center gap-2 rounded-r16 bg-black800 bg-opacity-50 py-7 pr-10 pl-8'
              type='button'>
              <Icon name='trash-can-outline' color='white50' size={20} />
              <span className='whitespace-nowrap text-Body13 text-white50'>삭제하기</span>
            </button>
          </div>
        ))}
        {!images.length && (
          <BigImage width={170} height={96} onClickNoImage={handleAttachSingleImage} />
        )}
      </div>
      <Controller
        key={selectedMessageIndex}
        name={`messages.${selectedMessageIndex}.content.text`}
        render={({ field }) => (
          <ResizableTextArea
            {...field}
            ref={ref}
            onChange={(e) => {
              handleTextAreaChange(e);

              if (
                actualTextLength >= MAX_LENGTH &&
                e.target.value.length > (messageContentText?.length ?? 0)
              )
                return;

              field.onChange(e);
            }}
            placeholder='내용을 입력해 주세요.'
          />
        )}
      />
    </LayoutBox>
  );
});

export default ImageContentForm;
