import Divider from '@afterdoc-design-system/components/Atoms/Divider/Divider';
import FilledTag from '@afterdoc-design-system/components/Atoms/Tag/FilledTag';
import { Color } from '@tailwind-base/styles/color';
import ContentText from '../components/ContentText';

type TranslatedMessageBundleProps = {
  first: string[];
  second: string[];
  isTruncate?: boolean;
};

export default function TranslatedMessageBundle({
  first,
  second,
  isTruncate = true,
}: TranslatedMessageBundleProps) {
  return (
    <div className='flex flex-col justify-start gap-10'>
      <ContentText parts={first} isTruncate={isTruncate} />
      <Divider type='line' />
      <div className='flex flex-col gap-6'>
        <FilledTag className='rounded-r10 px-8 py-3' textColor={Color.blue500}>
          번역
        </FilledTag>
        <ContentText textColor={Color.blue500} parts={second} isTruncate={isTruncate} />
      </div>
    </div>
  );
}
