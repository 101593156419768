import Rectangle from '@afterdoc-design-system/assets/icons/Rectangle';
import Scrollbar from '@afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import HoverTooltip from '@afterdoc-design-system/components/Atoms/Tooltip/HoverTooltip';
import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import { Color } from '@tailwind-base/styles/color';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import dayjs from 'dayjs';
import { useAtomValue, useSetAtom } from 'jotai';
import { memo, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import type { MessageInput } from 'web/apis/swaggers/swagger-docs';
import type { MarketingAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/components/RegisterMarketingAutomationDialogContent';
import {
  selectedContentTabIndexState,
  selectedMessageIndexState,
  selectedMessageState,
} from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/message-form';
import { useAlgorithmData } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/hooks/use-algorithm-data';

export default function AutomatedMessageLists() {
  useAlgorithmData();

  const {
    setValue,
    watch,
    getValues,
    formState: { defaultValues },
  } = useFormContext<MarketingAutomationAPIFormValues>();

  const [warningStates, setWarningStates] = useState<{ [index: number]: boolean }>({});

  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);
  const setSelectedMessage = useSetAtom(selectedMessageState);

  const messageLists = watch('messages');
  const name = watch(`messages.${selectedMessageIndex}.name`);
  const text = watch(`messages.${selectedMessageIndex}.content.text`);
  const startDay = watch('sendingSchedule.startDay');
  const sendingCount = watch('sendingSchedule.sendingCount');

  useEffect(() => {
    const newWarningStates = messageLists.reduce(
      (acc, _, index) => {
        const messageTitle = watch(`messages.${index}.name`);
        const messageContent = watch(`messages.${index}.content.text`);
        acc[index] = !messageTitle || !messageContent;
        return acc;
      },
      {} as { [index: number]: boolean },
    );
    setWarningStates(newWarningStates);
  }, [messageLists, name, text, sendingCount, startDay]);

  useEffect(() => {
    if (getValues('messages').length === 0) {
      const messageLists: MessageInput[] = [
        {
          content: {
            text: undefined,
          },
          name: '메시지 1',
          sendingDateTime: dayjs(startDay).hour(8).minute(0).second(0).millisecond(0).toISOString(),
          sendingType: 'BASIC',
        },
      ];
      setSelectedMessage({
        index: 0,
        ...messageLists[0],
      });
      setValue('messages', messageLists);
    }
  }, [sendingCount, startDay, defaultValues, getValues, setValue, setSelectedMessage]);

  return (
    <div className='flex h-screen max-h-[722px] min-w-[200px] max-w-[370px] flex-grow flex-col justify-between rounded-r16 bg-white50 py-20 shadow-modal'>
      <div>
        <div className='flex flex-col gap-4 px-20 pt-20'>
          <span className='text-Header16 text-black700'>메시지 목록</span>
          <p className='text-Body11 text-black200'>발송 회차별 메시지를 설정할 수 있습니다.</p>
        </div>
        <div className='mt-10'>
          <div className='h-[600px]'>
            <Scrollbar>
              {messageLists?.map((item, index) => {
                return (
                  <MessageItem
                    key={uuidv4()}
                    {...item}
                    index={index}
                    hasWarning={warningStates[index]}
                  />
                );
              })}
            </Scrollbar>
          </div>
        </div>
      </div>
    </div>
  );
}

interface MessageItemProps extends MessageInput {
  hasWarning: boolean;
  index: number;
}

const MessageItem = ({ ...props }: MessageItemProps) => {
  const { index, hasWarning, name } = props;

  const { watch } = useFormContext<MarketingAutomationAPIFormValues>();

  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);
  const setSelectedMessage = useSetAtom(selectedMessageState);
  const setSelectedContentTabIndex = useSetAtom(selectedContentTabIndexState);

  const image = watch(`messages.${index}.content.image`);
  const sendingDateTime = watch(`messages.${index}.sendingDateTime`);

  const handleClickMessage = () => {
    if (selectedMessageIndex === index) {
      return;
    }
    setSelectedMessage({ ...props, index });

    if (image && image.length > 0) {
      setSelectedContentTabIndex((prev) => ({
        ...prev,
        [index]: 1,
      }));
    } else {
      setSelectedContentTabIndex((prev) => ({
        ...prev,
        [index]: 0,
      }));
    }
  };

  return (
    <div className='relative'>
      <div
        onClick={handleClickMessage}
        className={customTwMerge(
          'group relative flex cursor-pointer items-center border-b border-b-white600 px-20 py-15',
        )}>
        {selectedMessageIndex === index && (
          <span className='absolute left-0 h-full w-[5px] bg-blue500' />
        )}
        <span className='mr-16 w-[66px] whitespace-nowrap font-bold text-Header12 text-black700'>
          {dayjs(sendingDateTime).format('YYYY-MM-DD')}
        </span>
        <span className='truncate text-Body12 text-black700'>{name}</span>
        <div className='ml-auto flex items-center gap-10'>{hasWarning && <WarningIcon />}</div>
      </div>
      <div className='-bottom-[6.3px] absolute left-[27px] z-10'>
        <Rectangle width={14} height={14} fill={Color.white50} />
      </div>
    </div>
  );
};

const WarningIcon = memo(() => (
  <HoverTooltip
    text='필수정보가 기입되지 않았습니다.'
    position='bottomLeft'
    offset={12}
    portalZindex={1000}>
    <Icon name='info-circle-outline' color='red500' size={16} />
  </HoverTooltip>
));
