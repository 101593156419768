export const MESSAGE_TYPE_MAP = {
  text: 100,
  image: 300,
  video: 400,
  file: 500,
};

export const TEXT_TYPE = 'text';
export const KAKAO_TEXT_TYPE_FOR_API = 'TX';
export const KAKAO_FILES_TYPE_FOR_API = 'kakaoFiles';
export const FILE_TYPE = 'files';
