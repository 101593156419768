import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { modalService } from '@afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { overlayPageService } from '@afterdoc-design-system/components/Molecules/OverlayPage/OverlayPage.service';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { AlgorithmWithMessagesInput, MessageInput } from 'web/apis/swaggers/swagger-docs';
import { OVERLAY_PAGE_ID } from 'web/shared/constants/overlay-page-id';
import { useProcessImagesForUpload } from 'web/shared/hooks/files/images/use-process-images-for-upload';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import type { MarketingAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/components/RegisterMarketingAutomationDialogContent';
import { selectedMessageIndexState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/message-form';
import { messageModeState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/message-mode';
import { useHandleMarketingAutomationMessages } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/hooks/use-handle-marketing-automation-messages';
import { useResetAll } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/hooks/use-reset-all';

const registerMarketingAutomation = async (params: AlgorithmWithMessagesInput) => {
  const response = await apiClient.v3.apiAutomationsElAlgorithmsSingle(params);
  return response.data;
};

export const useSubmitRegisterMarketingAutomation = () => {
  const queryClient = useQueryClient();
  const { getValues, reset } = useFormContext<MarketingAutomationAPIFormValues>();
  const { handleAutomationMessages } = useHandleMarketingAutomationMessages();

  const [disabled, setDisabled] = useState(true);
  const messageMode = useAtomValue(messageModeState);
  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);

  const { hospitalID } = useSelectedHospitalInfo();
  const messageLists = getValues('messages');
  const name = getValues(`messages.${selectedMessageIndex}.name`);
  const text = getValues(`messages.${selectedMessageIndex}.content.text`);

  const { processImagesForUpload } = useProcessImagesForUpload();

  const { resetAll } = useResetAll({
    reset,
  });

  const registerText = useMemo(() => {
    if (messageMode.mode === 'CREATE') {
      return '마케팅자동화가 등록되었습니다.';
    }

    if (messageMode.mode === 'COPY') {
      return '마케팅자동화가 복사되었습니다.';
    }
  }, [messageMode.mode]);

  const handleOnSuccess = (text: string) => {
    if (messageMode.mode === 'CREATE') return;

    toastService.successMsg({
      text,
    });
    resetAll();

    queryClient.invalidateQueries({
      queryKey: [QUERY_KEY.apiAutomationsElMainList, { hospitalID }],
    });
    queryClient.invalidateQueries({
      queryKey: [
        QUERY_KEY.apiAutomationsElAlgorithmsMessages,
        { algorithmId: messageMode.algorithmId, hospitalID },
      ],
    });
    queryClient.invalidateQueries({
      queryKey: [
        QUERY_KEY.singleAlgorithmHandler,
        { algorithmId: messageMode.algorithmId, hospitalID },
      ],
    });

    modalService.popAll();
    overlayPageService.popById(OVERLAY_PAGE_ID['register-marketing-automation-dialog']);
  };

  const registerMutation = useMutation({
    mutationFn: registerMarketingAutomation,
    onSuccess: (data) => {
      if (data.code === 0) {
        toastService.successMsg({
          text: registerText,
        });

        queryClient.invalidateQueries({
          queryKey: [QUERY_KEY.apiAutomationsElMainList, { hospitalID }],
        });

        resetAll();
        modalService.popAll();
        overlayPageService.popById(OVERLAY_PAGE_ID['register-marketing-automation-dialog']);
      } else {
        toastService.errorMsg({
          text: '마케팅자동화 등록을 실패했습니다. 다시 시도해 주세요.',
        });
      }
      setDisabled(false);
    },
    onError: (error) => {
      console.error('error', error);
      toastService.errorMsg({
        text: '마케팅자동화 등록을 실패했습니다. 다시 시도해 주세요.',
      });
      setDisabled(false);
    },
  });

  const handleRegister = async () => {
    if (disabled) return;

    const originValues = getValues() as unknown as Exclude<
      AlgorithmWithMessagesInput,
      'messages'
    > & {
      messages: (MessageInput & {
        id: string;
      })[];
    };
    const newValues = { ...originValues };

    setDisabled(true);

    try {
      for (let i = 0; i < newValues.messages.length; i++) {
        const message = newValues.messages[i];

        if (message.content.image) {
          newValues.messages[i].content.image = await processImagesForUpload({
            newImages: JSON.parse(message.content.image),
          });
        }
      }
    } catch (error) {
      console.error('File upload failed:', error);
      toastService.errorMsg({
        text: '파일 업로드에 실패했습니다. 다시 시도해 주세요.',
      });
      setDisabled(false);
      return;
    }

    if (messageMode.mode === 'EDIT') {
      handleAutomationMessages({
        onSuccess: () => {
          handleOnSuccess('마케팅자동화가 수정되었습니다.');
          setDisabled(false);
        },
        newMessagesValues: newValues.messages,
        setDisabled,
      });
    } else {
      registerMutation.mutate(newValues);
    }
  };

  useEffect(() => {
    if (!messageLists.length) return;

    const isDisabled = messageLists.some(
      (item) => !item.name?.length || !item.content.text?.length,
    );

    setDisabled(isDisabled);
  }, [messageLists, name, text]);

  return {
    disabled: disabled || registerMutation.isPending,
    handleRegister,
  };
};
