import LabelText from '@afterdoc-design-system/components/Atoms/LabelText/LabelText';
import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import TextFieldSelectBoxDropdown from '@afterdoc-design-system/components/Molecules/TextSelect/TextFieldSelectBoxDropdown';
import dayjs from 'dayjs';
import { useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useRef } from 'react';
import { useFormContext } from 'react-hook-form';
import type { MessageInput } from 'web/apis/swaggers/swagger-docs';
import type { MarketingAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/components/RegisterMarketingAutomationDialogContent';
import {
  selectedContentTabIndexState,
  selectedMessageState,
} from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/message-form';
import { selectedMarketingAutomationTabState } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/states/selected-marketing-automation-tab-state';
import NextButton from 'web/templates/Automation/containers/shared/components/Buttons/NextButton';
import PrevButton from 'web/templates/Automation/containers/shared/components/Buttons/PrevButton';
import DaySelector from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/AfterDoc/ReservationHistory/containers/components/DaySelector';

const isValidDate = (year: number, month: number, day: number) => {
  if (year < 1900 || year > 9999) return false;
  if (month < 1 || month > 12) return false;
  const daysInMonth = new Date(year, month, 0).getDate();
  if (day < 1 || day > daysInMonth) return false;
  return true;
};

export default function MarketingEventStartDateSettingContainer() {
  const { setValue, watch } = useFormContext<MarketingAutomationAPIFormValues>();

  const dropdownOptions = useRef(Array.from({ length: 50 }, (_, i) => `${i + 1}`));
  const setSelectedMessage = useSetAtom(selectedMessageState);

  const messages = watch('messages');
  const startDay = watch('sendingSchedule.startDay');
  const sendingCount = watch('sendingSchedule.sendingCount');

  const disabledBeforeDate = dayjs().toDate();
  const hasMessageContent =
    messages.length > 0 && messages.some((message) => !!message.content.text);

  const resetSelectedContentTabIndex = useResetAtom(selectedContentTabIndexState);
  const setSelectedAutomationTab = useSetAtom(selectedMarketingAutomationTabState);

  const onChangeMessageLists = (startDay: string, sendingCount: number) => {
    const messageLists: MessageInput[] = Array.from({ length: sendingCount }, (_, i) => {
      return {
        content: {
          text: undefined,
        },
        name: `메시지 ${i + 1}`,
        sendingDateTime: dayjs(startDay)
          .add(i, 'day')
          .hour(8)
          .minute(0)
          .second(0)
          .millisecond(0)
          .toISOString(),
        sendingType: 'BASIC',
      };
    });

    setSelectedMessage({
      index: 0,
      ...messageLists[0],
    });
    console.log('messageLists', messageLists);
    setValue('messages', messageLists);
  };

  return (
    <div className='mt-40 w-[700px] rounded-r16 bg-white50 px-40 py-20 shadow-modal'>
      <LabelText isRequired>시작일/회차</LabelText>
      {hasMessageContent && (
        <div className='text-Body10 text-red500'>
          회차를 수정할 경우, 등록된 메시지가 모두 초기화됩니다. 그대로 사용을 원하시는 경우&nbsp;
          <span className='font-bold'>시작일만 변경</span>해 주세요.
        </div>
      )}
      <div className='mt-8 flex items-center gap-8'>
        <DaySelector
          selectedDate={dayjs(startDay).hour(0).minute(0).second(0).toDate()}
          onSelect={(date) => {
            setValue(
              'sendingSchedule.startDay',
              dayjs(date).hour(8).minute(0).second(0).toISOString(),
            );
            resetSelectedContentTabIndex();
            onChangeMessageLists(
              dayjs(date).hour(8).minute(0).second(0).toISOString(),
              sendingCount ?? 1,
            );
          }}
          hasDayChangeButton={false}
          width={124}
          yearRange={[dayjs().year(), 2500]}
          disableBeforeDate={disabledBeforeDate}
        />
        <span className='text-Body13 text-black500'>부터</span>
        <TextFieldSelectBoxDropdown
          width={80}
          options={dropdownOptions.current}
          onSelect={(index) => {
            setValue('sendingSchedule.sendingCount', Number(dropdownOptions.current[index]));
            resetSelectedContentTabIndex();
            onChangeMessageLists(startDay, Number(dropdownOptions.current[index]));
          }}
          selectedIndex={dropdownOptions.current.findIndex(
            (option) => option === `${sendingCount}`,
          )}
        />
        <span className='text-Body13 text-black500'>회 메시지를 발송합니다.</span>
      </div>

      <div className='mx-auto mt-20 flex w-[200px] gap-8'>
        <PrevButton
          onClick={() => {
            setSelectedAutomationTab(0);
          }}
        />
        <NextButton
          onClick={() => {
            const year = dayjs(startDay).year();
            const month = dayjs(startDay).month() + 1;
            const day = dayjs(startDay).date();

            if (!isValidDate(year, month, day)) {
              return toastService.errorMsg({
                text: '유효한 날짜를 선택해 주세요.',
              });
            }

            if (dayjs(startDay).isBefore(dayjs(), 'day')) {
              return toastService.errorMsg({
                text: `발송 시작일은 ${dayjs().format('YYYY-MM-DD')}부터 설정 가능합니다.`,
              });
            }

            return setSelectedAutomationTab(2);
          }}
        />
      </div>
    </div>
  );
}
