import PopupDatePicker from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/components/PopupManageDialog/components/PopupPeriodSelector/components/PopupDatePicker';
import { selectedPopupEditDateErrorAtom } from '@templates/Content/containers/ContentManager/containers/NoticePopup/components/Popup/state/popup-edit-error-state';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import { useAtom } from 'jotai/index';
import { memo, useEffect, useState } from 'react';

interface PopupPeriodSelectorProps {
  startDate: string;
  endDate: string;
  isDisabled: boolean;
}

type ToggleStateType = {
  start: boolean;
  end: boolean;
};

function PopupPeriodSelector({ startDate, endDate, isDisabled }: PopupPeriodSelectorProps) {
  const [popupDateError, setPopupDateError] = useAtom(selectedPopupEditDateErrorAtom);

  const [toggleState, setToggleState] = useState<ToggleStateType>({ start: false, end: false });

  const handleToggle = (type: 'start' | 'end', isToggle: boolean) => {
    setToggleState(() => ({
      start: type === 'start' ? isToggle : false,
      end: type === 'end' ? isToggle : false,
    }));
  };

  useEffect(() => {
    setPopupDateError(null);
  }, [startDate, endDate]);

  return (
    <div className='flex min-h-40 items-start gap-24 py-4'>
      <LabelText width={76} isRequired className='flex h-32 items-center'>
        팝업기간
      </LabelText>
      <div className='flex flex-col'>
        <div className='flex items-center gap-10'>
          <PopupDatePicker
            type='start'
            targetDate={startDate}
            isToggle={toggleState.start}
            isDisabled={isDisabled}
            onToggle={(isToggle: boolean) => handleToggle('start', isToggle)}
          />
          <p className='text-Header12 text-black500'>~</p>
          <PopupDatePicker
            type='end'
            targetDate={endDate}
            isToggle={toggleState.end}
            isDisabled={isDisabled}
            onToggle={(isToggle: boolean) => handleToggle('end', isToggle)}
          />
        </div>
        {popupDateError && <p className='mt-4 text-Body10 text-red500'>{popupDateError}</p>}
      </div>
    </div>
  );
}

export default memo(PopupPeriodSelector);
