import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { SHARED_UTILS } from '@shared-utils/utils';
import { useAtomValue } from 'jotai';
import { isEqual } from 'lodash-es';
import { apiClient } from 'web/apis/instances/api-client';
import type {
  DeleteMessageInput,
  MessageInput,
  NewMessageInput,
  UpdateMessageInput,
} from 'web/apis/swaggers/swagger-docs';
import { messageModeState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-mode';
import { originMessagesState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/origin-message';

const createCounselAutomationMessages = async (params: NewMessageInput) => {
  const response = await apiClient.v3.apiAutomationsElAlgorithmsMessagesSingle(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const updateCounselAutomationMessages = async (params: UpdateMessageInput) => {
  const response = await apiClient.v3.apiAutomationsElAlgorithmsMessagesUpsertSingle(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const deleteCounselAutomationMessages = async (params: DeleteMessageInput) => {
  const response = await apiClient.v3.apiAutomationsElAlgorithmsMessagesDeleteSingle(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};
export const useHandleCounselAutomationMessages = () => {
  const messageMode = useAtomValue(messageModeState);
  const originMessages = useAtomValue(originMessagesState);

  const handleAutomationMessages = async ({
    onSuccess,
    newMessagesValues,
    setDisabled,
  }: {
    onSuccess?: () => void;
    newMessagesValues: (MessageInput & {
      id: string;
    })[];
    setDisabled: (disabled: boolean) => void;
  }) => {
    if (messageMode.mode !== 'EDIT') return;

    setDisabled(true);

    try {
      const deletedMessages = originMessages.filter(
        (originMsg) => !newMessagesValues.some((newMsg) => newMsg.id === originMsg.id),
      );

      for (const deletedMsg of deletedMessages) {
        await deleteCounselAutomationMessages({ messageId: deletedMsg.id });
      }

      const addedMessages = newMessagesValues.filter((newMsg) => !newMsg.id);

      for (const addedMsg of addedMessages) {
        const newMessageParams: NewMessageInput = {
          algorithmId: messageMode.algorithmId,
          name: addedMsg.name,
          sendingType: addedMsg.sendingType,
          daysAfter: addedMsg.daysAfter,
          timeOfDay: addedMsg.timeOfDay,
          content: addedMsg.content,
          consultationGuide: addedMsg.consultationGuide,
          showConsultationGuide: addedMsg.showConsultationGuide,
        };
        await createCounselAutomationMessages(newMessageParams);
      }

      const updatedMessages = newMessagesValues.filter((newMsg) => {
        const originalMsg = originMessages.find((originMsg) => originMsg.id === newMsg.id);
        return originalMsg && !isEqual(originalMsg, newMsg);
      });

      for (const updatedMsg of updatedMessages) {
        const updateMessageParams: UpdateMessageInput = {
          messageId: updatedMsg.id,
          algorithmId: messageMode.algorithmId,
          name: updatedMsg.name,
          sendingType: updatedMsg.sendingType,
          daysAfter: updatedMsg.daysAfter,
          timeOfDay: updatedMsg.timeOfDay,
          content: updatedMsg.content,
          showConsultationGuide: updatedMsg.showConsultationGuide,
          consultationGuide: updatedMsg.consultationGuide,
        };
        await updateCounselAutomationMessages(updateMessageParams);
      }

      onSuccess?.();
    } catch (error) {
      console.error('Error while processing messages:', error);
      toastService.errorMsg({
        text: '파일 업로드에 실패했습니다. 다시 시도해 주세요.',
      });
    } finally {
      setDisabled(false);
    }
  };

  return {
    handleAutomationMessages,
  };
};
