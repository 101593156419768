import BaseLoading from '@afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import Pagination from '@afterdoc-design-system/components/Molecules/Pagination/Pagination';
import { useSuspenseQuery } from '@tanstack/react-query';
import { Suspense, useState } from 'react';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ApiV2HreservationElCountParams2 } from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import TableBody from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/AfterDoc/ReservationHistory/components/ChangedReservationsList/components/TableBody';
import TableHeader from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/AfterDoc/ReservationHistory/components/ChangedReservationsList/components/TableHeader';
import { CHANGED_RESERVATIONS_TABLE_HEADERS } from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/AfterDoc/ReservationHistory/components/ChangedReservationsList/constants/changed-reservations-table-headers';

const fetchChangedReservationListCount = async (params: ApiV2HreservationElCountParams2) => {
  const response = await apiClient.v3.apiV2HreservationElCount(params);
  return response.data;
};

const CHANGED_RESERVATION_HISTORY_TABLE_LIMIT = 6;

interface ChangedReservationsListProps {
  reservationId: string;
}

export default function ChangedReservationsList({ reservationId }: ChangedReservationsListProps) {
  const [currentPage, setCurrentPage] = useState(1);
  const { hospitalID } = useSelectedHospitalInfo();

  const {
    data: { data: changedReservationCount },
  } = useSuspenseQuery({
    queryKey: [
      QUERY_KEY.apiV2HreservationElCount,
      {
        reservationID: reservationId,
        hospitalID,
      },
    ] as const,
    queryFn: ({ queryKey }) => fetchChangedReservationListCount(queryKey[1]),
  });

  const handlePageChange = (page: number) => {
    setCurrentPage(page);
  };

  return (
    <>
      <table className='table-fixed bg-white text-Body12'>
        <colgroup>
          <col className='w-[130px]' />
          <col className='w-[120px]' />
          <col className='w-[100px]' />
          <col className='w-[130px]' />
          <col className='w-[160px]' />
          <col className='w-[100px]' />
          <col className='w-[120px]' />
          <col className='w-[100px]' />
        </colgroup>
        <TableHeader />
        <tbody>
          <Suspense
            fallback={
              <tr>
                <td colSpan={CHANGED_RESERVATIONS_TABLE_HEADERS.length}>
                  <div className='flex h-[300px] w-full items-center justify-center'>
                    <BaseLoading />
                  </div>
                </td>
              </tr>
            }>
            <TableBody currentPage={currentPage} />
          </Suspense>
        </tbody>
      </table>
      {Math.floor(changedReservationCount / CHANGED_RESERVATION_HISTORY_TABLE_LIMIT) >= 0 && (
        <Pagination
          totalPages={Math.ceil(changedReservationCount / CHANGED_RESERVATION_HISTORY_TABLE_LIMIT)}
          defaultPage={currentPage}
          onPageChange={handlePageChange}
        />
      )}
    </>
  );
}
