import SearchInput from '@afterdoc-design-system/components/Atoms/Input/SearchInput';
import Scrollbar from '@afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import ButtonTab from '@afterdoc-design-system/components/Atoms/Tabs/components/ButtonTab';
import Tabs from '@afterdoc-design-system/components/Atoms/Tabs/components/Tabs';
import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import TextFieldSelectBoxDropdown from '@afterdoc-design-system/components/Molecules/TextSelect/TextFieldSelectBoxDropdown';
import { useIntersectionObserver } from '@shared-hooks/use-intersection-observer';
import { SHARED_UTILS } from '@shared-utils/utils';
import { useAtom, useSetAtom } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { apiClient } from 'web/apis/instances/api-client';
import FullLoading from 'web/shared/components/FullLoading/FullLoading';
import { useLocalStorageState } from 'web/shared/hooks/use-local-storage-state';
import { useUserInfo } from 'web/shared/hooks/use-user-info';
import { selectedMessageIdState } from 'web/templates/CustomerChat/components/SupportBot/states/selected-message-id';
import { TABS } from '../../constants/tab';
import { literalArrayTypeToStringArray } from '../../functions/literalArrayTypeToStringArray';
import { chatRoomPatientIDState } from '../../states/chat-room-patient';
import { chatRoomIDAtom } from '../../states/chatRoomID';
import { isActivatedChattingState } from '../../states/is-activated-chatting';
import { selectedChattingListTabState } from '../../states/selected-chatting-list-tab';
import { USER_TYPE_CODE } from '../ChattingRoom/components/ChattingContainer/components/ChattingContent/components/ChattingMessage/constants/message';
import ChattingListItem from './components/ChattingListItem';
import { displayNinetyNine } from './functions/displayNinetyNine';
import { filteredUnReadChattings } from './functions/filteredUnReadChattings';
import {
  type TempChatRoomHServiceLastMessageUser,
  useChattingList,
} from './hooks/use-chattinglist';
import { useSearchInput } from './hooks/use-search-input';
import {
  type RoomInfo,
  type RoomType,
  channelIntegrationInfoState,
} from './states/channel-integration-info-state';

export const SORT_OPTIONS = ['최근 상담순', '안 읽은 상담순'] as const;

interface TempChatRoomHServiceLastMessageUsers extends TempChatRoomHServiceLastMessageUser {
  roomType: RoomType;
  ikakaoInfo: RoomInfo;
}

const postChatroomUpdateRead = async (chatRoomID: string) => {
  const body = {
    chatRoomID,
  };
  const { data } = await apiClient.v3.apiChatroomElUpdateRead(body);
  return SHARED_UTILS.api.checkApiResponse(data) as TempChatRoomHServiceLastMessageUsers;
};

export default function ChattingList() {
  const { userId } = useUserInfo();
  const setChatRoomID = useSetAtom(chatRoomIDAtom);
  const resetChatRoomID = useResetAtom(chatRoomIDAtom);
  const setIsDisabledChatting = useSetAtom(isActivatedChattingState);
  const setChatRoomPatientID = useSetAtom(chatRoomPatientIDState);
  const setChannelIntegrationInfo = useSetAtom(channelIntegrationInfoState);
  const resetChatRoomPatientID = useResetAtom(chatRoomPatientIDState);

  const loadMoreRef = useRef<HTMLDivElement | null>(null);

  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [selectedSortsDropdownIndex, setSelectedSortsDropdownIndex] = useLocalStorageState(
    'sortedIndex',
    0,
  );
  const [selectedTab, setSelectedTab] = useAtom(selectedChattingListTabState);
  const resetSelectedMessageId = useResetAtom(selectedMessageIdState);
  const [searchParams, setSearchParams] = useSearchParams();

  const { inputValue, keyword, handleChange, handleKeyDown, handleDelete } = useSearchInput();

  const {
    lengthResult,
    chatRoomHServiceLastMessageUsers,
    isLoading,
    refetch,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  } = useChattingList({ keyword, sortOption: SORT_OPTIONS[selectedSortsDropdownIndex] });

  const chatRoomId = searchParams.get('chatRoomId');
  const patientId = searchParams.get('patientId');
  const isReload = searchParams.has('reload');

  const handleSelectChatRoom = async (
    chatRoomId: string,
    inProgressChatRooms: TempChatRoomHServiceLastMessageUser[],
    chatRoomPatientID: string,
  ) => {
    const existingChatRoom = inProgressChatRooms.some((room) => room._id === chatRoomId);
    setIsDisabledChatting(existingChatRoom);

    const { roomType, ikakaoInfo } = await postChatroomUpdateRead(chatRoomId);

    setChannelIntegrationInfo({ roomType: roomType ?? 'patient', roomInfo: ikakaoInfo });

    refetch();
    resetSelectedMessageId();

    setChatRoomPatientID(chatRoomPatientID);
    setSelectedItemId(chatRoomId);
    setChatRoomID(chatRoomId);
  };

  const filteredChatRoomHServiceLastMessageUsers = useMemo(() => {
    return chatRoomHServiceLastMessageUsers;
    // const sortOption = SORT_OPTIONS[selectedSortsDropdownIndex];
    // return pipe(
    //   (rooms: TempChatRoomHServiceLastMessageUser[]) =>
    //     sortingFunctionMap[sortOption](rooms, userId),
    // filteredINGChattings,//백엔드 로직으로 이전 완료, 예시로 남겨두기.
    // filteredIsCounselClosedChattings,
    // )(chatRoomHServiceLastMessageUsers); // 초기 값 전달
  }, [selectedSortsDropdownIndex, chatRoomHServiceLastMessageUsers, userId]);

  const tabItemsMap = useMemo(() => {
    // const ingChatRooms = filteredChatRoomHServiceLastMessageUsers;

    return {
      [TABS.IN_PROGRESS]: {
        isBadge:
          filteredUnReadChattings(filteredChatRoomHServiceLastMessageUsers, userId).length > 0,
        badgeCount: lengthResult?.ingLength ?? 0,
        chatRooms: filteredChatRoomHServiceLastMessageUsers,
      },
      [TABS.ALL]: {
        isBadge: false,
        badgeCount: lengthResult?.totalLength ?? 0,
        chatRooms: filteredChatRoomHServiceLastMessageUsers,
      },
    };
  }, [filteredChatRoomHServiceLastMessageUsers, lengthResult, userId]);

  const handleIntersection = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      if (entry.isIntersecting && hasNextPage && !isFetchingNextPage) {
        fetchNextPage();
      }
    },
    [fetchNextPage, hasNextPage, isFetchingNextPage],
  );

  useIntersectionObserver(handleIntersection, { root: null, rootMargin: '20px', threshold: 0 }, [
    loadMoreRef.current,
  ]);

  useEffect(() => {
    // URL 쿼리 스트링에 chatRoomId와 patientId가 있을 경우 handleSelectChatRoom 호출
    if (chatRoomId && patientId && (!selectedItemId || isReload)) {
      const inProgressChatRooms = tabItemsMap[TABS.IN_PROGRESS].chatRooms;
      handleSelectChatRoom(chatRoomId, inProgressChatRooms, patientId);
      setSelectedItemId(chatRoomId);

      if (isReload) {
        searchParams.delete('reload');
        setSearchParams(searchParams, { replace: true });
      }
    }
  }, [chatRoomId, patientId, selectedItemId, tabItemsMap, isReload]);

  useEffect(() => {}, []);

  useEffect(() => {
    resetChatRoomPatientID();
    resetChatRoomID();
    setSelectedItemId(null);
  }, [selectedTab]);

  const chatRooms = tabItemsMap[selectedTab].chatRooms;

  // console.log('chatRooms', chatRooms);

  if (isLoading) return <FullLoading />;

  return (
    <div
      className='flex h-full flex-col pb-10'
      // style={{ zIndex: Z_INDEXS.ChattingList }}
    >
      <div className='flex h-49 flex-row justify-between px-20 pt-20 pb-8'>
        <span className='text-Header16 text-black700'>고객상담</span>
        <TextFieldSelectBoxDropdown
          options={literalArrayTypeToStringArray(SORT_OPTIONS)}
          selectedIndex={selectedSortsDropdownIndex}
          defaultValue={SORT_OPTIONS[selectedSortsDropdownIndex]}
          onSelect={setSelectedSortsDropdownIndex}
          inputClassName='border-none text-Body11 px-8 text-black200'
          width={100}
          dropdownWrapperClassName='!w-120'
        />
      </div>
      <div>
        <Tabs value={selectedTab} onChange={setSelectedTab}>
          {Object.entries(tabItemsMap).map(([key, { isBadge, badgeCount }]) => (
            <ButtonTab
              className='border-b border-b-white400'
              hasBadge={isBadge}
              value={key}
              label={key}
              key={key}
              subText={displayNinetyNine(badgeCount)}>
              {key}
            </ButtonTab>
          ))}
        </Tabs>
        <div className='mb-8 p-10'>
          <SearchInput
            placeholder='고객명으로 검색'
            value={inputValue}
            onChange={handleChange}
            onKeyDown={handleKeyDown}
            onDelete={handleDelete}
          />
        </div>
        <div className='h-[calc(100vh-150px)]'>
          {chatRooms?.length === 0 ? (
            <div className='flex h-full w-full flex-center flex-col gap-16'>
              <Icon name='warning' size={48} color='white600' />
              {keyword ? (
                <div className='flex flex-col-center'>
                  <span className='text-Header14 text-black500'>검색 결과가 없습니다.</span>
                  <span className='text-Body13 text-black500'>
                    단어의 철자가 정확한지 확인해 주세요.
                  </span>
                </div>
              ) : (
                <div className='flex flex-col-center'>
                  <span className='text-Header14 text-black500'>상담목록이 없습니다.</span>
                  <span className='text-Body13 text-black500'>
                    고객의 문의가 들어올 경우 여기에 표시됩니다.
                  </span>
                </div>
              )}
            </div>
          ) : (
            <Scrollbar disabledX>
              {chatRooms?.map(
                ({
                  users,
                  _id: id,
                  lastMessageUpdatedAt,
                  lastMessage,
                  counselorID,
                  info,
                  userID: chatRoomPatient,
                  parentHServiceID,
                }) => {
                  const { content, isTranslated, translatedContent, type, userType } =
                    lastMessage ?? {
                      content: '',
                      isTranslated: false,
                      translatedContent: '',
                    };
                  const isSelected = selectedItemId === id;

                  if (typeof chatRoomPatient === 'string' || chatRoomPatient === null) return null; //시스템 채팅의 경우 string으로 들어올 수 있고 이 경우는 무시한다.
                  const { _id: chatRoomPatientID, name } = chatRoomPatient;

                  const userUnreadCount = info?.find((item) => item.userID === userId)?.count ?? 0;

                  const linkType = parentHServiceID?.linkType;
                  const isRun = parentHServiceID?.isRun;

                  const isAfterdoc = Number(linkType) <= 500 && isRun;
                  const isKakao = Number(linkType) === 600;

                  const isAfterdocAndKakao = isAfterdoc && parentHServiceID?.hasKakaoLinkage;

                  const isKakaoIcon = isAfterdocAndKakao || isKakao;
                  const isAfterdocIcon = isAfterdocAndKakao || isAfterdoc;

                  const isLeft = userType === USER_TYPE_CODE.PATIENT;

                  return (
                    <div
                      key={id}
                      className={`cursor-pointer ${
                        isSelected ? 'border-blue500 border-l-2 bg-blue50' : ''
                      }`}
                      onClick={() =>
                        handleSelectChatRoom(
                          id,
                          tabItemsMap[TABS.IN_PROGRESS].chatRooms,
                          chatRoomPatientID,
                        )
                      }>
                      <ChattingListItem
                        key={id}
                        id={id}
                        title={name ?? ''}
                        users={users ?? []}
                        counselorID={counselorID}
                        content={content}
                        lastMessageUpdatedAt={lastMessageUpdatedAt}
                        isBadge={userUnreadCount > 0}
                        isTranslated={isTranslated}
                        translatedContent={translatedContent}
                        type={type}
                        isKakaoIcon={isKakaoIcon}
                        isAfterdocIcon={isAfterdocIcon}
                        isLeft={isLeft}
                      />
                    </div>
                  );
                },
              )}
              <div ref={loadMoreRef} style={{ height: 1 }} />
            </Scrollbar>
          )}
        </div>
      </div>
    </div>
  );
}
