import Scrollbar from '@afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import ButtonTab from '@afterdoc-design-system/components/Atoms/Tabs/components/ButtonTab';
import Tabs from '@afterdoc-design-system/components/Atoms/Tabs/components/Tabs';
import { useSetAtom } from 'jotai';
import { useState } from 'react';
import ChatIsBookmarkListItem from 'web/templates/CustomerChat/components/SupportBot/components/ChatIsBookmarkListItem';
import NoSuportBotContents from 'web/templates/CustomerChat/components/SupportBot/components/NoSuportBotContents/NoSuportBotContents';
import { useInfiniteChatIsBookmarkData } from 'web/templates/CustomerChat/components/SupportBot/hooks/use-infinite-chat-is-bookmark-data';
import { selectedMessageIdState } from 'web/templates/CustomerChat/components/SupportBot/states/selected-message-id';
import { USER_TYPE_CODE } from '../ChattingRoom/components/ChattingContainer/components/ChattingContent/components/ChattingMessage/constants/message';
import { TABS } from './constants/tab';

export type TabValues = (typeof TABS)[keyof typeof TABS];

export const SupportBot = () => {
  const [selectedTab, setSelectedTab] = useState<TabValues>('채팅 북마크');
  const setSelectedMessageId = useSetAtom(selectedMessageIdState);
  const { data: chatRooms } = useInfiniteChatIsBookmarkData();

  const handleSelectIsBookmarkChat = async (chatRoomId: string) => {
    setSelectedMessageId(chatRoomId);
  };

  return (
    <div className='flex flex-col gap-8 border-t border-t-white400 pt-16 pb-10'>
      <Tabs value={selectedTab} onChange={setSelectedTab}>
        {Object.values(TABS).map((value) => (
          <ButtonTab className='border-b border-b-white400' value={value} label={value} key={value}>
            {value}
          </ButtonTab>
        ))}
      </Tabs>
      <div className='h-[calc(100vh-630px)]'>
        {selectedTab === TABS.BOOKMARK &&
          (chatRooms?.length === 0 ? (
            <NoSuportBotContents selectedTab={selectedTab} />
          ) : (
            <Scrollbar disabledX>
              {chatRooms?.map(
                ({
                  _id: id,
                  content,
                  sentAt,
                  isBookmark,
                  userRealName,
                  type,
                  isTranslated,
                  translatedContent,
                  userType,
                }) => {
                  return (
                    <div key={id} onClick={() => handleSelectIsBookmarkChat(id)}>
                      <ChatIsBookmarkListItem
                        id={id}
                        content={content}
                        userRealName={userRealName ?? ''}
                        sentAt={sentAt ?? ''}
                        isBookmark={isBookmark}
                        type={type}
                        isTranslated={isTranslated ?? false}
                        translatedContent={translatedContent ?? ''}
                        isLeft={userType === USER_TYPE_CODE.PATIENT}
                      />
                    </div>
                  );
                },
              )}
            </Scrollbar>
          ))}
        {selectedTab === TABS.GUIDE && <NoSuportBotContents selectedTab={selectedTab} />}
      </div>
    </div>
  );
};
