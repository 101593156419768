import type { EmojiClickData } from 'emoji-picker-react';
import { useCallback, useEffect, useRef, useState } from 'react';

export const useEmojiPicker = (setText: React.Dispatch<React.SetStateAction<string>>) => {
  const [showEmojiPicker, setShowEmojiPicker] = useState<boolean>(false);
  const [emojiPickerPosition, setEmojiPickerPosition] = useState<{ top: number; left: number }>({
    top: 0,
    left: 0,
  });
  const emojiPickerRef = useRef<HTMLDivElement>(null);

  const toggleEmojiPicker = useCallback((iconButtonRef: HTMLButtonElement | null) => {
    if (!iconButtonRef) return;

    const rect = iconButtonRef.getBoundingClientRect();
    const absoluteLeft = rect.left + window.scrollX;
    const absoluteTop = rect.top + window.scrollY;

    setEmojiPickerPosition({ top: absoluteTop - 380, left: absoluteLeft - 580 });
    setShowEmojiPicker((prevState) => !prevState);
  }, []);

  const onEmojiClick = useCallback(
    (emojiData: EmojiClickData) => {
      setText((prevText) => prevText + emojiData.emoji);
      setShowEmojiPicker(false);
    },
    [setText],
  );

  const handleClickOutside = (event: MouseEvent) => {
    if (emojiPickerRef.current && !emojiPickerRef.current.contains(event.target as Node)) {
      setShowEmojiPicker(false);
    }
  };
  // Close emoji picker when clicking outside
  useEffect(() => {
    if (showEmojiPicker) {
      document.addEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [showEmojiPicker]);

  return {
    showEmojiPicker,
    emojiPickerPosition,
    emojiPickerRef,
    toggleEmojiPicker,
    onEmojiClick,
  };
};
