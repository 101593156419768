import type { KakaoConsultationTalkLinkageHandlerBodyRequest } from '@apis/swaggers/swagger-docs';
import { hasLinkageOrRequestHistoryState } from '@templates/HospitalSetting/containers/ChannelIntegration/states/has-linkage-or-request-history-state';
import { searchableChannelIdErrorAtom } from '@templates/HospitalSetting/containers/ChannelIntegration/states/kakao-consult-form-error-state';
import TextInput from 'afterdoc-design-system/components/Atoms/Input/TextInput';
import LabelText from 'afterdoc-design-system/components/Atoms/LabelText/LabelText';
import HoverTooltip from 'afterdoc-design-system/components/Atoms/Tooltip/HoverTooltip';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { useAtom } from 'jotai';
import { useAtomValue } from 'jotai/index';
import { useFormContext } from 'react-hook-form';

interface ChannelIdInputProps {
  isAuthRequestDone?: boolean;
}

export default function ChannelIdInput({ isAuthRequestDone = false }: ChannelIdInputProps) {
  const { register } = useFormContext<KakaoConsultationTalkLinkageHandlerBodyRequest>();

  const [searchableChannelIdError, setSearchableChannelIdError] = useAtom(
    searchableChannelIdErrorAtom,
  );
  const hasLinkageOrRequestHistory = useAtomValue(hasLinkageOrRequestHistoryState);

  return (
    <div className='flex min-h-[32px] gap-10'>
      <div className='flex w-[140px] items-center gap-6'>
        <LabelText isRequired width={'w-fit'} className='flex-center'>
          검색용 아이디
        </LabelText>
        <HoverTooltip
          position='topRight'
          text={'카카오 채널 관리자 센터>프로필>프로필 설정에 등록된 ‘검색용 아이디’ 입니다.'}>
          <Icon name={'info-circle-outline'} size={16} />
        </HoverTooltip>
      </div>
      <TextInput
        maxLength={30}
        width={290}
        disabled={hasLinkageOrRequestHistory !== 'NotLinked' || isAuthRequestDone}
        hasError={searchableChannelIdError}
        errorTextPosition={'vertical'}
        placeholder='afterdocclinic'
        errorText='검색용 아이디를 입력해 주세요.'
        {...register('searchableChannelId', { onChange: () => setSearchableChannelIdError(false) })}
      />
    </div>
  );
}
