export const EDITABLE_TABLE_HEADERS_WITH_FILTER = [
  '초/재진',
  '국가번호*',
  '성별',
  '치료태그',
  '국적',
] as const;

export const EDITABLE_TABLE_HEADERS_WITHOUT_FILTER = [
  'No',
  '고객명*',
  '차트번호',
  '휴대폰번호*',
  '생년월일',
] as const;

export const EDITABLE_TABLE_HEADERS = [
  'No',
  '고객명*',
  '차트번호',
  '초/재진',
  '국가번호*',
  '휴대폰번호*',
  '생년월일',
  '성별',
  '치료태그',
  '국적',
] as const;
