import { contentPanelLayerState } from '@templates/Content/containers/ContentPanel/states/content-panel-layer-state';
import { focusedContentSubLayerState } from '@templates/Content/containers/ContentPanel/states/focused-content-sub-layer-state';
import { AnimatePresence, motion } from 'framer-motion';
import { useAtom } from 'jotai/index';

interface ContentPanelFolderItemProps {
  folderIndex: number;
  itemIndex: number;
}

export default function ContentPanelFolderItem({
  folderIndex,
  itemIndex,
}: ContentPanelFolderItemProps) {
  const [contentPanelLayer, _setContentPanelLayer] = useAtom(contentPanelLayerState);
  const [focusedContentSubLayer, setFocusedContentSubLayer] = useAtom(focusedContentSubLayerState);

  const folder = contentPanelLayer[folderIndex];
  const item = folder.items[itemIndex];

  const handleClick = () => {
    setFocusedContentSubLayer(item);
  };

  return (
    <AnimatePresence initial={false}>
      {folder.isOpen && (
        <motion.ul
          initial={folder.isOpen ? { height: 0, opacity: 0 } : { height: 'auto', opacity: 1 }}
          animate={{ height: 'auto', opacity: 1 }}
          exit={{ height: 0, opacity: 0 }}
          transition={{ duration: 0.4 }}
          className='overflow-hidden'>
          <li className='px-8 py-4'>
            <div
              onClick={handleClick}
              className={`flex w-full cursor-pointer items-center justify-between rounded-r6 py-11 pr-20 pl-56 text-Body12 hover:bg-blueLight ${
                focusedContentSubLayer?.key === item.key
                  ? 'bg-blue50 font-bold text-black500'
                  : 'bg-white50 font-regular text-black500'
              }`}>
              {item.text}
            </div>
          </li>
        </motion.ul>
      )}
    </AnimatePresence>
  );
}
