import BigImage from '@afterdoc-design-system/components/Atoms/BigImage/BigImage';
import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import NoData from '@afterdoc-design-system/components/Molecules/NoData/NoData';
import Title from '@afterdoc-design-system/components/Molecules/Title/Title';
import { useAtomValue } from 'jotai';
import { Fragment, useEffect } from 'react';
import { useFormContext } from 'react-hook-form';
import { v4 as uuidv4 } from 'uuid';
import type { ManualResponse } from 'web/apis/swaggers/swagger-docs';
import FilePicker from 'web/shared/components/FilePicker/FilePicker';
import {
  isLocalImage,
  useUploadMultipleImages,
} from 'web/shared/hooks/files/images/use-upload-multiple-images';
import { useUserInfo } from 'web/shared/hooks/use-user-info';
import type { UpdateSingleManualAPIFormValues } from 'web/templates/HospitalManual/components/HospitalManualContent/HospitalManualContent';
import { selectedTagIdState } from 'web/templates/HospitalManual/components/HospitalManualPanel/states/selected-tag-id';

interface ManualCautionImagesProps {
  fileID: ManualResponse['fileID'];
  cautionImages: ManualResponse['cautionImages'];
}

export default function ManualCautionImages({ fileID, cautionImages }: ManualCautionImagesProps) {
  const selectedTagId = useAtomValue(selectedTagIdState);
  const { setValue } = useFormContext<UpdateSingleManualAPIFormValues>();
  const {
    images,
    setImages,
    handleFileChange,
    handleRemoveImage,
    handleAttachMultipleImages,
    filePickerRef,
  } = useUploadMultipleImages();

  const { authorizationTypeID } = useUserInfo();

  const canUserCreateManual = authorizationTypeID?.canCreateManual;

  useEffect(() => {
    if (cautionImages && fileID) {
      setImages(cautionImages.map((img) => ({ id: fileID, url: img.url })) ?? []);
    } else {
      setImages([]);
    }
  }, [cautionImages, fileID, selectedTagId]);

  useEffect(() => {
    if (images.length === 0) {
      setValue(
        'fileID',
        JSON.stringify([
          {
            id: fileID,
          },
        ]),
      );
    } else {
      setValue('fileID', JSON.stringify(images));
    }
  }, [images.length]);

  return (
    <div className='mt-10 w-full'>
      <Title
        title='주의사항 이미지'
        subTitle='최대 10장의 이미지를 설정할 수 있습니다. 설정된 이미지는 고객관리 > 고객정보 입력 시 해당 치료태그가 입력된 고객에게 자동발송됩니다.'
      />
      <div className='flex flex-wrap gap-20'>
        <div className='mt-10 flex flex-wrap gap-10'>
          <FilePicker ref={filePickerRef} onFileChange={handleFileChange} />
          {images.map((image, index) => (
            <Fragment key={uuidv4()}>
              <div className='relative'>
                <img
                  src={isLocalImage(image) ? image.blobUrl : image.url}
                  alt={`Content-${index}`}
                  className='h-[96px] w-[170px] rounded-r10 border border-white600 object-cover'
                />
                {canUserCreateManual && (
                  <button
                    onClick={() => handleRemoveImage(index)}
                    className='absolute-center flex items-center gap-2 rounded-r16 bg-black800 bg-opacity-50 py-7 pr-10 pl-8'
                    type='button'>
                    <Icon name='trash-can-outline' color='white50' size={20} />
                    <span className='whitespace-nowrap text-Body13 text-white50'>삭제하기</span>
                  </button>
                )}
              </div>
            </Fragment>
          ))}
          {canUserCreateManual && images.length < 10 && (
            <BigImage width={170} height={96} onClickNoImage={handleAttachMultipleImages} />
          )}
        </div>
        {!images.length && !canUserCreateManual && (
          <NoData
            title='표시할 내용이 없습니다.'
            className='mx-auto py-60'
            iconProps={{
              name: 'warning',
              size: 48,
              color: 'white600',
            }}
          />
        )}
      </div>
    </div>
  );
}
