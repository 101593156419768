import type { UserNameAndType } from 'web/apis/swaggers/swagger-docs';

const isAfterdocMember = (user: UserNameAndType): boolean =>
  !!user.type && Number(user.type) >= 3000;

export const reorderUsersForCounselor = (users: UserNameAndType[]) => {
  const { afterdocMembers, nonAfterdocMembers } = users.reduce<{
    afterdocMembers: UserNameAndType[];
    nonAfterdocMembers: UserNameAndType[];
  }>(
    (acc, user) => {
      if (isAfterdocMember(user)) {
        // afterdocMembers 배열에 이름을 변경하여 추가
        acc.afterdocMembers.push({ ...user, name: '애프터닥' });
      } else {
        // nonAfterdocMembers 배열에 그대로 추가
        acc.nonAfterdocMembers.push(user);
      }
      return acc;
    },
    { afterdocMembers: [], nonAfterdocMembers: [] }, // 초기값
  );

  // 두 배열을 합쳐서 반환
  return afterdocMembers.concat(nonAfterdocMembers);
};
