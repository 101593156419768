import type React from 'react';
import { forwardRef, useEffect, useState } from 'react';
import { ColorService, Hue, type IColor, Saturation, useColor } from 'react-color-palette';
import 'react-color-palette/css';
import './CustomColorPicker.scss';
import { treatmentTagsState } from '@templates/HospitalSetting/containers/ServiceSetting/containers/TreatmentTag/states/hospital-treatment-tags';
import { useSetAtom } from 'jotai/index';

interface CustomColorPickerProps {
  defaultColor: string;
  id: string;
}

const CustomColorPicker = forwardRef<HTMLDivElement, CustomColorPickerProps>(
  ({ defaultColor, id }, ref) => {
    const [color, setColor] = useColor(defaultColor);
    const [selectedColor, setSelectedColor] = useState(defaultColor);
    const setTreatmentTags = useSetAtom(treatmentTagsState);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
      const inputValue = e.target.value;
      if (ColorService.toHex(inputValue) === inputValue) {
        setColor({ ...color, hex: inputValue });
      } else {
        setSelectedColor(inputValue);
      }
    };

    useEffect(() => {
      if (color.hex) {
        setSelectedColor(color.hex);
      }
    }, [color]);

    const handleChangeComplete = (color: IColor) => {
      setTreatmentTags((prev) =>
        prev.map((tag) => (tag.tagId === id ? { ...tag, color: color.hex } : tag)),
      );
    };

    return (
      <div
        ref={ref}
        className=' w-[182px] flex-col-center gap-10 rounded-r16 bg-white50 p-16 shadow-black100'
        style={{
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
        }}>
        <Saturation
          height={150}
          color={color}
          onChange={setColor}
          onChangeComplete={handleChangeComplete}
        />
        <div className='w-full px-4'>
          <Hue color={color} onChange={setColor} />
        </div>
        <div className='h-18 w-full flex-center gap-4'>
          <span className='px-6 text-Body12 text-black500'>HEX</span>
          <input
            type='text'
            value={selectedColor}
            onChange={handleChange}
            className='w-full rounded-r-[4px] border border-white500 px-6 py-2 text-Body12 text-black500 outline-none focus:border-blue500 focus:outline-none'
          />
        </div>
      </div>
    );
  },
);

export default CustomColorPicker;
