import SmartDoctorReservationHistoryTable from './components/SmartDoctorReservationHistoryTable';
import TitleAndSubText from './components/SmartDoctorReservationHistoryTitleAndSubText';

export default function SmartDoctorReservationHistoryContainer() {
  return (
    <>
      <TitleAndSubText />
      <SmartDoctorReservationHistoryTable />
    </>
  );
}
