import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import type { CustomerCountData } from 'web/apis/swaggers/swagger-docs';
import { CHART_OPTIONS } from 'web/templates/Automation/containers/Main/DashBoardContent/components/constants/chart-options';
import CardItemLayout from 'web/templates/Automation/containers/Main/shared/components/CardItemLayout';

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);

interface ActiveCustomersCardItemProps {
  activeCustomers?: CustomerCountData;
}

export default function ActiveCustomersCardItem({ activeCustomers }: ActiveCustomersCardItemProps) {
  if (!activeCustomers) {
    return <CardItemLayout />;
  }

  const { total, changeFromLastMonth, monthlyCounts } = activeCustomers;

  const chartData = {
    labels: monthlyCounts?.map((item) => `${item.month}월`) || [],
    datasets: [
      {
        label: '고객 수',
        data: monthlyCounts?.map((item) => item.count) || [],
        borderColor: '#64B5F6',
        backgroundColor: 'rgba(100, 181, 246, 0.1)',
        fill: true,
        tension: 0,
        pointRadius: 0,
        datalabels: {
          display: false,
        },
      },
    ],
  };

  return (
    <CardItemLayout className='flex flex-col gap-24'>
      <div>
        <div className='text-Header12 text-black500'>활성유저수</div>
        <div className='flex items-center gap-10'>
          <div className='text-Header16 text-black700'>
            총 {total ? total.toLocaleString() : '-'}명
          </div>
          <div className='flex items-center'>
            <span className='text-Body12 text-black200'>전달대비</span>
            <span
              className={`ml-6 text-Header12 ${changeFromLastMonth && changeFromLastMonth > 0 ? 'text-red500' : 'text-blue500'}`}>
              {changeFromLastMonth ? Math.abs(changeFromLastMonth) : '-'}명
            </span>
            {changeFromLastMonth && changeFromLastMonth > 0 ? (
              <Icon name='arrow-up-right-thick' color='red500' size={16} />
            ) : (
              <Icon name='arrow-down-left-thick' color='blue500' size={16} />
            )}
          </div>
        </div>
      </div>
      <div className='mt-4 h-90'>
        <Line data={chartData} options={CHART_OPTIONS} height={90} />
      </div>
    </CardItemLayout>
  );
}
