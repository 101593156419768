import { SHARED_UTILS } from '@shared-utils/utils';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { useEffect } from 'react';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ApiChatElFindParams } from 'web/apis/swaggers/swagger-docs';
import { selectedMessageIdState } from 'web/templates/CustomerChat/components/SupportBot/states/selected-message-id';
import { chatRoomIDAtom } from 'web/templates/CustomerChat/states/chatRoomID';
import { SORT_WAY } from '../../../../../constants/message-sort-way';

const INTERVAL_NUM = 20;

const getChat = async (query: ApiChatElFindParams) => {
  const { data } = await apiClient.v3.apiChatElFind(query);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export const useInfiniteChatData = () => {
  const chatRoomID = useAtomValue(chatRoomIDAtom);
  const selectedMessageId = useAtomValue(selectedMessageIdState);

  const query = {
    chatRoomID,
    key: 'createdAt',
    way: SORT_WAY,
  };

  const { data, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useInfiniteQuery({
      queryKey: [QUERY_KEY.apiChatElFind, query],
      queryFn: ({ pageParam }) => {
        const { skip, limit } = pageParam;
        return getChat({ ...query, skip, limit } as ApiChatElFindParams);
      },
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage?.length || selectedMessageId) return undefined; // 더 이상 페이지가 없음을 의미

        const pagesNum = pages.reduce((acc, page) => acc + page.length, 0);
        return { skip: pagesNum, limit: INTERVAL_NUM };
      },
      initialPageParam: { limit: INTERVAL_NUM, skip: 0 },
      enabled: !!chatRoomID,
      staleTime: 0,
    });

  const newMessages = data?.pages.flat() ?? [];

  useEffect(() => {
    if (chatRoomID) {
      refetch();
    }
  }, [chatRoomID, refetch]);

  return { data: newMessages, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading };
};
