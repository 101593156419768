import { useAtomValue } from 'jotai';
import TranslateTextMessage from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/components/ChattingMessage/Message/TextContentMessage/TranslateTextMessage';
import { isActivateChatTranslateAtom } from '../../../../../../../../states/isChatTranslate';
import { useChatMessageContext } from '../../ChatMessageContext';
import { MESSAGE_TYPE_CODE } from '../../constants/message';
import ContentText from '../components/ContentText';
import IsTranslatingMessage from './IsTranslatingMessage';

export default function TextContentMessage() {
  const { isMine, chattingWidth, contentParts, isTranslated, isLeft, type } =
    useChatMessageContext();
  const isActivateChatTranslate = useAtomValue(isActivateChatTranslateAtom);
  const messageWidthStyle = {
    maxWidth: `${chattingWidth / 2}px`,
    width: 'fit-content',
  };

  // const firstUrl = contentParts.find((part) => urlRegex.test(part));
  // const [metadata, _setMetadata] = useState<Metadata | null>(null);

  // useEffect(() => {
  //   if (!firstUrl) return;
  //   fetchMetadata(firstUrl);
  // }, [firstUrl]);

  if (isActivateChatTranslate && isTranslated === undefined) {
    if (isLeft) return <IsTranslatingMessage />;

    if (type === MESSAGE_TYPE_CODE.TEXT_MOCKING) return <IsTranslatingMessage />;
  }

  return (
    <div className='flex flex-col items-end gap-10'>
      {isTranslated ? (
        <>
          <TranslateTextMessage />
        </>
      ) : (
        <div
          className={`rounded-r10 px-16 py-10 shadow-modal ${isMine ? 'bg-[rgba(255,245,208,1)]' : 'bg-white50'}`}
          style={messageWidthStyle}>
          <ContentText parts={contentParts} />
        </div>
      )}
      {/* {firstUrl && (
        <div
          className={`rounded-r10 px-16 py-10 shadow-modal ${isMine ? 'bg-[rgba(255,245,208,1)]' : 'bg-white50'}`}
          style={messageWidthStyle}>
          <div>
            <strong>{metadata?.ogTitle}</strong>
            <p>{metadata?.ogDescription}</p>
            {metadata?.ogImage && <img src={metadata.ogImage} alt='Preview' />}
          </div>
        </div>
      )} */}
    </div>
  );
}
