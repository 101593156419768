export const CUSTOMER_SEARCH_FIELD_OPTIONS = [
  {
    key: 'patientName',
    value: '고객명',
  },
  {
    key: 'chartNumber',
    value: '차트번호',
  },
  {
    key: 'phoneNumber',
    value: '휴대폰번호',
  },
  {
    key: 'birthdate',
    value: '생년월일',
  },
] as const;
