import { fullDimmedLoadingService } from '@afterdoc-design-system/components/Atoms/Loading/FullDimmedLoading/FullDimmedLoading.service';
import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { modalService } from '@afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { SHARED_UTILS } from '@shared-utils/utils';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import type Excel from 'exceljs';
import * as ExcelJS from 'exceljs/dist/exceljs.min.js';
import { useResetAtom } from 'jotai/utils';
import Papa from 'papaparse';
import { useRef } from 'react';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import type { CustomerManagementTemporaryAPIFormValues } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/CustomerManagement/containers/CustomerManagementEditableTable/types/table';
import { searchTextState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/containers/SearchFilter/states/search';
import { selectedSearchCriteriaState } from 'web/templates/CustomerManagement/containers/BoardPanel/containers/shared/containers/SearchFilter/states/selected-search-criteria';

const MAX_FILE_SIZE_MB = 30;

export const useUploadExcel = () => {
  const queryClient = useQueryClient();
  const { hospitalID } = useSelectedHospitalInfo();

  const resetSelectedSearchCriteria = useResetAtom(selectedSearchCriteriaState);
  const resetSearchText = useResetAtom(searchTextState);

  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const abortController = useRef<AbortController | null>(null);

  const resetState = () => {
    fullDimmedLoadingService.off();
    toastService.close('uploading');
    if (fileInputRef.current) {
      fileInputRef.current.value = '';
    }
  };

  const postPatientsTemporaryInfo = async (
    params: Parameters<typeof apiClient.v3.apiPatientsElTemporary>[0],
  ) => {
    abortController.current = new AbortController();
    const response = await apiClient.v3.apiPatientsElTemporary(params, {
      signal: abortController.current.signal,
    });
    return SHARED_UTILS.api.checkApiResponse(response.data);
  };

  const validateTemplate = (
    headerValues: { A1: string; A2: string; A3: string; B3: string },
    _fileType: 'xlsx' | 'csv',
  ) => {
    return (
      headerValues.A1 === '고객 등록 양식' &&
      headerValues.A2 === '*양식을 임의로 수정하면 업로드에 실패할 수 있습니다.' &&
      headerValues.A3 === '고객명*' &&
      headerValues.B3 === '휴대폰번호*'
    );
  };

  const postUploadedExcelFile = useMutation({
    mutationFn: (params: Parameters<typeof postPatientsTemporaryInfo>[0]) =>
      postPatientsTemporaryInfo(params),
    onSuccess: (data) => {
      fullDimmedLoadingService.off();
      if (data.successCount > 0) {
        queryClient.resetQueries({ queryKey: [QUERY_KEY.apiPatientsElTemporaryListOrSearch] });
      }
    },
    onError: (error) => {
      console.error('임시 고객정보 저장 에러 발생 :', error);
    },
    onSettled: () => {
      resetSelectedSearchCriteria();
      resetSearchText();
      resetState();
    },
  });

  const readXlsxFile = async (file: File): Promise<void> => {
    const workbook = new ExcelJS.Workbook();
    try {
      await workbook.xlsx.load(file);
    } catch (error) {
      console.error('엑셀 파일 로드 에러 발생 :', error);
      toastService.errorMsg({ text: '엑셀 파일 로드에 실패했습니다.' });
      resetState();
      return;
    }

    const worksheet = workbook.getWorksheet(1);

    const headerValues = {
      A1: worksheet.getCell('A1').value as string,
      A2: worksheet.getCell('A2').value as string,
      A3: worksheet.getCell('A3').value as string,
      B3: worksheet.getCell('B3').value as string,
    };

    if (!validateTemplate(headerValues, 'xlsx')) {
      modalService.defaultWarning({
        title: '양식 오류',
        contents: '업로드한 파일의 양식이 올바르지 않습니다. 양식을 다시 확인해 주세요.',
        buttonType: 'CONFIRM',
        buttonText: '확인',
      });
      resetState();
      return;
    }

    const jsonData: Partial<CustomerManagementTemporaryAPIFormValues[number]>[] = [];

    let order = 1;
    worksheet.eachRow((row: Excel.Row, rowNumber: number) => {
      const rowData: Partial<CustomerManagementTemporaryAPIFormValues[number]> = {};

      let isTargetRow = false;

      row.eachCell({ includeEmpty: true }, (cell, colNumber) => {
        if (rowNumber === 4 && colNumber === 1 && cell.value === '홍길동') {
          isTargetRow = true;
        } else if (rowNumber === 4 && colNumber === 2 && cell.value === '01012345678') {
          isTargetRow = true;
        }

        if (colNumber === 1 && cell.value !== '홍길동') {
          rowData.name = cell.value ? cell.value.toString() : undefined;
        } else if (colNumber === 2 && cell.value !== '01012345678') {
          rowData.phoneNumber = cell.value ? cell.value.toString().replace(/-/g, '') : undefined;
        }
      });

      if (!isTargetRow || rowNumber !== 4) {
        jsonData.push(rowData);
        order += 1;
      }
    });

    const dataWithoutEmpty = jsonData?.slice(3)?.filter((item) => {
      return item !== undefined && Object.values(item).some((value) => value !== undefined);
    });

    const updatedData: CustomerManagementTemporaryAPIFormValues = dataWithoutEmpty?.map(
      ({ name, phoneNumber }, index) => {
        return {
          hospitalID,
          order: index + 1,
          name,
          phoneNumber,
          birthdate: undefined,
          chartNumber: undefined,
          countryCodeId: undefined,
          gender: undefined,
          isFirstVisit: undefined,
          nationalityId: undefined,
          treatmentTagIds: undefined,
        };
      },
    );

    if (!updatedData) {
      modalService.defaultWarning({
        title: '파일 등록 불가',
        contents: '등록하려는 파일 상태를 다시 확인해 주세요.',
        buttonType: 'CONFIRM',
        buttonText: '확인',
      });
      resetState();
      return;
    }

    if (updatedData.length === 0) {
      modalService.defaultWarning({
        title: '파일 데이터 없음',
        contents: '등록하려는 파일에 등록할 데이터가 없습니다.',
        buttonType: 'CONFIRM',
        buttonText: '확인',
      });
      resetState();
      return;
    }

    if (updatedData.length > 0) {
      postUploadedExcelFile.mutate(updatedData);
      return;
    }
  };

  const readCsvFile = (file: File) => {
    Papa.parse(file, {
      header: true,
      complete: (results: { data: { [key: string]: string }[] }) => {
        const data = results.data;

        if (data.length === 0) {
          modalService.defaultWarning({
            title: '파일 데이터 없음',
            contents: '등록하려는 파일에 등록할 데이터가 없습니다.',
            buttonType: 'CONFIRM',
            buttonText: '확인',
          });
          resetState();
          return;
        }

        const headerValues = {
          A1: data[0]['고객 등록 양식'] || '',
          A2: data[0] ? data[0][''] : '',
          A3: data[1] ? data[1]['고객 등록 양식'] : '',
          B3: data[1] ? data[1][''] : '',
        };

        if (!validateTemplate(headerValues, 'csv')) {
          modalService.defaultWarning({
            title: '파일 등록 불가',
            contents: '등록하려는 파일 상태를 다시 확인해 주세요.',
            buttonType: 'CONFIRM',
            buttonText: '확인',
          });
          resetState();
          return;
        }

        const updatedData: CustomerManagementTemporaryAPIFormValues = data
          .slice(2) // 3번째 줄부터 시작합니다.
          .filter((item: { [key: string]: string }) => {
            return item['고객 등록 양식'] !== undefined || item[''] !== undefined;
          })
          .map((row: { [key: string]: string }, index: number) => {
            return {
              order: index + 1,
              hospitalID,
              name: row['고객 등록 양식'],
              phoneNumber: row[''],
              birthdate: undefined,
              chartNumber: undefined,
              countryCodeId: undefined,
              gender: undefined,
              isFirstVisit: undefined,
              nationalityId: undefined,
              treatmentTagIds: undefined,
            };
          });

        if (updatedData.length > 0) {
          postUploadedExcelFile.mutate(updatedData);
          return;
        }
      },
      error: (error) => {
        console.error('CSV Parsing Error:', error);
        toastService.errorMsg({ text: 'CSV 파일 로드에 실패했습니다.' });
        resetState();
      },
    });
  };

  const onUpload = (event: React.ChangeEvent<HTMLInputElement>) => {
    toastService.loadingMsg({
      id: 'uploading',
      text: '파일 업로드 중입니다. 잠시만 기다려주세요.',
      rightButton: {
        text: '취소하기',
        onClick: () => {
          if (abortController.current) {
            abortController.current.abort();
          }
          resetState();
        },
      },
    });
    fullDimmedLoadingService.on();

    const file = event.target.files?.[0];
    if (file) {
      const fileSizeMB = file.size / (1024 * 1024);

      if (fileSizeMB > MAX_FILE_SIZE_MB) {
        modalService.defaultWarning({
          title: '파일 용량 초과',
          contents: '30MB 이하의 파일만 등록할 수 있습니다.',
          buttonType: 'CONFIRM',
          buttonText: '확인',
        });
        resetState();
        return;
      }

      const fileExtension = file.name.split('.').pop()?.toLowerCase();
      if (fileExtension === 'xlsx') {
        readXlsxFile(file);
      } else if (fileExtension === 'csv') {
        readCsvFile(file);
      } else {
        toastService.errorMsg({ text: '지원하지 않는 파일 형식입니다.' });
        resetState();
      }
    }
  };

  return {
    onUpload,
    fileInputRef,
  };
};
