import { SHARED_UTILS } from '@shared-utils/utils';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { Suspense, useMemo } from 'react';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ApiAutomationsElMainListParams } from 'web/apis/swaggers/swagger-docs';
import LayoutBox from 'web/shared/components/LayoutBox/LayoutBox';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import AutomationSearchFilter from 'web/templates/Automation/containers/Main/AutomationSearchFilter/AutomationSearchFilter';
import { automationSearchTextState } from 'web/templates/Automation/containers/Main/AutomationSearchFilter/states/automation-search';
import { selectedAutomationSearchCriteriaState } from 'web/templates/Automation/containers/Main/AutomationSearchFilter/states/selected-automation-search-criteria';
import CounselAutomationContent from 'web/templates/Automation/containers/Main/CounselAutomationContent/CounselAutomationContent';
import CounselAutomationTitle from 'web/templates/Automation/containers/Main/CounselAutomationContent/components/CounselAutomationTitle';
import DashBoardContent from 'web/templates/Automation/containers/Main/DashBoardContent/DashBoardContent';
import MarketingAutomationContent from 'web/templates/Automation/containers/Main/MarketingAutomationContent/MarketingAutomationContent';
import MarketingAutomationTitle from 'web/templates/Automation/containers/Main/MarketingAutomationContent/components/MarketingAutomationTitle';
import CardItemLayout from 'web/templates/Automation/containers/Main/shared/components/CardItemLayout';

export default function AutomationContainer() {
  return (
    <div className='bg-blueLight'>
      <AutomationSearchFilter />
      <div className='mx-auto grid h-full w-[85%] grid-cols-[1fr,370px] justify-center gap-x-50 gap-y-20 py-40'>
        <div className='flex w-full flex-col gap-10'>
          <Suspense
            fallback={
              <>
                <CounselAutomationTitle />
                <div className='grid w-full grid-cols-1 gap-x-20 gap-y-16 rounded-r16 bg-[#E5EEF8] p-10 sm:grid-cols-2 lg:grid-cols-3'>
                  <CardItemLayout className='min-h-[192px] w-[360px]' />
                </div>
                <MarketingAutomationTitle />
                <div className='grid w-full grid-cols-1 gap-x-20 gap-y-16 rounded-r16 bg-[#E5EEF8] p-10 sm:grid-cols-2 lg:grid-cols-3'>
                  <CardItemLayout className='min-h-[192px] w-[360px]' />
                </div>
              </>
            }>
            <Content />
          </Suspense>
        </div>
        {/* 대시보드 */}
        <LayoutBox noStyle>
          <DashBoardContent />
        </LayoutBox>
      </div>
    </div>
  );
}
const fetchAutomationsElMainList = async (params: ApiAutomationsElMainListParams) => {
  const response = await apiClient.v3.apiAutomationsElMainList(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const Content = () => {
  const { hospitalID } = useSelectedHospitalInfo();
  const automationSearchText = useAtomValue(automationSearchTextState);
  const selectedAutomationSearchCriteria = useAtomValue(selectedAutomationSearchCriteriaState);

  const params: ApiAutomationsElMainListParams = useMemo(() => {
    return {
      hospitalID,
      algorithmNameFilter:
        selectedAutomationSearchCriteria === 'algorithmName' ? automationSearchText : undefined,
      treatmentTagNameFilter:
        selectedAutomationSearchCriteria === 'treatmentTagName' ? automationSearchText : undefined,
      nationalityNameFilter:
        selectedAutomationSearchCriteria === 'nationalityName' ? automationSearchText : undefined,
    };
  }, [hospitalID, automationSearchText, selectedAutomationSearchCriteria]);

  const { data } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiAutomationsElMainList, { ...params }] as const,
    queryFn: ({ queryKey }) => fetchAutomationsElMainList(queryKey[1]),
  });

  return (
    <>
      {/* 공통 재내원 메시지 & 관리자동화 */}
      <CounselAutomationContent
        commonAlgorithm={data.commonAlgorithm}
        consultationAlgorithms={data.consultationAlgorithms}
      />
      {/* 마케팅자동화 */}
      <MarketingAutomationContent marketingAlgorithms={data.marketingAlgorithms} />
    </>
  );
};
