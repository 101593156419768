import { useChatMessageContext } from '../ChatMessageContext';
import TextContentMessage from './TextContentMessage/TextContentMessage';
import MessageTitle from './components/MessageTitle';
import VideoListCard from './components/VideoListCard';

export default function AlgorithmdVideoMessage() {
  const { fileIDs, content, isMine, messageWidthStyle } = useChatMessageContext();

  return (
    <div className='flex flex-col gap-10'>
      {content && <TextContentMessage />}
      <div
        className={`rounded-r10 px-16 py-10 shadow-modal ${isMine ? 'bg-[rgba(255,245,208,1)]' : 'bg-white50'}`}
        style={messageWidthStyle}>
        <MessageTitle title='운동 영상' />
        {/* TODO - vchID 추가하기 */}
        {fileIDs?.map(({ createdAt, aws, paths }) => {
          if (!aws || !paths) return null;
          const { fileName } = aws[0];
          return <VideoListCard key={createdAt} url={paths[0]} title={fileName} vchID={''} />;
        })}
      </div>
    </div>
  );
}
