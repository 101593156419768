import { useAtomValue } from 'jotai';
import { useEffect, useState } from 'react';
import { channelIntegrationInfoState } from 'web/templates/CustomerChat/components/ChattingList/states/channel-integration-info-state';
import type { AwsObject } from '../../../../../../../../../types';
import { useChatMessageContext } from '../ChatMessageContext';
import { IMAGE_MESSAGE_LIMIT_TIME } from '../components/RightMessage/constants/rightMessage';
import ImageList from './components/ImageList';

//TODO 에러 이미지 올리기

const item = {
  Bucket: '',
  Etag: '',
  Key: '',
  Location:
    'https://afterdoc.s3.ap-northeast-2.amazonaws.com/613aa681cb516152eed8fdb3%2F20241107182629%2FisChatting.png',
  fileName: 'mock',
  fileSize: -1,
  key: 'mock',
};

export default function MockingImageMessage() {
  const { fileIDs, status } = useChatMessageContext();
  const { roomType } = useAtomValue(channelIntegrationInfoState);
  const [mockitem, setMockItem] = useState<AwsObject>(item);

  if (!fileIDs || (roomType !== 'ikakao' && fileIDs?.length === 0)) return null;

  useEffect(() => {
    if (status === 'failed') {
      setMockItem({
        ...item,
        fileName: 'err',
        Location:
          'https://afterdoc.s3.ap-northeast-2.amazonaws.com/613aa681cb516152eed8fdb3%2F20241107182428%2Fchatting.png',
      });
    }
    setTimeout(() => {
      setMockItem({
        ...item,
        fileName: 'err',
        Location:
          'https://afterdoc.s3.ap-northeast-2.amazonaws.com/613aa681cb516152eed8fdb3%2F20241107182428%2Fchatting.png',
      });
    }, IMAGE_MESSAGE_LIMIT_TIME);
  }, []);

  const aws = Array.from({ length: 1 }, () => mockitem);
  //swagger에서 정상적으로 주지 않는 타입
  return <ImageList aws={aws as AwsObject[]} />;
}
