import { SHARED_UTILS } from '@shared-utils/utils';
import { useInfiniteQuery } from '@tanstack/react-query';
import { useAtomValue } from 'jotai';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ApiChatElFindParams } from 'web/apis/swaggers/swagger-docs';
import { chatRoomIDAtom } from 'web/templates/CustomerChat/states/chatRoomID';

const INTERVAL_NUM = 20;

const getChat = async (query: ApiChatElFindParams) => {
  const { data } = await apiClient.v3.apiChatElFind(query);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export const useInfiniteChatIsBookmarkData = () => {
  const chatRoomID = useAtomValue(chatRoomIDAtom);

  const query = {
    chatRoomID,
    isBookmark: true,
  };

  const { data, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading } =
    useInfiniteQuery({
      queryKey: [QUERY_KEY.apiChatElFind, chatRoomID],
      queryFn: ({ pageParam }) => {
        const { skip, limit } = pageParam;
        return getChat({ ...query, skip, limit } as ApiChatElFindParams);
      },
      getNextPageParam: (lastPage, pages) => {
        if (!lastPage?.length) return undefined; // 더 이상 페이지가 없음을 의미

        const pagesNum = pages.length;
        return { skip: pagesNum * INTERVAL_NUM, limit: INTERVAL_NUM };
      },
      initialPageParam: { limit: INTERVAL_NUM, skip: 0 },
      enabled: !!chatRoomID,
      staleTime: 0,
    });

  const newMessages = data?.pages.flat().reverse() ?? [];

  return { data: newMessages, refetch, fetchNextPage, hasNextPage, isFetchingNextPage, isLoading };
};
