import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';

export type RoomInfo = {
  pf_id: string;
  user_key: string;
};

export type RoomType = 'patient' | 'ikakao' | 'manager';

// 기본 roomType atom
const roomTypeState = atomWithReset<RoomType>('patient');
// 기본 roomInfo atom
const roomInfoState = atom({
  pf_id: '',
  user_key: '',
});

export const channelIntegrationInfoState = atom(
  (get) => {
    const roomType = get(roomTypeState);
    const roomInfo = get(roomInfoState);

    return {
      roomType,
      roomInfo,
    };
  },
  (get, set, update: { roomType?: RoomType; roomInfo?: RoomInfo }) => {
    const { roomType, roomInfo } = update;
    if (roomType) {
      set(roomTypeState, roomType);
    }
    if (roomInfo) {
      set(roomInfoState, roomInfo);
    }
  },
);
