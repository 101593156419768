import { RESERVATION_HISTORY_TABLE_HEADERS } from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/AfterDoc/ReservationHistory/components/ReservationHistoryTable/constants/reservation-history-table-headers';

export default function TableHeader() {
  return (
    <thead>
      <tr>
        {RESERVATION_HISTORY_TABLE_HEADERS.map((header) => (
          <th
            key={header}
            className='h-fit whitespace-nowrap border-line-table border-b bg-table px-12 py-10 text-left text-Header14 text-black700'>
            {header}
          </th>
        ))}
      </tr>
    </thead>
  );
}
