import Badge from '@afterdoc-design-system/components/Atoms/Badge/Badge';
import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import dayjs from 'dayjs';
import type { Chat } from 'web/apis/swaggers/swagger-docs';
import { displayTextContent } from '../functions/displayTextContent';
import type { TempChatRoomHServiceLastMessageUser } from '../hooks/use-chattinglist';
import ParticipantsChip from './components/ParticipantsChip';

type ChattingListItemProps = Omit<
  TempChatRoomHServiceLastMessageUser,
  'hserviceID' | 'userID' | 'info' | 'c_UnreadCount' | '_id' | 'parentHServiceID'
> &
  Pick<Chat, 'content' | 'isTranslated' | 'translatedContent' | 'type'> & {
    isBadge?: boolean;
    id: string;
    isKakaoIcon: boolean;
    isAfterdocIcon: boolean;
    isLeft: boolean;
  };

export default function ChattingListItem({
  id,
  content,
  counselorID,
  isBadge,
  isTranslated,
  translatedContent,
  type,
  users,
  title,
  lastMessageUpdatedAt,
  isKakaoIcon,
  isAfterdocIcon,
  isLeft,
}: ChattingListItemProps) {
  const formatedUpdatedAt = dayjs(lastMessageUpdatedAt).format('YYYY-MM-DD');

  const usersWithoutPatient = users.filter((user) => user.name !== title);

  const defaultCallback = () => {
    return !isLeft && isTranslated ? (translatedContent ?? '') : (content ?? '');
  };

  return (
    <div key={id} className='flex h-86 flex-col px-20 py-12'>
      <div className='flex-center justify-between'>
        <div className='flex gap-6'>
          <span className='text-Header14 text-black700'>{title}</span>
          <div className='flex'>
            {isAfterdocIcon && <Icon name='afterdoc' />}
            {isKakaoIcon && <Icon name='kakaotalk' />}
          </div>
        </div>
        <Badge isBadge={isBadge}>
          <ParticipantsChip
            targetChatRoomID={id}
            users={usersWithoutPatient}
            counselorID={counselorID}
          />
        </Badge>
      </div>
      <div className='flex h-36 flex-row justify-between'>
        <span className='line-clamp-2 w-[280px] text-Body12 text-black200'>
          {displayTextContent(type, defaultCallback)}
        </span>
        <span className='content-end text-Caption9 text-black200'>{formatedUpdatedAt}</span>
      </div>
    </div>
  );
}
