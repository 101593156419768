import { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import TextEditor from 'web/shared/components/TextEditor/TextEditor';
import { useUserInfo } from 'web/shared/hooks/use-user-info';
import type { UpdateSingleManualAPIFormValues } from 'web/templates/HospitalManual/components/HospitalManualContent/HospitalManualContent';

export default function ManualContentEditor() {
  const { watch, setValue } = useFormContext<UpdateSingleManualAPIFormValues>();
  const { authorizationTypeID } = useUserInfo();

  const canUserCreateManual = authorizationTypeID?.canCreateManual;

  const manualContent = watch('manualContent');

  const handleEditorInput = useCallback(
    (value: string) => {
      setValue('manualContent', value);
    },
    [setValue],
  );

  return (
    <div className='pt-30'>
      <TextEditor
        disabled={!canUserCreateManual}
        height={350}
        value={manualContent}
        onChange={handleEditorInput}
      />
    </div>
  );
}
