import { useAtomValue } from 'jotai';
import MonthSelector from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/AfterDoc/ReservationHistory/containers/components/MonthSelector';
import WeekSelector from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/AfterDoc/ReservationHistory/containers/components/WeekSelector';
import DaySelector from './components/DaySelector';
import YearSelector from './components/YearSelector';
import { periodTabState } from './states/period-tab';

export default function ReservationHistoryDatePickerContainer() {
  const selectedTab = useAtomValue(periodTabState);

  if (selectedTab === '연간') {
    return <YearSelector />;
  }

  if (selectedTab === '월간') {
    return <MonthSelector />;
  }

  if (selectedTab === '주간') {
    return <WeekSelector />;
  }

  if (selectedTab === '일간') {
    return <DaySelector onSelect={(date) => date} />;
  }

  return null;
}
