import { H3 } from '@afterdoc-design-system/assets/icons';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import imageCompression from 'browser-image-compression';
import { useCallback, useEffect, useRef, useState } from 'react';
import ReactDOMServer from 'react-dom/server';
import ReactQuill, { Quill, type QuillOptionsStatic } from 'react-quill-new';
import CustomToolbar from './CustomToolbar';
import './TextEditor.scss';

interface TextEditorProps {
  value?: string;
  disabled?: boolean;
  onChange?: (value: string) => void;
  height?: number;
  className?: string;
}

interface QuillIcons {
  header: {
    [key: number]: string;
  };
  [key: string]: unknown;
}

interface HistoryModule {
  undo(): void;
  redo(): void;
}

const imageOptions = {
  maxSizeMB: 0.5,
  maxWidthOrHeight: 1280,
  useWebWorker: true,
};

const { BlockBlot } = Quill.import('parchment');
const Block = Quill.import('blots/block') as typeof BlockBlot;
class HorizontalRuleBlot extends Block {
  static blotName = 'horizontal';
  static tagName = 'hr';
  static className = 'custom-horizontal-line';
}
Quill.register(HorizontalRuleBlot);

const icons = Quill.import('ui/icons') as QuillIcons;
icons.header[3] = ReactDOMServer.renderToStaticMarkup(<H3 />);

const convertBlobToBase64 = async (blobUrl: string): Promise<string> => {
  const response = await fetch(blobUrl);
  const blob = await response.blob();
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.onloadend = () => {
      resolve(reader.result as string);
    };
    reader.onerror = reject;
    reader.readAsDataURL(blob);
  });
};

const modules: QuillOptionsStatic['modules'] = {
  toolbar: {
    container: '#toolbar',
  },
  history: {
    delay: 1000,
    maxStack: 50,
    userOnly: true,
  },
};

export default function TextEditor({
  value,
  disabled,
  onChange,
  height,
  className,
}: TextEditorProps) {
  const [mounted, setMounted] = useState(false);
  const quillRef = useRef<ReactQuill | null>(null);

  const handleUndo = useCallback(() => {
    if (disabled) return;

    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      const history = editor.getModule('history') as HistoryModule;
      history.undo();
    }
  }, [disabled]);

  const handleRedo = useCallback(() => {
    if (disabled) return;

    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      const history = editor.getModule('history') as HistoryModule;
      history.redo();
    }
  }, [disabled]);

  const handleInsertHorizontal = useCallback(() => {
    if (disabled) return;

    if (quillRef.current) {
      const editor = quillRef.current.getEditor();
      const range = editor.getSelection(true);

      if (range) {
        editor.insertEmbed(range.index, 'horizontal', true, Quill.sources.USER);
        editor.setSelection(range.index + 2, 0);
      }
    }
  }, [disabled]);

  const compressAndConvertToWebP = async (file: File) => {
    try {
      const compressedFile = await imageCompression(file, imageOptions);

      const base64WebP = await convertToWebP(compressedFile);
      return base64WebP;
    } catch (error) {
      console.error('이미지 압축 또는 변환 실패:', error);
      throw error;
    }
  };

  const convertToWebP = (blob: Blob): Promise<string> =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = () => {
        const img = new Image();
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          if (!ctx) return reject('Canvas context 생성 실패');

          ctx.drawImage(img, 0, 0);
          const webpBase64 = canvas.toDataURL('image/webp', 0.8);
          resolve(webpBase64);
        };
        img.onerror = reject;
        img.src = reader.result as string;
      };
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    });

  const handleImageFile = useCallback(() => {
    if (disabled) return;

    const input = document.createElement('input');
    input.setAttribute('type', 'file');
    input.setAttribute('accept', 'image/*');
    input.click();

    input.addEventListener('change', async () => {
      if (!input.files) return;

      const file = input.files[0];

      if (file) {
        try {
          let base64Data: string;

          if (file.type === 'image/gif') {
            const blobUrl = URL.createObjectURL(file);
            base64Data = await convertBlobToBase64(blobUrl);
            URL.revokeObjectURL(blobUrl);
          } else {
            base64Data = await compressAndConvertToWebP(file);
          }

          if (!quillRef.current) return;

          const editor = quillRef.current.getEditor();
          const range = editor.getSelection();

          if (range) {
            editor.insertEmbed(range.index, 'image', base64Data);
            editor.setSelection({
              index: range.index + 1,
              length: 0,
            });
          }
        } catch (error) {
          console.error('이미지 처리 실패:', error);
        }
      }
    });
  }, [disabled]);

  const handleContentChange = (content: string) => {
    if (!onChange || disabled) return;
    if (content === '<p><br></p>') {
      onChange('');
    } else {
      onChange(content);
    }
  };

  useEffect(() => {
    setMounted(true);
  }, []);

  return (
    <>
      {mounted && (
        <div>
          <CustomToolbar />
          <ReactQuill
            readOnly={disabled}
            className={customTwMerge('text-editor', className)}
            style={{
              height: height ?? 316,
            }}
            value={value}
            onChange={handleContentChange}
            ref={quillRef}
            modules={{
              ...modules,
              toolbar: {
                ...(modules?.toolbar as Record<string, unknown>),
                handlers: {
                  undo: handleUndo,
                  redo: handleRedo,
                  horizontal: handleInsertHorizontal,
                  image: handleImageFile,
                },
              },
            }}
            theme='snow'
            placeholder='내용을 입력해 주세요.'
          />
        </div>
      )}
    </>
  );
}
