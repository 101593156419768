import { useChatMessageContext } from '../ChatMessageContext';
import ListCard from './components/EventListCard';
import MessageTitle from './components/MessageTitle';

export default function ElectronEventMessage() {
  const { haftercareID, isMine, messageWidthStyle } = useChatMessageContext();
  if (haftercareID === undefined) return null;
  const representImage = haftercareID.representImage;
  const paths = representImage?.paths;
  const url = paths ? paths[0] : '';
  const { title, price, saleRate, salePrice } = haftercareID;
  if (title === undefined) return null;
  if (price === undefined || saleRate === undefined || salePrice === undefined) return null;
  const priceAfterSale = price - salePrice;
  return (
    <>
      <div
        className={`rounded-r10 px-16 py-10 shadow-modal ${isMine ? 'bg-[rgba(255,245,208,1)]' : 'bg-white50'}`}
        style={messageWidthStyle}>
        <MessageTitle title='홈케어 상품' />
        <ListCard
          url={url}
          description={title}
          originalPrice={price}
          eventPrice={priceAfterSale}
          percentage={saleRate}
        />
      </div>
    </>
  );
}
