import * as React from "react";
import type { SVGProps } from "react";
const SvgUndo = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    fill="none"
    viewBox="0 0 48 48"
    {...props}
  >
    <path
      fill="#000"
      d="M25 16c-5.3 0-10.1 2-13.8 5.2L4 14v18h18l-7.24-7.24C17.54 22.44 21.08 21 25 21c7.08 0 13.1 4.62 15.2 11l4.74-1.56C42.16 22.06 34.3 16 25 16"
      className="undo_svg__ql-fill"
    />
  </svg>
);
export default SvgUndo;
