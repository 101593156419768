import { useAtom } from 'jotai';
import { useEffect } from 'react';
import { useChatRoomInfoOne } from '../../../hooks/use-chatroom-info-one';
import { isActivateChatTranslateAtom } from '../../../states/isChatTranslate';

export function useChatTranslationToggle() {
  const { isUsingTranslation } = useChatRoomInfoOne();
  const [isActivateChatTranslate, setIsActivateChatTranslate] = useAtom(
    isActivateChatTranslateAtom,
  );

  useEffect(() => {
    if (isUsingTranslation !== undefined) {
      setIsActivateChatTranslate(isUsingTranslation);
    }
  }, [isUsingTranslation, setIsActivateChatTranslate]);

  return { isActivateChatTranslate, setIsActivateChatTranslate };
}
