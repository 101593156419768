import { useSetAtom } from 'jotai';
import { Suspense, useEffect } from 'react';
import LayoutBox from 'web/shared/components/LayoutBox/LayoutBox';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import SmartDoctorCustomerMemoContainer from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/SmartDoctor/components/SmartDoctorCustomerMemo/SmartDoctorCustomerMemo.container';
import SmartDoctorReservationHistoryContainer from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/SmartDoctor/components/SmartDoctorReservationHistory/SmartDoctorReservationHistory.container';
import { smartDoctorTokenState } from './state/token';

export default function SmartDoctorReservationContainer() {
  const { integrationSetting } = useSelectedHospitalInfo();

  const setSmartDoctorToken = useSetAtom(smartDoctorTokenState);

  useEffect(() => {
    if (integrationSetting && !integrationSetting.isRemoved) {
      setSmartDoctorToken(integrationSetting.clientToken);
    }
  }, [integrationSetting]);

  if (!integrationSetting) {
    return null;
  }

  return (
    <div className='flex h-full flex-col gap-16'>
      {/* 스마트닥터 고객메모 */}
      <Suspense>
        <SmartDoctorCustomerMemoContainer isRemoved={integrationSetting.isRemoved} />
      </Suspense>
      {/* 스마트닥터 예약내역 */}
      <LayoutBox noStyle>
        <SmartDoctorReservationHistoryContainer />
      </LayoutBox>
    </div>
  );
}
