import { IMAGE_EXTENSIONS } from '../constants/file';

const limitSizeMap = {
  image: 1024 * 1024 * 5,
  file: 1024 * 1024 * 10,
};

type LimitSizeType = keyof typeof limitSizeMap;

export const validateFileSize = (file: File) => {
  const { type, size } = file;
  const fileType = type.split('/')[1] as LimitSizeType;
  const keyType = IMAGE_EXTENSIONS.find((ext) => ext === fileType) ? 'image' : 'file';
  return size <= limitSizeMap[keyType];
};
