import Scrollbar from '@afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import DOMPurify from 'dompurify';

type DialogContentProps = {
  displayCreated?: string;
  description?: string;
  title?: string;
  content?: string;
  children?: React.ReactNode;
};

export const MessageDialogContent = ({
  displayCreated,
  description,
  title,
  content,
  children,
}: DialogContentProps) => {
  const sanitizedContent = DOMPurify.sanitize(content ?? '');
  return (
    <div className='h-[730px] w-[400px] px-20 py-10'>
      <Scrollbar>
        <div className='h-auto flex-col-center gap-10 pb-10'>
          <div className='text-Header16 text-black500'> {title}</div>
          <div className='text-Body12 text-black500'>{displayCreated}</div>
          <div
            className=' w-[320px] bg-black100'
            // biome-ignore lint/security/noDangerouslySetInnerHtml: <explanation>
            dangerouslySetInnerHTML={{ __html: sanitizedContent }}
          />
          {children}
          <div className='mb-[200px] text-Body13 text-black500'>{description}</div>
        </div>
      </Scrollbar>
    </div>
  );
};
