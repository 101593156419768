import ContainedButton from '@afterdoc-design-system/components/Atoms/Button/ContainedButton';
import { dialogService } from '@afterdoc-design-system/components/Molecules/Dialog/Dialog.service';
import NoData from '@afterdoc-design-system/components/Molecules/NoData/NoData';
import { SHARED_UTILS } from '@shared-utils/utils';
import { useSuspenseQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { Suspense, useEffect } from 'react';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type {
  ApiV2ReservationElFindParams,
  ReservationDetail,
} from 'web/apis/swaggers/swagger-docs';
import ErrorBoundary from 'web/shared/components/ErrorBoundary/ErrorBoundary';
import { usePatientDetailInfo } from 'web/shared/hooks/use-get-patient-detail-info';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import ChangedReservationsList from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/AfterDoc/ReservationHistory/components/ChangedReservationsList/ChangedReservationsList';
import { RESERVATION_HISTORY_TABLE_HEADERS } from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/AfterDoc/ReservationHistory/components/ReservationHistoryTable/constants/reservation-history-table-headers';
import { RESERVATION_HISTORY_TABLE_LIMIT } from 'web/templates/CustomerManagement/containers/CustomerDetailInfo/containers/ReservationManagement/containers/AfterDoc/ReservationHistory/components/ReservationHistoryTable/constants/reservation-history-table-limit';

const fetchReservationHistoryList = async (params: ApiV2ReservationElFindParams) => {
  const response = await apiClient.v2.apiV2ReservationElFind(params);
  return SHARED_UTILS.api.checkApiResponse<ReservationDetail[]>(response.data);
};

interface TableBodyProps {
  reservationSelectedDate: {
    startedAt: Date;
    endedAt: Date;
  } | null;
  currentPage: number;
}

export default function TableBody({ reservationSelectedDate, currentPage }: TableBodyProps) {
  const skip = (currentPage - 1) * RESERVATION_HISTORY_TABLE_LIMIT;
  const { hospitalID } = useSelectedHospitalInfo();
  const { patientId } = usePatientDetailInfo();

  const params = {
    hospitalID,
    hserviceID: patientId,
    limit: RESERVATION_HISTORY_TABLE_LIMIT,
    skip,
    key: 'startedAt',
    way: -1,
    startedAt: reservationSelectedDate?.startedAt,
    endedAt: reservationSelectedDate?.endedAt,
  };

  const { data: reservationList, refetch } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiV2ReservationElFind, params] as const,
    queryFn: ({ queryKey }) => fetchReservationHistoryList(queryKey[1]),
  });

  useEffect(() => {
    window.electron?.ipcRenderer.on('Application.doneCreateReservation', (_, payload) => {
      if (payload) {
        refetch();
      }
    });

    return () => {
      window.electron?.ipcRenderer.removeAllListeners('Application.doneCreateReservation');
    };
  }, []);

  if (!reservationList || reservationList.length === 0) {
    return (
      <tr>
        <td colSpan={RESERVATION_HISTORY_TABLE_HEADERS.length}>
          <div className='flex h-[230px] w-full items-center justify-center'>
            <NoData
              iconProps={{
                name: 'calendar-blank-outline',
                size: 48,
                color: 'white400',
              }}
              title='등록한 예약이 없습니다.'
              subTitle='애프터닥 예약으로 고객의 예약 내역을 관리할 수 있습니다.'
            />
          </div>
        </td>
      </tr>
    );
  }

  return (
    <>
      {reservationList.map((data, index) => {
        const {
          groupTitle,
          columnTitle,
          programTitle,
          note,
          writerName,
          createdAt,
          startedAt,
          _id: reservationId,
        } = data;

        return (
          <tr key={reservationId} className={`${index === 2 ? 'text-red500' : ''}`}>
            {/* 예약일시 */}
            <td className='truncate border-line-table border-b px-12 py-10'>
              {dayjs(startedAt).format('YYYY-MM-DD HH:mm') ?? '-'}
            </td>
            {/* 부서 */}
            <td className='truncate border-line-table border-b px-12 py-10'>{groupTitle ?? '-'}</td>
            {/* 프로그램명 */}
            <td className='truncate border-line-table border-b px-12 py-10'>
              {programTitle ?? '-'}
            </td>
            {/* 담당자 */}
            <td className='truncate border-line-table border-b px-12 py-10'>
              {columnTitle ?? '-'}
            </td>
            {/* 예약메모 */}
            <td className='truncate border-line-table border-b px-12 py-10'>{note ?? '-'}</td>
            {/* 최종수정자 */}
            <td className='truncate border-line-table border-b px-12 py-10'>{writerName ?? '-'}</td>
            <td className='truncate border-line-table border-b px-12 py-10'>
              <ContainedButton
                buttonSize='small'
                btnColor='secondary'
                type='button'
                onClick={() => {
                  dialogService.push(
                    <ErrorBoundary>
                      <Suspense>
                        <ChangedReservationsList reservationId={reservationId} />
                      </Suspense>
                    </ErrorBoundary>,
                    {
                      titleProps: {
                        title: '예약 변경 내역',
                      },
                      hasCloseButton: true,
                      wrapperClassName: 'h-[398px]',
                      width: 1000,
                    },
                  );
                }}
                disabled={!createdAt}>
                보기
              </ContainedButton>
            </td>
          </tr>
        );
      })}
    </>
  );
}
