import Rectangle from '@afterdoc-design-system/assets/icons/Rectangle';
import IconButton from '@afterdoc-design-system/components/Atoms/Button/IconButton';
import OutlinedButton from '@afterdoc-design-system/components/Atoms/Button/OutlinedButton';
import Scrollbar from '@afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import HoverTooltip from '@afterdoc-design-system/components/Atoms/Tooltip/HoverTooltip';
import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import { modalService } from '@afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import dayjs from 'dayjs';
import { useAtomValue, useSetAtom } from 'jotai';
import { omit } from 'lodash-es';
import { memo, useEffect, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { usePrevious } from 'react-use';
import { v4 as uuidv4 } from 'uuid';
import type { MessageInput } from 'web/apis/swaggers/swagger-docs';
import type { CounselAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/components/RegisterCounselAutomationDialogContent';
import { MESSAGE_OPTIONS } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/constants/counsel-message-options';
import {
  selectedContentTabIndexState,
  selectedMessageIndexState,
  selectedMessageState,
} from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-form';
import { showMessageTypeDialogState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-type-dialog';
import { useAlgorithmData } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/hooks/use-algorithm-data';

export default function AutomatedMessageLists() {
  useAlgorithmData();

  const { setValue, watch } = useFormContext<CounselAutomationAPIFormValues>();

  const [warningStates, setWarningStates] = useState<{ [index: number]: boolean }>({});

  const setSelectedMessage = useSetAtom(selectedMessageState);
  const setShowMessageTypeDialog = useSetAtom(showMessageTypeDialogState);
  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);

  const messageLists = watch('messages');
  const message = watch(`messages.${selectedMessageIndex}`);
  const daysAfter = watch(`messages.${selectedMessageIndex}.daysAfter`);
  const timeOfDay = watch(`messages.${selectedMessageIndex}.timeOfDay`);
  const prevMessageLists = usePrevious(messageLists);
  const name = watch(`messages.${selectedMessageIndex}.name`);
  const text = watch(`messages.${selectedMessageIndex}.content.text`);
  const sendingType = watch(`messages.${selectedMessageIndex}.sendingType`);
  const surveyQuestion = watch(`messages.${selectedMessageIndex}.content.survey.question`);
  const firstSurveyAnswer = watch(`messages.${selectedMessageIndex}.content.survey.answers.0`);
  const secondSurveyAnswer = watch(`messages.${selectedMessageIndex}.content.survey.answers.1`);

  const handleOpenMessageTypeDialogWithCreateType = () => {
    setShowMessageTypeDialog({
      type: 'create',
      isShow: true,
    });
  };

  useEffect(() => {
    if (messageLists.length === 0 || !message) return;

    const sortedMessages = [...messageLists].sort((a, b) => {
      const daysAfterA = a.daysAfter ?? 0;
      const daysAfterB = b.daysAfter ?? 0;
      const timeOfDayA = a.timeOfDay ? dayjs(a.timeOfDay, 'HH:mm').valueOf() : dayjs().valueOf();
      const timeOfDayB = b.timeOfDay ? dayjs(b.timeOfDay, 'HH:mm').valueOf() : dayjs().valueOf();

      if (daysAfterA !== daysAfterB) return daysAfterA - daysAfterB;
      return timeOfDayA - timeOfDayB;
    });

    if (JSON.stringify(sortedMessages) !== JSON.stringify(prevMessageLists)) {
      const selectedMessageWithoutDaysAfterAndIndex = omit(message, [
        'daysAfter',
        'index',
        'hasWarning',
      ]);

      setValue('messages', sortedMessages);
      const newIndex = sortedMessages.findIndex((message) => {
        return (
          JSON.stringify(omit(message, ['daysAfter', 'index', 'hasWarning'])) ===
          JSON.stringify(selectedMessageWithoutDaysAfterAndIndex)
        );
      });

      if (newIndex !== -1) {
        setSelectedMessage({
          ...message,
          index: newIndex,
          daysAfter: sortedMessages[newIndex].daysAfter,
          timeOfDay: sortedMessages[newIndex].timeOfDay,
        });
      }
    }
  }, [prevMessageLists, setValue, daysAfter, timeOfDay, text, name]);

  useEffect(() => {
    const newWarningStates = messageLists.reduce(
      (acc, _, index) => {
        const sendingType = watch(`messages.${index}.sendingType`);
        const messageTitle = watch(`messages.${index}.name`);
        const messageContent = watch(`messages.${index}.content.text`);
        const messageSurvey = watch(`messages.${index}.content.survey`);

        if (sendingType === 'SURVEY') {
          acc[index] =
            !messageTitle ||
            !messageSurvey ||
            !messageSurvey.answers ||
            (messageSurvey.answers?.every((answer) => !answer.length) &&
              !messageSurvey.question?.length);
        } else {
          acc[index] = !messageTitle || !messageContent;
        }
        return acc;
      },
      {} as { [index: number]: boolean },
    );
    setWarningStates(newWarningStates);
  }, [
    messageLists,
    name,
    text,
    sendingType,
    surveyQuestion,
    firstSurveyAnswer,
    secondSurveyAnswer,
  ]);

  return (
    <div className='flex h-screen max-h-[722px] min-w-[200px] max-w-[370px] flex-grow flex-col justify-between rounded-r16 bg-white50 py-20 shadow-modal'>
      <div>
        <div className='flex flex-col gap-4 px-20 pt-20'>
          <span className='text-Header16 text-black700'>메시지 목록</span>
          <p className='text-Body11 text-black200'>
            치료태그를 입력한 일자를 기준으로 발송할 메시지를 설정합니다.
          </p>
        </div>
        <div className='mt-10'>
          <div className='h-[500px]'>
            <Scrollbar>
              {messageLists?.map((item, index) => {
                return (
                  <MessageItem
                    key={uuidv4()}
                    {...item}
                    index={index}
                    hasWarning={warningStates[index]}
                  />
                );
              })}
            </Scrollbar>
          </div>
          <div className='p-15'>
            <OutlinedButton
              btnColor='secondary'
              className='w-full'
              onClick={handleOpenMessageTypeDialogWithCreateType}>
              <div className='flex items-center justify-center gap-4'>
                <Icon name='add-circle' color='white800' size={20} />
                <div className='text-Body13 text-white800'>메시지 추가</div>
              </div>
            </OutlinedButton>
          </div>
        </div>
      </div>

      <div className='flex justify-center gap-20 px-20 py-10'>
        {MESSAGE_OPTIONS.map((option) => (
          <div className='flex items-center gap-6' key={option.id}>
            <div
              className={customTwMerge(
                'h-6 w-6 rounded-full border',
                option.circleBgClassName,
                option.circleBorderClassName,
              )}
            />
            <div className='text-Body13 text-black200'>{option.label}</div>
          </div>
        ))}
      </div>
    </div>
  );
}

interface MessageItemProps extends MessageInput {
  hasWarning: boolean;
  index: number;
}

const MessageItem = ({ ...props }: MessageItemProps) => {
  const { index, hasWarning, daysAfter, name, sendingType } = props;

  const { getValues, setValue, watch } = useFormContext<CounselAutomationAPIFormValues>();

  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);
  const setSelectedMessage = useSetAtom(selectedMessageState);
  const setSelectedContentTabIndex = useSetAtom(selectedContentTabIndexState);

  const image = watch(`messages.${index}.content.image`);
  const video = watch(`messages.${index}.content.video`);
  const workoutVideo = watch(`messages.${index}.content.workoutVideo`);
  const event = watch(`messages.${index}.content.event`);
  const homecareContent = watch(`messages.${index}.content.homecareContent`);

  const handleClickMessage = () => {
    if (selectedMessageIndex === index) {
      return;
    }
    setSelectedMessage({ ...props, index });

    if (sendingType === 'SURVEY') {
      setSelectedContentTabIndex((prev) => ({
        ...prev,
        [index]: 5,
      }));
      return;
    }

    if (image && image.length > 0) {
      setSelectedContentTabIndex((prev) => ({
        ...prev,
        [index]: 1,
      }));
    } else if (video) {
      setSelectedContentTabIndex((prev) => ({
        ...prev,
        [index]: 2,
      }));
    } else if (workoutVideo) {
      setSelectedContentTabIndex((prev) => ({
        ...prev,
        [index]: 3,
      }));
    } else if (homecareContent || event) {
      setSelectedContentTabIndex((prev) => ({
        ...prev,
        [index]: 4,
      }));
    }
  };

  const handleDeleteMessage = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.stopPropagation();

    modalService.defaultWarning({
      id: 'delete-message',
      title: '메시지를 삭제하시겠어요?',
      contents: '선택한 메시지를 삭제합니다.',
      onConfirm: () => {
        if (getValues(`messages.${index - 1}`)) {
          setSelectedMessage({
            ...getValues(`messages.${index - 1}`),
            index: index - 1,
          });
        } else if (getValues(`messages.${index + 1}`)) {
          setSelectedMessage({
            ...getValues(`messages.${index + 1}`),
            index: index + 1,
          });
        } else {
          setSelectedMessage(undefined);
        }

        setValue(
          'messages',
          getValues('messages')?.filter((_, i) => i !== index),
        );
        modalService.popById('delete-message');
        toastService.successMsg({
          text: '메시지가 삭제되었습니다.',
        });
      },
    });
  };

  return (
    <div className='relative'>
      <div
        onClick={handleClickMessage}
        className={customTwMerge(
          'group relative flex cursor-pointer items-center border-b border-b-white600 px-20 py-15',
          MESSAGE_OPTIONS.find((option) => option.id === sendingType)?.bgClassName,
        )}>
        {selectedMessageIndex === index && (
          <span className='absolute left-0 h-full w-[5px] bg-blue500' />
        )}
        <span className='mr-4 w-[50px] whitespace-nowrap font-bold text-Header12 text-black700'>
          {daysAfter}일차
        </span>
        <span className='truncate text-Body12 text-black700'>{name}</span>
        <div className='ml-auto flex items-center gap-10'>
          <IconButton
            className='opacity-0 transition-opacity group-hover:opacity-100'
            icon='trash-can-outline'
            color='black200'
            size={16}
            onClick={handleDeleteMessage}
          />
          {hasWarning && <WarningIcon />}
        </div>
      </div>
      <div className='-bottom-[6.3px] absolute left-[27px] z-10'>
        <Rectangle
          width={14}
          height={14}
          fill={MESSAGE_OPTIONS.find((option) => option.id === sendingType)?.bgColor}
        />
      </div>
    </div>
  );
};

const WarningIcon = memo(() => (
  <HoverTooltip
    text='필수정보가 기입되지 않았습니다.'
    position='bottomLeft'
    offset={12}
    portalZindex={1000}>
    <Icon name='info-circle-outline' color='red500' size={16} />
  </HoverTooltip>
));
