import { useMemo } from 'react';
import type { DataResponse } from '../../../hooks/use-conditional-chat-data';
import { USER_TYPE_CODE } from '../components/ChattingMessage/constants/message';

type useMessagesWithUnreadProps = {
  messages: DataResponse[];
  chatRoomPatientUnreadCount: number;
};

export function useMessagesWithUnread({
  messages,
  chatRoomPatientUnreadCount,
}: useMessagesWithUnreadProps) {
  return useMemo(() => {
    let count = chatRoomPatientUnreadCount;
    const processedMessages = Array(messages.length); // 미리 크기를 설정한 배열 생성

    for (let i = 0; i < messages.length; i++) {
      const message = messages[i];
      const isLeft = message.userType === USER_TYPE_CODE.PATIENT; // 1000: 환자
      const isUnreadCondition =
        !!count &&
        !isLeft &&
        message.type !== 11400 &&
        message.type !== 13900 &&
        message.type !== 13910 &&
        message.type !== 50000;

      if (isUnreadCondition) {
        count--;
      }

      processedMessages[i] = { ...message, isUnread: isUnreadCondition };
    }

    return processedMessages;
  }, [messages, chatRoomPatientUnreadCount]);
}
