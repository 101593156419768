import TextButton from '@afterdoc-design-system/components/Atoms/Button/TextButton';
import LoadingSpinner from '@afterdoc-design-system/components/Atoms/Loading/LoadingSpinner';
import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import { SHARED_UTILS } from '@shared-utils/utils';
import { useAtomValue } from 'jotai';
import { useState } from 'react';
import { apiClient } from 'web/apis/instances/api-client';
import ImageViewer from 'web/shared/components/ImageViewer/ImageViewer';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { useConditionalChatData } from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/hooks/use-conditional-chat-data';
import { selectedMessageIdState } from 'web/templates/CustomerChat/components/SupportBot/states/selected-message-id';
import { usePatient } from 'web/templates/CustomerChat/hooks/use-patient';
import { chatRoomIDAtom } from 'web/templates/CustomerChat/states/chatRoomID';
import { NoCautionImages } from '../NoCautionImages';
import { truncateFileName } from './functions/splitFileName';
import { useGetImagesList } from './hooks/use-get-images-list';

type CautionImagesSelectProps = {
  treatmentTagId: string;
};

type PostSendCautionImagesParams = {
  chatRoomID: string;
  patientId: string;
  hospitalID: string;
  treatmentTagId: string;
  selectedImageIndices: number[];
};

const postSendCautionImages = async ({
  chatRoomID,
  patientId,
  hospitalID,
  treatmentTagId,
  selectedImageIndices,
}: PostSendCautionImagesParams) => {
  const newParams = {
    targetChatRoomID: chatRoomID,
    targetPatientID: patientId,
    hospitalID,
    treatmentTagID: treatmentTagId,
    selectedImageIndices: selectedImageIndices,
  };
  const { data } = await apiClient.v3.apiManualsElTreatmentCautionsChat(newParams);
  return SHARED_UTILS.api.checkApiResponse(data);
};

export default function CautionImagesSelect({ treatmentTagId }: CautionImagesSelectProps) {
  const chatRoomID = useAtomValue(chatRoomIDAtom);
  const { patientId } = usePatient();
  const { hospitalID } = useSelectedHospitalInfo();
  const selectedMessageId = useAtomValue(selectedMessageIdState);

  const { refetch } = useConditionalChatData(selectedMessageId, chatRoomID);

  const [selectedImageIndices, setSelectedImagesIndices] = useState<number[]>([]);
  const [isImageViewerOpen, setIsImageViewerOpen] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const { data, isLoading } = useGetImagesList({ treatmentTagId });
  const isData = data.length > 0;
  const treatmentCautionImages = data?.map(({ url }) => url) ?? [];

  const toggleSelectImage = (index: number) => {
    setSelectedImageIndex(index);
    setSelectedImagesIndices((prevSelected) =>
      prevSelected.includes(index)
        ? prevSelected.filter((i) => i !== index)
        : [...prevSelected, index],
    );
  };

  const handleOpenImageViewer = () => {
    setIsImageViewerOpen(true);
  };

  const handleClose = () => {
    setIsImageViewerOpen(false);
  };

  const sendCautionImages = async () => {
    if (!chatRoomID) return;
    if (!patientId) return;

    const { isSentSuccess } = await postSendCautionImages({
      chatRoomID,
      patientId,
      hospitalID,
      treatmentTagId,
      selectedImageIndices,
    });
    isSentSuccess && refetch();
  };

  const isChatRoomNotNull = chatRoomID;

  return (
    <div className='flex flex-col'>
      {isData && isChatRoomNotNull && (
        <div className=' flex h-40 flex-center flex-row justify-between bg-white100 px-20 py-9'>
          <span className='text-Body10 text-black500'>
            {`${selectedImageIndices.length}개 선택(최대 10개)`}
          </span>
          <TextButton
            textColor='white50'
            className='h-22 w-49 rounded-r6 bg-blue500 px-14 py-3 text-Body11'
            onClick={sendCautionImages}>
            전송
          </TextButton>
        </div>
      )}

      <div className='mx-20 flex flex-wrap justify-between'>
        {isLoading && (
          <div className='flex flex-center'>
            <LoadingSpinner />
          </div>
        )}
        {!isData && !isLoading && <NoCautionImages />}
        {isData &&
          data.map(({ fileName, url }, index) => {
            const isSelected = selectedImageIndices.includes(index);
            return (
              <div key={url} className='my-10 flex w-[170px] flex-col'>
                <div
                  key={url}
                  className={`relative inline-block h-96 w-[170px] overflow-hidden border border-1 ${isSelected ? 'border-blue500' : 'border-white600'}`}
                  style={{ borderRadius: '10px' }}>
                  <img
                    src={url}
                    alt='주의사항 이미지'
                    className='h-96 w-[170px]'
                    style={{ borderRadius: '10px' }}
                  />

                  {isChatRoomNotNull && (
                    <div
                      className='absolute top-2 left-2 h-24 w-24 cursor-pointer'
                      onClick={() => toggleSelectImage(index)}>
                      {isSelected ? (
                        <Icon name='checkbox-marked' size={24} color='blue500' />
                      ) : (
                        <Icon name='checkbox-blank-outline' size={24} color='white600' />
                      )}
                    </div>
                  )}

                  <div
                    className='absolute right-2 bottom-2 flex h-32 w-32 flex-center cursor-pointer bg-black200'
                    onClick={handleOpenImageViewer}
                    style={{ borderRadius: '6px' }}>
                    <Icon name='magnify-scan' size={20} color='white50' />
                  </div>
                </div>
                <span className='my-10 line-clamp-1 text-Body13 text-black700'>
                  {truncateFileName(fileName)}
                </span>
              </div>
            );
          })}
      </div>
      {isImageViewerOpen && (
        <ImageViewer
          selectedIndex={selectedImageIndex}
          images={treatmentCautionImages}
          onClose={handleClose}
        />
      )}
    </div>
  );
}
