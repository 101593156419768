import { useChatMessageContext } from '../ChatMessageContext';
import MessageTitle from './components/MessageTitle';
import VideoListCard from './components/VideoListCard';

export default function OldVideoMessage() {
  const { fileIDs, isMine, messageWidthStyle } = useChatMessageContext();
  return (
    <div className='flex flex-col gap-10'>
      <div
        className={`rounded-r10 px-16 py-10 shadow-modal ${isMine ? 'bg-[rgba(255,245,208,1)]' : 'bg-white50'}`}
        style={messageWidthStyle}>
        <MessageTitle title='운동 영상' />
        {fileIDs?.map(({ createdAt, aws, paths }) => {
          if (!aws || !paths) return null;
          const { fileName } = aws[0];
          const url = paths[0];
          // TODO - vchID 추가하기
          return <VideoListCard key={createdAt} url={url} title={fileName} />;
        })}
      </div>
    </div>
  );
}
