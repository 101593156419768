import { apiClient } from '@apis/instances/api-client';
import { QUERY_KEY } from '@apis/swaggers/query-key';
import type {
  ApiServiceSettingsElFindOneParams,
  ApiVideocontentcategoryElFindParams,
  VideoContentCategoryParentInfos,
} from '@apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from '@shared/hooks/use-selected-hospital-info';
import { useSuspenseQuery } from '@tanstack/react-query';
import { contentEditState } from '@templates/Content/containers/ContentManager/states/content-edit-state';
import ContentPanelFolder from '@templates/Content/containers/ContentPanel/components/ContentPanelFolder';
import ContentPanelFolderItem from '@templates/Content/containers/ContentPanel/components/ContentPanelFolderItem';
import {
  CONTENT_PANEL_ITEMS,
  type ContentLayer,
  type ContentSubLayer,
} from '@templates/Content/containers/ContentPanel/constants/tab-items';
import { contentPanelLayerState } from '@templates/Content/containers/ContentPanel/states/content-panel-layer-state';
import { focusedContentSubLayerState } from '@templates/Content/containers/ContentPanel/states/focused-content-sub-layer-state';
import Scrollbar from 'afterdoc-design-system/components/Atoms/Scrollbar/Scrollbar';
import Title from 'afterdoc-design-system/components/Molecules/Title/Title';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import { useEffect, useState } from 'react';
import { SHARED_UTILS } from 'utils/utils';

const fetchServiceSetting = async (params: ApiServiceSettingsElFindOneParams) => {
  const response = await apiClient.v3.apiServiceSettingsElFindOne(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const fetchVideoContentCategories = async (params: ApiVideocontentcategoryElFindParams) => {
  const response = await apiClient.v3.apiVideocontentcategoryElFind(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

const createContentItem = (category: VideoContentCategoryParentInfos): ContentSubLayer => ({
  key: category._id ?? '',
  text: category.categoryName ?? '',
  data: category,
});

const addCategoryToLayer = (
  layers: ContentLayer[],
  category: VideoContentCategoryParentInfos,
  parentCategoryName: string,
): ContentLayer[] => {
  const existingLayer = layers.find((layer) => layer.title === parentCategoryName);

  const contentItem = createContentItem(category);

  if (existingLayer) {
    existingLayer.items.push(contentItem);
  } else {
    layers.push({
      title: parentCategoryName,
      isOpen: true,
      items: [contentItem],
    });
  }

  return layers;
};

const buildContentPanelItems = (categories: VideoContentCategoryParentInfos[]): ContentLayer[] => {
  const layers: ContentLayer[] = [];
  for (const category of categories) {
    for (const parent of category.parentCategoryIDs ?? []) {
      addCategoryToLayer(layers, category, parent.categoryName ?? '');
    }
  }
  return layers;
};

export default function ContentPanelContainer() {
  const [categories, setCategories] = useState<VideoContentCategoryParentInfos[]>([]);
  const [contentPanelLayer, setContentPanelLayer] = useAtom(contentPanelLayerState);
  const setFocusedContentSubLayer = useSetAtom(focusedContentSubLayerState);

  const contentEdit = useAtomValue(contentEditState);

  const { hospitalID } = useSelectedHospitalInfo();

  const { data: serviceSettings } = useSuspenseQuery({
    queryKey: [QUERY_KEY.apiServiceSettingsElFindOne, { hospitalID }] as const,
    queryFn: ({ queryKey }) => fetchServiceSetting(queryKey[1]),
  });

  useEffect(() => {
    const fetchData = async () => {
      if (serviceSettings?.usingAutoSendVideo) {
        const uniqueParentCategoryIDs = [
          ...new Set(
            serviceSettings.usingAutoSendVideo
              .map((video) => video._id)
              .filter((id): id is string => id !== undefined),
          ),
        ];

        if (uniqueParentCategoryIDs.length > 0) {
          const data = await fetchVideoContentCategories({
            isTop: false,
            isParent: true,
            'parentCategoryIDs[]': uniqueParentCategoryIDs,
          });
          setCategories(data);
          return;
        }
      }
      setContentPanelLayer(CONTENT_PANEL_ITEMS);
      setFocusedContentSubLayer(CONTENT_PANEL_ITEMS[0].items[0]);
    };
    fetchData();
  }, [serviceSettings]);

  useEffect(() => {
    if (categories.length > 0) {
      const contentPanelItems = buildContentPanelItems(categories);

      setContentPanelLayer([...contentPanelItems, ...CONTENT_PANEL_ITEMS]);

      if (contentPanelItems.length > 0 && contentPanelItems[0].items.length > 0) {
        setFocusedContentSubLayer(contentPanelItems[0].items[0]);
      }
    }
  }, [categories]);

  return (
    <>
      <div
        className='z-10 border-r border-r-white400 bg-white50'
        style={{ display: contentEdit.isEditing ? 'none' : 'block' }}>
        <Scrollbar disabledX>
          <Title title='콘텐츠' />
          <div className='select-none'>
            {contentPanelLayer.map((parentItem, folderIndex) => (
              <ContentPanelFolder key={parentItem.title} folderIndex={folderIndex}>
                {parentItem.items.map((childItem, itemIndex) => (
                  <ContentPanelFolderItem
                    key={childItem.key}
                    folderIndex={folderIndex}
                    itemIndex={itemIndex}
                  />
                ))}
              </ContentPanelFolder>
            ))}
          </div>
        </Scrollbar>
      </div>
    </>
  );
}
