import TextArea from '@afterdoc-design-system/components/Atoms/Input/TextArea/TextArea';
import BaseLoading from '@afterdoc-design-system/components/Atoms/Loading/BaseLoading';
import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { useSetAtom } from 'jotai';
import { useEffect, useMemo, useState } from 'react';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { ApiPatientsMemosElFindOneParams } from 'web/apis/swaggers/swagger-docs';
import { usePatientDetailInfo } from 'web/shared/hooks/use-get-patient-detail-info';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import { useUserInfo } from 'web/shared/hooks/use-user-info';
import { isThereSomeThingToSaveState } from 'web/shared/states/is-there-some-thing-to-save';

const getPatientsMemo = async (params: ApiPatientsMemosElFindOneParams) => {
  const { data } = await apiClient.v3.apiPatientsMemosElFindOne(params);
  return data.data;
};

const postPatientsMemo = async (params: ApiPatientsMemosElFindOneParams, text: string) => {
  const body = {
    ...params,
    content: text,
  };
  const response = await apiClient.v3.apiPatientsMemosElUpdate(body);
  return response;
};

export default function CustomerInfoCustomerMemo() {
  const queryClient = useQueryClient();

  const { patientId } = usePatientDetailInfo();
  const { hospitalID } = useSelectedHospitalInfo();
  const { userId } = useUserInfo();

  const params: ApiPatientsMemosElFindOneParams = useMemo(
    () => ({
      hospitalID,
      userID: userId,
      hserviceID: patientId,
    }),
    [hospitalID, userId, patientId],
  );

  const { data, isLoading } = useQuery({
    queryKey: [QUERY_KEY.apiPatientsMemosElFindOne, params] as const,
    queryFn: ({ queryKey }) => getPatientsMemo(queryKey[1]),
  });

  const mutation = useMutation({
    mutationFn: (text: string) => postPatientsMemo(params, text),
    onSuccess: () => {
      queryClient.invalidateQueries({
        queryKey: [QUERY_KEY.apiPatientsMemosElFindOne, params],
      });
      toastService.successMsg({ text: '고객메모를 저장했습니다.' });
      setOriginalText(text);
    },
    onError: (error) => {
      console.error('Error saving memo:', error);
      toastService.errorMsg({ text: '고객메모 저장을 실패했습니다.' });
    },
  });

  const [isSaveButtonDisabled, setIsSaveButtonDisabled] = useState<boolean>(false);
  const [text, setText] = useState<string>();
  const [originalText, setOriginalText] = useState<string>();
  const setIsThereSomeThingToSave = useSetAtom(isThereSomeThingToSaveState);

  const bottomText = dayjs(data?.updatedAt).isValid()
    ? `${dayjs(data?.updatedAt).format('YYYY-MM-DD HH:mm')}/${data?.modifierID?.realName ?? '-'}`
    : undefined;

  const handleSave = () => {
    mutation.mutate(text ?? '');
  };

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setText(event.target.value);
  };

  const handleInput = (event: React.FormEvent<HTMLTextAreaElement>) => {
    setText(event.currentTarget.value);
  };

  useEffect(() => {
    if (!data) return;
    setText(data.content ?? '');
    setOriginalText(data.content ?? '');
  }, [data]);

  useEffect(() => {
    if (!text || !data) return;
    setIsSaveButtonDisabled(text.trim().length === 0 || text === data.content);
  }, [text, data]);

  useEffect(() => {
    if (text !== originalText) {
      setIsThereSomeThingToSave((prev) => ({
        ...prev,
        CUSTOMER_MANAGEMENT: {
          ...prev.CUSTOMER_MANAGEMENT,
          customerInfoCustomerMemo: true,
        },
      }));
    } else {
      setIsThereSomeThingToSave((prev) => ({
        ...prev,
        CUSTOMER_MANAGEMENT: {
          ...prev.CUSTOMER_MANAGEMENT,
          customerInfoCustomerMemo: false,
        },
      }));
    }
  }, [text, originalText, setIsThereSomeThingToSave]);

  if (isLoading) {
    return (
      <div className='flex h-full w-full flex-col gap-4'>
        <div className='text-Header14 text-black700'>고객메모</div>
        <div className='mb-16 text-Body12 text-black500'>
          입력한 참고사항은 고객상담 화면에서 확인할 수 있습니다.
        </div>
        <div className='flex-full-center'>
          <BaseLoading />
        </div>
      </div>
    );
  }

  return (
    <div className='flex h-full w-full flex-col gap-4'>
      <div className='text-Header14 text-black700'>고객메모</div>
      <div className='mb-16 text-Body12 text-black500'>
        입력한 참고사항은 고객상담 화면에서 확인할 수 있습니다.
      </div>
      <TextArea
        placeholder='고객 상담 시, 참고 사항을 기록해둘 수 있습니다.'
        onSave={handleSave}
        onChange={handleChange}
        onInput={handleInput}
        leftBottomText={bottomText}
        value={text}
        isSaveButtonDisabled={isSaveButtonDisabled}
      />
    </div>
  );
}
