import ContainedButton from '@afterdoc-design-system/components/Atoms/Button/ContainedButton';
import Dropdown from '@afterdoc-design-system/components/Atoms/Dropdown/Dropdown';
import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import dayjs from 'dayjs';
import { useAtomValue, useSetAtom } from 'jotai';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import type { MarketingAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/components/RegisterMarketingAutomationDialogContent';
import {
  selectedContentTabIndexState,
  selectedMessageIndexState,
} from 'web/templates/Automation/containers/Dialog/RegisterMarketingAutomationDialog/containers/MarketingMessageSetting/states/message-form';

export default function AutomationMessageHeader() {
  const { setValue, getValues } = useFormContext<MarketingAutomationAPIFormValues>();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const selectedMessageIndex = useAtomValue(selectedMessageIndexState);
  const setSelectedContentTabIndex = useSetAtom(selectedContentTabIndexState);

  const messageLists = getValues('messages');
  const messageName = getValues(`messages.${selectedMessageIndex}.name`);
  const messageSendingDateTime = getValues(`messages.${selectedMessageIndex}.sendingDateTime`);

  return (
    <div className='flex w-full justify-between py-20'>
      <div className='flex items-center gap-20'>
        <div className='rounded-r6 border border-white600 bg-white50 px-10 py-4 text-Header14'>
          {dayjs(messageSendingDateTime).format('YYYY-MM-DD')}
        </div>
        <div className='text-Header16 text-black500'>{messageName}</div>
      </div>
      <div className='relative'>
        <ContainedButton
          onClick={() => {
            setIsDropdownOpen((prev) => !prev);
          }}
          iconProps={{
            name: 'chevron-down',
            size: 20,
            color: 'white800',
          }}
          className='w-[150px]'
          btnColor='secondary'>
          메시지 내용 복사
        </ContainedButton>
        {isDropdownOpen &&
          (messageLists.filter((_, index) => index !== selectedMessageIndex).length > 0 ? (
            <Dropdown
              handleToggle={() => {
                setIsDropdownOpen(false);
              }}
              onSelect={(index) => {
                setValue(`messages.${selectedMessageIndex}.content`, messageLists[index].content);
                if (messageLists[index].content.image) {
                  setSelectedContentTabIndex((prev) => ({
                    ...prev,
                    [selectedMessageIndex]: 1,
                  }));
                } else {
                  setSelectedContentTabIndex((prev) => ({
                    ...prev,
                    [selectedMessageIndex]: 0,
                  }));
                }

                toastService.successMsg({
                  text: '전송내용을 불러왔습니다.',
                });
                setIsDropdownOpen(false);
              }}
              width={160}
              options={messageLists
                .filter((_, index) => index !== selectedMessageIndex)
                .map((message) => message.name)}
            />
          ) : (
            <Dropdown
              handleToggle={() => {
                setIsDropdownOpen(false);
              }}
              width={160}
              options={['전송내용이 없습니다.']}
            />
          ))}
      </div>
    </div>
  );
}
