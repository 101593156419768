import { useAtomValue } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useEffect, useRef, useState } from 'react';
import FullLoading from 'web/shared/components/FullLoading/FullLoading';
import ChattingContent from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/ChattingContent';
import { useBottomScrollObserver } from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/hooks/use-bottom-scroll-observer';
import { useChatRoomPatientUnreadCount } from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/hooks/use-chat-room-patient-unread-count';
import { useMessagesWithUnread } from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/hooks/use-messages-with-unread';
import { useTopScrollObserver } from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/ChattingContent/hooks/use-top-scroll-observer';
import { MoveBelowButton } from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/components/MoveBelowButton/MoveBelowButton';
import { useConditionalChatData } from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/hooks/use-conditional-chat-data';
import { useForcedMoveToBottom } from 'web/templates/CustomerChat/components/ChattingRoom/components/ChattingContainer/hooks/use-forced-move-to-bottom';
import { selectedMessageIdState } from 'web/templates/CustomerChat/components/SupportBot/states/selected-message-id';
import './styles/ChattingContainer.scss';

export default function ChattingContainer({ chatRoomID }: { chatRoomID: string }) {
  const selectedMessageId = useAtomValue(selectedMessageIdState);
  const [previousScrollHeight, setPreviousScrollHeight] = useState(0); // 데이터 fetch 전 스크롤 scrollHeight 저장

  const resetSelectedMessageId = useResetAtom(selectedMessageIdState);

  const loadPreviuseMoreRef = useRef<HTMLDivElement>(null); // 스크롤 상단 감시용 ref
  const loadNextMoreRef = useRef<HTMLDivElement>(null); // 스크롤 하단 감시용 ref
  const chatContainerRef = useRef<HTMLDivElement>(null);

  const { chatRoomPatientUnreadCount } = useChatRoomPatientUnreadCount();

  const {
    data,
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    hasPreviousPage,
    isFetchingPreviousPage,
    fetchPreviousPage,
    isLoading,
  } = useConditionalChatData(selectedMessageId, chatRoomID);

  useTopScrollObserver({
    hasPreviousPage,
    isFetchingPreviousPage,
    fetchPreviousPage,
    isLoading,
    chatContainerRef,
    loadPreviuseMoreRef,
    setPreviousScrollHeight,
  });

  const { isBottomButtonVisible } = useBottomScrollObserver({
    hasNextPage,
    isFetchingNextPage,
    fetchNextPage,
    isLoading,
    chatContainerRef,
    loadNextMoreRef,
    setPreviousScrollHeight,
    previousScrollHeight,
    messages: data,
  });

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (selectedMessageId) {
      resetSelectedMessageId();
    }
  }, []);

  useForcedMoveToBottom({ chatContainerRef, isLoading });

  // console.log('check data:', data);
  const messagesWithUnread = useMessagesWithUnread({
    messages: data,
    chatRoomPatientUnreadCount,
  });

  if (!data) return <FullLoading />;

  // 실제로는 아래의 케이스가 없어야 함, 하지만 개발 모드에서는 존재, 보수적으로 방어 로직을 추가
  // if (!data || (!data.length && selectedTab === TABS.IN_PROGRESS)) return <FullLoading />;

  //채팅 스크롤 컨트롤을 위해 css로 뒤집음
  return (
    <>
      <div
        id='chatting-container'
        className='relative flex max-h-full flex-col gap-y-20 overflow-y-auto '
        ref={chatContainerRef}>
        <div ref={loadNextMoreRef} />

        <ChattingContent messages={messagesWithUnread} />

        <div ref={loadPreviuseMoreRef} />
      </div>
      {isBottomButtonVisible && <MoveBelowButton ref={chatContainerRef} />}
    </>
  );
}
