import { toastService } from '@afterdoc-design-system/components/Atoms/Toast/Toast.service';
import { SHARED_UTILS } from '@shared-utils/utils';
import { useSuspenseQuery } from '@tanstack/react-query';
import { useAtomValue, useSetAtom } from 'jotai';
import { useFormContext } from 'react-hook-form';
import { apiClient } from 'web/apis/instances/api-client';
import { QUERY_KEY } from 'web/apis/swaggers/query-key';
import type { TargetableNationalitiesHandlerParams } from 'web/apis/swaggers/swagger-docs';
import { useSelectedHospitalInfo } from 'web/shared/hooks/use-selected-hospital-info';
import type { CounselAutomationAPIFormValues } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/components/RegisterCounselAutomationDialogContent';
import { messageModeState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/containers/CounselMessageSetting/states/message-mode';
import { selectedCounselAutomationTabState } from 'web/templates/Automation/containers/Dialog/RegisterCounselAutomationDialog/states/selected-counsel-automation-tab-state';
import NextButton from 'web/templates/Automation/containers/shared/components/Buttons/NextButton';

const getTargetableNationalities = async (params: TargetableNationalitiesHandlerParams) => {
  const response = await apiClient.v3.targetableNationalitiesHandler(params);
  return SHARED_UTILS.api.checkApiResponse(response.data);
};

export default function BaseSettingNextButton() {
  const {
    watch,
    formState: { isDirty },
  } = useFormContext<CounselAutomationAPIFormValues>();
  const { hospitalID } = useSelectedHospitalInfo();

  const messageMode = useAtomValue(messageModeState);
  const name = watch('name');
  const targetTreatmentTags = watch('targetTreatmentTags');
  const toBeAppliedNationalityIds = watch('toBeAppliedNationalityIds');

  const { data: targetableNationalities } = useSuspenseQuery({
    queryKey: [
      QUERY_KEY.targetableNationalitiesHandler,
      {
        hospitalID,
        treatmentTagId: targetTreatmentTags?.join(','),
      },
    ] as const,
    queryFn: ({ queryKey }) => getTargetableNationalities(queryKey[1]),
    staleTime: 0,
    gcTime: 0,
  });

  const setSelectedAutomationTab = useSetAtom(selectedCounselAutomationTabState);

  const onChangeTab = () => {
    if (!name?.length || !targetTreatmentTags?.length) {
      return toastService.errorMsg({
        text: '현재 페이지의 필수정보를 모두 입력해 주세요.',
      });
    }

    if (
      !!(messageMode.mode === 'EDIT' && !isDirty) ||
      !!(targetableNationalities && targetableNationalities.length > 0) ||
      !!(targetableNationalities && targetableNationalities[0]._id === '-1') ||
      !!(toBeAppliedNationalityIds && toBeAppliedNationalityIds.length > 0)
    ) {
      return setSelectedAutomationTab(1);
    }
    return toastService.errorMsg({
      text: '현재 설정된 치료태그로 설정된 자동화가 이미 존재합니다.',
    });
  };

  return <NextButton className='mx-auto mt-20 w-[200px]' onClick={onChangeTab} />;
}
