import Icon from '@afterdoc-design-system/components/Foundations/Icon/Icon';
import type { AwsObject } from '../../../../../../../../../../types';
import { useChatMessageContext } from '../../ChatMessageContext';

export default function ImageList({ aws }: { aws: AwsObject[] }) {
  if (!aws) return null;
  const { messageWidthStyle, chattingWidth } = useChatMessageContext();
  const messageOneWidthStyle = {
    ...messageWidthStyle,
    maxWidth: `${chattingWidth / 2}px`,
    width: 'fit-content',
  };
  const imagesLength = aws.length;
  switch (imagesLength) {
    case 0:
      return null;
    case 1:
      return (
        <div className='flex w-full flex-wrap bg-transparent' style={messageOneWidthStyle}>
          {aws?.map(({ fileName, Location, Key }, index) => (
            <img
              key={Key + { index }}
              src={Location}
              alt={fileName}
              className='h-full max-h-[330px] w-full max-w-[330px] rounded-r10 object-center'
            />
          ))}
        </div>
      );
    default: {
      const firstImage = aws[0];
      const secondImage = aws[1];
      return (
        <div className='flex w-full flex-wrap' style={messageWidthStyle}>
          <img
            src={firstImage.Location}
            alt={firstImage.fileName}
            className='mr-4 max-h-[330px] w-[calc(50%-4px)] max-w-[calc(50%-4px)] rounded-r10 object-center'
          />
          <div className='relative ml-4 max-h-[330px] w-[calc(50%-4px)] max-w-[calc(50%-4px)]'>
            <img
              src={secondImage.Location}
              alt={secondImage.fileName}
              className='h-full w-full rounded-r10 object-center'
            />
            {imagesLength > 2 && (
              <>
                <div className='absolute inset-0 rounded-r10 bg-black opacity-50 ' />
                <div className='absolute inset-0 flex items-center justify-center'>
                  <div
                    className='flex h-28 w-81 items-center justify-center gap-6 rounded-r10 text-Body11 text-white50'
                    style={{ backgroundColor: 'rgba(34, 34, 34, 0.5)' }}>
                    <Icon name='image-filter' color='white50' size={16} />총 {imagesLength}장
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      );
    }
  }
}
