import { modalService } from '@afterdoc-design-system/components/Molecules/Modal/Modal.service';
import { useClickOutside } from '@shared-hooks/use-click-outside';
import { SHARED_UTILS } from '@shared-utils/utils';
import { customTwMerge } from '@tailwind-base/utils/custom-tw-merge';
import Icon from 'afterdoc-design-system/components/Foundations/Icon/Icon';
import { useAtomValue } from 'jotai';
import { useResetAtom } from 'jotai/utils';
import { useCallback, useId, useRef } from 'react';
import { useHandleCustomerInfo } from 'web/shared/hooks/overlayPage/use-handle-customer-detail-info';
import { useUserInfo } from 'web/shared/hooks/use-user-info';
import { Z_INDEXS } from 'web/templates/CustomerChat/constants/layout';
import { usePatient } from 'web/templates/CustomerChat/hooks/use-patient';
import { chatRoomPatientIDState } from 'web/templates/CustomerChat/states/chat-room-patient';
import { chatRoomIDAtom } from '../../states/chatRoomID';
import { hserviceIDValueState } from '../../states/hserviceID-value';
import { useChattingList } from '../ChattingList/hooks/use-chattinglist';
import { useSearchInput } from '../ChattingList/hooks/use-search-input';
import BottomButtons from './components/BottomButtons/BottomButtons';
import ChildDropdown from './components/ChildDropdown/ChildDropdown';
import { useRenderingPosition } from './components/ChildDropdown/hooks/use-rendering-position';
import { ADDITIONAL_OPTIONS, OPTIONS, OPTION_USER_ADD, WIDTH } from './constants/dropdown';
import { postChatroomUserRemove } from './functions/postChatroomUserRemove';
import { useNextDropdown } from './hooks/use-next-dropdown';

export interface DropdownProps {
  options: string[];
  handleToggle: () => void;
  redTextIndices?: number[];
}

const calculateDropdownHeight = (options: string[]) => 30 * options.length + 20;

export default function ParentDropdown({
  options,
  handleToggle,
  redTextIndices = [],
}: DropdownProps) {
  const chatRoomID = useAtomValue(chatRoomIDAtom);
  const patientID = useAtomValue(chatRoomPatientIDState);
  const thatHospitalPatientId = useAtomValue(hserviceIDValueState);

  if (!patientID || !chatRoomID) return null; //해당 내역이 없을 경우 렌더링하지 않음, 실제로 논리적으로 불가능한 경우
  const { userId: userID } = useUserInfo();
  const { name } = usePatient();
  const { keyword } = useSearchInput();
  const { refetch } = useChattingList({ keyword });
  const resetChatRoomID = useResetAtom(chatRoomIDAtom);

  const { openCustomerInfo } = useHandleCustomerInfo(thatHospitalPatientId);

  const id = useId();
  const dropdownRef = useRef<HTMLDivElement>(null);
  const contentRef = useRef<HTMLDivElement>(null);
  const dropdownHeight = calculateDropdownHeight(options);
  const { position } = useRenderingPosition({
    renderingRef: dropdownRef,
    renderingHeight: dropdownHeight,
  });

  const {
    nextDropdownPosition,
    isNextDropdownOpen,
    updateNextDropdownPosition,
    toggleNextDropdown,
  } = useNextDropdown();

  const handleMouseHover = (el: EventTarget & HTMLLIElement, option: string) => {
    const isChildDropdown = option === OPTION_USER_ADD;

    const parentRect = dropdownRef.current?.getBoundingClientRect();
    const rect = el.getBoundingClientRect();

    const top = parentRect ? rect.top - parentRect.top : rect.top;

    updateNextDropdownPosition({ top, left: WIDTH });
    toggleNextDropdown(isChildDropdown);
  };

  const onMouseLeaveOption = useCallback(() => {
    toggleNextDropdown(false);
    handleToggle();
  }, [handleToggle, toggleNextDropdown]);

  const handleClick = () => {
    window.electron?.ipcRenderer.send('Application.showReservationWindow', {
      value: { hserviceID: thatHospitalPatientId },
    });
  };

  const handleClickOption = async (userID: string, name: string | undefined, option: string) => {
    switch (option) {
      case OPTIONS[0]:
        handleClick(); //TODO 정확한 정보를 던지고 받아야함
        break;
      case OPTIONS[1]:
        openCustomerInfo();
        break;
      case OPTIONS[2]:
        modalService.defaultSuccess({
          title: '채팅방 나가기',
          contents: (
            <div className='flex flex-col'>
              <div>채팅방을 나간 후에는 채팅 내역을 확인할 수 없습니다.</div>
              <div>{name}님 채팅방을 나가시겠습니까?</div>
            </div>
          ),
          buttonType: 'CUSTOM',
          bottomButtons: BottomButtons({
            handleConfirmClick: async () => {
              await postChatroomUserRemove({ chatRoomID, userIDs: [userID] });
              modalService.pop();
              refetch();
              resetChatRoomID();
            },
          }),
        });
        break;
      case ADDITIONAL_OPTIONS[1]:
        console.info('상담종료 클릭');
        break;

      default:
        break;
    }
  };

  useClickOutside({
    id,
    ref: dropdownRef,
    contentRef,
    onClose: handleToggle,
  });

  return (
    <div
      className='relative'
      onMouseLeave={onMouseLeaveOption}
      style={{ zIndex: Z_INDEXS.ChattingTooltip }}>
      <div ref={dropdownRef}>
        <div
          ref={contentRef}
          className={customTwMerge(
            'absolute w-full select-none rounded-r6 bg-white50 py-4 shadow-modal ring-1 ring-black ring-opacity-5',
            position === 'top' ? 'bottom-full mb-2' : 'top-full mt-2',
          )}
          data-ignore-click-outside={true}
          style={{
            height: dropdownHeight,
            width: SHARED_UTILS.css.getCssSizeValue(WIDTH),
          }}>
          <ul aria-orientation='vertical' aria-labelledby='options-menu'>
            {options.map((option, index) => (
              <li
                key={option}
                tabIndex={index}
                onClick={() => handleClickOption(userID, name, option)}
                onMouseEnter={(event) => {
                  const liElement = event.currentTarget;
                  handleMouseHover?.(liElement, option);
                }}
                className={customTwMerge(
                  `block cursor-pointer px-10 py-7 text-Body12 focus:bg-blue50 focus:outline-none ${
                    redTextIndices.includes(index) ? 'text-red500' : 'text-black500'
                  } flex items-center justify-between hover:bg-blue50 hover:text-Header12`,
                )}
                item-index={index}>
                <span>{option}</span>
                {option === OPTION_USER_ADD && (
                  <Icon name='chevron-right' color='black200' size={16} />
                )}
              </li>
            ))}
          </ul>
        </div>
      </div>
      {isNextDropdownOpen && (
        <ChildDropdown
          triggerPosition={nextDropdownPosition}
          handleToggle={handleToggle}
          isOnMouseLeave={false}
        />
      )}
    </div>
  );
}
