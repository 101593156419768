import SearchInput from '@afterdoc-design-system/components/Atoms/Input/SearchInput';
import ButtonTab from '@afterdoc-design-system/components/Atoms/Tabs/components/ButtonTab';
import Tabs from '@afterdoc-design-system/components/Atoms/Tabs/components/Tabs';
import { useState } from 'react';
import { useSearchInput } from '../ChattingList/hooks/use-search-input';
import ContentsList from './components/ContentsList/ContentsList';
import ManualList from './components/ManualList/ManualList';

const tabItemsMap = {
  원내메뉴얼: '',
  콘텐츠: '',
};

export default function Menual() {
  const [selectedTab, setSelectedTab] = useState('원내메뉴얼');

  const { inputValue, keyword, handleChange, handleKeyDown, handleDelete } = useSearchInput();

  const isInnerManual = selectedTab === '원내메뉴얼';
  const isContents = selectedTab === '콘텐츠';

  return (
    <div
      className='mb-10 flex h-full flex-col'
      // style={{ zIndex: Z_INDEXS.ChattingList }}
    >
      <div className='flex flex-row justify-between px-20 pt-20 pb-8'>
        <span className='text-Header16'>상담 백과사전</span>
        <span />
      </div>
      <div>
        <Tabs value={selectedTab} onChange={setSelectedTab}>
          {Object.entries(tabItemsMap).map(([key]) => (
            <ButtonTab className='border-b border-b-white400' value={key} label={key} key={key}>
              {key}
            </ButtonTab>
          ))}
        </Tabs>
        {isInnerManual && (
          <div className='mx-10 pt-10'>
            <SearchInput
              placeholder='태그명으로 검색'
              value={inputValue}
              onChange={handleChange}
              onKeyDown={handleKeyDown}
              onDelete={handleDelete}
            />
          </div>
        )}
        {isInnerManual && (
          <div className='h-[calc(100vh-130px)]'>
            <ManualList keyword={keyword} />
          </div>
        )}
        {isContents && (
          <div className='h-[calc(100vh-90px)]'>
            <ContentsList />
          </div>
        )}
      </div>
    </div>
  );
}
